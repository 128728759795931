import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IForm } from "./useForm";
import getLocales from "src/components/i18n/getLocales";
import getLocaleName from "src/components/i18n/getLocaleName";
import { TextOptionProps } from "src/components/Form/FormOptions";

export interface ITranslation {
  culture?: string;
}

export interface ITranslatable<TTranslation extends ITranslation> {
  translations?: TTranslation[];
}

export interface ITranslatedForm<TTranslation> {
  culture: string;
  setCulture: (culture: string) => void;
  cultures: TextOptionProps[];

  translation?: TTranslation | undefined;
  translationIndex?: number | undefined;

  getTranslationFieldName: (fieldName: string, prefix?: string) => string;
}

const useTranslationForm = <TData extends ITranslatable<TTranslation>, TTranslation extends ITranslation>(form: IForm<TData>): ITranslatedForm<TTranslation> => {
  const { i18n } = useTranslation();

  const [culture, setCulture] = useState(i18n.resolvedLanguage ?? "en");
  const translation = form.data?.translations?.find(t => t.culture === culture);
  const translationIndex = form?.data?.translations?.findIndex(t => t.culture === culture);
  const cultures = getLocales().map(culture => ({
    id: culture,
    value: culture,
    label: getLocaleName(culture)
  }) as TextOptionProps);

  useEffect(() => {
    if (!form.data?.translations?.some(t => t.culture === culture)) {
      form.setData({ ...form.data, translations: [...form.data?.translations ?? [], form.data?.translations?.length ? { ...form.data.translations[0], culture: culture } : { culture }] } as TData);
    }
  }, [culture]);

  useEffect(() => {
    for (const key in form.validationData) {
      if (key.includes('translations.')) {
        const found = key.match(/translations\.([0-9]+)/g);
        if (found && found?.length >= 0) {
          const invalidIndex = Number(found[0].replace('translations.', ''));
          const invalidTranslation = form.data.translations?.find((t, i) => i === invalidIndex);
          if (invalidTranslation && invalidTranslation.culture) {
            setCulture(invalidTranslation?.culture)
          }
        }
      }
    }
  }, [form.validationData]);

  const getTranslationFieldName = (name: string, prefix?: string) => `${prefix ? `${prefix}.` : ""}translations.${translationIndex}.${name}`;

  return {
    culture,
    setCulture,
    cultures,

    translation,
    translationIndex,

    getTranslationFieldName
  }
}

export default useTranslationForm;