import ReactGA from 'react-ga4';
import configuration from "src/config/config";
import * as CookieConsent from "vanilla-cookieconsent";

const useEventTracking = (category: string, action: string, label: string) => {
  return () => {
    if (configuration.integrations?.googleAnalyticsApiKey && CookieConsent.acceptedCategory('analytics')) {
      ReactGA.event({ category, action, label });
    }
  }
};

export default useEventTracking;