import { faTimes } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export function DialogCloseButton(props: DialogCloseButtonComponentProps) {
  const { onClick } = props;
  return <FontAwesomeIcon icon={faTimes} className="text-gray-500 cursor-pointer mt-1" onClick={onClick} />;
} export interface DialogCloseButtonComponentProps {
  onClick: () => void;
}

