import { useEffect, useState } from "react";
import { ClientGalleriesClient, Content, ContentTranslation } from "src/api/cms/Cms";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import Spinner from "../Feedback/Spinner";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import ContentParse from "./ContentParse";

export interface GalleryComponentProps {
  id?: string;
  url?: string;
  title?: boolean;
  content?: boolean;
  raw?: boolean;
}

export interface GalleryElementComponentProps {
  gallery: Content;
}

const GalleryHeader = (props: GalleryElementComponentProps) => {
  const { gallery } = props;
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(gallery);
  return (
    <div className="border-b border-gray-200 pb-5 mt-12 mb-5">
      <h3 className="text-2xl font-medium leading-6 text-gray-900">{translation?.title}</h3>
    </div>
  );
}

const GalleryContent = (props: GalleryElementComponentProps) => {
  const { gallery } = props;
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(gallery);

  return <ContentParse>{translation?.content}</ContentParse>;
}

const Gallery = (props: GalleryComponentProps) => {
  const { id, url, title, content, raw } = props;
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientGalleriesClient(apiConfiguration);

  const [loading, setLoading] = useState(false);
  const [gallery, setGallery] = useState<Content | undefined>(undefined);

  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(gallery);

  useEffect(() => {
    if (id) {
      setLoading(true);
      apiClient.findById(id)
        .then(response => setGallery(response))
        .catch(console.error)
        .finally(() => setLoading(false));
    } else if (url) {
      setLoading(true);
      apiClient.findByUrl(url)
        .then(response => setGallery(response))
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [id, url])

  if (!id && !url) {
    return <><span>You must reference exactly one identifier to find.</span></>
  }
  if (!title && !content) {
    return <><span>You must select at least one thing to render.</span></>
  }

  if (loading) {
    return <Spinner className="mx-auto h-4" />;
  }

  if (!gallery) {
    return <><span>Gallery not found.</span></>;
  }

  if (title && content) {
    if (raw) {
      return <><span>Raw gallery title and content is not available.</span></>
    } else {
      return (
        <>
          <GalleryHeader gallery={gallery} />
          <GalleryContent gallery={gallery} />
        </>
      );
    }
  } else if (title) {
    if (raw) {
      return <>{translation?.title?.replace(/<[^>]+>/g, '') ?? ""}</>;
    } else {
      return <GalleryHeader gallery={gallery} />;
    }
  } else if (content) {
    if (raw) {
      return <>{translation?.content?.replace(/<[^>]+>/g, '') ?? ""}</>;
    } else {
      return <GalleryContent gallery={gallery} />;
    }
  }
  return <><span>Cannot render.</span></>
}

export default Gallery;