import { useEffect } from "react";
import { ConfigurationApis, getApiBaseUrl } from "src/config/config";
import signalR, { useSignalRHub } from "use-signalr-hub"

interface AuthenticationSignalRClientProps {
  fetchUser: () => void;
  signOut: () => void;
  token: string;
}

export default (props: AuthenticationSignalRClientProps) => {
  const { token, fetchUser, signOut } = props;

  const signalRCallbacks = {
    onDelete: (_id: string) => signOut(),
    onUpdate: (_id: string) => fetchUser(),
  };

  signalR.setDefaults({
    httpTransportTypeOrOptions: { accessTokenFactory: () => token },
    automaticReconnect: false
  });

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Auth)}/hubs/AuthHub`);

  useEffect(() => {
    if (signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
    }
  }, [signalRHub]);

  return <></>;
}
