import Button from "src/components/Actions/Button";
import { WizardStepProps } from "./Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/pro-duotone-svg-icons";

export default (props: WizardStepProps) => {
  const { nextStep } = props;
  return (
    <>
      <h3 className="text-sm">Cześć,</h3>
      <p className="text-sm text-gray-600">
        W celu jak największej optymalizacji czasu i zasobów przygotowaliśmy dla Ciebie intuicyjny konfigurator, który poprowadzi Cię przez wszystkie najważniejsze kroki, aby utworzyć stronę dla Twojej stajni.
        Wszystkie te informacje będziesz mógł w każdej chwili edytować w panelu sterowania na stronie docelowej.
        Skorzystanie z konfiguratora nie jest obowiązkowe, ale zalecane - pozwala znacząco przyspieszyć i ułatwić proces tworzenia strony.
        Na początku ustawimy zabezpieczenia dla Twojego konta, a następnie spersonalizujemy stronę Twojej stajni i cały system rezerwacyjny.
      </p>
      <p className="text-sm text-gray-600 py-4 font-medium">Jakich informacji potrzebujemy?</p>
      <ul className="list-disc list-inside pl-3 text-gray-600 text-sm">
        <li>Tymczasowe hasło, które zostało wysłane na maila,</li>
        <li>Nowe hasło, którym będziesz się logował do Twojego konta,</li>
        <li>Kilka informacji o preferencjach rezerwacji w Twojej stajni,</li>
        <li>Kilka decyzji na temat wyglądu strony,</li>
        <li>Imiona koni,</li>
        <li>Imiona instruktorów,</li>
        <li>Miejsca, w których odbywają się treningi,</li>
        <li>Rodzaje treningów,</li>
        <li>Konta innych administratorów.</li>
      </ul>
      <p className="text-sm text-gray-600 py-4">Wszystkie te dane będziesz mógł później edytować w swoim panelu.</p>
      <p className="text-sm text-gray-600 py-4">Szacowany czas konfiguracji: <u>15-30 minut</u>. Czas jest zależny od ilości danych, które będą wprowadzone.</p>
      <div className="text-end border-t border-gray-100 pt-4 mt-8">
        <Button colorName="primary" className="px-5 py-3" onClick={nextStep}>
          <FontAwesomeIcon icon={faChevronCircleRight} className="mr-3" />
          Przejdź dalej
        </Button>
      </div>
    </>
  );
};
