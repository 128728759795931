import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import FormInput from 'src/components/Form/FormInput'
import { IForm } from 'src/hooks/useForm';
import { UserForm } from '../Form';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';

export interface PasswordFormComponentProps {
  form: IForm<UserForm>;
}

export default function PasswordForm(props: PasswordFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();

  if (form.data.email === 'developer@localhost') return <></>;

  return (
    <div className="md:flex md:flex-row md:mt-5 pb-5 border-b border-gray-200">
      <div className="md:w-2/3 md:px-4 pt-5 md:pt-10 sm:px-0">
        <h3 className="text-base font-medium leading-7 text-gray-900">{t('users.form.password.header')}</h3>
        <div className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{t('users.form.password.subheader')}</div>
      </div>
      <div className="md:w-2/3 md:px-4 pt-5 md:pt-10 md:border-l border-gray-100">
        <div className="mb-10">
          <FormInput.Default {...form.input("password", "password", { placeholder: t('auth.fields.newPassword'), autoComplete: 'off' })} icon={<FontAwesomeIcon icon={faLock} className="h-5 w-5 text-gray-400" aria-hidden="true" />} />
        </div>
        <div className="mb-10">
          <FormInput.Default {...form.input("repeatPassword", "password", { placeholder: t('auth.fields.repeatPassword'), autoComplete: 'off' })} icon={<FontAwesomeIcon icon={faLockOpen} className="h-5 w-5 text-gray-400" aria-hidden="true" />} />
        </div>
      </div>
      <div className="md:w-2/3 md:px-4 pt-5 md:pt-10">
      </div>
    </div>
  )
}