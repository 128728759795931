import ReactGA from 'react-ga4';
import configuration from "src/config/config";
import * as CookieConsent from "vanilla-cookieconsent";

const useCommonEventTracking = () => {
  const trackEvent = (category: string, action: string, label: string) => {
    if (configuration.integrations?.googleAnalyticsApiKey && CookieConsent.acceptedCategory('analytics')) {
      ReactGA.event({ category, action, label });
    }
  }
  return {
    trackEvent
  }
};

export default useCommonEventTracking;