import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Tabs, { Tab } from "src/components/Tabs/Pills";
import AgendaToday from "./AgendaSections/Today";
import AgendaWeek from "./AgendaSections/Week";
import { StableUser, StableUsersClient } from "src/api/stable/Stable";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import Alert from "src/components/Feedback/Alert";

const AgendaView = () => {
  const { t } = useTranslation();
  const [user, setUser] = useState<StableUser | undefined>();
  const [error, setError] = useState(false);
  const apiConfiguration = useApiConfiguration();
  const apiClient = new StableUsersClient(apiConfiguration);

  const fetchUser = () => {
    apiClient.me()
      .then(response => setUser(response))
      .catch(() => setError(true));
  }

  useEffect(() => {
    fetchUser();
  }, []);

  const tabs: Tab[] = [
    { id: 'today', name: t('booking.events.filters.today'), visible: false },
    { id: 'week', name: t('booking.events.filters.thisWeek') },
  ]
  const [currentTab, setCurrentTab] = useState('week');


  return (
    <>
      <div className="mb-5">
        <h1 className="leading-1 text-2xl">{t('panel.settings.agenda')}</h1>
      </div>
      <Tabs tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} />
      {error && <Alert.Error title={t('common.status.error')}>{t('common.errors.403')}</Alert.Error>}
      {user && (
        <>
          <div className="my-6 py-6 border-y border-gray-100 text-sm leading-6">
            {currentTab == 'today' && <AgendaToday />}
            {currentTab == 'week' && <AgendaWeek user={user} />}
          </div>
        </>
      )}
    </>
  )
}

export default AgendaView;