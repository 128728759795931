import { FC, useEffect } from 'react';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Payment, PaymentRequest, PaymentRequestsClient } from 'src/api/financial/Accountancy';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import Header from './Header';
import GeneralForm from './Form/General';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import Button from 'src/components/Actions/Button';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import { AccountancyProvider } from 'src/store/accountancy/context';
import PaymentsForm from '../Shared/Payments';

const PaymentRequestsForm: FC = () => {
  const apiConfiguration = useApiConfiguration();

  const apiClient = new PaymentRequestsClient(apiConfiguration);
  const form = useForm<PaymentRequest>();
  const authGuard = useAuthGuard('/panel/', ['PaymentRequestsRead']);
  const paymentRequestsUpdateClaim = useClaim('PaymentRequestsUpdate');
  const { id } = useParams<string>();
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();

  const onDelete = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id === 'create') return;
    apiClient.delete(id)
      .finally(() => {
        form.setPending(false);
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        navigate(`/panel/accountancy/payment-requests/`)
      })
  }

  const onSubmit = () => {
    form.setPending(true);
    const data = { ...form.data } as PaymentRequest;
    delete data.price;
    delete data.valueObject;
    delete data.valuePaid;
    delete data.valueRemain;
    data.payments = [...data.payments || []].map(p => {
      const payment = { ...p } as Payment;
      delete payment.valueObject;
      delete payment.negativePrice;
      delete payment.negativeValueObject;
      delete payment.price;
      payment.value = Number(payment.value);
      return payment;
    });
    if (!data || !id) return;
    if (id !== 'create') {
      apiClient.update(id, data)
        .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    } else {
      apiClient.create(data)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.form.saved"));
          navigate(`/panel/accountancy/payment-requests/`);
        })
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    }
  }

  const fetch = () => {
    if (!id) return;
    apiClient.find(id)
      .then(form.setData)
      .catch(form.catchAnyException);
  }

  useEffect(() => {
    if (id !== 'create') fetch();
    applicationDispatch(
      setBreadcrumbs([
        { label: "accountancy.paymentRequests.group", href: "/panel/accountancy/payment-requests/" },
        { label: id !== 'create' ? 'common.actions.edit' : 'common.actions.createNew', href: `/panel/accountancy/payment-requests/${id}` }
      ])
    );
  }, [id]);

  useEffect(() => {
    form.setReadOnly(!paymentRequestsUpdateClaim);
  }, [paymentRequestsUpdateClaim])

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <AccountancyProvider>
        <form onSubmit={e => form.onSubmit(e, onSubmit)}>
          <Header paymentRequest={form.data} onDelete={onDelete} form={form} />
          <GeneralForm form={form} />
          <PaymentsForm form={form} />
          <div className="text-end mt-5">
            <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
              {t('common.actions.save')}
            </Button>
          </div>
        </form >
      </AccountancyProvider>
    </>
  )
}

export default PaymentRequestsForm;