import React, { FC, useReducer } from 'react';
import { applicationReducer } from './reducer';
import _ from 'lodash';
import { initialNotificationState, NotificationState } from './state';
import { NotificationActions } from './actions';
import NotificationSignalRWatch from './NotificationSignalRWatch';

export const NotificationContext = React.createContext<{
  state: NotificationState;
  dispatch: React.Dispatch<NotificationActions>;
}>({
  state: initialNotificationState,
  dispatch: () => undefined,
});

interface NotificationProviderProps {
  children: React.ReactNode;
}
export const NotificationProvider: FC<NotificationProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(
    applicationReducer,
    {
      ...initialNotificationState,
    }
  );

  return (
    <NotificationContext.Provider value={{ state, dispatch }}>
      <NotificationSignalRWatch />
      {children}
    </NotificationContext.Provider>
  );
};