import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Settings, SettingsClient } from "src/api/core/Core";
import Button from "src/components/Actions/Button";
import Alert from "src/components/Feedback/Alert";
import Toast from "src/components/Feedback/Toast";
import ColorSelect, { availableColors } from "src/components/Form/FormColorSelect";
import ImageSelect from "src/components/Form/FormImageSelect";
import FormSwitch from "src/components/Form/FormSwitch";
import configuration from "src/config/config";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useConfigurationDispatch from "src/hooks/useConfigurationDispatch";
import useConfigurationState from "src/hooks/useConfigurationState";

import { setConfiguration } from "src/store/configuration/actions";
import { Layout } from "src/store/configuration/state";

const GeneralPersonalizationSection = () => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const apiConfiguration = useApiConfiguration();
  const apiClient = new SettingsClient(apiConfiguration);
  const configurationState = useConfigurationState();
  const configurationDispatch = useConfigurationDispatch();

  const colors = availableColors.map(c => ({ id: c, color: c, label: c }));

  const layouts = [
    { id: String(Layout.Horizontal), value: Layout.Horizontal as Layout, image: '/img/layout/horizontal.webp' },
    { id: String(Layout.MapHorizontal), value: Layout.MapHorizontal as Layout, image: '/img/layout/map-horizontal.webp' },
    { id: String(Layout.PictureHorizontal), value: Layout.PictureHorizontal as Layout, image: '/img/layout/picture-horizontal.webp', disabled: !configuration.integrations?.googleMapsApiKey || !configuration.meta.address?.coordinates },
  ];

  const onSaveSuccess = () => {
    Toast.success(t("common.status.success"), t("common.feedback.saved"));
  }

  const onSaveFailure = () => {
    setError(true);
    Toast.error(t("common.status.error"), t("common.errors.500"));
  }

  const onSave = () => {
    setLoading(true);
    setError(false);
    apiClient.set('layout', undefined, { name: 'layout', value: configurationState.layout } as Settings)
      .catch(onSaveFailure)
      .then(onSaveSuccess)
      .finally(() => setLoading(false));
  }

  return (
    <>
      {error && <Alert.Error title={t('common.errors.500')} noClose />}
      <dl className="my-6 space-y-6 divide-y divide-gray-50 border-y border-gray-100 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('panel.settings.layout.primaryColor')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <ColorSelect options={colors} value={configurationState.layout.primaryColor} onChange={(e) => configurationDispatch(setConfiguration('layout.primaryColor', e.target.value))} />
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('panel.settings.layout.landing.header')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <div className="mb-5">
                <FormSwitch.Decorated
                  name="switch"
                  checked={configurationState.layout.landing.slider}
                  onChange={(e) => configurationDispatch(setConfiguration('layout.landing.slider', e.target.checked))}
                  placeholder={t('panel.settings.layout.landing.slider.title')}
                  aria-description={t('panel.settings.layout.landing.slider.description')}
                />
              </div>
              <div className="mb-5">
                <FormSwitch.Decorated
                  name="switch"
                  checked={configurationState.layout.landing.newsfeed}
                  onChange={(e) => configurationDispatch(setConfiguration('layout.landing.newsfeed', e.target.checked))}
                  placeholder={t('panel.settings.layout.landing.newsfeed.title')}
                  aria-description={t('panel.settings.layout.landing.newsfeed.description')}
                />
              </div>
              <div className="mb-5">
                <FormSwitch.Decorated
                  name="switch"
                  checked={configurationState.layout.landing.aboutUs}
                  onChange={(e) => configurationDispatch(setConfiguration('layout.landing.aboutUs', e.target.checked))}
                  placeholder={t('panel.settings.layout.landing.aboutUs.title')}
                  aria-description={t('panel.settings.layout.landing.aboutUs.description')}
                />
              </div>
              <div className="mb-5">
                <FormSwitch.Decorated
                  name="switch"
                  checked={configurationState.layout.landing.trainingTypes}
                  onChange={(e) => configurationDispatch(setConfiguration('layout.landing.trainingTypes', e.target.checked))}
                  placeholder={t('panel.settings.layout.landing.trainingTypes.title')}
                  aria-description={t('panel.settings.layout.landing.trainingTypes.description')}
                />
              </div>
              <div className="mb-5">
                <FormSwitch.Decorated
                  name="switch"
                  checked={configurationState.layout.landing.instructors}
                  onChange={(e) => configurationDispatch(setConfiguration('layout.landing.instructors', e.target.checked))}
                  placeholder={t('panel.settings.layout.landing.instructors.title')}
                  aria-description={t('panel.settings.layout.landing.instructors.description')}
                />
              </div>
              <div className="mb-5">
                <FormSwitch.Decorated
                  name="switch"
                  checked={configurationState.layout.landing.horses}
                  onChange={(e) => configurationDispatch(setConfiguration('layout.landing.horses', e.target.checked))}
                  placeholder={t('panel.settings.layout.landing.horses.title')}
                  aria-description={t('panel.settings.layout.landing.horses.description')}
                />
              </div>
            </div>
          </dd>
        </div>
        <div className="py-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('panel.settings.layout.contactPage')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <ImageSelect
              options={layouts.filter(l => l.disabled !== true)}
              value={Number(configurationState.layout.contactFormLayout)}
              onChange={(e) => { configurationDispatch(setConfiguration('layout.contactFormLayout', Number(e.target.value))); }}
            />
          </dd>
        </div>
      </dl>
      <div className="flex justify-end">
        <Button colorName="primary" onClick={onSave} disabled={loading} className="px-5 py-3 mt-8"><FontAwesomeIcon icon={faSave} className="h-5" /> {t('common.actions.save')}</Button>
      </div>
    </>
  );
}

export default GeneralPersonalizationSection;