import Button, { IButtonProps } from "../Actions/Button";


export function DialogButton(props: IButtonProps) {
  const { children, colorName, ...otherProps } = props;
  return (
    <Button type="button" colorName={colorName ?? "indigo"} {...otherProps}>
      {children}
    </Button>
  );
}

