import { FC, Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Outlet, useLocation } from 'react-router-dom';
import Breadcrumbs from '../../Navigation/Breadcrumbs';
import TenantMenu from './TenantMenu';
import LocaleMenu from './LocaleMenu';
import UserMenu from './UserMenu';
import classNames from 'src/components/Utilities/classNames';
import useUser from 'src/hooks/useUser';
import { useTranslation } from 'react-i18next';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import DeveloperMenu from './DeveloperMenu';
import Notifications from '../Front/Notifications';
import Footer from './Footer';
import configuration from 'src/config/config';
import { faAngleLeft, faAngleRight, faBell, faBellConcierge, faCamera, faChartBar, faHatCowboy, faHatCowboySide, faHome, faHorseHead, faKey, faLifeRing, faLinesLeaning, faPencil, faSearch, faTicket, faUser, faUsers, faWrench } from '@fortawesome/free-solid-svg-icons';
import { faBookUser, faCreditCardAlt, faDollarSign, faFarm, faFence, faFileInvoiceDollar, faFileInvoice, faHorseSaddle, faMoneyCheck, faUserTie, faUsd, faReply, faCalendarAlt, faPaintBrushAlt } from '@fortawesome/pro-duotone-svg-icons';
import ApplicationLogo from 'src/components/Branding/ApplicationLogo';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import MessageTopicsMenu from 'src/components/Messages/MessageTopicsMenu';
import useFunctionalities from 'src/hooks/stores/useFunctionalities';
import { LicenseProvider } from 'src/store/license/context';

interface INavigationItem extends INavigationChildren {
  icon: IconProp;
  childrens?: INavigationChildren[];
}
interface INavigationChildren {
  name: string;
  href: string;
  claims?: string[];
  functionalities?: string[];
  exact?: boolean;
}
interface INavigationSection {
  name: string;
  icon: IconProp;
  claims?: string[];
  functionalities?: string[];
  items: INavigationItem[];
}
type INavigation = INavigationSection[];

const definedNavigation: INavigation = [
  {
    name: 'common.form.general.header',
    icon: faHome,
    items: [
      {
        name: 'panel.dashboard',
        href: '/panel/',
        icon: faChartBar,
        claims: ["Panel"],
        exact: true
      },
      {
        name: 'panel.support.header',
        href: '/panel/support',
        icon: faLifeRing,
        claims: ["Support"]
      },
      {
        name: 'panel.settings.agenda',
        href: '/panel/agenda',
        icon: faCalendarAlt
      },
      {
        name: 'panel.settings.header',
        href: '/panel/settings',
        icon: faWrench,
        claims: ["SettingsUpdate"]
      },
      {
        name: 'panel.settings.layout.header',
        href: '/panel/personalization',
        icon: faPaintBrushAlt,
        claims: ["SettingsUpdate"]
      },
    ]
  },
  {
    name: 'stable.section.title',
    icon: faFarm,
    claims: [],
    items: [
      {
        name: 'stable.horses.group',
        href: '/panel/stable/horses',
        icon: faHorseHead,
        claims: ["HorsesRead"],
        childrens: [
          { name: 'stable.horses.group', href: '/panel/stable/horses/', claims: [] },
          { name: 'stable.horses.actions.create', href: '/panel/stable/horses/create', claims: ["HorsesUpdate"] },
          { name: 'stable.tasks.group', href: '/panel/stable/horses/tasks/', claims: ["TasksRead"] },
          { name: 'stable.tasks.actions.create', href: '/panel/stable/horses/tasks/create', claims: ["TasksUpdate"] },
        ]
      },
      {
        name: 'stable.instructors.group',
        href: '/panel/stable/instructors',
        icon: faHatCowboySide,
        claims: ["InstructorsRead"],
        childrens: [
          { name: 'stable.instructors.group', href: '/panel/stable/instructors/', claims: [] },
          { name: 'stable.instructors.actions.create', href: '/panel/stable/instructors/create', claims: ["InstructorsUpdate"] },
          { name: 'stable.tasks.group', href: '/panel/stable/workers/tasks/', claims: ["TasksRead"] },
          { name: 'stable.tasks.actions.create', href: '/panel/stable/workers/tasks/create', claims: ["TasksUpdate"] },
        ]
      },
      {
        name: 'stable.workers.group',
        href: '/panel/stable/workers',
        icon: faHatCowboySide,
        claims: ["WorkersRead"],
        childrens: [
          { name: 'stable.workers.group', href: '/panel/stable/workers/', claims: [] },
          { name: 'stable.workers.actions.create', href: '/panel/stable/workers/create', claims: ["WorkersUpdate"] },
          { name: 'stable.tasks.group', href: '/panel/stable/workers/tasks/', claims: ["TasksRead"] },
          { name: 'stable.tasks.actions.create', href: '/panel/stable/workers/tasks/create', claims: ["TasksUpdate"] },
        ]
      },
      {
        name: 'stable.places.group',
        href: '/panel/stable/places',
        icon: faFence,
        claims: ["PlacesRead"],
        childrens: [
          { name: 'stable.places.group', href: '/panel/stable/places/', claims: [] },
          { name: 'stable.places.actions.create', href: '/panel/stable/places/create', claims: ["PlacesUpdate"] }
        ]
      },
      {
        name: 'stable.trainingTypes.group',
        href: '/panel/stable/training-types',
        icon: faHorseSaddle,
        claims: ["TrainingTypesRead"],
        childrens: [
          { name: 'stable.trainingTypes.group', href: '/panel/stable/training-types/', claims: [] },
          { name: 'stable.trainingTypes.actions.create', href: '/panel/stable/training-types/create', claims: ["TrainingTypesUpdate"] },
        ]
      },
      {
        name: 'stable.riders.group',
        href: '/panel/stable/riders',
        icon: faHatCowboy,
        claims: ["RidersRead"],
        childrens: [
          { name: 'stable.riders.group', href: '/panel/stable/riders/', claims: [] },
          { name: 'stable.riders.actions.create', href: '/panel/stable/riders/create', claims: ["RidersUpdate"] }
        ]
      },
      {
        name: 'stable.trainings.group',
        href: '/panel/booking/trainings',
        icon: faHorseSaddle,
        claims: ["TrainingsRead"],
        childrens: [
          { name: 'stable.trainings.group', href: '/panel/booking/trainings/', claims: [] },
          { name: 'stable.trainings.mine', href: '/panel/booking/trainings/#mine', claims: [], exact: true },
          { name: 'stable.trainings.confirmed', href: '/panel/booking/trainings/#confirmed', claims: [], exact: true },
          { name: 'stable.trainings.notConfirmed', href: '/panel/booking/trainings/#not-confirmed', claims: [], exact: true },
          //{ name: 'booking.events.filters.today', href: '/panel/booking/trainings/create', claims: ["TrainingsUpdate"] },
          //{ name: 'booking.events.filters.upcoming', href: '/panel/booking/trainings/', claims: [] },
          //{ name: 'booking.events.filters.archive', href: '/panel/booking/trainings/create', claims: ["TrainingsUpdate"] },
          { name: 'stable.trainings.actions.create', href: '/panel/booking/trainings/create', claims: ["TrainingsUpdate"] }
        ]
      },
      {
        name: 'stable.coupons.group',
        href: '/panel/booking/coupons',
        icon: faTicket,
        claims: ["CouponsRead"],
        functionalities: ["module-payments"],
        childrens: [
          { name: 'stable.coupons.group', href: '/panel/booking/coupons/', claims: [] },
          { name: 'stable.coupons.actions.create', href: '/panel/booking/coupons/create', claims: ["CouponsUpdate"] },
        ]
      },
      {
        name: 'stable.userCoupons.group',
        href: '/panel/booking/user-coupons',
        icon: faTicket,
        claims: ["CouponsRead"],
        functionalities: ["module-payments"],
        childrens: [
          { name: 'stable.userCoupons.group', href: '/panel/booking/user-coupons/', claims: [] },
          { name: 'stable.userCoupons.actions.create', href: '/panel/booking/user-coupons/create', claims: ["CouponsUpdate"] },
        ]
      },
    ]
  },
  {
    name: 'accountancy.section',
    icon: faDollarSign,
    claims: [],
    functionalities: ["module-payments", "module-accountancy"],
    items: [
      {
        name: 'accountancy.paymentMethods.group',
        href: '/panel/accountancy/payment-methods',
        icon: faCreditCardAlt,
        claims: ["PaymentMethodsRead"],
        functionalities: ["module-payments"],
        childrens: [
          { name: 'accountancy.paymentMethods.group', href: '/panel/accountancy/payment-methods/', claims: [] },
          { name: 'accountancy.paymentMethods.actions.create', href: '/panel/accountancy/payment-methods/create', claims: ["PaymentMethodsUpdate"] },
        ]
      },
      {
        name: 'accountancy.invoiceNumberings.group',
        href: '/panel/accountancy/invoice-numberings',
        icon: faFileInvoice,
        claims: ["InvoiceNumberingsRead"],
        functionalities: ["module-accountancy"],
        childrens: [
          { name: 'accountancy.invoiceNumberings.group', href: '/panel/accountancy/invoice-numberings/', claims: [] },
          { name: 'accountancy.invoiceNumberings.actions.create', href: '/panel/accountancy/invoice-numberings/create', claims: ["InvoiceNumberingsUpdate"] },
        ]
      },
      {
        name: 'accountancy.currencies.group',
        href: '/panel/accountancy/currencies',
        icon: faUsd,
        claims: ["CurrenciesRead"],
        functionalities: ["module-accountancy"],
        childrens: [
          { name: 'accountancy.currencies.group', href: '/panel/accountancy/currencies/', claims: [] },
          { name: 'accountancy.currencies.actions.create', href: '/panel/accountancy/currencies/create', claims: ["CurrenciesUpdate"] },
        ]
      },
      {
        name: 'accountancy.sellers.group',
        href: '/panel/accountancy/sellers',
        icon: faUserTie,
        claims: ["SellersRead"],
        functionalities: ["module-accountancy"],
        childrens: [
          { name: 'accountancy.sellers.group', href: '/panel/accountancy/sellers/', claims: [] },
          { name: 'accountancy.sellers.actions.create', href: '/panel/accountancy/sellers/create', claims: ["SellersUpdate"] },
        ]
      },
      {
        name: 'accountancy.buyers.group',
        href: '/panel/accountancy/buyers',
        icon: faBookUser,
        claims: ["BuyersRead"],
        functionalities: ["module-accountancy"],
        childrens: [
          { name: 'accountancy.buyers.group', href: '/panel/accountancy/buyers/', claims: [] },
          { name: 'accountancy.buyers.actions.create', href: '/panel/accountancy/buyers/create', claims: ["BuyersUpdate"] },
        ]
      },
      {
        name: 'accountancy.invoices.group',
        href: '/panel/accountancy/invoices',
        icon: faFileInvoiceDollar,
        claims: ["InvoicesRead"],
        functionalities: ["module-accountancy"],
        childrens: [
          { name: 'accountancy.invoices.group', href: '/panel/accountancy/invoices/', claims: [] },
          { name: 'accountancy.invoices.actions.create', href: '/panel/accountancy/invoices/create', claims: ["InvoicesUpdate"] },
        ]
      },
      {
        name: 'accountancy.paymentRequests.group',
        href: '/panel/accountancy/payment-requests',
        icon: faMoneyCheck,
        claims: ["PaymentRequestsRead"],
        functionalities: ["module-accountancy"],
        childrens: [
          { name: 'accountancy.paymentRequests.group', href: '/panel/accountancy/payment-requests/', claims: [] },
          { name: 'accountancy.paymentRequests.actions.create', href: '/panel/accountancy/payment-requests/create', claims: ["PaymentRequestsUpdate"] },
        ]
      }
    ]
  },
  {
    name: 'cms.section',
    icon: faPencil,
    claims: [],
    items: [
      /*{
        name: 'cms.help.pages.group',
        href: '/panel/content/help',
        icon: faFilePlus,
        claims: ["HelpPagesRead"],
        childrens: [
          { name: 'cms.help.pages.group', href: '/panel/content/help/pages/', claims: [] },
          { name: 'cms.help.pages.actions.create', href: '/panel/content/help/pages/create', claims: ["HelpPagesUpdate"] },
          { name: 'cms.common.categories.group', href: '/panel/content/help/categories/', claims: [] },
          { name: 'cms.common.categories.actions.create', href: '/panel/content/help/categories/create', claims: ["HelpPagesUpdate"] }
        ]
      },
      {
        name: 'cms.pages.pages.group',
        href: '/panel/content/pages',
        icon: faFileText,
        claims: ["PagesRead"],
        childrens: [
          { name: 'cms.pages.pages.group', href: '/panel/content/pages/pages/', claims: [] },
          { name: 'cms.pages.pages.actions.create', href: '/panel/content/pages/pages/create', claims: ["PagesUpdate"] },
        ]
      },*/
      {
        name: 'cms.newsfeed.posts.group',
        href: '/panel/content/newsfeed',
        icon: faLinesLeaning,
        claims: ["NewsfeedRead"],
        childrens: [
          { name: 'cms.newsfeed.posts.group', href: '/panel/content/newsfeed/posts/', claims: [] },
          { name: 'cms.newsfeed.posts.actions.create', href: '/panel/content/newsfeed/posts/create', claims: ["NewsfeedUpdate"] },
          { name: 'cms.common.categories.group', href: '/panel/content/newsfeed/categories/', claims: [] },
          { name: 'cms.common.categories.actions.create', href: '/panel/content/newsfeed/categories/create', claims: ["NewsfeedUpdate"] }
        ]
      },
      {
        name: 'cms.galleries.galleries.group',
        href: '/panel/content/galleries',
        icon: faCamera,
        claims: ["GalleriesRead"],
        childrens: [
          { name: 'cms.galleries.galleries.group', href: '/panel/content/galleries/galleries/', claims: [] },
          { name: 'cms.galleries.galleries.actions.create', href: '/panel/content/galleries/galleries/create', claims: ["GalleriesUpdate"] },
          { name: 'cms.common.categories.group', href: '/panel/content/galleries/categories/', claims: [] },
          { name: 'cms.common.categories.actions.create', href: '/panel/content/galleries/categories/create', claims: ["GalleriesUpdate"] }
        ]
      },
    ]
  },
  {
    name: 'auth.section',
    icon: faKey,
    claims: [],
    items: [
      {
        name: 'users.group',
        href: '/panel/users',
        icon: faUser,
        claims: ["UsersRead"],
        childrens: [
          { name: 'users.group', href: '/panel/users/', claims: ["UsersRead"] },
          { name: 'users.actions.create', href: '/panel/users/create', claims: ["UsersCreate"] }
        ]
      },
      {
        name: 'roles.group',
        href: '/panel/roles',
        icon: faUsers,
        claims: ["RolesRead"],
        childrens: [
          { name: 'roles.group', href: '/panel/roles/', claims: ["RolesRead"] },
          { name: 'roles.actions.create', href: '/panel/roles/create', claims: ["RolesCreate"] }
        ]
      },
    ]
  },
  {
    name: 'notifications.notifications.group',
    icon: faBell,
    claims: [],
    items: [
      {
        name: 'notifications.broadcasters.group',
        href: '/panel/notifications/broadcasters/',
        icon: faBell,
        claims: ["NotificationBroadcastersRead"],
        childrens: [
          { name: 'notifications.broadcasters.group', href: '/panel/notifications/broadcasters/', claims: ["NotificationBroadcastersRead"] },
          { name: 'notifications.broadcasters.actions.create', href: '/panel/notifications/broadcasters/create', claims: ["NotificationBroadcastersCreate"] }
        ]
      },
      {
        name: 'notifications.types.group',
        href: '/panel/notifications/types/',
        icon: faBellConcierge,
        claims: ["NotificationTypesRead"],
        childrens: [
          { name: 'notifications.types.group', href: '/panel/notifications/types/', claims: ["NotificationTypesRead"] },
          { name: 'notifications.types.actions.create', href: '/panel/notifications/types/create', claims: ["NotificationTypesCreate"] }
        ]
      },
    ]
  }
];

interface NavigationChildrenComponentProps {
  child: INavigationChildren;
}
interface NavigationItemComponentProps {
  item: INavigationItem;
}
interface NavigationSectionComponentProps {
  section: INavigationSection;
}
interface NavigationComponentProps {
  items: INavigation;
}

const MobileNavigationChildren = (props: NavigationChildrenComponentProps) => {
  const { child } = props;
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const active = pathname.substring(3) === child.href;
  const licenseFunctionalities = useFunctionalities();
  const user = useUser();

  if ((child.functionalities ?? []).length > 0 && !child.functionalities?.some(f => licenseFunctionalities?.includes(f))) {
    return <></>;
  }

  if (child.claims?.some(c => !user?.claims?.includes(c))) {
    return <></>;
  }

  return (
    <li key={child.name}>
      <LocalizedLink
        to={child.href}
        className={classNames(
          active
            ? 'text-[--color-primary-300]'
            : 'text-white hover:text-[--color-primary-200] hover:bg-gray-800',
          'group flex gap-x-3 rounded-md p-1 ml-12 text-sm leading-7'
        )}>
        {t(child.name)}
      </LocalizedLink>
    </li>
  )
}

const MobileNavigationItem = (props: NavigationItemComponentProps) => {
  const { item } = props;
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const active = item.exact ? pathname.substring(3) === item.href : pathname.substring(3).startsWith(item.href);
  const licenseFunctionalities = useFunctionalities();
  const user = useUser();

  if ((item.functionalities ?? []).length > 0 && !item.functionalities?.some(f => licenseFunctionalities?.includes(f))) {
    return <></>;
  }

  if (item.claims?.some(c => !user?.claims?.includes(c))) {
    return <></>;
  }

  return (
    <>
      <LocalizedLink
        to={item.href}
        className={classNames(
          active
            ? 'bg-[--color-primary-600] text-white'
            : 'text-white hover:text-[--color-primary-200] hover:bg-gray-800',
          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium'
        )}>
        <FontAwesomeIcon
          icon={item.icon}
          className={classNames(
            active ? 'text-white' : 'text-[--color-primary-600] group-hover:text-[--color-primary-600]',
            'h-6 w-6 shrink-0'
          )}
          aria-hidden="true"
        />
        {t(item.name)}
      </LocalizedLink>
      {active && item.childrens && <ul role="list" className="-mx-2 space-y-1">
        {item.childrens.map(child => <MobileNavigationChildren key={child.name} child={child} />)}
      </ul>
      }
    </>
  )
}

const MobileNavigationSection = (props: NavigationSectionComponentProps) => {
  const { section } = props;
  const { t } = useTranslation();
  const licenseFunctionalities = useFunctionalities();
  const user = useUser();

  if ((section.functionalities ?? []).length > 0 && !section.functionalities?.some(f => licenseFunctionalities?.includes(f))) {
    return <></>;
  }

  const claims = section.items.map(i => i.claims).flat().filter(c => c) as string[];
  if (!claims.some(c => user?.claims?.includes(c))) {
    return <></>;
  }

  return (
    <li key={section.name}>
      <h3 className="text-white border-b border-white/10 pb-3">
        <FontAwesomeIcon
          icon={section.icon} className={'text-white h-6 w-6 shrink-0 inline mr-3'} aria-hidden="true" />
        {t(section.name)}
      </h3>
      <div className="bg-black bg-opacity-20 rounded-b mb-5">
        {section.items.map(item => <MobileNavigationItem key={item.name} item={item} />)}
      </div>
    </li>
  )
}

const MobileNavigation = (props: NavigationComponentProps) => {
  const { items } = props;
  return (

    <nav className="flex flex-1 flex-col">
      <ul role="list" className="flex flex-1 flex-col gap-y-7">
        <li>
          <ul role="list" className="-mx-2 space-y-1">
            {items.map(item => <MobileNavigationSection key={item.name} section={item} />)}
          </ul>
        </li>
        <DeveloperMenu />
      </ul>
    </nav>
  )
}

const DesktopNavigationChildren = (props: NavigationChildrenComponentProps) => {
  const { child } = props;
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const active = pathname.substring(3) === child.href;
  const licenseFunctionalities = useFunctionalities();
  const user = useUser();

  if ((child.functionalities ?? []).length > 0 && !child.functionalities?.some(f => licenseFunctionalities?.includes(f))) {
    return <></>;
  }

  if (child.claims?.some(c => !user?.claims?.includes(c))) {
    return <></>;
  }

  return (
    <li key={child.name}>
      <LocalizedLink
        to={child.href}
        className={classNames(
          active
            ? 'text-[--color-primary-300]'
            : 'text-white hover:text-[--color-primary-200] hover:bg-gray-800',
          'group flex gap-x-3 rounded-md p-1 ml-12 text-sm leading-7'
        )}>
        {t(child.name)}
      </LocalizedLink>
    </li>
  )
}

const DesktopNavigationItem = (props: NavigationItemComponentProps) => {
  const { item } = props;
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const user = useUser();
  const active = item.exact ? pathname.substring(3) === item.href : pathname.substring(3).startsWith(item.href);
  const licenseFunctionalities = useFunctionalities();

  if ((item.functionalities ?? []).length > 0 && !item.functionalities?.some(f => licenseFunctionalities?.includes(f))) {
    return <></>;
  }

  if (item.claims?.some(c => !user?.claims?.includes(c))) {
    return <></>;
  }

  return (
    <>
      <LocalizedLink
        to={item.href}
        className={classNames(
          active
            ? 'bg-[--color-primary-600] text-white'
            : 'text-white hover:text-[--color-primary-200] hover:bg-gray-800',
          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium'
        )}>
        <FontAwesomeIcon
          icon={item.icon}
          className={classNames(
            active ? 'text-white' : 'text-[--color-primary-600] group-hover:text-[--color-primary-600]',
            'h-6 w-6 shrink-0'
          )}
          aria-hidden="true"
        />
        {t(item.name)}
      </LocalizedLink>
      {active && item.childrens && <ul role="list" className="-mx-2 space-y-1">
        {item.childrens.map(child => <DesktopNavigationChildren key={child.name} child={child} />)}
      </ul>
      }
    </>
  )
}

const DesktopNavigationSection = (props: NavigationSectionComponentProps) => {
  const { section } = props;
  const { t } = useTranslation();
  const user = useUser();
  const licenseFunctionalities = useFunctionalities();

  if ((section.functionalities ?? []).length > 0 && !section.functionalities?.some(f => licenseFunctionalities?.includes(f))) {
    return <></>;
  }

  const claims = section.items.map(i => i.claims).flat().filter(c => c) as string[];
  if (!claims.some(c => user?.claims?.includes(c))) {
    return <></>;
  }

  return (
    <li key={section.name}>
      <h3 className="text-white border-b border-white/10 pb-3">
        <FontAwesomeIcon
          icon={section.icon} className={'text-white h-6 w-6 shrink-0 inline mr-3'} aria-hidden="true" />
        {t(section.name)}
      </h3>
      <div className="bg-black bg-opacity-20 rounded-b mb-5">
        {section.items.map(item => <DesktopNavigationItem key={item.name} item={item} />)}
      </div>
    </li>
  )
}

const DesktopNavigation = (props: NavigationComponentProps) => {
  const { items } = props;
  return (

    <nav className="flex flex-1 flex-col">
      <ul role="list" className="flex flex-1 flex-col gap-y-7">
        <li>
          <ul role="list" className="-mx-2 space-y-1">
            {items.map(item => <DesktopNavigationSection key={item.name} section={item} />)}
          </ul>
        </li>
        <DeveloperMenu />
      </ul>
    </nav>
  )
}

const PanelLayout: FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const user = useUser();
  const { t } = useTranslation();
  const navigation = definedNavigation.filter(n => !n.claims || !n.claims.some(c => !user?.claims?.includes(c)));
  const authGuard = useAuthGuard('/', ['Panel']);

  if (authGuard === undefined) return <AuthGuardLoading />;

  return (
    <LicenseProvider>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex ">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <FontAwesomeIcon icon={faAngleLeft} className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <h3 className="text-white text-3xl">
                        <ApplicationLogo className="inline h-8 mb-1 mr-3 w-auto fill-[--color-primary-600] hover:fill-[--color-primary-500]" />
                        Redini
                      </h3>
                    </div>
                    <MobileNavigation items={navigation} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-[--color-primary-200] bg-gray-900 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
              <h1 className="text-white leading-6 text-2xl mt-5">
                <ApplicationLogo className="inline h-8 w-auto mb-1 mr-5 fill-[--color-primary-600]" />
                Redini
                <span className="text-xs text-gray-600 ml-5">v. {configuration.version}</span>
              </h1>
            </div>
            <LocalizedLink to="/" className="text-white hover:text-[--color-primary-200] hover:bg-gray-800 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium">
              <FontAwesomeIcon
                icon={faReply}
                className="h-6 w-6 shrink-0 text-[--color-primary-600] group-hover:text-[--color-primary-600]"
                aria-hidden="true"
              />
              {t('plans.features.frontPage')}
            </LocalizedLink>
            <DesktopNavigation items={navigation} />
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 lg:mx-auto lg:px-8">
            <div className="flex h-16 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <FontAwesomeIcon icon={faAngleRight} className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Separator */}
              <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

              <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                <form className="relative flex flex-1" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <FontAwesomeIcon icon={faSearch}
                    className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <input
                    id="search-field"
                    className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    type="search"
                    name="search"
                  />
                </form>
                <div className="flex items-center">

                  <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />
                  <Notifications />
                  <MessageTopicsMenu />
                  <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200 mr-3" aria-hidden="true" />
                  <LocaleMenu />
                  <TenantMenu />
                  <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200 mx-3" aria-hidden="true" />

                  <UserMenu />
                </div>
              </div>
            </div>
          </div>
          <div className="lg:mx-auto lg:px-8 pb-5">
            <div className="border-b border-gray-100 bg-white px-4 py-5">
              <Breadcrumbs />
            </div>
          </div>
          <main className="lg:mx-auto lg:px-8 pb-5">
            <div className="mx-auto px-4 relative">
              <Outlet />
              <Footer />
            </div>
          </main>
        </div>
      </div >
    </LicenseProvider>
  )
}

export default PanelLayout;