import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Tabs, { Tab } from "src/components/Tabs/Pills";
import GeneralSettingsSection from "./SettingsSections/General";
import BookingSettingsSection from "./SettingsSections/Booking";
import SocialSettingsSection from "./SettingsSections/Social";
import DocumentsSettingsSection from "./SettingsSections/Documents";
import QrSettingsSection from "./SettingsSections/QR";


const SettingsView = () => {
  const { t } = useTranslation();
  const tabs: Tab[] = [
    { id: 'general', name: 'panel.settings.general.header' },
    { id: 'booking', name: 'panel.settings.booking.header' },
    { id: 'documents', name: 'panel.settings.documents.header' },
    { id: 'social', name: 'panel.settings.social.header' },
    { id: 'qr', name: 'panel.settings.qr.header' },
  ]
  const [currentTab, setCurrentTab] = useState('');

  return (
    <>
      <div className="mb-5">
        <h1 className="leading-1 text-2xl">{t('panel.settings.header')}</h1>
      </div>
      <Tabs tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} />
      {currentTab === 'general' && <GeneralSettingsSection />}
      {currentTab === 'booking' && <BookingSettingsSection />}
      {currentTab === 'documents' && <DocumentsSettingsSection />}
      {currentTab === 'social' && <SocialSettingsSection />}
      {currentTab === 'qr' && <QrSettingsSection />}
    </>
  )
}

export default SettingsView;