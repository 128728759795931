import { ChangeEvent, Fragment, SelectHTMLAttributes, createRef, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import simulateChangeEvent from './simulateChangeEvent';
import { useTranslation } from 'react-i18next';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { OptionLabel, OptionProps, SelectOption } from './FormOptions';
import FormRequired from './FormRequired';

interface SelectComponentProps extends SelectHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  options: OptionProps[];
}

const Select = (props: SelectComponentProps) => {
  const { id, required, value, placeholder, options, multiple, className, onChange } = props;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const [val, setVal] = useState<string | undefined>((!multiple ? String(value) : "") || "");
  const [multipleVal, setMultipleVal] = useState<string[] | undefined>(multiple && Array.isArray(value) ? [...value] : []);
  const inputRef = createRef<HTMLInputElement>();
  const description = props["aria-description"] ?? "";

  useEffect(() => {
    if (multiple) return;
    if (val === value) return;
    simulateChangeEvent(inputRef?.current, val);
    if (open) {
      setOpen(false);
    }
  }, [val]);

  useEffect(() => {
    if (!multiple) return;
    simulateChangeEvent(inputRef?.current, multipleVal);
  }, [multipleVal]);

  useEffect(() => {
    if (multiple) {
      setMultipleVal(Array.isArray(value) ? value : [] || []);
    } else {
      if (value !== val) {
        setVal(String(value) || "");
      }
    }
  }, [value]);

  const onChangeMiddleware = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange)
      onChange(e);
  }

  const onClickOption = (clickedValue: string) => {
    if (multiple) {
      if (multipleVal?.some(v => v === clickedValue)) {
        setMultipleVal([...multipleVal.filter(v => v !== clickedValue)]);
      } else {
        setMultipleVal([...multipleVal || [], clickedValue]);
      }
    } else {
      setVal(clickedValue);
    }
  }

  const selectedOption = !multiple && options?.find(o => String(o.value) === val);

  return (
    <Listbox value={val} onChange={setVal}>
      {placeholder && <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">{placeholder}<FormRequired required={required} /></Listbox.Label>}
      {description && <div id="comments-description" className="text-gray-500 text-xs font-normal">{description}</div>}
      <div className="relative mt-2">
        <Listbox.Button
          onClick={() => setOpen(!open)}
          className={`relative cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ${className?.includes('ring-0') ? '' : 'ring-1'} ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-[--color-primary-600] sm:text-sm sm:leading-6 w-full ${className}`}>
          {!multiple && <span className="ml-3 text-base block truncate">{(selectedOption && <OptionLabel {...selectedOption} />) || t("ui.select.pleaseSelect")}</span>}
          {multiple && <span className="ml-3 text-base block truncate">{multipleVal?.length && t("ui.select.selectedItems", { count: multipleVal.length }) || t("ui.select.pleaseSelect")}</span>}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <FontAwesomeIcon icon={faAngleDown} className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          show={open}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-20 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options?.map((option, i) => <SelectOption key={i} {...option} onClick={() => onClickOption(option.value)} selected={multiple ? multipleVal?.includes(option.value) : val === option.value} />)}
          </Listbox.Options>
        </Transition>
        <input ref={inputRef} id={id} name={id} value={value} onChange={onChangeMiddleware} title={placeholder} className="hidden" />
      </div>
    </Listbox>
  )
}

export default Select;