import _ from "lodash";
import useLicenseState from "./useLicenseState"


export default (functionality: string) => {
  const state = useLicenseState();

  if (!state.info?.id) return true;
  const functionalities = _.pickBy(state.info?.products, function (value, key) {
    return key === functionality && value === true;
  });

  return _.size(functionalities) > 0;
}