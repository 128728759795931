import { LicenseInfo } from "src/api/licensing/Licensing";

export enum ActionType {
    SetLicenseInfo
}

export interface SetLicenseInfo {
    type: ActionType.SetLicenseInfo;
    payload: LicenseInfo;
}

export const setLicense = (info: LicenseInfo): SetLicenseInfo => ({
    type: ActionType.SetLicenseInfo,
    payload: info,
});

export type LicenseActions = SetLicenseInfo;