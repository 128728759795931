import _ from 'lodash';
import { Dictionary } from 'lodash';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import ComboBox from 'src/components/Form/FormComboBox';
import { TextOptionProps } from 'src/components/Form/FormOptions';
import getCountryName from './getCountryName';
import { useTranslation } from 'react-i18next';
import getCountryCodes from './getCountries';

export interface CountrySelectComponentProps {
  onChange: ((id: string | Dictionary<string | undefined>, entity?: string) => void);
  value?: string | string[];
  multiple?: boolean;
  placeholder?: string;
}

const CountrySelect = (props: CountrySelectComponentProps) => {
  const { onChange, value, multiple, ...otherProps } = props;
  const { i18n } = useTranslation();
  const [results, setResults] = useState<TextOptionProps[]>([]);
  const autocompleteTimeout = useRef<(NodeJS.Timeout) | undefined>(undefined);

  const translateToOption = (countryCode: string): TextOptionProps => ({
    id: countryCode,
    value: countryCode.toLocaleLowerCase(),
    label: getCountryName(countryCode, i18n.resolvedLanguage) || countryCode
  });

  useEffect(() => {
    applyAutocomplete('');
  }, []);

  const onChangeMiddleware: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value;
    onChange(newValue, results.find(e => e.id === newValue)?.id);
  };
  const onChangeMiddlewareMultiple: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValues = e.target.value.split(',').filter(v => v.length > 0);
    onChange(
      _.chain(newValues.map(v => ({ id: v, value: results.find(e => e.id === v)?.id })))
        .keyBy('id')
        .mapValues('value')
        .value()
    );
  };
  const applyAutocomplete = (query: string) => {
    if (query === '') {
      return setResults(
        [...getCountryCodes()]
          .map(translateToOption)
      );
    }
    setResults(
      [...getCountryCodes()]
        .map(translateToOption)
        .filter(option => option.label.toLowerCase().includes(query.toLowerCase()) || option.id.toLowerCase().includes(query.toLowerCase()))
    );
  };
  const onAutoComplete = (query: string) => {
    if (autocompleteTimeout.current) {
      clearTimeout(autocompleteTimeout.current);
      autocompleteTimeout.current = undefined;
    }
    autocompleteTimeout.current = setTimeout(() => applyAutocomplete(query), 250);
  };

  return (
    <ComboBox options={results} value={value} onChange={multiple ? onChangeMiddlewareMultiple : onChangeMiddleware} onAutoComplete={onAutoComplete} multiple={multiple} {...otherProps} />
  );
};

export default CountrySelect;