export default interface ArrayDataCache<T> {
  data: T[];
  loaded: boolean;
  isLoading: boolean;
}

export function createArrayCache<T>(): ArrayDataCache<T> {
  return {
    data: [],
    loaded: false,
    isLoading: false,
  };
}