import _ from "lodash";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import Button from "src/components/Actions/Button";
import Alert from "src/components/Feedback/Alert";


const QrSettingsSection = () => {
  const { t, i18n } = useTranslation();
  const domain = window.location.origin;
  const downloadRef = useRef<HTMLAnchorElement>(null);

  const download = (format: string) => {
    const svg = document.querySelector<SVGSVGElement>('#HpQrcode > svg');
    if (!svg) return;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const serializer = new XMLSerializer();
    const svgBlob = new Blob([serializer.serializeToString(svg)], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(svgBlob);
    const img = new Image();

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      // Set white background
      if (!ctx) return;
      if (format === 'jpeg') {
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }
      ctx.drawImage(img, 0, 0);
      URL.revokeObjectURL(url);

      canvas.toBlob((blob) => {
        if (blob && downloadRef.current) {
          const fileUrl = URL.createObjectURL(blob);
          downloadRef.current.href = fileUrl;
          downloadRef.current.download = `qr-redini.${format}`;
          downloadRef.current.click();
          URL.revokeObjectURL(fileUrl);
        }
      }, `image/${format}`);
    };

    img.src = url;
  }

  return (
    <>
      <Alert.Information title={t('panel.settings.qr.title')}>
        {t('panel.settings.qr.info')}
      </Alert.Information>
      <dl className="my-6 space-y-6 divide-y divide-gray-50 border-y border-gray-100 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('panel.settings.qr.header')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="w-full">
              <div className="mb-10">
                <div className="mx-auto w-full lg:w-1/4 mb-10" id="HpQrcode">
                  <QRCode
                    value={`${domain}/${i18n.resolvedLanguage}/download`}
                    bgColor="transparent"
                    fgColor="black"
                    className="w-full h-auto"
                    size={512}
                  />
                </div>
                <div className="flex justify-end gap-x-5">
                  <Button colorName="primary" className="px-5 py-3" onClick={() => download('jpeg')}>{t('common.actions.download')} JPEG</Button>
                  <Button colorName="primary" className="px-5 py-3" onClick={() => download('png')}>{t('common.actions.download')} PNG</Button>
                </div>
                <a ref={downloadRef} className="hidden" />
              </div>
            </div>
          </dd>
        </div >
      </dl >
    </>
  );
}

export default QrSettingsSection;