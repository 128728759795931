import { useTranslation } from 'react-i18next';
import { Invoice } from 'src/api/financial/Accountancy';
import BuyerSelect from 'src/components/Accountancy/BuyerSelect';
import FormInput, { Feedback } from 'src/components/Form/FormInput';
import CountrySelect from 'src/components/i18n/CountryComboBox';
import FormSection from 'src/components/Layout/Panel/FormSection';
import { IForm } from 'src/hooks/useForm';

export interface BuyerFormComponentProps {
  form: IForm<Invoice>;
}

export interface BuyerDataFormComponentProps {
  form: IForm<Invoice>;
}

export function BuyerDataForm(props: BuyerDataFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className="mb-10">
        <FormInput.Overlapping
          {...form.input(
            'buyer.name',
            'text',
            {
              placeholder: t('common.fields.name'),
              required: true
            }
          )}
        />
      </div>
      <div className="mb-10">
        <FormInput.Overlapping
          {...form.input(
            'buyer.vatId',
            'text',
            {
              placeholder: t('accountancy.sides.fields.vatId')
            }
          )}
        />
      </div>
      <div className="mb-10">
        <FormInput.Overlapping
          {...form.input(
            'buyer.address',
            'text',
            {
              placeholder: t('accountancy.sides.fields.address'),
              required: true
            }
          )}
        />
      </div>
      <div className="mb-10">
        <FormInput.Overlapping
          {...form.input(
            'buyer.extra',
            'text',
            {
              placeholder: t('accountancy.sides.fields.extra')
            }
          )}
        />
      </div>
      <div className="md:flex md:gap-3">
        <div className="md:w-1/4 mb-10 md:mb-0">
          <FormInput.Overlapping
            {...form.input(
              'buyer.postCode',
              'text',
              {
                placeholder: t('accountancy.sides.fields.postCode'),
                required: true
              }
            )}
          />
        </div>
        <div className="md:w-3/4">
          <FormInput.Overlapping
            {...form.input(
              'buyer.city',
              'text',
              {
                placeholder: t('accountancy.sides.fields.city'),
                required: true
              }
            )}
          />
        </div>
      </div>
      <div className="mb-10">
        <CountrySelect
          value={form.data.buyer?.countryCode}
          onChange={(id) => form.setData({ ...form.data, buyer: { ...form.data.buyer, countryCode: new String(id) } } as Invoice)}
          placeholder={t('common.fields.countryCode')}
        />
      </div>
      <div className="mb-10">
        <FormInput.Overlapping
          {...form.input(
            'buyer.email',
            'text',
            {
              placeholder: t('accountancy.sides.fields.email')
            }
          )}
        />
      </div>
      <div className="mb-10">
        <FormInput.Overlapping
          {...form.input(
            'buyer.phoneNumber',
            'text',
            {
              placeholder: t('accountancy.sides.fields.phoneNumber')
            }
          )}
        />
      </div>
    </>
  )
}

export default function BuyerForm(props: BuyerFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();

  return (
    <FormSection
      title={t('accountancy.invoices.form.buyer.header')}
      description={t('accountancy.invoices.form.buyer.subheader')}
    >
      <div className="mb-10">
        <BuyerSelect
          value={form.data.buyerId}
          onChange={(value) => form.setData({ ...form.data, buyerId: new String(value) } as Invoice)}
          allowCreate
        />
        <Feedback {...form.input('buyerId')} />
      </div>
      {form.data.buyerId == "create" && <BuyerDataForm form={form} />}
    </FormSection>
  )
}