import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface ITranslation {
  culture?: string;
}

export interface ITranslatable<TTranslation extends ITranslation> {
  translations?: TTranslation[];
}


export interface IEntityTranslation<TTranslatable, TTranslation> {
  culture?: string;
  setCulture: (culture: string) => void;

  getCurrentTranslation: (e: TTranslatable | undefined) => TTranslation | undefined;
  getTranslation: (e: TTranslatable | undefined, c: string) => TTranslation | undefined;
}

const useEntityTranslation = <TData extends ITranslatable<TTranslation>, TTranslation extends ITranslation>(): IEntityTranslation<TData, TTranslation> => {
  const { i18n } = useTranslation();
  const [culture, setCulture] = useState(i18n?.resolvedLanguage)

  const getTranslation = (e: TData | undefined, c?: string) => e?.translations?.find(t => t.culture === c) || e?.translations?.find(t => t.culture === i18n.language) || e?.translations?.find(_t => true);
  const getCurrentTranslation = (e: TData | undefined) => getTranslation(e, culture);

  useEffect(() => {
    setCulture(i18n?.resolvedLanguage ?? "en");
  }, [i18n?.resolvedLanguage])

  return {
    culture,
    setCulture,
    getCurrentTranslation,
    getTranslation
  };
}

export default useEntityTranslation;