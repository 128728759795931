import { FC, useEffect } from 'react';
import useForm from '../../../../../hooks/useForm';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Content, ClientGalleriesClient } from 'src/api/cms/Cms';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import Header from './Header';
import GeneralForm from './Form/General';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import Button from 'src/components/Actions/Button';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import TranslationForm from './Form/Translation';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import PhotosForm from './Form/Photos';
import urlParse from 'src/components/Cms/UrlParse';
import Alert from 'src/components/Feedback/Alert';

const GalleryGalleriesForm: FC = () => {
  const apiConfiguration = useApiConfiguration();

  const apiClient = new ClientGalleriesClient(apiConfiguration);
  const form = useForm({ isVisible: true } as Content);
  const authGuard = useAuthGuard('/panel/', ['NewsfeedRead']);
  const contentsUpdateClaim = useClaim('NewsfeedUpdate');
  const { id } = useParams<string>();
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();

  const onDelete = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id === 'create') return;
    apiClient.delete(id)
      .finally(() => {
        form.setPending(false);
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        navigate(`/panel/content/galleries/galleries/`)
      })
  }

  const onSubmit = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    const payload = {
      ...form.data,
      parentId: form.data.parentId === 'null' ? null : form.data.parentId,
      translations: form.data.translations?.map(t => ({ ...t, url: urlParse(t.title) })) || [],
    } as Content;
    if (id !== 'create') {
      apiClient.update(id, payload)
        .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    } else {
      apiClient.create(payload)
        .then((response) => {
          Toast.success(t("common.status.success"), t("common.form.saved"));
          navigate(`/panel/content/galleries/galleries/${response.id}`);
        })
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    }
  }

  const fetch = () => {
    if (!id) return;
    apiClient.findById(id)
      .then(form.setData)
      .catch(form.catchAnyException);
  }

  useEffect(() => {
    if (id !== 'create') fetch();
    applicationDispatch(
      setBreadcrumbs([
        { label: "cms.galleries.galleries.group", href: "/panel/content/galleries/galleries/" },
        { label: id !== 'create' ? 'common.actions.edit' : 'common.actions.createNew', href: `/panel/content/galleries/galleries/${id}` }
      ])
    );
  }, [id]);

  useEffect(() => {
    form.setReadOnly(!contentsUpdateClaim);
  }, [contentsUpdateClaim])

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <form onSubmit={e => form.onSubmit(e, onSubmit)}>
        <Header content={form.data} onDelete={onDelete} form={form} />
        <GeneralForm form={form} />
        <TranslationForm form={form} />
        {form.data.id ? <PhotosForm form={form} /> : <Alert.Information title={t('cms.galleries.galleries.saveAtFirst')} />}
        <div className="text-end mt-5">
          <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
            {t('common.actions.save')}
          </Button>
        </div>
      </form >
    </>
  )
}

export default GalleryGalleriesForm;