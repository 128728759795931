import { faExclamationTriangle, faTimes } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClientGalleriesClient, ClientGalleryPhotosClient, Content } from 'src/api/cms/Cms';
import Button from 'src/components/Actions/Button';
import Spinner from 'src/components/Feedback/Spinner';
import FileUploadDialog from 'src/components/Form/File/FileUploadDialog';
import FormSection from 'src/components/Layout/Panel/FormSection';
import configuration, { ConfigurationApis } from 'src/config/config';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { IForm } from 'src/hooks/useForm';
import useTenant from 'src/hooks/useTenant';

export interface PhotosFormComponentProps {
  form: IForm<Content>;
}

export interface PhotoComponentProps extends HTMLAttributes<HTMLElement> {
  photo: Content;
}

function Photo(props: PhotoComponentProps) {
  const { photo, onClick, ...otherProps } = props;
  const tenant = useTenant();
  const url = `${configuration.api[ConfigurationApis.Content]}/api/v1/galleries/photos/${photo.id}/download?XTenantId=${tenant}`;
  return (
    <div className="aspect-video relative" {...otherProps}>
      <img src={url} alt={photo.name} title={photo.name} className="absolute w-full h-full object-cover" />
      <Button colorName="rose" className="absolute right-2 top-2 opacity-50 hover:opacity-75 active:opacity-100" onClick={onClick}>
        <FontAwesomeIcon icon={faTimes} className="size-4" />
      </Button>
    </div>
  )
}

export default function PhotosForm(props: PhotosFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const galleriesClient = new ClientGalleriesClient(apiConfiguration);
  const photosClient = new ClientGalleryPhotosClient(apiConfiguration);
  const [loading, setLoading] = useState(false);
  const [isUploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [photos, setPhotos] = useState<Content[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);

  useEffect(() => {
    if (!form.data.id) return;
    setLoading(true);
    galleriesClient
      .getPhotos(
        form.data.id!,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      )
      .then(response => setPhotos(response.items || []))
      .catch(console.error)
      .finally(() => setLoading(false))
  }, [form.data.id]);

  useEffect(() => {
    if (filesToUpload.length === 0) return;
    const file = filesToUpload[0];
    photosClient.uploadPhoto(form.data.id!, undefined, { fileName: file.name, data: file })
      .then(response => {
        setPhotos([...photos, response]);
        setUploadError(false);
        setFilesToUpload([...filesToUpload.slice(1)]);
      })
      .catch(_ => setUploadError(true));
  }, [filesToUpload]);

  const onSelectFiles = (files: File[]) => {
    setFilesToUpload(files);
    setUploadDialogOpen(false);
  }

  const deleteFile = (id: string) => {
    photosClient.deletePhoto(id)
      .then(() => setPhotos([...photos.filter(p => p.id !== id)]))
      .catch(console.error);
  }


  return (
    <FormSection
      title={t('cms.galleries.galleries.form.photos.header')}
      description={t('cms.galleries.galleries.form.photos.description')}
      full
    >
      {loading && <Spinner className="h-8 mx-auto" />}
      <FileUploadDialog open={isUploadDialogOpen} setOpen={setUploadDialogOpen} onSelectFiles={onSelectFiles} uploadError={uploadError} />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-3 mb-8">
        {photos.map(photo => <Photo key={photo.id} photo={photo} onClick={() => deleteFile(photo.id!)} />)}
      </div>
      <div className="flex justify-between">
        <div>
          {uploadError && <FontAwesomeIcon icon={faExclamationTriangle} className="text-rose-700" />}
          {filesToUpload.length > 0 && <Spinner className="mx-auto size-8" />}
        </div>
        <div>
          <Button colorName="paradise" className="px-5 py-3" type="button" onClick={() => setUploadDialogOpen(true)}>
            {t('common.upload.action')}
          </Button>
        </div>
      </div>
    </FormSection>
  )
}