import { faFileText, faGlobeEurope, faParagraph, faTag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { Content, ContentTranslation } from 'src/api/cms/Cms';
import Editor from 'src/components/Form/FormEditor';
import FormInput from 'src/components/Form/FormInput'
import { LocalizedFormSection } from 'src/components/Layout/Panel/FormSection';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import { IForm } from 'src/hooks/useForm';
import useTranslationForm from 'src/hooks/useTranslationForm';

export interface TranslationFormComponentProps {
  form: IForm<Content>;
}

export default function TranslationForm(props: TranslationFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const translationForm = useTranslationForm(form);
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const { getTranslationFieldName, translation, culture } = translationForm;

  const currentLink = `${window.location.origin}/${culture}/n/c/${entityTranslation.getTranslation(form.data, culture)?.url}`

  return (
    <LocalizedFormSection
      title={t('common.form.translation.header')}
      description={t('common.form.translation.subheader')}
      form={translationForm}
      full
    >
      <div className="mb-10 hidden">
        <FormInput.Default {...form.input(getTranslationFieldName("url"), "text", { placeholder: t('common.fields.url') })} icon={<FontAwesomeIcon icon={faGlobeEurope} className="h-5 w-5 text-gray-400" aria-hidden="true" />} />
        <div className="border-l-gray-400 border-l-2 text-gray-500 text-sm pl-3 py-1 mt-3">{currentLink} <a href={currentLink} target="_blank" title={currentLink}><FontAwesomeIcon icon={faGlobeEurope} className="inline h-3 color-primary-800" /></a></div>
      </div>
      <div className="mb-10">
        <FormInput.Default {...form.input(getTranslationFieldName("title"), "text", { placeholder: t('common.fields.title') })} icon={<FontAwesomeIcon icon={faFileText} className="h-5 w-5 text-gray-400" aria-hidden="true" />} />
      </div>
      <div className="mb-10">
        {translation && <Editor {...form.input(getTranslationFieldName("content"), "text", { placeholder: t('common.fields.description') })} key={`${form.data.id}_${culture}_content`} style={{ height: '50vh' }} />}
      </div>
      <h3 className="text-xl pt-10 mb-5 border-b border-gray-200">{t('common.fields.meta')}</h3>
      <div className="mb-10">
        <FormInput.Default {...form.input(getTranslationFieldName("meta.title"), "text", { placeholder: t('common.fields.title') })} icon={<FontAwesomeIcon icon={faFileText} className="h-5 w-5 text-gray-400" aria-hidden="true" />} />
      </div>
      <div className="mb-10">
        <FormInput.Default {...form.input(getTranslationFieldName("meta.description"), "text", { placeholder: t('common.fields.description') })} icon={<FontAwesomeIcon icon={faParagraph} className="h-5 w-5 text-gray-400" aria-hidden="true" />} />
      </div>
      <div className="mb-10">
        <FormInput.Default {...form.input(getTranslationFieldName("meta.keywords"), "text", { placeholder: t('common.fields.keywords') })} icon={<FontAwesomeIcon icon={faTag} className="h-5 w-5 text-gray-400" aria-hidden="true" />} />
      </div>
    </LocalizedFormSection >
  );
}