import classNames from '../Utilities/classNames';
import { DialogButton } from './DialogButton';
import { DialogFooter } from './DialogFooter';
import { DialogHeader } from './DialogHeader';
import { DialogCloseButton } from './DialogCloseButton';
import { DialogTitle } from './DialogTitle';
import { useTranslation } from 'react-i18next';
import { IForm, Validation } from 'src/hooks/useForm';
import { useRef } from 'react';
import { uniqueId } from 'lodash';

export interface DialogComponentProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
  noCloseButton?: boolean;
}

export interface DefaultDialogComponentProps extends DialogComponentProps {
  footer?: React.ReactNode;
}

export interface PromptDialogComponentProps extends DialogComponentProps {
  onClickYes: () => void;
  onClickNo: () => void;
  yesText?: string;
  noText?: string;
  closeOnFeedback?: boolean;
}

export interface FormDialogComponentProps<T> extends DialogComponentProps {
  form: IForm<T>;
  submitText?: string;
  cancelText?: string;
  closeOnFeedback?: boolean;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  validate?: Validation<HTMLFormElement>;
  setPendingToFalseAtEnd?: boolean;
}

export interface OneButtonDialogComponentProps extends DialogComponentProps {
  onClickButton: () => void;
  buttonText: string;
  closeOnClick?: boolean;
}

export function DefaultDialog(props: DefaultDialogComponentProps) {
  const { open, setOpen, children, title, footer, noCloseButton } = props;

  return (
    <>
      <div className={classNames(["z-50 transition-opacity ease-in-out delay-200 duration-300", open ? "relative opacity-100" : "hidden opacity-0"])} aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <DialogHeader>
                <DialogTitle>
                  {title ?? "Dialog"}
                </DialogTitle>
                {noCloseButton || <DialogCloseButton onClick={() => setOpen(false)} />}
              </DialogHeader>
              <div className="text-sm">
                {children}
              </div>
              <DialogFooter>
                {footer}
              </DialogFooter>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export function OneButtonDialog(props: OneButtonDialogComponentProps) {
  const { onClickButton, buttonText, closeOnClick, ...otherProps } = props;

  const onClickMiddleware = () => {
    onClickButton();
    if (closeOnClick) {
      props.setOpen(false);
    }
  }

  const footer = () => {
    return (
      <>
        <div className="flex">
          <DialogButton colorName="gray" className="flex-1" onClick={onClickMiddleware}>
            {buttonText}
          </DialogButton>
        </div>
      </>
    )
  }

  return (
    <DefaultDialog {...otherProps} footer={footer()} />
  )
}

export function PromptDialog(props: PromptDialogComponentProps) {
  const { onClickYes, onClickNo, yesText, noText, closeOnFeedback, ...otherProps } = props;
  const { t } = useTranslation();

  const onClickYesMiddleware = () => {
    onClickYes();
    if (closeOnFeedback) {
      props.setOpen(false);
    }
  }

  const onClickNoMiddleware = () => {
    onClickNo();
    if (closeOnFeedback) {
      props.setOpen(false);
    }
  }

  const footer = () => {
    return (
      <>
        <div className="flex">
          <DialogButton colorName="gray" className="flex-1" onClick={onClickNoMiddleware}>
            {noText ?? t('common.bool.no')}
          </DialogButton>
          <DialogButton colorName="primary" className="flex-1" onClick={onClickYesMiddleware}>
            {yesText ?? t('common.bool.yes')}
          </DialogButton>
        </div>
      </>
    )
  }

  return (
    <DefaultDialog {...otherProps} footer={footer()} />
  )
}

export function FormDialog<T>(props: FormDialogComponentProps<T>) {
  const { cancelText, submitText, children, form, onSubmit, setPendingToFalseAtEnd, validate, ...otherProps } = props;
  const { t } = useTranslation();
  const id = useRef(uniqueId());

  const footer = () => {
    return (
      <>
        <div className="flex">
          <DialogButton type="reset" form={id.current} colorName="gray" className="flex-1" disabled={form.pending}>
            {cancelText ?? t('common.actions.cancel')}
          </DialogButton>
          <DialogButton type="submit" form={id.current} colorName="primary" className="flex-1" disabled={form.pending}>
            {submitText ?? t('common.actions.save')}
          </DialogButton>
        </div>
      </>
    )
  }

  return (
    <DefaultDialog {...otherProps} footer={footer()}>
      <form id={id.current} onSubmit={e => form.onSubmit(e, onSubmit, validate, setPendingToFalseAtEnd)}>
        {children}
      </form>
    </DefaultDialog>
  )
}

export default {
  Default: DefaultDialog,
  Prompt: PromptDialog,
  OneButton: OneButtonDialog,
  Form: FormDialog
}