import { ActionType, NotificationActions } from './actions';
import { NotificationState } from './state';

export function applicationReducer(state: NotificationState, action: NotificationActions): NotificationState {
    switch (action.type) {
        case ActionType.SetUnread:
            return { ...state, unread: action.payload, refresh: false };
        case ActionType.Refresh:
            return { ...state, refresh: true };
        default:
            return state;
    }
}