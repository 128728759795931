import useUser from "src/hooks/useUser";
import Avatar from "../User/Avatar";
import { Message } from "src/api/notifications/Notifications";

export interface MessageBubbleProps {
  message: Message;
}

export const MessageBubble = (props: MessageBubbleProps) => {
  const { message } = props;
  const user = useUser();
  if (user?.id === message.userId) {
    return (
      <>
        <div className="flex mt-3">
          <div className="w-8 ml-3 mr-20"></div>
          <div className="flex-1 bg-[--color-primary-500] rounded-lg text-white px-5 py-3">
            <div className="text-sm">{message.content}</div>
            <span className="text-xs text-white">{message.created?.toLocaleTimeString()}</span>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="flex mt-3">
          <div>
            <Avatar userId={message.userId} className="w-8 h-8 rounded-full border border-gray-300 my-3 mr-3" />
          </div>
          <div className="flex-1 bg-gray-50 rounded-lg text-gray-700 px-5 py-3 mr-20">
            <div className="text-sm">{message.content}</div>
            <span className="text-xs text-gray-500">{message.created?.toLocaleTimeString()}</span>
          </div>
        </div>
      </>
    )
  }
}