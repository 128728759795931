import _ from "lodash";
import useLicenseState from "./useLicenseState"


export default (): string[] | undefined => {
  const state = useLicenseState();

  if (!state.info?.id) return [];
  const functionalities = _.pickBy(state.info?.products, function (value) {
    return value === true;
  });

  const keys = _.keys(functionalities);
  if (keys.length < 1) return undefined;
  return keys;
}