import { useTranslation } from "react-i18next";
import getCountryName from "./getCountryName";

export interface LocaleNameProps {
  countryCode?: string;
}

const CountryName = (props: LocaleNameProps) => {
  const { countryCode } = props;
  const { i18n } = useTranslation();
  const displayLocale = i18n.resolvedLanguage;
  return <>{getCountryName(countryCode, displayLocale)}</>;
}

export default CountryName;