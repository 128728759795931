import { useTranslation } from "react-i18next";
import FormInput from "../Form/FormInput";
import { ChangeEventHandler } from "react";
import { DayOfWeek, WorkingHours } from "src/store/configuration/state";
import _ from "lodash";
import FormCheckbox from "../Form/FormCheckbox";

export const daysOfWeek = Object.keys(DayOfWeek)
  .filter(key => isNaN(Number(key)))
  .map(key => DayOfWeek[key as keyof typeof DayOfWeek]);

export interface BookingWorkingHoursProps {
  dayOfWeek: DayOfWeek;
  value?: WorkingHours;
  onChange: (v: WorkingHours) => void;
}

export interface DayOfWeekNameProps {
  value: DayOfWeek;
}

const DayOfWeekName = (props: DayOfWeekNameProps) => {
  const { value } = props;
  const { t } = useTranslation();
  switch (value) {
    case DayOfWeek.Monday:
      return <>{t('common.days.monday')}</>;
    case DayOfWeek.Tuesday:
      return <>{t('common.days.tuesday')}</>
    case DayOfWeek.Wednesday:
      return <>{t('common.days.wednesday')}</>
    case DayOfWeek.Thursday:
      return <>{t('common.days.thursday')}</>
    case DayOfWeek.Friday:
      return <>{t('common.days.friday')}</>
    case DayOfWeek.Saturday:
      return <>{t('common.days.saturday')}</>
    case DayOfWeek.Sunday:
      return <>{t('common.days.sunday')}</>
    default:
      return <>{value}</>;
  }
}

const BookingWorkingHours = (props: BookingWorkingHoursProps) => {
  const { dayOfWeek, value, onChange } = props;
  const { t } = useTranslation();

  const onInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(_.set({ ...value || { start: [0, 0], end: [0, 0] } }, e.target.name, Number(e.target.value)) as WorkingHours);
  }

  const onSwitchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { checked } = e.target;
    if (checked) {
      onChange({ start: [8, 0], end: [16, 0] } as WorkingHours);
    }
    else {
      onChange({ start: [0, 0], end: [0, 0] } as WorkingHours);
    }
  }

  const isEnabledDay = !(value?.start[0] === 0 && value?.start[1] === 0 && value?.end[0] === 0 && value?.end[1] === 0);

  return (
    <tr>
      <td>
        <FormCheckbox.Input
          name={`dayOfWeek.${dayOfWeek}`}
          checked={isEnabledDay}
          onChange={onSwitchChange}
          value="true"
        />
      </td>
      <td className="pr-3">
        <DayOfWeekName value={dayOfWeek} />
      </td>
      {isEnabledDay && <>
        <td>
          <FormInput.Overlapping
            type="number"
            value={value?.start[0]}
            name="start.0"
            placeholder={t('common.fields.start')}
            onChange={onInputChange}
            min={1}
            max={24}
          />
        </td>
        <td className="py-3">:</td>
        <td>
          <FormInput.Overlapping
            type="number"
            value={value?.start[1]}
            name="start.1"
            onChange={onInputChange}
            min={0}
            max={60}
          />
        </td>
        <td className="p-3"> - </td>
        <td>
          <FormInput.Overlapping
            type="number"
            value={value?.end[0]}
            name="end.0"
            placeholder={t('common.fields.end')}
            onChange={onInputChange}
            min={1}
            max={24}
          />
        </td>
        <td className="py-3">:</td>
        <td>
          <FormInput.Overlapping
            type="number"
            value={value?.end[1]}
            name="end.1"
            onChange={onInputChange}
            min={0}
            max={60}
          />
        </td>
      </>}
    </tr>
  );
}

export default BookingWorkingHours;