import { useTranslation } from "react-i18next";
import getCurrencyName from "./getCurrencyName";

export interface CurrencyNameProps {
  symbol?: string;
}

const CurrencyName = (props: CurrencyNameProps) => {
  const { symbol } = props;
  const { i18n } = useTranslation();
  const displayLocale = i18n.resolvedLanguage;
  return <>{getCurrencyName(symbol, displayLocale)}</>;
}

export default CurrencyName;