import { faMessage } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { MessagesClient, MessageTopic } from "src/api/notifications/Notifications"
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useUser from "src/hooks/useUser";
import classNames from "../Utilities/classNames";
import { setActiveTopic, setTopics } from "src/store/messaging/actions";
import useMessagingContext from "src/hooks/stores/useMessagingContext";
import Alert from "../Feedback/Alert";

export interface MessageTopicRowProps {
  topic: MessageTopic;
  onClick: () => void;
}

export const MessageTopicRow = (props: MessageTopicRowProps) => {
  const { topic, onClick } = props;
  const { t } = useTranslation();
  const user = useUser();

  const getTopicTitle = () => {
    if (topic.title) return topic.title;
    const otherUsers = topic.users?.filter(u => u.userId !== user?.id);
    if (otherUsers?.length) return `${otherUsers.map(u => u.givenName).join(', ')}`;
    return t('crm.messages.empty');
  }

  const readAt = topic.users?.find(u => u.userId === user?.id)?.readAt;
  const lastMessageAt = topic.messages?.find(_m => true)?.created;
  const notReaded = readAt && lastMessageAt ? readAt < lastMessageAt : true;

  return (
    <>
      <div className="flex px-5 py-3 hover:bg-gray-50 active:bg-gray-100 cursor-pointer" onClick={onClick}>
        <div className="mt-1">
          <FontAwesomeIcon icon={faMessage} className={classNames(['text-lg mr-3 ', notReaded ? 'text-[--color-primary-500]' : 'text-gray-500'])} />
        </div>
        <h4 className={classNames(['flex-1 ', notReaded ? 'font-medium' : 'font-light'])}>{getTopicTitle()}</h4>
        <div className="text-xs">
          <span className="text-gray-500">{topic.messages?.find(_m => true)?.created?.toLocaleDateString()}</span><br />
          <span className="text-gray-700">{topic.messages?.find(_m => true)?.created?.toLocaleTimeString()}</span>
        </div>
      </div>
    </>
  );
}

export default () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new MessagesClient(apiConfiguration);
  const { state, dispatch } = useMessagingContext();

  const fetchTopics = () => {
    if (loading) return;
    setLoading(true);
    apiClient.listTopics(10, 0)
      .then(response => dispatch(setTopics(response.items ?? [])))
      .catch(_ => dispatch(setTopics([])))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchTopics();
  }, []);

  return (
    <div>
      {loading ?? <div>Loading...</div>}
      {state.topics.map(topic => <MessageTopicRow key={topic.id} topic={topic} onClick={() => dispatch(setActiveTopic(topic))} />)}
      {state.topics?.length === 0 && <div className="px-3"><Alert.Information title={t('common.noResults')} noClose /></div>}
    </div>
  )
}