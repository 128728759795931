import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MessagesTopic from "./MessagesTopic";
import { faTimes } from "@fortawesome/pro-duotone-svg-icons";
import MessageReply from "./MessageReply";
import { useState } from "react";
import { Message, MessageTopic } from "src/api/notifications/Notifications";
import { useTranslation } from "react-i18next";
import useUser from "src/hooks/useUser";

export interface MessagesWindowProps {
  onClose: () => void;
  topic: MessageTopic;
}

export default (props: MessagesWindowProps) => {
  const { topic, onClose } = props;
  const { t } = useTranslation();
  const user = useUser();
  const [repliedMessage, setRepliedMessage] = useState<Message | undefined>();

  const getTopicTitle = () => {
    if (topic.title) return topic.title;
    const otherUsers = topic.users?.filter(u => u.userId !== user?.id);
    if (otherUsers?.length) return `${otherUsers.map(u => u.givenName).join(', ')}`;
    return t('crm.messages.empty');
  }

  return (
    <>
      <div className="bg-white fixed right-8 bottom-8 w-1/4 min-w-96 max-h-svh rounded-xl overflow-hidden border-4 border-gray-700/20">
        <div className="bg-[--color-primary-500] text-white flex justify-between px-5 py-3">
          <h4 className="text-lg">{getTopicTitle()}</h4>
          <FontAwesomeIcon icon={faTimes} className="relative text-xl top-1 cursor-pointer opacity-75 hover:opacity-90 active:opacity-100" onClick={onClose} />
        </div>
        <div className="px-5 py-3">
          <MessagesTopic topicId={topic.id!} repliedMessage={repliedMessage} />
        </div>
        <MessageReply topicId={topic.id!} setRepliedMessage={setRepliedMessage} />
      </div>
    </>
  )
}