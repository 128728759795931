import { faCircle, faCircleCheck, faMagicWandSparkles } from "@fortawesome/pro-duotone-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import Steps from "./Steps"
import WizardStepWelcome from "./WizardStepWelcome"
import WizardStepAccess from "./WizardStepAccess"
import WizardStepAppearance from "./WizardStepAppearance"
import WizardStepBooking from "./WizardStepBooking"
import WizardStepHorses from "./WizardStepHorses"
import WizardStepInstructors from "./WizardStepInstructors"
import WizardStepTrainingPlaces from "./WizardStepTrainingPlaces"
import WizardStepTrainingTypes from "./WizardStepTrainingTypes"
import WizardStepOtherUsers from "./WizardStepOtherUsers"
import WizardStepInstallation from "./WizardStepInstallation"
import ApplicationLogo from "src/components/Branding/ApplicationLogo"
import useAuthGuard from "src/hooks/useAuthGuard"
import AuthGuardLoading from "src/components/Feedback/AuthGuardLoading"
import { Horse, Instructor, Place, TrainingType } from "src/api/stable/Stable"
import { User } from "src/api/access/Authority"

export interface WizardStepProps {
  nextStep: () => void;
  prevStep: () => void;
}

export interface WizardStepTitleProps {
  step: number;
}

export const WizardStepTitle = (props: WizardStepTitleProps) => {
  const { step } = props;
  const { t } = useTranslation();
  const stepData = Steps[step];
  return <span>{t(`wizard.steps.${stepData?.key}`)}</span>
}

const WizardIndex = () => {
  const { t } = useTranslation();
  const authGuard = useAuthGuard('/auth/sign-in/', ['Panel']);
  const [step, setStep] = useState(0);
  const [horses, setHorses] = useState<Horse[]>([]);
  const [instructors, setInstructors] = useState<Instructor[]>([]);
  const [places, setPlaces] = useState<Place[]>([]);
  const [trainingTypes, setTrainingTypes] = useState<TrainingType[]>([]);
  const [otherUsers, setOtherUsers] = useState<User[]>([]);

  const nextStep = () => {
    if (step < Steps.length - 1) setStep(step + 1);
  }
  const prevStep = () => {
    if (step > 0) setStep(step - 1);
  }
  const humanReadableStep = step + 1;

  if (authGuard === undefined) return <AuthGuardLoading />;

  return (
    <>
      <div className="min-h-svh bg-gradient-to-br bg-gray-100 flex items-center justify-center mx-auto lg:py-16">
        <div className="flex-1 max-w-3xl mx-auto">
          <div className="relative rounded-lg bg-white shadow-lg border-gray-200 p-6">
            <div className="text-2xl border-b border-gray-100 pb-4 mb-8 flex justify-between">
              <div>
                <h1>
                  <FontAwesomeIcon icon={faMagicWandSparkles} className="text-[--color-primary-700] mr-3" />
                  {t('wizard.header')}
                </h1>
                <h2 className="ml-10 text-gray-700 text-lg">
                  {humanReadableStep}: <WizardStepTitle step={step} />
                </h2>
              </div>
              <div className="text-end">
                {Steps.map((s, i) => <>
                  {step > i && <FontAwesomeIcon icon={faCircleCheck} className="text-[--color-primary-700] mr-3" onClick={() => setStep(i)} />}
                  {step === i && <FontAwesomeIcon icon={faCircle} className="text-[--color-primary-700] opacity-50 mr-3" onClick={() => setStep(i)} />}
                  {step < i && <FontAwesomeIcon icon={faCircle} className="text-gray-300 mr-3" onClick={() => setStep(i)} />}
                </>)}
              </div>
            </div>
            {step === 0 && <WizardStepWelcome nextStep={nextStep} prevStep={prevStep} />}
            {step === 1 && <WizardStepAccess nextStep={nextStep} prevStep={prevStep} />}
            {step === 2 && <WizardStepAppearance nextStep={nextStep} prevStep={prevStep} />}
            {step === 3 && <WizardStepBooking nextStep={nextStep} prevStep={prevStep} />}
            {step === 4 && <WizardStepHorses nextStep={nextStep} prevStep={prevStep} data={horses} setData={setHorses} />}
            {step === 5 && <WizardStepInstructors nextStep={nextStep} prevStep={prevStep} data={instructors} setData={setInstructors} horses={horses} />}
            {step === 6 && <WizardStepTrainingPlaces nextStep={nextStep} prevStep={prevStep} data={places} setData={setPlaces} />}
            {step === 7 && <WizardStepTrainingTypes nextStep={nextStep} prevStep={prevStep} data={trainingTypes} setData={setTrainingTypes} horses={horses} instructors={instructors} places={places} />}
            {step === 8 && <WizardStepOtherUsers nextStep={nextStep} prevStep={prevStep} data={otherUsers} setData={setOtherUsers} />}
            {step === 9 && <WizardStepInstallation nextStep={nextStep} prevStep={prevStep} horses={horses} instructors={instructors} places={places} trainingTypes={trainingTypes} />}
          </div>
          <div className="text-center text-gray-400 mt-4">
            <span className="relative font-branding tracking-wide text-4xl top-2">Redini</span>
            <ApplicationLogo className="h-8 w-8 fill-gray-400 inline ml-3" />
          </div>
        </div>
      </div>
    </>
  )
}

export default WizardIndex;