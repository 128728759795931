import React, { FC, useEffect, useReducer } from 'react';
import { ApplicationState, initialApplicationState } from './state';
import { ApplicationActions } from './actions';
import { applicationReducer } from './reducer';
import _ from 'lodash';
import AuthenticationWatch from './AuthenticationWatch';

export const ApplicationContext = React.createContext<{
  state: ApplicationState;
  dispatch: React.Dispatch<ApplicationActions>;
}>({
  state: initialApplicationState,
  dispatch: () => undefined,
});

interface ApplicationProviderProps {
  children: React.ReactNode;
}

const getCurrentLocalStorage = () => {
  const json = localStorage.getItem("applicationState") || "{}";
  const state = JSON.parse(json);
  return state;
}

export const ApplicationProvider: FC<ApplicationProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(
    applicationReducer,
    {
      ...initialApplicationState,
      ...getCurrentLocalStorage()
    }
  );

  useEffect(() => {
    const stateToSave = JSON.stringify(_.omit(state, ["online", "breadcrumbs", "user", "tenant"]) || {});
    localStorage.setItem("applicationState", stateToSave);
  }, [state]);

  return (
    <ApplicationContext.Provider value={{ state, dispatch }}>
      <AuthenticationWatch />
      {children}
    </ApplicationContext.Provider>
  );
};