import { faExchange } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ExchangeRate, ExchangeRatesClient } from "src/api/financial/Accountancy";
import CurrencySelect from "src/components/Accountancy/CurrencySelect";
import Dialog from "src/components/Dialogs/Dialog";
import FormDatePicker from "src/components/Form/FormDatePicker";
import FormInput from "src/components/Form/FormInput";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useForm from "src/hooks/useForm";

export interface AddExchangeRateDialog {
  currencyCode: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function AddExchangeRateDialog(props: AddExchangeRateDialog) {
  const { currencyCode, open, setOpen } = props;
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new ExchangeRatesClient(apiConfiguration);

  const [isExchanged, setExchanged] = useState(false);
  const form = useForm<ExchangeRate>({
    date: new Date()
  } as ExchangeRate);

  useEffect(() => {
    if (isExchanged) {
      form.setData({ ...form.data, sourceCurrencyCode: currencyCode } as ExchangeRate);
    } else {
      form.setData({ ...form.data, targetCurrencyCode: currencyCode } as ExchangeRate);
    }
  }, [currencyCode])

  const onExchange = () => {
    setExchanged(!isExchanged);
    form.setData({
      ...form.data,
      rate: form.data.rate ? (1 / form.data.rate) : 0.0000,
      targetCurrencyCode: form.data.sourceCurrencyCode,
      sourceCurrencyCode: form.data.targetCurrencyCode
    } as ExchangeRate);
  }

  const onSubmit = () => {
    const data = { ...form.data } as ExchangeRate;
    data.rate = Number(data.rate);
    apiClient
      .create(data)
      .catch(form.catchAnyException)
      .finally(() => {
        form.setPending(false);
        setOpen(false);
      });
  }

  return (
    <Dialog.Form<ExchangeRate>
      title={`${currencyCode}: ${t('accountancy.currencies.exchangeRates.actions.add')}`}
      open={open}
      setOpen={setOpen}
      submitText={t('accountancy.currencies.exchangeRates.actions.add')}
      form={form}
      onSubmit={onSubmit}
    >
      <div className="mb-10">
        <FormDatePicker
          value={form.data.date}
          onChange={(value) => form.setData({ ...form.data, date: value } as ExchangeRate)}
          placeholder={t('common.fields.date')}
        />
      </div>
      <div className="mb-10">
        {isExchanged ?
          <CurrencySelect
            value={form.data.targetCurrencyCode}
            onChange={(value) => form.setData({ ...form.data, sourceCurrencyCode: value } as ExchangeRate)}
            placeholder={t('common.fields.currencyCode')}
          />
          :
          <CurrencySelect
            value={form.data.sourceCurrencyCode}
            onChange={(value) => form.setData({ ...form.data, sourceCurrencyCode: value } as ExchangeRate)}
            placeholder={t('common.fields.currencyCode')}
          />
        }
      </div>
      <div className="mb-10">
        <FormInput.Default {...form.input('rate', 'number', { min: 0.0001, step: 0.0001, required: true, placeholder: t('accountancy.currencies.exchangeRates.fields.rate') })} />
      </div>
      {
        form.data.sourceCurrencyCode && form.data.targetCurrencyCode &&
        <div className="mb-5 text-center">
          <div className="text-lg">
            {Intl.NumberFormat('en-US', { style: 'currency', currency: form.data.sourceCurrencyCode, minimumSignificantDigits: 4, maximumSignificantDigits: 4 }).format(1)} = {Intl.NumberFormat('en-US', { style: 'currency', currency: form.data.targetCurrencyCode, minimumSignificantDigits: 4, maximumSignificantDigits: 4 }).format(form.data.rate ?? 0.000)}
          </div>
          <div className="mt-3">
            <FontAwesomeIcon icon={faExchange} onClick={onExchange} />
          </div>
        </div>
      }
    </Dialog.Form>
  )
}