import { Combobox, Listbox } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from '../Utilities/classNames'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { User } from 'src/api/access/Authority';
import Avatar from '../User/Avatar';

export interface UserOptionProps {
  id?: string;
  label?: string;
  value: any; /* eslint-disable-line @typescript-eslint/no-explicit-any */
  selected?: boolean;
  disabled?: boolean;
  user: User;
  onClick?: () => void;
}

export interface TextOptionProps {
  id: string;
  label: React.ReactNode;
  value: any; /* eslint-disable-line @typescript-eslint/no-explicit-any */
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export type OptionProps = TextOptionProps | UserOptionProps;

export const isUserOption = (option: object): option is UserOptionProps => (option as UserOptionProps).user !== undefined;

export const SelectUserOption = (props: UserOptionProps) => {
  const { id, label, value, selected, disabled, user, onClick } = props;

  const onClickOption = () => {
    if (disabled) return;
    if (!onClick) return;
    onClick();
  }

  return (
    <Listbox.Option
      key={id || user.id}
      className={classNames(
        disabled && 'opacity-25 ',
        selected ? 'bg-[--color-primary-600] text-white ' : 'text-gray-900 ',
        'relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-[--color-primary-50] focus:bg-[--color-primary-200]'
      )}
      value={value}
      disabled={disabled}
      onClick={onClickOption}
    >
      <div className="flex">
        <div>
          <Avatar userId={user.id} className="size-8 rounded-full ring-1 mr-3" />
        </div>
        {label &&
          <span className={classNames(selected ? 'font-medium' : 'font-normal', 'block truncate mr-3 mt-1')}>
            {label}
          </span>
        }
        {!label &&
          <>
            <span className={classNames(selected ? 'font-medium' : 'font-normal', 'block truncate mr-2 mt-1')}>
              {user.fullName}
            </span>
            <span className={classNames(selected ? 'text-gray-100' : 'text-gray-500', 'block truncate mr-3 mt-1 ')}>
              ({user.email})
            </span>
          </>
        }
        {selected && (
          <span
            className={classNames(
              selected ? 'text-white' : 'text-[--color-primary-600]',
              'absolute inset-y-0 right-0 flex items-center pr-4 ml-auto'
            )}
          >
            <FontAwesomeIcon icon={faCheck} className="h-5 w-5" aria-hidden="true" />
          </span>
        )}
      </div>
    </Listbox.Option >
  )
}

export const SelectTextOption = (props: TextOptionProps) => {
  const { id, label, value, selected, disabled, onClick } = props;

  const onClickOption = () => {
    if (disabled) return;
    if (!onClick) return;
    onClick();
  }

  return (
    <Listbox.Option
      key={id}
      className={classNames(
        disabled && 'opacity-25 ',
        selected ? 'bg-[--color-primary-600] text-white ' : 'text-gray-900 ',
        'relative cursor-pointer select-none py-2 pl-3 pr-9 hover:bg-[--color-primary-50] focus:bg-[--color-primary-200]'
      )}
      value={value}
      disabled={disabled}
      onClick={onClickOption}
    >
      <>
        <span className={classNames(selected ? 'font-medium' : 'font-normal', 'block truncate')}>
          {label}
        </span>
        {selected && (
          <span
            className={classNames(
              selected ? 'text-white' : 'text-[--color-primary-600]',
              'absolute inset-y-0 right-0 flex items-center pr-4'
            )}
          >
            <FontAwesomeIcon icon={faCheck} className="h-5 w-5" aria-hidden="true" />
          </span>
        )}
      </>
    </Listbox.Option >
  )
}

export const SelectOption = (props: OptionProps) => {
  if (isUserOption(props)) {
    return <SelectUserOption {...props} />;
  }
  else {
    return <SelectTextOption {...props} />;
  }
}

export const ComboBoxUserOption = (props: UserOptionProps) => {
  const { id, label, value, selected, disabled, user, onClick } = props;

  const onClickOption = () => {
    if (disabled) return;
    if (!onClick) return;
    onClick();
  }

  return (
    <Combobox.Option
      key={id}
      id={id}
      value={value}
      disabled={disabled}
      className={({ active }) =>
        classNames(
          'relative cursor-pointer select-none py-2 pl-3 pr-9',
          active ? 'bg-[--color-primary-600] text-white' : 'text-gray-900'
        )
      }
      onClick={onClickOption}
    >
      <div className="flex">
        <div>
          <Avatar userId={user.id} className="size-8 rounded-full ring-1 mr-3" />
        </div>
        {label &&
          <span className={classNames(selected ? 'font-medium' : 'font-normal', 'block truncate mr-3 mt-1')}>
            {label}
          </span>
        }
        {!label &&
          <>
            <span className={classNames(selected ? 'font-medium' : 'font-normal', 'block truncate mr-2 mt-1')}>
              {user.fullName}
            </span>
            <span className={classNames(selected ? 'text-gray-100' : 'text-gray-500', 'block truncate mr-3 mt-1 ')}>
              ({user.email})
            </span>
          </>
        }
        {selected && (
          <span className='absolute inset-y-0 right-0 flex items-center pr-4 ml-auto'>
            <FontAwesomeIcon icon={faCheck} className="h-5 w-5" aria-hidden="true" />
          </span>
        )}
      </div>
    </Combobox.Option>
  )
}

export const ComboBoxTextOption = (props: TextOptionProps) => {
  const { id, label, value, selected, disabled, onClick } = props;

  const onClickOption = () => {
    if (disabled) return;
    if (!onClick) return;
    onClick();
  }

  return (
    <>
      <Combobox.Option
        key={id}
        id={id}
        value={value}
        disabled={disabled}
        className={({ active }) =>
          classNames(
            'relative cursor-pointer select-none py-2 pl-3 pr-9',
            active ? 'bg-[--color-primary-600] text-white' : 'text-gray-900'
          )
        }
        onClick={onClickOption}
      >
        <span className={classNames(selected ? 'font-medium' : 'font-normal', 'block truncate')}>
          {label}
        </span>
        {selected && (
          <span className="absolute inset-y-0 right-0 flex items-center pr-4">
            <FontAwesomeIcon icon={faCheck} className="h-5 w-5" aria-hidden="true" />
          </span>
        )}
      </Combobox.Option>
    </>
  )
}

export const ComboBoxOption = (props: OptionProps) => {
  if (isUserOption(props)) {
    return <ComboBoxUserOption {...props} />;
  }
  else {
    return <ComboBoxTextOption {...props} />;
  }
}

export const UserLabel = (props: UserOptionProps) => {
  const { label, user } = props;
  return <span className="flex">
    <Avatar userId={user.id} className="h-6 w-6 rounded-full ring-1 mr-3" />
    <span className="mr-3">{label || user.fullName}</span>
    <span className="text-gray-500">({user.email})</span>
  </span>;
}

export const TextLabel = (props: TextOptionProps) => {
  const { label } = props;
  return <span>{label}</span>;
}

export const OptionLabel = (props: OptionProps) => {
  if (isUserOption(props)) {
    return <UserLabel {...props} />;
  }
  else {
    return <TextLabel {...props} />;
  }
}

export const optionText = (option?: OptionProps) => {
  if (!option) return "";
  if (isUserOption(option)) {
    return option.label || option.user.fullName || "";
  }
  else {
    return typeof option.label == "string" ? option.label : option.id;
  }
}

export const optionImage = (option?: OptionProps) => {
  if (!option) return "";
  if (isUserOption(option)) {
    return option.label || option.user.fullName || "";
  }
  else {
    return option.label;
  }
}