import { HTMLAttributes, useState } from "react"
import { ConfigurationApis, getApiBaseUrl } from "src/config/config";
import ApplicationLogo from "./ApplicationLogo";
import useTenant from "src/hooks/useTenant";

interface LogoProps extends HTMLAttributes<HTMLOrSVGElement> {
  localLogoClassName?: string;
  appLogoClassName?: string;
}

export default (props: LogoProps) => {
  const { className, localLogoClassName, appLogoClassName, ...otherProps } = props;
  const tenant = useTenant();
  const [fallback, setFallback] = useState(false);
  const defaultSource = `${getApiBaseUrl(ConfigurationApis.Core)}/api/v1/logo/download?XTenantId=${tenant}`;
  const onImageError = () => setFallback(true);
  return fallback ? <ApplicationLogo className={`${className} ${appLogoClassName}`} {...otherProps} /> : <img src={defaultSource} onError={onImageError} className={`${className} ${localLogoClassName}`} {...otherProps} />;
}