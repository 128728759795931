export enum ActionType {
    SetUnread,
    Refresh
}

export interface SetUnread {
    type: ActionType.SetUnread;
    payload?: number;
}

export interface Refresh {
    type: ActionType.Refresh;
}

export const setUnread = (unread?: number): SetUnread => ({
    type: ActionType.SetUnread,
    payload: unread,
});

export const refresh = (): Refresh => ({
    type: ActionType.Refresh,
});

export type NotificationActions = SetUnread | Refresh;