import { useTranslation } from "react-i18next"
import { Link, LinkProps } from "react-router-dom"


const LocalizedLink = (props: LinkProps & React.RefAttributes<HTMLAnchorElement>) => {
  const { to, ...trimmedProps } = props;
  const { i18n } = useTranslation();
  const localizedTo = `/${i18n?.resolvedLanguage}/${to.toString()[0] === '/' ? to.toString().substring(1) : to.toString()}`;
  return <Link to={localizedTo} {...trimmedProps} />;
}

export default LocalizedLink;