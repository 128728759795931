import { Menu, Transition } from "@headlessui/react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from "react"
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { User } from "src/api/access/Auth";
import LocalizedLink from "src/components/Router/LocalizedLink";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Avatar from "src/components/User/Avatar";
import useClaim from "src/hooks/useClaim";

export interface MenuItemComponentProps {
  to: string;
  label: string;
}

const MenuButton = (props: MenuItemComponentProps) => {
  const { to, label } = props;
  const { t } = useTranslation();
  return (
    <Menu.Item>
      <LocalizedLink to={to} className="block px-5 py-3 text-sm leading-6 text-gray-900 hover:bg-[--color-primary-400] active:bg-[--color-primary-700] hover:text-white">
        {t(label)}
      </LocalizedLink>
    </Menu.Item>
  )
}

export interface AuthenticatedMenuComponentProps {
  user: User;
}

const AuthenticatedMenu = (props: AuthenticatedMenuComponentProps) => {
  const { user } = props;
  const { t } = useTranslation();
  const panelClaim = useClaim('Panel');

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="-m-1.5 flex items-center">
        <span className="sr-only">{t('userMenu.header')}</span>
        <span className="flex items-center">
          <Avatar className="rounded-full h-8 w-8 ring-2 ring-gray-300" />
          <span className="hidden xl:block ml-4 text-sm font-medium leading-6 text-gray-900" aria-hidden="true">
            {user.givenName || user.userName}
          </span>
          <FontAwesomeIcon icon={faAngleDown} className="ml-2 h-5 w-5 text-gray-400 mr-6" aria-hidden="true" />
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none overflow-hidden">
          <Menu.Item>
            <span className="block px-5 py-3 text-sm leading-6 text-gray-400 border-b border-gray-200">{t('users.hello')}, {user.givenName || user.userName}</span>
          </Menu.Item>
          <MenuButton to="/user/" label={t('users.panel.header')} />
          {panelClaim && <MenuButton to="/panel/" label={t('panel.panel')} />}
          <Menu.Item><hr className="border-gray-100" /></Menu.Item>
          <MenuButton to="/auth/sign-out/" label={t('auth.signOut.header')} />
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default AuthenticatedMenu;