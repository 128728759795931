import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User, UsersClient } from 'src/api/access/Authority';
import { HttpQueryFilter, Worker } from 'src/api/stable/Stable';
import ComboBox from 'src/components/Form/FormComboBox';
import FormInput from 'src/components/Form/FormInput'
import { UserOptionProps } from 'src/components/Form/FormOptions';
import FormSection from 'src/components/Layout/Panel/FormSection';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useClaim from 'src/hooks/useClaim';
import { IForm } from 'src/hooks/useForm';

export interface GeneralFormComponentProps {
  form: IForm<Worker>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const isDeveloper = useClaim('Developer');

  const apiConfiguration = useApiConfiguration();
  const usersClient = new UsersClient(apiConfiguration);
  const [users, setUsers] = useState<User[]>([]);
  const userAutocompleteTimeout = useRef<(NodeJS.Timeout) | undefined>(undefined);

  useEffect(() => {
    setUsers([]);
    usersClient
      .get(
        [],
        [],
        1000,
        0,
        undefined,
        undefined
      )
      .then(response => setUsers(response.items ?? []))
      .catch(console.error);
  }, []);

  const onChangeUser: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    form.setData({ ...form.data, userId: value, user: users.find(u => u.id === value) } as Worker);
  }

  const applyUserAutocomplete = (query: string) => {
    usersClient
      .get(
        query
          .split(' ')
          .filter(phrase => phrase.length > 0)
          .map(phrase => ({ type: '%', property: 'GivenName,Surname,Email', value: phrase } as HttpQueryFilter)),
        [],
        1000,
        0,
        undefined,
        undefined
      )
      .then(response => setUsers(response.items ?? []))
      .catch(console.error);
  }

  const onUserAutocomplete = (query: string) => {
    if (userAutocompleteTimeout.current) {
      clearTimeout(userAutocompleteTimeout.current);
      userAutocompleteTimeout.current = undefined;
    }
    userAutocompleteTimeout.current = setTimeout(() => applyUserAutocomplete(query), 250);
  }

  const userOptions = users?.map(r => ({ value: r.id, user: r }) as UserOptionProps);

  return (
    <FormSection
      title={t('common.form.general.header')}
      description={t('common.form.general.subheader')}
    >
      {isDeveloper && <div className="mb-10">
        <FormInput.Clean name="id" placeholder="Id" value={form.data.id} readOnly />
      </div>}
      <div className="mb-10">
        <span>{t("users.item")}</span>
        <ComboBox options={userOptions} value={form.data?.userId} onChange={onChangeUser} onAutoComplete={onUserAutocomplete} />
      </div>
    </FormSection>
  )
}