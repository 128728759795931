import { ChangeEvent, SelectHTMLAttributes, createRef, useEffect, useState } from "react";
import classNames from "../Utilities/classNames";
import simulateChangeEvent from "./simulateChangeEvent";

export interface ColorSelectOption {
  id: string;
  color: string;
  label?: string;
  description?: string;
  disabled?: boolean;
}

interface ColorSelectOptionComponentProps {
  option: ColorSelectOption;
  isActive?: boolean;
  onClick: () => void;
}

interface ColorSelectComponentProps extends SelectHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  options: ColorSelectOption[];
}


const ColorSelectOption = (props: ColorSelectOptionComponentProps) => {
  const { option, isActive, onClick } = props;

  const isColorHex = option.color.startsWith('#');
  const isColorWithShade = option.color.includes('-');

  return (
    (
      <div
        key={option.id}
        className={classNames([
          'w-12 h-12 rounded-md overflow-hidden cursor-pointer m-1',
          isActive === true && 'border-2 border-white ring ring-[--color-primary-500] ',
          option.disabled !== true && 'opacity-25 '
        ])}
        onClick={() => !option.disabled && onClick()}
      >
        {isColorHex && <div className="h-full w-full"></div>}
        {!isColorHex && !isColorWithShade && <div className={classNames(["h-full w-full", isActive ? `bg-${option.color}-600` : `bg-${option.color}-300 hover:bg-${option.color}-500 active:bg-${option.color}-600`])}></div>}
        {!isColorHex && isColorWithShade && <div className="h-full w-full"></div>}
      </div>
    )
  );
}

const ColorSelect = (props: ColorSelectComponentProps) => {
  const { id, options, value, placeholder, onChange } = props;

  const [val, setVal] = useState<string>();
  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (val === value) return;
    if (!val && value) return;
    simulateChangeEvent(inputRef?.current, val);
  }, [val]);

  useEffect(() => {
    if (value !== val) setVal(String(value) ?? "");
  }, [value]);

  const onChangeMiddleware = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange)
      onChange(e);
  }

  return (

    <div className="flex flex-wrap">
      {options.map((option, i) => <ColorSelectOption key={i} onClick={() => setVal(option.id)} option={option} isActive={val === option.id} />)}
      <input ref={inputRef} id={id} name={id} value={value} onChange={onChangeMiddleware} title={placeholder} className="hidden" />
    </div>
  )

}

export const availableColors = [
  'slate', 'gray', 'neutral', 'stone',
  'red', 'orange',
  'sand', 'amber', 'sun',
  'lime', 'green', 'emerald', 'teal', 'cactus',
  'cyan', 'paradise', 'sky', 'cornflower',
  'indigo', 'violet',
  'purple', 'fuchsia', 'lily', 'pink', 'rose'
];

export default ColorSelect;