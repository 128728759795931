import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HttpQueryFilter } from 'src/api/access/Authority'
import { ClientGalleryPhotosClient } from 'src/api/cms/Cms'
import Gallery from 'src/components/Cms/Gallery'
import LocalizedLink from 'src/components/Router/LocalizedLink'
import useApiConfiguration from 'src/hooks/useApiConfiguration'
import configuration, { ConfigurationApis } from 'src/config/config'
import useTenant from 'src/hooks/useTenant'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


const src = (photo: string, tenant?: string) => `${configuration.api[ConfigurationApis.Content]}/api/v1/galleries/photos/${photo}/download?XTenantId=${tenant}`;

const Slider = () => {
  const tenant = useTenant();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientGalleryPhotosClient(apiConfiguration);
  const [photos, setPhotos] = useState<string[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    apiClient
      .get(
        [{ property: 'parentId', value: '7bd2e5c8-2a2d-4cdd-a51e-639e2aaa1852', type: '=' } as HttpQueryFilter],
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      )
      .then(response => setPhotos(response.items?.map(p => p.id!) || []))
      .catch(console.error);
  }, []);

  return (
    <>
      <Swiper
        rewind={true}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          dynamicBullets: true
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="absolute top-0 left-0 h-full w-full opacity-30 z-20"
        style={{
          "--swiper-pagination-color": "#fff",
          "--swiper-navigation-color": "#fff",
        }}
      >
        {photos.map((photo, id) => (<SwiperSlide key={id}><img src={src(photo, tenant)} className="absolute object-cover h-screen w-full" alt="..." /></SwiperSlide>))}
      </Swiper>
    </>
  );
}

const HeroIndexWidget = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="h-screen w-full"></div>
      <div className="bg-black h-screen w-full absolute top-0 left-0">

        <div
          className="absolute top-0 left-0 inset transform-gpu overflow-hidden h-full w-full"
        >
          <div className="relative h-full w-full">
            <Slider />
            <div className="absolute w-full lg:w-auto bottom-0 right-0 z-30 p-20 xl:p-40 2xl:p-64 pointer-events-none">
              <div className="text-center lg:text-end">
                <h1 className="text-4xl font-medium tracking-tight text-white sm:text-6xl">
                  <Gallery id="7bd2e5c8-2a2d-4cdd-a51e-639e2aaa1852" title raw />
                </h1>
                <div className="mt-6 text-lg leading-8 text-white hidden md:block">
                  <Gallery id="7bd2e5c8-2a2d-4cdd-a51e-639e2aaa1852" content raw />
                </div>
                <div className="mt-10 flex gap-x-10 justify-center lg:justify-end">
                  <LocalizedLink
                    to="/stable/book-training"
                    className="rounded-md bg-[--color-primary-600] px-3.5 py-2.5 text-lg font-medium text-white shadow-sm hover:bg-[--color-primary-500] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600  pointer-events-auto"
                  >
                    {t('stable.actions.bookRide')}
                  </LocalizedLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroIndexWidget;