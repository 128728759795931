import classNames from "../Utilities/classNames";

export interface StatData {
  name: string;
  value: number;
  change?: number;
  formatter: (v: number) => string;
}

export interface StatComponentProps {
  stat: StatData;
  id: number;
}

const Stat = (props: StatComponentProps) => {
  const { stat, id } = props;
  const changeType = stat.change && stat.change !== 0 ? (stat.change > 0 ? 'positive' : 'negative') : 'neutral';
  return (
    <div
      key={stat.name}
      className={classNames(
        id % 2 === 1 ? 'sm:border-l' : id === 2 ? 'lg:border-l' : '',
        changeType === 'positive' && 'bg-paradise-50',
        changeType === 'negative' && 'bg-rose-50',
        changeType === 'neutral' && 'bg-gray-50',
        'flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8 overflow-hidden'
      )}
    >
      <dt className="md:w-full xl:w-auto text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
      {stat.change && <dd
        className={classNames(
          changeType === 'positive' && 'text-paradise-600 ',
          changeType === 'negative' && 'text-rose-600 ',
          changeType === 'neutral' && 'text-gray-700 ',
          'md:w-full xl:w-auto text-xs font-medium'
        )}
      >
        {stat.change.toFixed(0)}%
      </dd>}
      <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
        {stat.formatter(stat.value)}
      </dd>
    </div>
  );
}

export default Stat;