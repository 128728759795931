import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import FormInput from 'src/components/Form/FormInput'
import { IForm } from 'src/hooks/useForm';
import { UserForm } from '../Form';
import { faEnvelope, faFileText, faKey } from '@fortawesome/free-solid-svg-icons';
import Select from 'src/components/Form/FormSelect';
import configuration from 'src/config/config';
import getLocaleName from 'src/components/i18n/getLocaleName';
import { TextOptionProps } from 'src/components/Form/FormOptions';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import PhoneNumberForm from 'src/components/Form/PhoneNumberForm';
import { Uploader } from 'src/hooks/useUploader';
import { FormImageComponentRef, FormImageUploader } from 'src/components/Form/FormImage';
import { RefObject } from 'react';

export interface GeneralFormComponentProps {
  form: IForm<UserForm>;
  uploader: Uploader;
  uploaderRef: RefObject<FormImageComponentRef>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form, uploader, uploaderRef } = props;
  const { t, i18n } = useTranslation();

  const cultures: TextOptionProps[] = configuration.locales.map((locale) => ({
    id: locale,
    value: locale,
    label: getLocaleName(locale, i18n.resolvedLanguage)
  } as TextOptionProps));

  return (
    <div>
      <div className="md:flex md:flex-row md:mt-5 pb-5 border-b border-gray-200">
        <div className="md:w-2/3 md:px-4 pt-5 md:pt-10 sm:px-0">
          <h3 className="text-base font-medium leading-7 text-gray-900">{t('users.form.general.header')}</h3>
          <div className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{t('users.form.general.subheader')}</div>
        </div>
        <div className="md:w-2/3 md:px-4 pt-5 md:pt-10 md:border-l border-gray-100">
          <div className="mb-10">
            <FormInput.Clean name="id" placeholder="Id" value={form.data.id} readOnly />
          </div>
          <div className="mb-10">
            <FormInput.Default {...form.input("email", "email", { placeholder: t('users.fields.email'), disabled: form.data.email === 'developer@localhost' })} icon={<FontAwesomeIcon icon={faEnvelope} className="h-5 w-5 text-gray-400" aria-hidden="true" />} />
            {form.data.email && <FormCheckbox.Input {...form.input('emailConfirmed', 'checkbox', { value: 'true', placeholder: t('auth.fields.emailConfirmed') })} />}
          </div>
          <div className="mb-10 group">
            <FormInput.Default {...form.input("userName", "email", { placeholder: t('users.fields.userName'), disabled: form.data.email === 'developer@localhost' })} icon={<FontAwesomeIcon icon={faKey} className="h-5 w-5 text-gray-400" aria-hidden="true" />} />
            <div className="h-0 text-xs text-transparent group-hover:text-gray-400 pt-3">{t('users.form.general.userNameTip')}</div>
          </div>
          <div className="mb-10">
            <FormInput.Default {...form.input("givenName", "text", { placeholder: t('users.fields.givenName') })} icon={<FontAwesomeIcon icon={faFileText} className="h-5 w-5 text-gray-400" aria-hidden="true" />} />
          </div>
          <div className="mb-10">
            <FormInput.Default {...form.input("surname", "text", { placeholder: t('users.fields.surname') })} icon={<FontAwesomeIcon icon={faFileText} className="h-5 w-5 text-gray-400" aria-hidden="true" />} />
          </div>
          <div className="mb-10">
            <PhoneNumberForm {...form.input('phoneNumber', 'text', { placeholder: t('auth.fields.phoneNumber'), disabled: form.data.email === 'developer@localhost' })} />
            {form.data.phoneNumber && <FormCheckbox.Input {...form.input('phoneNumberConfirmed', 'checkbox', { value: 'true', placeholder: t('auth.fields.phoneNumberConfimed') })} />}
            <span className="text-xs text-gray-500">{t('auth.description.phoneNumber')}</span>
          </div>
          <div className="mb-10">
            <Select {...form.input("culture", "select", { placeholder: t('users.fields.culture') })} options={cultures} />
          </div>
          <div className="mb-10">
            <span>{t("common.fields.photo")}</span>
            <FormImageUploader ref={uploaderRef} uploader={uploader} noUploadButton={form.data.id === undefined} />
            <span className="text-xs text-gray-500">{t('ui.uploader.squarePhotos')}</span>
          </div>
        </div>
        <div className="md:w-2/3 md:px-4 pt-5 md:pt-10">
        </div>
      </div>
    </div>
  )
}