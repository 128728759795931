import { useTranslation } from 'react-i18next';
import { Currency } from 'src/api/financial/Accountancy';
import FormInput from 'src/components/Form/FormInput'
import getCurrencyName from 'src/components/i18n/getCurrencyName';
import FormSection from 'src/components/Layout/Panel/FormSection';
import { IForm } from 'src/hooks/useForm';

export interface GeneralFormComponentProps {
  form: IForm<Currency>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form } = props;
  const { t, i18n } = useTranslation();

  return (
    <FormSection
      title={t('common.form.general.header')}
      description={t('common.form.general.subheader')}
    >
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('id', 'text', { placeholder: t('accountancy.currencies.fields.symbol') })} />
      </div>
      <div className="mb-10">
        <FormInput.Clean name="name" placeholder={t('common.fields.name')} value={getCurrencyName(form.data.id, i18n.resolvedLanguage)} readOnly />
      </div>
    </FormSection>
  )
}