import { faCheckCircle, faExclamationCircle, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react';
import { toast, TypeOptions } from "react-toastify";

export enum ToastType {
  Success,
  Error,
  Warning,
  Information
}

export interface ToastProps {
  title: string;
  children: React.ReactNode;
}

export interface ToastWithTypeProps extends ToastProps {
  type: ToastType;
}

function Toast(props: ToastWithTypeProps) {
  const { type, title, children } = props;
  return (
    <div className="flex items-start">
      <div className="flex-shrink-0">
        {type === ToastType.Success && <FontAwesomeIcon icon={faCheckCircle} className="h-6 w-6 text-green-500" aria-hidden="true" />}
        {type === ToastType.Error && <FontAwesomeIcon icon={faExclamationCircle} className="h-6 w-6 text-red-600" aria-hidden="true" />}
        {type === ToastType.Warning && <FontAwesomeIcon icon={faExclamationTriangle} className="h-6 w-6 text-amber-500" aria-hidden="true" />}
        {type === ToastType.Information && <FontAwesomeIcon icon={faInfoCircle} className="h-6 w-6 text-sky-500" aria-hidden="true" />}
      </div>
      <div className="ml-3 flex-1 pt-0.5">
        <div className="text-sm font-medium text-gray-900">{title}</div>
        {typeof (children) === 'string' && <div className="mt-1 text-sm text-gray-500">{children}</div>}
        {typeof (children) !== 'string' && children}
      </div>
    </div>
  );
}

const Success = (props: ToastProps) => <Toast type={ToastType.Success} {...props} />;
const Error = (props: ToastProps) => <Toast type={ToastType.Error} {...props} />;
const Warning = (props: ToastProps) => <Toast type={ToastType.Warning} {...props} />;
const Information = (props: ToastProps) => <Toast type={ToastType.Information} {...props} />;

const typeToStyle = (type: ToastType): TypeOptions => {
  switch (type) {
    case ToastType.Success: return "success";
    case ToastType.Error: return "error";
    case ToastType.Warning: return "warning";
    case ToastType.Information: return "info";
  }
}

const showToast = (type: ToastType, title: string, content: ReactNode) => {
  toast(<Toast type={type} title={title}>{content}</Toast>, { type: typeToStyle(type), icon: false })
}

const showSuccess = (title: string, content: ReactNode) =>
  showToast(ToastType.Success, title, content);
const showError = (title: string, content: ReactNode) =>
  showToast(ToastType.Error, title, content);
const showWarning = (title: string, content: ReactNode) =>
  showToast(ToastType.Warning, title, content);
const showInformation = (title: string, content: ReactNode) =>
  showToast(ToastType.Information, title, content);

export default {
  Default: Toast,
  Success,
  Error,
  Warning,
  Information,
  toast: showToast,
  success: showSuccess,
  error: showError,
  warning: showWarning,
  information: showInformation
};