import { Dictionary } from "lodash";
import FormInput from "src/components/Form/FormInput";
import useForm from "src/hooks/useForm";
import { WizardStepProps } from "./Index";
import { useTranslation } from "react-i18next";
import Button from "src/components/Actions/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/pro-duotone-svg-icons";
import useUser from "src/hooks/useUser";
import { useEffect } from "react";
import { PasswordChangeRequest, PasswordClient } from "src/api/access/Auth";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import FormAlert from "src/components/Form/FormAlert";

export interface WizardStepAccessForm {
  email: string;
  oldPassword: string;
  newPassword: string;
  repeatPassword: string;
}

export default (props: WizardStepProps) => {
  const { nextStep, prevStep } = props;
  const { t } = useTranslation();
  const form = useForm({} as WizardStepAccessForm);
  const user = useUser();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new PasswordClient(apiConfiguration);

  useEffect(() => {
    if (!user) return;
    form.setData({ email: user.email, oldPassword: '', newPassword: '', repeatPassword: '' } as WizardStepAccessForm);
  }, [user?.email])

  const onSubmit = () => {
    apiClient.passwordChange({ oldPassword: form.data.oldPassword, newPassword: form.data.newPassword } as PasswordChangeRequest)
      .then(() => {
        nextStep();
      })
      .catch(ex => {
        if (ex.status === 400 && ex.title === "PasswordMismatch") return form.setValidation({ oldPassword: ["IncorrectPassword"] });
        form.catchAnyException(ex);
      })
      .finally(() => form.setPending(false));
  }

  const validate = (): Dictionary<string[]> => {
    const result = {} as Dictionary<string[]>;
    if (form.data.newPassword !== form.data.repeatPassword) {
      result["repeatPassword"] = ["MustBeTheSame"];
    }
    return result;
  }

  return (
    <form onSubmit={e => form.onSubmit(e, onSubmit, validate)}>
      <p className="text-sm text-gray-600 py-4">Zanim zaczniemy tworzyć Twoją stronę, zdefiniuj nowe hasło dla swojego konta.</p>
      {form.error}
      <FormAlert
        code={form.error}
        errorMessages={{
          ...t("common.errors", { returnObjects: true }),
          ...{ '400': 'Incorrect password' }
        }}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <FormInput.Overlapping {...form.input('email', 'email', { placeholder: t('auth.fields.email'), disabled: true })} className="md:flex-auto" />
        </div>
        <div>

        </div>
        <div>
          <FormInput.Overlapping {...form.input('oldPassword', 'password', {
            placeholder: t('auth.fields.oldPassword'),
            minLength: 8,
            maxLength: 48,
            required: true,
          })
          } />
        </div>
        <div>

        </div>
        <div>
          <FormInput.Overlapping
            {...form.input('newPassword', 'password', {
              placeholder: t('auth.fields.newPassword'),
              minLength: 8,
              maxLength: 48,
              required: true
            })}
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
            title={t('validation.passwordRules')} />
        </div>
        <div>
          <FormInput.Overlapping {...form.input('repeatPassword', 'password', { placeholder: t('auth.fields.repeatNewPassword'), required: true })} className="md:flex-auto" />
        </div>
      </div>
      <div className="flex justify-between border-t border-gray-100 pt-4 mt-8">
        <Button type="button" colorName="gray" className="px-5 py-3" onClick={prevStep}>
          <FontAwesomeIcon icon={faChevronCircleLeft} className="mr-3" />
          {t('ui.goBack')}
        </Button>
        <Button type="submit" colorName="primary" className="px-5 py-3">
          <FontAwesomeIcon icon={faChevronCircleRight} className="mr-3" />
          {t('ui.goNext')}
        </Button>
      </div>
    </form>
  );
};
