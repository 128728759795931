import { CookieConsentConfig } from "vanilla-cookieconsent";

// Enable dark mode
document.documentElement.classList.add('cc--darkmode');

const CookiesConfiguration: CookieConsentConfig = {
    disablePageInteraction: true,
    guiOptions: {
        consentModal: {
            layout: "box",
            position: "bottom right",
            equalWeightButtons: false,
            flipButtons: false
        },
        preferencesModal: {
            layout: "bar wide",
            position: "right",
            equalWeightButtons: true,
            flipButtons: true
        }
    },
    categories: {
        necessary: {
            readOnly: true,
            enabled: true
        },
        analytics: {
            enabled: true
        },
        functionality: {},
        marketing: {}
    },
    language: {
        default: "pl",
        autoDetect: "document",
        translations: {
            en: {
                consentModal: {
                    title: "We use cookies files - by your agreement",
                    description: "Some cookies are necessary - which you will need to accept to use this website. Others can be managed by your choice.",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    showPreferencesBtn: "Manage preferences",
                    footer: "<a href=\"#link\">Privacy Policy</a>\n<a href=\"#link\">Terms and conditions</a>"
                },
                preferencesModal: {
                    title: "Consent Preferences Center",
                    acceptAllBtn: "Accept all",
                    acceptNecessaryBtn: "Reject all",
                    savePreferencesBtn: "Save preferences",
                    closeIconLabel: "Close modal",
                    serviceCounterLabel: "Service|Services",
                    sections: [
                        {
                            title: "Strictly Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
                            description: "All cookies and storage functionalities on your device (local, session storage) which are used for authentication, order service and contact relation service purposes. Even for remembering your choice about cookies.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Analytics",
                            description: "Cookies which are used by Google Analytics services, you can read more which cookies are used for what purposes on this webiste: <a href=\"https://business.safety.google/adscookies/\" target=\"blank\">https://business.safety.google/adscookies/</a>",
                            linkedCategory: "analytics"
                        },
                    ]
                }
            },
            pl: {
                consentModal: {
                    title: "Używamy plików cookie - za Twoją zgodą",
                    description: "Niektóre pliki cookie są niezbędne - należy je zaakceptować, aby móc korzystać z tej witryny. Innymi można zarządzać według własnego uznania.",
                    acceptAllBtn: "Zaakceptuj wszystko",
                    acceptNecessaryBtn: "Odrzuć wszystko",
                    showPreferencesBtn: "Zarządzanie preferencjami",
                    footer: "<a href=\"#link\">Polityka prywatności</a>\n<a href=\"#link\">Warunki użytkowania</a>"
                },
                preferencesModal: {
                    title: "Centrum preferencji zgód",
                    acceptAllBtn: "Zaakceptuj wszystko",
                    acceptNecessaryBtn: "Odrzuć wszystko",
                    savePreferencesBtn: "Zapisywanie preferencji",
                    closeIconLabel: "Zamknij okno dialogowe",
                    serviceCounterLabel: "Usługa|Usługi",
                    sections: [
                        {
                            title: "Niezbędne pliki cookie <span class=\"pm__badge\">Zawsze włączone</span>",
                            description: "Wszystkie pliki cookie i funkcje przechowywania na urządzeniu użytkownika (lokalne, sesyjne), które są wykorzystywane do uwierzytelniania, obsługi zamówień i obsługi kontaktów. Nawet do zapamiętywania wyboru użytkownika dotyczącego plików cookie.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Analityka",
                            description: "Ciasteczka, które są używane przez usługi Google Analytics, możesz przeczytać więcej o tym, które ciasteczka są używane do jakich celów na tej stronie: <a href=\"https://business.safety.google/adscookies/\" target=\"blank\">https://business.safety.google/adscookies/</a>",
                            linkedCategory: "analytics"
                        },
                    ]
                }
            },
            de: {
                consentModal: {
                    title: "Wir verwenden Cookies-Dateien - mit Ihrem Einverständnis",
                    description: "Einige Cookies sind notwendig - diese müssen Sie akzeptieren, um diese Website nutzen zu können. Andere können durch Ihre Wahl verwaltet werden.",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Alle ablehnen",
                    showPreferencesBtn: "Einstellungen verwalten",
                    footer: "<a href=\"#link\">Datenschutzrichtlinie</a>\n<a href=\"#link\">Geschäftsbedingungen</a>"
                },
                preferencesModal: {
                    title: "Zentrum für Zustimmungspräferenzen",
                    acceptAllBtn: "Alle akzeptieren",
                    acceptNecessaryBtn: "Alle ablehnen",
                    savePreferencesBtn: "Einstellungen speichern",
                    closeIconLabel: "Modal schließen",
                    serviceCounterLabel: "Service|Dienstleistungen",
                    sections: [
                        {
                            title: "Streng notwendige Cookies <span class=\"pm__badge\">Immer aktiviert</span>",
                            description: "Alle Cookies und Speicherfunktionen auf Ihrem Gerät (lokale Speicherung, Sitzungsspeicherung), die für die Authentifizierung, den Bestellservice und die Kontaktaufnahme verwendet werden. Auch für die Erinnerung an Ihre Wahl über Cookies.",
                            linkedCategory: "necessary"
                        },
                        {
                            title: "Analytik",
                            description: "Cookies which are used by Google Analytics services, you can read more which cookies are used for what purposes on this webiste: <a href=\"https://business.safety.google/adscookies/\" target=\"blank\">https://business.safety.google/adscookies/</a>",
                            linkedCategory: "analytics"
                        },
                    ]
                }
            }
        }
    }
};

export default CookiesConfiguration;