import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HttpQueryOrder, QueryOrderDirection, User, UsersClient } from "src/api/access/Authority";
import LocalizedLink from "src/components/Router/LocalizedLink";
import Avatar from "src/components/User/Avatar";
import useApiConfiguration from "src/hooks/useApiConfiguration";

interface RowComponentProps {
  user: User;
}

const Row = (props: RowComponentProps) => {
  const { user } = props;
  const { i18n } = useTranslation();

  return (
    <li key={user.email} className="flex justify-between gap-x-6 py-5">
      <div className="flex min-w-0 gap-x-4">
        <Avatar userId={user.id} className="object-cover h-12 w-12 flex-none rounded-full bg-gray-50" />
        <div className="min-w-0 flex-auto">
          <div className="text-sm font-medium leading-6 text-gray-900">
            <LocalizedLink to={`/panel/users/${user.id}`} className="hover:underline">
              {user.fullName && user.fullName.length > 1 ? user.fullName : user.email}
            </LocalizedLink>
          </div>
          <div className="mt-1 flex text-xs leading-5 text-gray-500">
            {user.email}
          </div>
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-x-6">
        <div className="hidden sm:flex sm:flex-col sm:items-end">
          <div className="text-sm leading-6 text-gray-900">{(user.updated || user.created)!.toLocaleString(i18n?.resolvedLanguage)}</div>
        </div>
      </div>
    </li>
  )
}

const LatestUsersTable = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<User[]>([]);
  const apiConfiguration = useApiConfiguration();
  const usersClient = new UsersClient(apiConfiguration);

  useEffect(() => {
    usersClient.get(undefined, [{ property: 'Created', direction: QueryOrderDirection.DESC } as HttpQueryOrder], undefined, undefined, 1, 10)
      .then(response => setUsers(response.items || []))
      .catch(console.error);
  }, []);

  return (
    <div className="p-3">
      <h2 className="leading-2 text-xl my-3">{t('panel.widgets.latestUsersTable.header')}</h2>
      <ul role="list" className="divide-y divide-gray-100">
        {users.map((user) => <Row key={user.id} user={user} />)}
      </ul>
    </div>
  )
}

export default LatestUsersTable;