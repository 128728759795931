import React, { FC, useEffect, useReducer, useState } from 'react';
import { LicenseState, initialLicenseState } from './state';
import { LicenseActions, setLicense } from './actions';
import { configurationReducer } from './reducer';
import _ from 'lodash';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { LicenseInfo, LicensesClient } from 'src/api/licensing/Licensing';
import configuration from 'src/config/config';
import useLocalizedNavigate from 'src/hooks/useNavigate';

export const LicenseContext = React.createContext<{
  state: LicenseState;
  dispatch: React.Dispatch<LicenseActions>;
}>({
  state: initialLicenseState,
  dispatch: () => undefined,
});

interface LicenseProviderProps {
  children: React.ReactNode;
}

export const LicenseProvider: FC<LicenseProviderProps> = ({ children }) => {
  const apiLicense = useApiConfiguration();
  const apiClient = new LicensesClient(apiLicense);
  const [tries, setTries] = useState(5);
  const navigate = useLocalizedNavigate();
  const [state, dispatch] = useReducer(
    configurationReducer,
    {
      ...initialLicenseState,
    }
  );

  useEffect(() => {
    apiClient
      .findInfo(configuration.license ?? "")
      .catch(() => {
        if (tries === 0) {
          dispatch(setLicense({ id: "free", products: {} } as LicenseInfo));
        } else {
          setTimeout(() => setTries(tries - 1), 1000);
        }
      })
      .then(response => {
        if (response && response.id)
          dispatch(setLicense(response))
      });
  }, [tries]);

  useEffect(() => {
    if (!state.info?.id) return;
    const plans = _.pickBy(state.info?.products, function (value, key) {
      return _.startsWith(key, "plan-") && value === true;
    });
    if (_.size(plans) === 0) {
      navigate('/402');
    }
  }, [state.info?.id]);

  return (
    <LicenseContext.Provider value={{ state, dispatch }}>
      {children}
    </LicenseContext.Provider>
  );
};