import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export interface BooleanIconComponentProps {
  value?: boolean;
  titleNull?: string;
  titleTrue?: string;
  titleFalse?: string;
  className?: string;
}

export const BooleanIcon = (props: BooleanIconComponentProps) => {
  const { value, titleNull, titleTrue, titleFalse, className } = props;
  return (<>
    {value === null && <FontAwesomeIcon icon={faCircle} title={titleNull} className={className ? `${className} text-gray-300` : "inline h-8 text-gray-300 mr-3"} />}
    {value === true && <FontAwesomeIcon icon={faCheckCircle} title={titleTrue} className={className ? `${className} text-emerald-500` : "inline h-8 text-emerald-500 mr-3"} />}
    {value === false && <FontAwesomeIcon icon={faTimesCircle} title={titleFalse} className={className ? `${className} text-red-500` : "inline h-8 text-red-500 mr-3"} />}
  </>);
}