
export default <TType>(input?: HTMLInputElement | null, value?: TType) => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
  if (!input) return;
  const nativeInputCheckedSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")?.set;
  if (!nativeInputCheckedSetter) return;
  nativeInputCheckedSetter.call(input, value ?? undefined);
  const inputEvent = new Event('input', { bubbles: true });
  input.dispatchEvent(inputEvent);
}


export function simulateSelectEvent<TType>(input?: HTMLSelectElement | null, value?: TType) { /* eslint-disable-line @typescript-eslint/no-explicit-any */
  if (!input) return;
  const nativeInputCheckedSetter = Object.getOwnPropertyDescriptor(window.HTMLSelectElement.prototype, "value")?.set;
  if (!nativeInputCheckedSetter) return;
  nativeInputCheckedSetter.call(input, value ?? undefined);
  const inputEvent = new Event('select', { bubbles: true });
  input.dispatchEvent(inputEvent);
}