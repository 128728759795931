import _ from 'lodash';
import { Dictionary } from 'lodash';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { HttpQueryFilter, Seller, SellersClient } from 'src/api/financial/Accountancy';
import ComboBox from 'src/components/Form/FormComboBox';
import { TextOptionProps } from 'src/components/Form/FormOptions';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { useTranslation } from 'react-i18next';
import getCountryName from '../i18n/getCountryName';

export interface SellerSelectComponentProps {
  onChange: ((id: string | Dictionary<Seller | undefined>, entity?: Seller) => void);
  value?: string | string[];
  multiple?: boolean;
  placeholder?: string;
  allowCreate?: boolean;
}

const SellerSelect = (props: SellerSelectComponentProps) => {
  const { onChange, value, multiple, allowCreate, ...otherProps } = props;
  const { t, i18n } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const client = new SellersClient(apiConfiguration);
  const [entities, setEntities] = useState<Seller[]>([]);
  const autocompleteTimeout = useRef<(NodeJS.Timeout) | undefined>(undefined);

  const getId = (buyer: Seller) => buyer.name;

  const getLabel = (buyer: Seller) => {
    return <div>
      <div>
        {buyer.name && <b>{buyer.name}</b>}
      </div>
      <div className="text-sm">
        {buyer.vatId && <i>({t('accountancy.sides.fields.vatId')}: {buyer.vatId})</i>}
      </div>
      <div className="text-sm">
        {buyer.address && <span>{buyer.address}</span>}
        {buyer.extra && <span>, {buyer.extra}</span>}
        {buyer.postCode && <span>, {buyer.postCode} </span>}
        {buyer.city && <span>{buyer.city}</span>}
        {buyer.countryCode && <span>, {getCountryName(buyer.countryCode, i18n.resolvedLanguage)}</span>}
      </div>
      <div className="text-xs">
        {buyer.email && <span>{buyer.email} </span>}
        {buyer.phoneNumber && <span>{buyer.phoneNumber}</span>}
      </div>
    </div>
  }

  useEffect(() => {
    applyAutocomplete('');
  }, []);

  const onChangeMiddleware: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value;
    onChange(newValue, entities.find(e => e.id === newValue));
  };
  const onChangeMiddlewareMultiple: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValues = e.target.value.split(',').filter(v => v.length > 0);
    onChange(
      _.chain(newValues.map(v => ({ id: v, value: entities.find(e => e.id === v) })))
        .keyBy('id')
        .mapValues('value')
        .value()
    );
  };
  const applyAutocomplete = (query: string) => {
    client
      .get(
        query
          .split(' ')
          .filter(phrase => phrase.length > 0)
          .map(phrase => ({ type: '%', property: 'Name,VatId,Address,Extra,PostCode,City,PhoneNumber,Email', value: phrase } as HttpQueryFilter)),
        [],
        10,
        0,
        undefined,
        undefined
      )
      .then(response => setEntities(response.items ?? []))
      .catch(console.error);
  };
  const onAutoComplete = (query: string) => {
    if (autocompleteTimeout.current) {
      clearTimeout(autocompleteTimeout.current);
      autocompleteTimeout.current = undefined;
    }
    autocompleteTimeout.current = setTimeout(() => applyAutocomplete(query), 250);
  };
  const options = [
    { value: "create", id: t('common.actions.createNew'), label: t('common.actions.createNew') } as TextOptionProps,
    ...[...entities ?? []].map(r => ({ value: r.id, id: getId(r), label: getLabel(r) }) as TextOptionProps)
  ]
  if (allowCreate) {
    options.push();
  }

  return (
    <ComboBox options={options} value={value} onChange={multiple ? onChangeMiddlewareMultiple : onChangeMiddleware} onAutoComplete={onAutoComplete} multiple={multiple} {...otherProps} />
  );
};

export default SellerSelect;