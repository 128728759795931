
import { ReactNode } from "react";

export interface DetailedListItemComponentProps {
  id: string;
  image: React.ReactNode;
  label: string;
  slogan?: string;
  children?: ReactNode;
  className?: string;
}

export const DetailedListItem = (props: DetailedListItemComponentProps) => {
  const { id, image, label, slogan, children, className } = props;
  return (
    <li key={id} className={`flex flex-col gap-10 pt-12 sm:flex-row ${className}`}>
      {typeof image === 'string' && <img className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" src={image} alt="" />}
      {typeof image !== 'string' && image}
      <div className="max-w-xl flex-auto">
        <h4 className="text-2xl font-medium leading-8 tracking-tight">{label}</h4>
        {slogan && <div className="text-base leading-7 text-opacity-60">{slogan}</div>}
        <div className="mt-6 text-lg leading-7 text-opacity-75">{children}</div>
      </div>
    </li>
  );
}

export interface DetailedListComponentProps {
  children: ReactNode;
}

export const DetailedList = (props: DetailedListComponentProps) => {
  const { children } = props;
  return (
    <ul role="list" className="-mt-12 space-y-12 divide-y divide-gray-200 xl:col-span-3">
      {children}
    </ul>
  )
}