import { faMinusCircle, faPlusCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Invoice, InvoicePosition, PriceType } from 'src/api/financial/Accountancy';
import Button from 'src/components/Actions/Button';
import NoRecordsFound from 'src/components/Feedback/NoRecordsFound';
import FormInput from 'src/components/Form/FormInput';
import Select from 'src/components/Form/FormSelect';
import FormSwitch from 'src/components/Form/FormSwitch';
import FormSection from 'src/components/Layout/Panel/FormSection';
import { IForm } from 'src/hooks/useForm';

export interface PositionsFormComponentProps {
  form: IForm<Invoice>;
}

export interface PositionFormComponentProps {
  form: IForm<Invoice>;
  id: number;
  row: InvoicePosition;
  advanced?: boolean;
  onRemove: () => void;
}

function PositionForm(props: PositionFormComponentProps) {
  const { form, id, row, advanced, onRemove } = props;
  const { t, i18n } = useTranslation();

  const priceTypes = [
    { id: '0', value: 0, label: t('accountancy.enums.priceType.0') },
    { id: '1', value: 1, label: t('accountancy.enums.priceType.1') }
  ];
  const taxRates = [
    { id: '0', value: '0', label: '0%' },
    { id: '0.05', value: 0.05, label: '5%' },
    { id: '0.08', value: 0.08, label: '8%' },
    { id: '0.23', value: 0.23, label: '23%' }
  ];

  const formatCurrency = (amount: number) =>
    Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency: form.data.currencyCode }).format(amount);

  const calculateNetCost = (): number => {
    if (!row.amount) return 0.00;
    if (!row.price) return 0.00;
    if (!row.taxRate) return 0.00;
    if (row.priceType == PriceType.FromNet)
      return row.amount * row.price;
    return row.amount * row.price * (1 / (1 + row.taxRate));
  }
  const calculateGrossCost = (): number => {
    if (!row.amount) return 0.00;
    if (!row.price) return 0.00;
    if (!row.taxRate) return 0.00;
    if (row.priceType == PriceType.FromNet)
      return row.amount * row.price * (1 + row.taxRate);
    return row.amount * row.price;
  }

  const calculateNetRebate = (): number => {
    if (!row.rebateRate && !row.rebateValue) return 0.00;
    if (!row.amount) return 0.00;
    if (!row.price) return 0.00;
    if (!row.taxRate) return 0.00;

    let rebate = 0.00;
    if (row.rebateValue)
      rebate += Number(row.rebateValue) * (1 / (1 + row.taxRate));
    if (row.rebateRate)
      rebate += calculateNetCost() * Number(row.rebateRate);
    return rebate;
  }

  const calculateGrossRebate = (): number => {
    if (!row.rebateRate && !row.rebateValue) return 0.00;
    if (!row.amount) return 0.00;
    if (!row.price) return 0.00;
    if (!row.taxRate) return 0.00;

    let rebate = 0.00;
    if (row.rebateValue)
      rebate += Number(row.rebateValue);
    if (row.rebateRate)
      rebate += calculateGrossCost() * Number(row.rebateRate);
    return rebate;
  }

  const rebateNet = calculateNetRebate();
  const rebateGross = calculateGrossRebate();
  const costNet = calculateNetCost() - rebateNet;
  const costGross = calculateGrossCost() - rebateGross;
  const costTax = costGross - costNet;

  return (
    <tr>
      <td>{id + 1}</td>
      <td className={advanced ? "pb-3 px-3" : "hidden pb-3 px-3"}>
        <FormInput.WithoutLabel
          {...form.input(
            `positions.${id}.productNumber`,
            'text',
            {
              placeholder: t('accountancy.invoices.positions.fields.productNumber')
            }
          )}
        />
      </td>
      <td className={advanced ? "pb-3 px-3" : "hidden pb-3 px-3"}>
        <FormInput.WithoutLabel
          {...form.input(
            `positions.${id}.code`,
            'text',
            {
              placeholder: t('accountancy.invoices.positions.fields.code')
            }
          )}
        />
      </td>
      <td className={advanced ? "pb-3 px-3" : "hidden pb-3 px-3"}>
        <FormInput.WithoutLabel
          {...form.input(
            `positions.${id}.ean`,
            'text',
            {
              placeholder: t('accountancy.invoices.positions.fields.ean')
            }
          )}
        />
      </td>
      <td className="pb-3 px-3">
        <FormInput.WithoutLabel
          {...form.input(
            `positions.${id}.name`,
            'text',
            {
              placeholder: t('accountancy.invoices.positions.fields.name')
            }
          )}
        />
      </td>
      <td className={advanced ? "pb-3 px-3" : "hidden pb-3 px-3"}>
        <FormInput.WithoutLabel
          {...form.input(
            `positions.${id}.pkwiu`,
            'text',
            {
              placeholder: t('accountancy.invoices.positions.fields.pkwiu')
            }
          )}
        />
      </td>
      <td className={advanced ? "pb-3 px-3" : "hidden pb-3 px-3"}>
        <FormInput.WithoutLabel
          {...form.input(
            `positions.${id}.gtu`,
            'text',
            {
              placeholder: t('accountancy.invoices.positions.fields.gtu')
            }
          )}
        />
      </td>
      <td className="pb-3 px-3">
        <FormInput.WithoutLabel
          {...form.input(
            `positions.${id}.amount`,
            'number',
            {
              min: 0.01,
              step: 0.01,
              placeholder: t('accountancy.invoices.positions.fields.amount')
            }
          )}
        />
      </td>
      <td className={advanced ? "pb-3 px-3" : "hidden pb-3 px-3"}>
        <FormInput.WithoutLabel
          {...form.input(
            `positions.${id}.unit`,
            'text',
            {
              placeholder: t('accountancy.invoices.positions.fields.unit')
            }
          )}
        />
      </td>
      <td className={advanced ? "pb-3 px-3" : "hidden pb-3 px-3"}>
        <Select
          options={priceTypes}
          {...form.input(
            `positions.${id}.priceType`,
            'select'
          )}
        />
      </td>
      <td className="pb-3 px-3">
        <FormInput.WithoutLabel
          {...form.input(
            `positions.${id}.price`,
            'number',
            {
              min: 0.01,
              step: 0.01,
              placeholder: t('accountancy.invoices.positions.fields.price')
            }
          )}
        />
      </td>
      <td className="pb-3 px-3">
        <Select
          options={taxRates}
          {...form.input(
            `positions.${id}.taxRate`,
            'select'
          )}
        />
      </td>
      <td className={advanced ? "pb-3 px-3" : "hidden pb-3 px-3"}>
        <FormInput.WithoutLabel
          {...form.input(
            `positions.${id}.rebateRate`,
            'number',
            {
              min: 0.01,
              step: 0.01,
              placeholder: t('accountancy.invoices.positions.fields.rebateRate')
            }
          )}
        />
      </td>
      <td className={advanced ? "pb-3 px-3" : "hidden pb-3 px-3"}>
        <FormInput.WithoutLabel
          {...form.input(
            `positions.${id}.rebateValue`,
            'number',
            {
              min: 0.01,
              step: 0.01,
              placeholder: t('accountancy.invoices.positions.fields.rebateValue')
            }
          )}
        />
      </td>
      <td className="pb-3 px-3 text-end">
        {formatCurrency(costNet)}
      </td>
      <td className={advanced ? "pb-3 px-3 text-end" : "hidden pb-3 px-3 text-end"}>
        {formatCurrency(rebateGross)}
      </td>
      <td className="pb-3 px-3 text-end">
        {formatCurrency(costTax)}
      </td>
      <td className="pb-3 px-3 text-end">
        {formatCurrency(costGross)}
      </td>
      <td>
        <FontAwesomeIcon className="text-red-700 cursor-pointer mb-2" icon={faMinusCircle} onClick={onRemove} />
      </td>
    </tr>
  )
}

export default function PositionsForm(props: PositionsFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const [advanced, setAdvanced] = useState(false);

  const addPosition = () => {
    form.setData({
      ...form.data,
      positions: [
        ...form.data.positions || [],
        {
          productNumber: '',
          code: '',
          ean: '',
          name: '',
          pkwiu: '',
          gtu: '',
          amount: 0.00,
          priceType: 0,
          price: 0.00,
          taxRate: 0.23,
        }
      ]
    } as Invoice);
  }

  const removePosition = (index: number) => {
    const updatedPositions = [...form.data.positions ?? []];
    updatedPositions.splice(index, 1);
    const data = {
      ...form.data,
      positions: updatedPositions
    } as Invoice;
    form.setData(data);
  }

  return (
    <FormSection
      title={t('accountancy.invoices.form.positions.header')}
      description={t('accountancy.invoices.form.positions.subheader')}
      full
      withoutSidebar
    >
      <div className="overflow-x-auto overflow-y-visible">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th>{t('accountancy.invoices.positions.fields.no')}</th>
              <th className={advanced ? "min-w-32" : "hidden min-w-32"}>{t('accountancy.invoices.positions.fields.productNumber')}</th>
              <th className={advanced ? "min-w-32" : "hidden min-w-32"}>{t('accountancy.invoices.positions.fields.code')}</th>
              <th className={advanced ? "min-w-32" : "hidden min-w-32"}>{t('accountancy.invoices.positions.fields.ean')}</th>
              <th className="min-w-64">{t('accountancy.invoices.positions.fields.name')}</th>
              <th className={advanced ? "min-w-32" : "hidden min-w-32"}>{t('accountancy.invoices.positions.fields.pkwiu')}</th>
              <th className={advanced ? "min-w-24" : "hidden min-w-24"}>{t('accountancy.invoices.positions.fields.gtu')}</th>
              <th className="min-w-24">{t('accountancy.invoices.positions.fields.amount')}</th>
              <th className={advanced ? "min-w-24" : "hidden min-w-24"}>{t('accountancy.invoices.positions.fields.unit')}</th>
              <th className={advanced ? "min-w-24" : "hidden min-w-24"}>{t('accountancy.invoices.positions.fields.priceType')}</th>
              <th className="min-w-24">{t('accountancy.invoices.positions.fields.price')}</th>
              <th className="min-w-24">{t('accountancy.invoices.positions.fields.taxRate')}</th>
              <th className={advanced ? "min-w-24" : "hidden min-w-24"}>{t('accountancy.invoices.positions.fields.rebateRate')}</th>
              <th className={advanced ? "min-w-24" : "hidden min-w-24"}>{t('accountancy.invoices.positions.fields.rebateValue')}</th>
              <th className="min-w-32">{t('accountancy.invoices.positions.fields.costNet')}</th>
              <th className={advanced ? "min-w-32" : "hidden min-w-32"}>{t('accountancy.invoices.positions.fields.rebate')}</th>
              <th className="min-w-32">{t('accountancy.invoices.positions.fields.tax')}</th>
              <th className="min-w-32">{t('accountancy.invoices.positions.fields.costGross')}</th>
              <th><FontAwesomeIcon className="text-emerald-700 cursor-pointer" icon={faPlusCircle} onClick={addPosition} /></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {form.data.positions?.map((row, id) => <PositionForm key={id} id={id} row={row} form={form} advanced={advanced} onRemove={() => removePosition(id)} />)}
            {!form.data.positions?.length && <NoRecordsFound colSpan={20} onClickAdd={addPosition} addLabel={t('accountancy.invoices.positions.actions.add')} />}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={10}>
                <FormSwitch.Decorated
                  name="advanced"
                  checked={advanced}
                  onChange={(e) => setAdvanced(e.target.checked)}
                  placeholder={t('accountancy.invoices.positions.advanced')}
                />
              </td>
              <td colSpan={10} className="pt-3 text-end">
                <Button type="button" colorName="emerald" onClick={addPosition}><FontAwesomeIcon icon={faPlusCircle} /> {t('accountancy.invoices.positions.actions.add')}</Button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </FormSection>
  )
}