import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MetaClient } from "src/api/notifications/Notifications";
import Spinner from "src/components/Feedback/Spinner";
import useApiConfiguration from "src/hooks/useApiConfiguration";


const SMSWidget = () => {
  const { t, i18n } = useTranslation();
  const [sentSmsMessages, setSentSmsMessages] = useState<number>();
  const smsPrice = 0.123;
  const smsMesssagesCost = sentSmsMessages ? (sentSmsMessages * smsPrice) : 0;
  const apiConfiguration = useApiConfiguration();
  const apiClient = new MetaClient(apiConfiguration);

  useEffect(() => {
    apiClient.statistics()
      .then(response => setSentSmsMessages(response?.sms))
  }, []);

  return (
    <div className="py-3 w-full max-w-sm h-full">
      <h2 className="leading-2 text-xl mt-3">{t('panel.sms.header')}</h2>
      <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5 py-6">
        {sentSmsMessages !== undefined && <>
          <div className="flex gap-3 justify-between">
            <div>
              <h4 className="uppercase text-sm text-gray-400">{t('panel.sms.sent')}</h4>
              <div className="text-xl">{sentSmsMessages}</div>
            </div>
            <div>
              <h4 className="uppercase text-sm text-gray-400">{t('panel.sms.cost')}</h4>
              <div className="text-xl">{smsMesssagesCost.toLocaleString(i18n.resolvedLanguage, { style: 'currency', currency: 'PLN' })}</div>
            </div>
          </div>
          <div className="text-xs text-gray-400 mt-3">
            {t('panel.sms.month')}
          </div>
        </>}
        {sentSmsMessages === undefined && <div className="text-center w-full py-6"><Spinner className="mx-auto h-8" /></div>}
      </div>
    </div>
  )
}

export default SMSWidget;