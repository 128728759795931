import { MessagingState } from './state';
import { ActionType, MessagingActions } from './actions';
import { Message, MessageTopic } from 'src/api/notifications/Notifications';

export function applicationReducer(state: MessagingState, action: MessagingActions): MessagingState {
    switch (action.type) {
        case ActionType.SetActiveTopic:
            return { ...state, activeTopic: action.payload, messages: [] };
        case ActionType.SetUnread:
            return { ...state, unread: action.payload };
        case ActionType.SetMessages:
            return { ...state, messages: purizeMessages(state.messages, action.payload) };
        case ActionType.SetTopics:
            return { ...state, topics: action.payload };
        case ActionType.CountUnread:
            return { ...state, unread: countUnread(state.topics, action.payload, state.activeTopic) };
        default:
            return state;
    }
}

const countUnread = (topics: MessageTopic[], userId?: string, activeTopic?: MessageTopic): number => {
    if (!userId) return 0;
    return [...topics.map(topic => {
        const user = topic.users?.find(u => u.userId === userId);
        const lastMessage = topic.messages?.find(_m => true);
        if (activeTopic && activeTopic.id === topic.id) return false;
        if (!lastMessage) return false;
        if (!user) return false;
        if (!lastMessage?.created) return false;
        if (!user?.readAt) return true;
        if (user.readAt < lastMessage.created) return true;

    })]
        .filter(topic => topic === true)
        .length;
}

const purizeMessages = (array: Message[], secondArray: Message[]): Message[] => {
    const distinct = distinctMessages(array, secondArray);
    const orderedMessages = setOrderedMessages(distinct);
    return orderedMessages;
}

const distinctMessages = (array: Message[], secondArray: Message[]): Message[] => {
    const merged = [...array, ...secondArray];
    const distinct = merged.filter((value, index, self) => self.findIndex(v => v.id === value.id) === index);
    return distinct;
}

const setOrderedMessages = (messages: Message[]): Message[] => {
    return messages.sort((a: Message, b: Message) => (a.created?.getTime() ?? 0) - (b.created?.getTime() ?? 0));
}