import { faPlusCircle, faTimes } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Currency, ExchangeRate, ExchangeRatesClient, HttpQueryFilter } from 'src/api/financial/Accountancy';
import Button from 'src/components/Actions/Button';
import Table, { TableComponentRef } from 'src/components/Data/Table';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import Toast from 'src/components/Feedback/Toast';
import { ITableColumn } from 'src/components/Table/Table';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useAuthGuard from 'src/hooks/useAuthGuard';
import useClaim from 'src/hooks/useClaim';
import { IForm } from 'src/hooks/useForm';

export interface ExchangeRatesFormComponentProps {
  form: IForm<Currency>;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function ExchangeRatesForm(props: ExchangeRatesFormComponentProps) {
  const { form, open, setOpen } = props;
  const { t, i18n } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new ExchangeRatesClient(apiConfiguration);
  const tableRef = createRef<TableComponentRef<ExchangeRate>>();
  const updateClaim = useClaim('CurrenciesUpdate');
  const deleteClaim = useClaim('CurrenciesDelete');
  const authGuard = useAuthGuard('/panel/', ['CurrenciesRead']);
  const filters = [{ property: 'TargetCurrencyCode,SourceCurrencyCode', value: form.data.id, type: '%' } as HttpQueryFilter];

  useEffect(() => {
    if (!open) tableRef.current?.refresh();
  }, [open]);


  /*const signalRCallbacks = {
    onUpdate: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onDelete: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onCreate: (_id: string) => tableRef.current?.isLastInvoice() && tableRef.current?.refresh()
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Accountancy)}/hubs/InvoicesHub`);

  useEffect(() => {
    if (tableRef.current && signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
      signalRHub.on("onCreate", signalRCallbacks.onCreate)
    }
  }, [tableRef.current, signalRHub]);*/

  const rateFormat = (row: ExchangeRate) => {
    if (row.rate === undefined) return "0.0000";
    if (form.data.id === row.sourceCurrencyCode)
      return Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency: row.targetCurrencyCode, minimumSignificantDigits: 4, maximumSignificantDigits: 4 }).format(row.rate);
    else
      return Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency: row.sourceCurrencyCode, minimumSignificantDigits: 4, maximumSignificantDigits: 4 }).format(1 / row.rate);
  }

  const onClickDelete = (entity: ExchangeRate) => {
    if (entity?.id) {
      apiClient.delete(entity.id)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.deleted"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
  }

  const columns: ITableColumn<ExchangeRate>[] = [
    { label: t('common.fields.date'), selector: row => row.date?.toLocaleDateString(), id: "date" },
    { label: t('accountancy.currencies.exchangeRates.fields.sourceCurrency'), selector: row => row.sourceCurrencyCode, id: "sourceCurrencyCode" },
    { label: t('accountancy.currencies.exchangeRates.fields.targetCurrency'), selector: row => row.targetCurrencyCode, id: "targetCurrencyCode" },
    { label: t('accountancy.currencies.exchangeRates.fields.rate'), selector: rateFormat, id: "rate" },
    { label: t('common.fields.source'), selector: () => "Internal", id: "id" },
    {
      id: 'buttons', label: '', selector: (row) => <div className="text-end">
        {deleteClaim && form.data.id && <Button type="button" colorName="red" onClick={() => onClickDelete(row)}>
          <FontAwesomeIcon icon={faTimes} className="w-5" />
        </Button>}
      </div >
    }
  ];

  if (authGuard === undefined) return <AuthGuardLoading />;

  return (
    <>
      <Table<ExchangeRate>
        ref={tableRef}
        apiClient={apiClient}
        columnDefinitions={columns}
        filters={filters}
      />
      <div className="text-end">
        <Button type="button" colorName="emerald" className="px-3 py-1" disabled={!updateClaim} onClick={() => setOpen(true)}>
          <FontAwesomeIcon icon={faPlusCircle} /> {t('accountancy.currencies.exchangeRates.actions.add')}
        </Button>
      </div>
    </>
  )
}