import React, { FC, useEffect, useReducer } from 'react';
import { AccountancyState, initialAccountancyState } from './state';
import { AccountancyActions, setPaymentMethods, setPaymentMethodStatuses } from './actions';
import { configurationReducer } from './reducer';
import _ from 'lodash';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { PaymentMethodsClient } from 'src/api/financial/Accountancy';

export const AccountancyContext = React.createContext<{
  state: AccountancyState;
  dispatch: React.Dispatch<AccountancyActions>;
}>({
  state: initialAccountancyState,
  dispatch: () => undefined,
});

interface AccountancyProviderProps {
  children: React.ReactNode;
}

export const AccountancyProvider: FC<AccountancyProviderProps> = ({ children }) => {
  const apiConfiguration = useApiConfiguration();
  const apiClient = new PaymentMethodsClient(apiConfiguration);

  const [state, dispatch] = useReducer(
    configurationReducer,
    {
      ...initialAccountancyState,
    }
  );

  const fetchPaymentMethods = () => {
    apiClient.get([], [], 1000, 0, undefined, undefined)
      .then(response => dispatch(setPaymentMethods(response.items)))
      .catch(() => dispatch(setPaymentMethods([])));
  }

  const fetchPaymentMethodStatuses = (methodId: string) => {
    apiClient.find(methodId)
      .then(response => dispatch(setPaymentMethodStatuses(methodId, response.statuses ?? [])))
      .catch(() => dispatch(setPaymentMethods([])));
  }

  useEffect(() => {
    if (state.paymentMethods.isLoading && !state.paymentMethods.loaded) {
      fetchPaymentMethods();
    }
  }, [state.paymentMethods.isLoading]);

  useEffect(() => {
    if (!state.paymentMethodStatuses.isLoading && state.requestedPaymentMethodStatuses.length > 0) {
      fetchPaymentMethodStatuses(state.requestedPaymentMethodStatuses[0])
    }
  }, [state.requestedPaymentMethodStatuses]);

  return (
    <AccountancyContext.Provider value={{ state, dispatch }}>
      {children}
    </AccountancyContext.Provider>
  );
};