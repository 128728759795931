import { InputHTMLAttributes, Ref, useEffect, useImperativeHandle, useState } from "react";
import { useCallback } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import Button from "../Actions/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileUpload, faTimes } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../Feedback/Spinner";
import { useTranslation } from "react-i18next";
import React from "react";
import { Uploader } from "src/hooks/useUploader";

interface FormImageUploaderComponentProps extends InputHTMLAttributes<HTMLInputElement> {
  uploader: Uploader;
  noUploadButton?: boolean;
}

export const FormImageUploader = React.forwardRef((props: FormImageUploaderComponentProps, ref: Ref<FormImageComponentRef>) => {
  const { uploader, noUploadButton } = props;
  return <FormImage ref={ref} onUploadFile={uploader.onUploadFile} current={uploader.photo} noUploadButton={noUploadButton} />
});

interface FormImageComponentProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  current?: string;
  onUploadFile: (file: File) => Promise<string>;
  noUploadButton?: boolean;
}

export interface FormImageComponentRef {
  upload: () => void;
  reset: () => void;
  file?: File;
}

export interface FileDropzoneComponentProps {
  onSelectFile: (file: File) => void;
  options?: DropzoneOptions;
}

const FileDropzone = (props: FileDropzoneComponentProps) => {
  const { onSelectFile, options } = props;
  const { t } = useTranslation();
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      onSelectFile(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    ...options,
    onDrop
  });

  return (
    <div {...getRootProps()}>
      <Button type="button" colorName="primary" className="mt-3 mb-0"><FontAwesomeIcon icon={faFileUpload} className="h-5" /> {t('ui.uploader.selectFile')}</Button>
      <input {...getInputProps()} />
    </div>
  );
}

const FormImage = React.forwardRef((props: FormImageComponentProps, ref: Ref<FormImageComponentRef>) => {
  const { current, onUploadFile, noUploadButton } = props;
  const { t } = useTranslation();
  const [file, setFile] = useState<File>();
  const [fallback, setFallback] = useState(false);
  const [fileBase64, setFileBase64] = useState<string | ArrayBuffer | null>();
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (!file) {
      setFileBase64(undefined);
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => setFileBase64(reader.result);
    reader.onerror = console.error;
  }, [file]);

  useEffect(() => {
    setFallback(false);
  }, [current]);

  const onImageError = () => setFallback(true);

  const onReset = () => {
    setFile(undefined);
  }

  const onConfirm = () => {
    if (!file) return;
    setUploading(true);
    onUploadFile(file)
      .then(() => setUploading(false));
    setFileBase64(undefined);
  }

  useImperativeHandle(ref, () => ({
    upload: onConfirm,
    reset: onReset,
    file
  }));

  return (
    <div className="bg-gray-100 rounded-md gap-3 w-auto overflow-hidden cursor-pointer" >
      <div className="flex flex-wrap">
        <div className="h-24 w-24 mr-5">
          {fileBase64 && <img src={fileBase64 as string} />}
          {!fileBase64 && !fallback && <img className="rounder-sm" src={current} onError={onImageError} />}
        </div>
        <div className="py-3 px-5">
          {current && !fallback && <div className="text-gray-500 text-xs">{t('ui.uploader.changeCurrentImage')}</div>}
          {(!current || fallback) && <div className="text-gray-500 text-xs">{t('ui.uploader.uploadNewImage')}</div>}
          {uploading && <Spinner className="mt-3 h-6" />}
          {!uploading && !fileBase64 && <FileDropzone onSelectFile={setFile} options={{
            accept: {
              'image/png': ['.png'],
              'image/jpg': ['.jpeg', '.jpg'],
              'image/gif': ['.gif'],
              'image/tiff': ['.tiff'],
            }
          }} />}
          {!uploading && fileBase64 && <Button type="button" onClick={onReset} colorName="red" className="mt-3 mb-0"><FontAwesomeIcon icon={faTimes} className="h-5" /></Button>}
          {!uploading && fileBase64 && noUploadButton !== true && <Button type="button" onClick={onConfirm} colorName="primary" className="mt-3 mb-0"><FontAwesomeIcon icon={faFileUpload} className="h-5" /> {t('ui.uploader.uploadImage')}</Button>}
        </div>
      </div>
    </div>
  )
})

export default FormImage;