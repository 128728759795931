import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { InvoiceNumbering } from 'src/api/financial/Accountancy';
import Button from 'src/components/Actions/Button';
import FormInput from 'src/components/Form/FormInput'
import { TextOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormSection from 'src/components/Layout/Panel/FormSection';
import useClaim from 'src/hooks/useClaim';
import { IForm } from 'src/hooks/useForm';

export interface GeneralFormComponentProps {
  form: IForm<InvoiceNumbering>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const isDeveloper = useClaim('Developer');

  const invoiceTypes = [0, 1, 2, 3, 4, 5];
  const invoiceTypeOptions: TextOptionProps[] =
    invoiceTypes.map((i) => ({
      id: String(i),
      value: Number(i),
      label: t(`accountancy.enums.invoiceType.${i}`)
    }));

  const invoiceNumberingPeriods = [0, 1, 2, 3, 4];
  const invoiceNumberingPeriodOptions: TextOptionProps[] =
    invoiceNumberingPeriods.map((i) => ({
      id: String(i),
      value: Number(i),
      label: t(`accountancy.enums.invoiceNumberingPeriod.${i}`)
    }));

  const generateCurrentPeriodStamp = () => {
    let generatedStamp: string = '';
    const date = moment(new Date());
    switch (Number(form.data.period)) {
      case 0:
        generatedStamp = date.format('YYYY');
        break;
      case 1:
        generatedStamp = date.format('YYYYQ');
        break;
      case 2:
        generatedStamp = date.format('YYYYMM');
        break;
      case 3:
        generatedStamp = date.format('YYYYww');
        break;
      case 4:
        generatedStamp = date.format('YYYYMMDD');
        break;
      default:
        break;
    }
    form.setData({ ...form.data, periodStamp: generatedStamp } as InvoiceNumbering);
  }

  return (
    <FormSection
      title={t('common.form.general.header')}
      description={t('common.form.general.subheader')}
    >
      {isDeveloper && <div className="mb-10">
        <FormInput.Clean name="id" placeholder="Id" value={form.data.id} readOnly />
      </div>}
      <div className="mb-10">
        <Select {...form.input('type', 'select', { placeholder: t('accountancy.invoiceNumberings.fields.type') })} options={invoiceTypeOptions} />
      </div>
      <div className="mb-10">
        <Select {...form.input('period', 'select', { placeholder: t('accountancy.invoiceNumberings.fields.period') })} options={invoiceNumberingPeriodOptions} />
      </div>
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('format', 'text', { required: true, minLength: 3, placeholder: t('accountancy.invoiceNumberings.fields.format') })} />
      </div>
      <div className="mb-10 flex">
        <div className="flex-1">
          <FormInput.Overlapping {...form.input('periodStamp', 'text', { placeholder: t('accountancy.invoiceNumberings.fields.periodStamp') })} />
        </div>
        <div>
          <Button type="button" className="px-2.5 py-2.5 mt-2" onClick={generateCurrentPeriodStamp}><FontAwesomeIcon icon={faCog} className="h-4" /></Button>
        </div>
      </div>
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('current', 'number', { min: 0, placeholder: t('accountancy.invoiceNumberings.fields.current') })} />
      </div>
    </FormSection>
  )
}