import { useTranslation } from 'react-i18next';
import ApplicationLogo from 'src/components/Branding/ApplicationLogo';
import configuration from 'src/config/config';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="bg-white border-t border-gray-200 pt-4 text-xs mt-8">
      <div className="flex justify-between">
        <div className="flex-1">
          <div className="text-xs leading-5 text-gray-400 mt-2">&copy; {new Date().getFullYear()} Redini.app. {t('footer.copyright')}.</div>
        </div>
        <a href="https://redini.app/" target="_blank" rel="noopener">
          <div className="text-gray-500 text-xl opacity-50 hover:opacity-75 active:opacity-100">
            <span className="font-branding mr-2">Redini</span>
            <ApplicationLogo className="inline h-4 w-auto mb-1 mr-3 fill-gray-500 flex-1" />
            <span className="text-xs ml-3">v. {configuration.version}</span>
          </div>
        </a>
      </div>
    </footer>
  )
}

export default Footer;