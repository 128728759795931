import NewsletterBar from 'src/components/Front/NewsletterBar'
import Instructors from '../Stable/Instructors'
import TrainingTypes from '../Stable/TrainingTypes'
import Horses from '../Stable/Horses'
import useConfigurationState from 'src/hooks/useConfigurationState'
import HeroIndexWidget from './Widgets/Hero'
import AboutIndexWidget from './Widgets/About'
import NewsfeedIndexWidget from './Widgets/Newsfeed'
import { MetaHead } from 'src/components/Meta/MetaHead'


const Index = () => {
  const configurationState = useConfigurationState();

  return (
    <>
      <MetaHead />
      {configurationState.layout.landing.slider && <HeroIndexWidget />}
      {configurationState.layout.landing.newsfeed && <NewsfeedIndexWidget />}
      {configurationState.layout.landing.aboutUs && <AboutIndexWidget />}
      {configurationState.layout.landing.trainingTypes && <TrainingTypes />}
      {configurationState.layout.landing.horses && <Horses />}
      {configurationState.layout.landing.instructors && <Instructors />}
      <NewsletterBar />
    </>
  )
}

export default Index;