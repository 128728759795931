import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Actions/Button";
import Toast from "src/components/Feedback/Toast";
import useApiConfiguration from "src/hooks/useApiConfiguration";

import TranslationForm from "../../Cms/Galleries/Galleries/Form/Translation";
import useForm from "src/hooks/useForm";
import { ClientGalleriesClient, Content } from "src/api/cms/Cms";
import FormAlert from "src/components/Form/FormAlert";
import PhotosForm from "../../Cms/Galleries/Galleries/Form/Photos";
import { LogoClient } from "src/api/core/Core";
import { ConfigurationApis, getApiBaseUrl } from "src/config/config";
import useTenant from "src/hooks/useTenant";
import FormImage from "src/components/Form/FormImage";

const LandingPersonalizationSection = () => {
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const tenant = useTenant();

  const form = useForm<Content>();
  const apiClient = new ClientGalleriesClient(apiConfiguration);

  const [_error, setError] = useState(false);
  const logoClient = new LogoClient(apiConfiguration);
  const logoUrl = `${getApiBaseUrl(ConfigurationApis.Core)}/api/v1/logo/download?XTenantId=${tenant}`;
  const [logo, setLogo] = useState<string>(logoUrl);

  const uploadLogo = (file: File) => new Promise<string>(
    (resolve, reject) => {
      const onLogoUploadError = () => {
        setError(true);
        setLogo(logo);
        reject();
      }
      const onLogoUploadSuccess = () => {
        setError(false);
        setLogo(`${logo}&${(new Date()).getTime()}`);
        resolve(logo);
      }

      logoClient
        .uploadLogo(undefined, { fileName: file.name, data: file })
        .catch(onLogoUploadError)
        .then(onLogoUploadSuccess);
    }
  );

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    apiClient.findById('7bd2e5c8-2a2d-4cdd-a51e-639e2aaa1852')
      .then(response => form.setData(response))
      .catch(form.catchAnyException);
  }

  const onSubmit = () => {
    apiClient.update('7bd2e5c8-2a2d-4cdd-a51e-639e2aaa1852', form.data)
      .then(response => {
        form.setData(response);
        Toast.success(t("common.status.success"), t("common.feedback.saved"));
      })
      .catch(ex => form.catchAnyException(ex, true))
      .finally(() => form.setPending(false));
  }

  return (
    <>
      <form onSubmit={e => form.onSubmit(e, onSubmit)}>
        <FormAlert code={form.error} errorMessages={t('common.errors', { returnObjects: true })} />
        <TranslationForm form={form} noMeta />
        <PhotosForm form={form} />
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6 hidden md:block">{t('panel.settings.general.logo.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="w-full lg:w-1/2 mb-8">
              <FormImage current={logo} onUploadFile={uploadLogo} />
            </div>
          </dd>
        </div>
        <div className="flex justify-end">
          <Button colorName="primary" disabled={form.pending} className="px-5 py-3 mt-8"><FontAwesomeIcon icon={faSave} className="h-5" /> {t('common.actions.save')}</Button>
        </div>
      </form>
    </>
  );
}

export default LandingPersonalizationSection;