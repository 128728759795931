import { Outlet, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocalizedLink from "../Router/LocalizedLink";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import usePageTracking from "src/hooks/usePageTracking";

function SignInLink() {
  const { t } = useTranslation();
  return (
    <LocalizedLink to="/auth/sign-in" className="font-medium text-gray-400 hover:text-gray-500">
      <FontAwesomeIcon icon={faAngleLeft} className="inline w-4 mr-1" />
      {t('auth.signIn.action')}
    </LocalizedLink>
  )
}

function HomeLink() {
  const { t } = useTranslation();
  return (
    <LocalizedLink to="/" className="font-medium text-gray-400 hover:text-gray-500">
      <FontAwesomeIcon icon={faAngleLeft} className="inline w-4 mr-1" />
      {t('common.errors.actions.goBackHome')}
    </LocalizedLink>
  )
}

export default function AuthorizationLayout() {
  const location = useLocation();

  const isSignInPage = location.pathname.includes('/auth/sign-in');

  usePageTracking();
  return (
    <>
      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            {isSignInPage ? <HomeLink /> : <SignInLink />}

            <Outlet />
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/img/backgrounds/enjoy2.webp"
            alt=""
          />
        </div>
      </div>
    </>
  )
}