import { FC, useEffect } from 'react';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { NotificationBroadcaster, NotificationBroadcastersClient } from 'src/api/notifications/Notifications';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import Header from './Header';
import GeneralForm from './Form/General';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import Button from 'src/components/Actions/Button';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import TranslationForm from './Form/Translation';
import useLocalizedNavigate from 'src/hooks/useNavigate';

const NotificationBroadcastersForm: FC = () => {
  const apiConfiguration = useApiConfiguration();

  const apiClient = new NotificationBroadcastersClient(apiConfiguration);
  const form = useForm<NotificationBroadcaster>();
  const authGuard = useAuthGuard('/panel/', ['NotificationBroadcastersRead']);
  const broadcastersUpdateClaim = useClaim('NotificationBroadcastersUpdate');
  const { id } = useParams<string>();
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();

  const onDelete = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id === 'create') return;
    apiClient.delete(id)
      .finally(() => {
        form.setPending(false);
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        navigate(`/panel/notifications/broadcasters/`)
      })
  }

  const onSubmit = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id !== 'create') {
      apiClient.update(id, form.data)
        .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    } else {
      apiClient.create(form.data)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.form.saved"));
          navigate(`/panel/notifications/broadcasters/`);
        })
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    }
  }

  const fetch = () => {
    if (!id) return;
    apiClient.find(id)
      .then(form.setData)
      .catch(form.catchAnyException);
  }

  useEffect(() => {
    if (id !== 'create') fetch();
    applicationDispatch(
      setBreadcrumbs([
        { label: "notifications.broadcasters.group", href: "/panel/notifications/broadcasters/" },
        { label: id !== 'create' ? 'common.actions.edit' : 'common.actions.createNew', href: `/panel/notifications/broadcasters/${id}` }
      ])
    );
  }, [id]);

  useEffect(() => {
    form.setReadOnly(!broadcastersUpdateClaim);
  }, [broadcastersUpdateClaim])

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <form onSubmit={e => form.onSubmit(e, onSubmit)}>
        <Header broadcaster={form.data} onDelete={onDelete} form={form} />
        <GeneralForm form={form} />
        <TranslationForm form={form} />
        <div className="text-end mt-5">
          <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
            {t('common.actions.save')}
          </Button>
        </div>
      </form >
    </>
  )
}

export default NotificationBroadcastersForm;