import { faFence } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RiderTrainingsClient,
  Training,
  TrainingType,
  TrainingTypeTranslation,
  Instructor,
  InstructorTranslation,
  Horse,
  HorseTranslation,
  Place,
  QueryOrderDirection,
  HttpQueryOrder,
  InstructorTrainingsClient,
} from "src/api/stable/Booking";
import { PlaceTranslation } from "src/api/stable/Stable";
import LoadingOverlay from "src/components/Feedback/LoadingOverlay";
import LocalizedLink from "src/components/Router/LocalizedLink";
import { HorseImage } from "src/components/Stable/HorseImage";
import Avatar from "src/components/User/Avatar";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import classNames from "src/components/Utilities/classNames";
import { BooleanIcon } from "src/components/Feedback/BooleanIcon";
import StatusCodeAlert from "src/components/Feedback/StatusCodeAlert";
import Alert from "src/components/Feedback/Alert";
import useClaim from "src/hooks/useClaim";
import useUser from "src/hooks/useUser";
import MessageToUserButton from "src/components/Messages/MessageToUserButton";

interface TrainingCardComponentProps {
  training: Training;
}

const TrainingCard = (props: TrainingCardComponentProps) => {
  const { training } = props;
  const { t, i18n } = useTranslation();
  const user = useUser();
  const trainingTypeTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();
  const instructorTranslation = useEntityTranslation<Instructor, InstructorTranslation>();
  const horseTranslation = useEntityTranslation<Horse, HorseTranslation>();
  const placeTranslation = useEntityTranslation<Place, PlaceTranslation>();
  return (
    <>
      <LocalizedLink to={`/user/trainings/${training.id}`}>
        <div className={classNames(["my-3 mb-8 p-5 bg-gray-50 rounded-lg", training?.isConfirmed ? "opacity-100" : "opacity-50"])}>
          <div className="flex flex-wrap justify-between">
            <div>
              <h3 className="text-2xl">
                {trainingTypeTranslation.getCurrentTranslation(training.type)?.name}
                <BooleanIcon
                  value={training.isConfirmed}
                  titleNull={t('stable.trainings.isConfirmed.null')}
                  titleFalse={t('stable.trainings.isConfirmed.false')}
                  titleTrue={t('stable.trainings.fields.isConfirmed')}
                  className="inline size-6 mx-3"
                />
              </h3>
              <div className="text-lg">{training.number || training.id}</div>
            </div>
            <div className="text-end text-lg">
              <div>{training.start.toLocaleString(i18n.resolvedLanguage, { weekday: 'long', day: 'numeric', month: 'long', hour: '2-digit', minute: '2-digit' })}</div>
              <div>
                {training.isConfirmed === true && t('stable.trainings.fields.isConfirmed')}
                {training.isConfirmed === false && t('stable.trainings.isConfirmed.false')}
                {training.isConfirmed === null && t('stable.trainings.isConfirmed.null')}
              </div>
            </div>
          </div>
          <div className="flex flex-wrap divide-x divide-gray-100 border-t border-gray-100 mt-3 py-3">
            {user?.id === training?.rider?.userId && <div className="md:flex-1 w-1/2 md:w-auto mb-8 md:mb-0 flex">
              <div>
                <Avatar userId={training.instructor?.userId} className="size-8 object-cover rounded-full ring-1 mr-3" />
              </div>
              <div>
                <div className="text-sm font-light">{t('stable.instructors.item')}</div>
                <div className="font-medium">{instructorTranslation.getCurrentTranslation(training.instructor)?.name}</div>
              </div>
              <div className="relative ml-3">
                <MessageToUserButton userId={training?.instructor?.userId} />
              </div>
            </div>}
            {user?.id === training?.instructor?.userId && <div className="md:flex-1 w-1/2 md:w-auto mb-8 md:mb-0 flex">
              <div>
                <Avatar userId={training.rider?.userId} className="size-8 object-cover rounded-full ring-1 mr-3" />
              </div>
              <div>
                <div className="text-sm font-light">{t('stable.riders.item')}</div>
                <div className="font-medium">{training?.rider?.user?.fullName}</div>
              </div>
              <div className="relative ml-3">
                <MessageToUserButton userId={training?.rider?.userId} />
              </div>
            </div>}
            <div className="md:flex-1 w-1/2 md:w-auto mb-8 md:mb-0 flex">
              <div >
                <HorseImage id={training.horseId} className="size-8 object-cover rounded-full ring-1 mr-3" />
              </div>
              <div>
                <div className="text-sm font-light">{t('stable.horses.item')}</div>
                <div className="font-medium">{horseTranslation.getCurrentTranslation(training.horse)?.name}</div>
              </div>
            </div>
            <div className="md:flex-1 w-1/2 md:w-auto flex">
              <div>
                <FontAwesomeIcon icon={faFence} className="inline size-8 text-gray-500 mr-3" />
              </div>
              <div>
                <div className="text-sm font-light">{t('stable.places.item')}</div>
                <div className="font-medium">{placeTranslation.getCurrentTranslation(training.place)?.name}</div>
              </div>
            </div>
            <div className="hidden">
              <div>
                <BooleanIcon value={training.isPaid} className="inline size-8 mr-3" />
              </div>
              <div>
                <div className="text-sm font-light">{t('accountancy.paymentMethods.item')}</div>
                <div className="font-medium">On-line</div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </LocalizedLink>
    </>
  )
}

const Trainings = () => {
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const [error, setError] = useState<number | undefined>();
  const [loading, setLoading] = useState(false);
  const [trainings, setTrainings] = useState<Training[] | undefined>(undefined);
  const instructorClaim = useClaim('TrainingsRead');

  const trainingsApi = instructorClaim ? new InstructorTrainingsClient(apiConfiguration) : new RiderTrainingsClient(apiConfiguration);

  const fetchTrainings = () => {
    setLoading(true);
    trainingsApi
      .get(undefined, [{ property: 'End', direction: QueryOrderDirection.DESC }] as HttpQueryOrder[], undefined, undefined, undefined, undefined)
      .catch(error => setError(error.status))
      .then((response) => setTrainings(response?.items))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchTrainings();
  }, []);

  const pastTrainings = trainings?.filter(t => t.start && t.start < new Date()) || [];
  const upcomingTrainings = trainings?.filter(t => t.start && t.start >= new Date()) || [];
  const todayTrainings = trainings?.filter(t => t.start && t.start.toDateString() === new Date().toDateString()) || [];

  return (
    <>
      <div>
        <h2 className="text-2xl font-medium leading-9 tracking-tight text-gray-900">
          {t('stable.trainings.group')}
        </h2>
      </div>
      <div className="relative">
        {trainings?.length === 0 && !loading && <Alert.Information title={t('common.noResults')} noClose />}
        <StatusCodeAlert code={error} messages={{ "404": t('common.noResults') }} noClose />
        {loading && <LoadingOverlay />}
        {todayTrainings.length > 0 &&
          <>
            <h3 className="text-3xl mt-8">{t('stable.trainings.time.today')}</h3>
            <div className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-700">
              {todayTrainings?.map((training) => <TrainingCard key={training.id} training={training} />)}
            </div>
          </>
        }
        {upcomingTrainings.length > 0 &&
          <>
            <h3 className="text-3xl mt-8">{t('stable.trainings.time.upcoming')}</h3>
            <div className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-700">
              {upcomingTrainings?.map((training) => <TrainingCard key={training.id} training={training} />)}
            </div>
          </>
        }
        {pastTrainings.length > 0 &&
          <>
            <h3 className="text-3xl mt-8">{t('stable.trainings.time.past')}</h3>
            <div className="mt-6 divide-y divide-gray-200 border-t border-gray-200 text-sm font-medium text-gray-700">
              {pastTrainings?.map((training) => <TrainingCard key={training.id} training={training} />)}
            </div>
          </>
        }
      </div>
    </>
  )
}

export default Trainings;