import { HTMLAttributes, useEffect, useState } from "react";
import { ClientPagesClient, Content, ContentTranslation } from "src/api/cms/Cms";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import LocalizedLink from "../Router/LocalizedLink";
import Spinner from "../Feedback/Spinner";

export interface PageLinkComponentProps {
  id?: string;
}

const PageLink = (props: PageLinkComponentProps & HTMLAttributes<HTMLAnchorElement>) => {
  const { id, ...otherProps } = props;
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientPagesClient(apiConfiguration);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<Content | undefined>(undefined);

  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(page);

  useEffect(() => {
    if (id) {
      setLoading(true);
      apiClient.findById(id)
        .then(response => setPage(response))
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [id])

  if (loading) {
    return <Spinner className="mx-auto h-4" />;
  }

  if (!page) {
    return <><span>Page not found.</span></>;
  }

  return <LocalizedLink to={`/p/${translation?.url}`} {...otherProps}>{translation?.title}</LocalizedLink>
}

export default PageLink;