import { createRef, useEffect } from "react";
import { Message } from "src/api/notifications/Notifications";
import { MessageBubble } from "./MessageBubble";
import useMessagingContext from "src/hooks/stores/useMessagingContext";
import { setMessages } from "src/store/messaging/actions";

export interface MessagesTopicProps {
  topicId: string;
  repliedMessage?: Message;
}

export default (props: MessagesTopicProps) => {
  const { repliedMessage } = props;
  const divRef = createRef<HTMLDivElement>();
  const { state, dispatch } = useMessagingContext();

  useEffect(() => {
    if (repliedMessage) {
      dispatch(setMessages([repliedMessage]));
    }
  }, [repliedMessage]);

  useEffect(() => {
    divRef.current?.scrollTo(0, divRef.current?.scrollHeight);
  }, [state.messages]);

  return (
    <>
      <div ref={divRef} className="overflow-y-scroll max-h-[50vh]">
        {state.messages?.map(message => <MessageBubble key={message.id} message={message} />)}
      </div>
    </>
  );
}