import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { ClientPagesClient, Content, ContentTranslation, ProblemDetails } from "src/api/cms/Cms";
import Spinner from "src/components/Feedback/Spinner";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import Breadcrumbs from "src/components/Navigation/Breadcrumbs";
import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import { setBreadcrumbs } from "src/store/application/actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useLocalizedNavigate from "src/hooks/useNavigate";
import { validate } from "uuid";
import NewsletterBar from "src/components/Front/NewsletterBar";
import ContentParse from "src/components/Cms/ContentParse";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import SectionHeader from "src/components/Typography/SectionHeader";
import { MetaPageHead } from "src/components/Meta/MetaHead";

export interface PageElementComponentProps {
  page: Content;
}

export const PageHeader = (props: PageElementComponentProps) => {
  const { page } = props;
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(page);
  return (
    <>
      <MetaPageHead id={page.id} />
      <div>
        <Breadcrumbs />
      </div>
      <div className="flex border-b border-gray-200 pb-5 mt-12 mb-5 justify-between">
        <SectionHeader>{translation?.title}</SectionHeader>
      </div>
    </>
  )
}

export const PageContent = (props: PageElementComponentProps) => {
  const { page } = props;
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(page);
  return (
    <div className="border-b border-gray-200 pb-5 mb-5">
      <ContentParse>{translation?.content}</ContentParse>
    </div>
  )
}

export const PageFooter = (props: PageElementComponentProps) => {
  const { page } = props;
  const { t, i18n } = useTranslation();
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(page);
  return (
    <div className="border-b border-gray-200 pb-5 flex justify-between text-xs text-gray-400">
      <div>
        <FontAwesomeIcon icon={faTag} className="inline h-3 mb-1 mr-3" /> {translation?.meta?.keywords}
        &nbsp;
      </div>
      <div>
        {page.updated && <span>{t('common.fields.updated')}: {page.updated?.toLocaleString(i18n.resolvedLanguage)}</span>}
      </div>
    </div>
  )
}

const Page = () => {
  const { url } = useParams<string>();
  const { i18n } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientPagesClient(apiConfiguration);
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const applicationDispatch = useApplicationDispatch();
  const navigate = useLocalizedNavigate();

  const [page, setPage] = useState<Content | undefined>();
  const translation = entityTranslation.getCurrentTranslation(page);

  const onError = (e: ProblemDetails) => {
    switch (e.status) {
      case 404:
        navigate('/404');
        break;
      default:
        console.error(e);
        break;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (validate(url || "")) {
      apiClient.findById(url || "")
        .then(response => setPage(response))
        .catch(onError);
    } else {
      apiClient.findByUrl(url || "")
        .then(response => setPage(response))
        .catch(onError);
    }
  }, [url, i18n?.resolvedLanguage]);

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: translation?.meta?.title || translation?.title || "Untitled", href: `/p/${url}` }
      ])
    );
  }, [translation?.meta?.title, translation?.title]);

  if (!page) {
    return <Spinner className="h-24" />;
  }

  return (
    <>

      <div className="px-3 md:px-0 max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto">
        <PageHeader page={page} />
        <PageContent page={page} />
        <PageFooter page={page} />
      </div>
      <NewsletterBar />
    </>
  )
}

export default Page;