import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import './MessagesTopicsMenu.css';
import { useTranslation } from 'react-i18next';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessages } from '@fortawesome/pro-duotone-svg-icons';
import MessageTopicsList from './MessageTopicsList';
import useMessagingContext from 'src/hooks/stores/useMessagingContext';
import StartNewConversation from './StartNewConversation';




export default function MessageTopicsMenu() {
  const { t } = useTranslation();
  const { state } = useMessagingContext();

  return (
    <div className="flex justify-end mr-6 md:mr-5">
      <Menu as="div" className="md:relative inline-block text-left">
        <Menu.Button className="flex items-center text-gray-400 hover:text-[--color-primary-700]">
          <FontAwesomeIcon icon={faMessages} className="h-6 ml-3 mr-2 inline" />
          {state.unread! > 0 && <span className="bg-[--color-primary-700] text-white px-2 py-1 rounded-md text-xs">{state.unread}</span>}
          <FontAwesomeIcon icon={faAngleDown} className="inline -mr-1 h-5 w-5" aria-hidden="true" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute w-full left-0 md:left-auto md:right-0 md:w-96 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <h3 className="text-lg p-3">
              <div className="lg:flex">
                <div className="lg:flex lg:flex-1">
                  {t('crm.messages.group')}
                </div>
              </div>
            </h3>
            <div className="messages-container">
              <MessageTopicsList />
            </div>
            <StartNewConversation />
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
