import usePageTracking from 'src/hooks/usePageTracking';
import Footer from './Footer'
import Header from './Header'
import { Outlet } from 'react-router'
import { LicenseProvider } from 'src/store/license/context';

const FrontLayout = () => {
  usePageTracking();
  return (
    <>
      <LicenseProvider>
        <div className="bg-gradient-to-t from-[--color-primary-50] to-white">
          <Header />
          <div className="pt-4">
            <Outlet />
          </div>
          <Footer />
        </div>
      </LicenseProvider>
    </>
  )
}

export default FrontLayout;