import { Message, MessageTopic } from "src/api/notifications/Notifications";

export enum ActionType {
    SetActiveTopic,
    SetUnread,
    SetMessages,
    SetTopics,
    CountUnread
}

export interface SetActiveTopic {
    type: ActionType.SetActiveTopic;
    payload?: MessageTopic;
}

export interface SetUnread {
    type: ActionType.SetUnread;
    payload?: number;
}

export interface SetMessages {
    type: ActionType.SetMessages;
    payload: Message[];
}

export interface SetTopics {
    type: ActionType.SetTopics;
    payload: MessageTopic[];
}

export interface CountUnread {
    type: ActionType.CountUnread;
    payload?: string;
}

export const setActiveTopic = (topic?: MessageTopic): SetActiveTopic => ({
    type: ActionType.SetActiveTopic,
    payload: topic,
});

export const setUnread = (unread?: number): SetUnread => ({
    type: ActionType.SetUnread,
    payload: unread,
});

export const setMessages = (messages: Message[]): SetMessages => ({
    type: ActionType.SetMessages,
    payload: messages,
});

export const setTopics = (topics: MessageTopic[]): SetTopics => ({
    type: ActionType.SetTopics,
    payload: topics,
});

export const countUnread = (userId?: string): CountUnread => ({
    type: ActionType.CountUnread,
    payload: userId,
});

export type MessagingActions = SetActiveTopic | SetUnread | SetMessages | SetTopics | CountUnread;