import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from "react";
import classNames from "../Utilities/classNames";
import ExtendedInputHTMLAttributes from "./ExtendedInputHTMLAttributes";
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import FormRequired from './FormRequired';

interface FormInputHTMLAttributes<T> extends ExtendedInputHTMLAttributes<T> {
  noBorder?: boolean;
  suffix?: string;
}

export const FormInput: FC<FormInputHTMLAttributes<HTMLInputElement>> = (props: FormInputHTMLAttributes<HTMLInputElement>) => {
  const { name, isInvalid, isValid, validationFeedback, icon, noBorder, suffix, className, ...otherProps } = props;
  const haveFeedback = validationFeedback && (isInvalid || isValid);

  return (
    <div className={classNames(
      !noBorder && "shadow-sm mt-2",
      "relative rounded-md",
    )}>
      {icon && <div
        className={classNames(
          !noBorder && "pl-3",
          noBorder && "pl-0",
          "pointer-events-none absolute inset-y-0 left-0 flex items-center"
        )}>{icon}</div>}
      <input
        {...otherProps}
        id={name}
        name={name}
        className={classNames(
          (!className?.includes('ring-0') && !noBorder) && `ring-1 ring-inset ring-gray-300 shadow-smfocus:ring-2 focus:ring-inset focus:ring-[--color-primary-600] `,
          noBorder && "focus:ring-0 p-0 ",
          icon && !noBorder && "pl-10 ",
          icon && noBorder && "pl-7 ",
          !icon && !noBorder && "pl-3 ",
          "block w-full rounded-md border-0 py-1.5 placeholder:text-gray-400 sm:text-sm sm:leading-6 ",
          className
        )}
        aria-describedby={haveFeedback ? `${name}-feedback` : undefined}
      />
      {suffix && <span className="pointer-events-none absolute inset-y-0 right-8 flex items-center text-gray-500">{suffix}</span>}
      {isInvalid &&
        <div className={classNames(
          !noBorder && " pr-3",
          noBorder && "pr-0",
          "pointer-events-none absolute inset-y-0 right-0 flex items-center"
        )}>
          <FontAwesomeIcon icon={faTimes} className="h-5 w-5 text-red-500" aria-hidden="true" title="Invalid" />
        </div>
      }
      {
        isValid &&
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <FontAwesomeIcon icon={faCheck} className="h-5 w-5 text-green-500" aria-hidden="true" title="Valid" />
        </div>
      }
    </div >
  )
}

export const Feedback: FC<ExtendedInputHTMLAttributes<HTMLInputElement>> = (props: ExtendedInputHTMLAttributes<HTMLInputElement>) => {
  const { name, isInvalid, isValid, validationFeedback } = props;
  const haveFeedback = validationFeedback && (isInvalid || isValid);

  if (!haveFeedback) return <></>;

  return (
    <div className="pb-2">
      {isInvalid && <div className="mt-2 text-sm text-red-600" id={`${name}-feedback`}>{validationFeedback}</div>}
      {isValid && <div className="mt-2 text-sm text-green-600" id={`${name}-feedback`}>{validationFeedback}</div>}
    </div>
  )
}

export const FormLabel: FC<ExtendedInputHTMLAttributes<HTMLInputElement>> = (props: ExtendedInputHTMLAttributes<HTMLInputElement>) => {
  const { name, placeholder, required } = props;
  return (
    <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900 mt-2">
      {placeholder}<FormRequired required={required} />
    </label>
  )
}

const FormInputDefault: FC<ExtendedInputHTMLAttributes<HTMLInputElement>> = (props: ExtendedInputHTMLAttributes<HTMLInputElement>) => {
  return (
    <div>
      {<FormLabel {...props} />}
      {<FormInput {...props} />}
      {<Feedback {...props} />}
      {props["aria-description"] && <div className="text-xs text-gray-500">{props["aria-description"]}</div>}
    </div>
  )
}

const FormInputWithoutLabel: FC<ExtendedInputHTMLAttributes<HTMLInputElement>> = (props: ExtendedInputHTMLAttributes<HTMLInputElement>) => {
  return (
    <div>
      {<FormInput {...props} />}
      {<Feedback {...props} />}
      {props["aria-description"] && <div className="text-xs text-gray-500">{props["aria-description"]}</div>}
    </div>
  )
}

const FormInputOverlapping: FC<ExtendedInputHTMLAttributes<HTMLInputElement>> = (props: ExtendedInputHTMLAttributes<HTMLInputElement>) => {
  const { placeholder, required } = props;

  return (
    <div className="relative">
      <label htmlFor={props.id} className="absolute z-10 -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">
        {placeholder}<FormRequired required={required} />
      </label>
      {<FormInput {...props} className="pt-1" />}
      {<Feedback {...props} />}
      {props["aria-description"] && <div className="text-xs text-gray-500">{props["aria-description"]}</div>}
    </div>
  )
}

const FormInputInset: FC<ExtendedInputHTMLAttributes<HTMLInputElement>> = (props: ExtendedInputHTMLAttributes<HTMLInputElement>) => {
  const { placeholder, required } = props;

  return (
    <div>
      <div className="bg-white rounded-md px-3 pb-1.5 pt-2.5 shadow-sm relative ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-[--color-primary-600]">
        <label htmlFor={props.id} className="block text-xs font-medium text-gray-900">
          {placeholder}<FormRequired required={required} />
        </label>
        {<FormInput {...props} noBorder={true} />}
      </div>
      {<Feedback {...props} />}
      {props["aria-description"] && <div className="text-xs text-gray-500">{props["aria-description"]}</div>}
    </div>
  )
}

const FormInputClean: FC<ExtendedInputHTMLAttributes<HTMLInputElement>> = (props: ExtendedInputHTMLAttributes<HTMLInputElement>) => {
  return (
    <div>
      {<FormLabel {...props} />}
      {<FormInput {...props} noBorder={true} />}
      {<Feedback {...props} />}
      {props["aria-description"] && <div className="text-xs text-gray-500">{props["aria-description"]}</div>}
    </div>
  )
}

const FormInputIndev: FC<ExtendedInputHTMLAttributes<HTMLInputElement>> = (props: ExtendedInputHTMLAttributes<HTMLInputElement>) => {
  const { placeholder } = props;

  return (
    <div>
      <div className="rounded-md px-3 pb-1.5 pt-2.5 shadow-sm relative ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-[--color-primary-600]">
        <label htmlFor={props.id} className="block text-xs font-medium text-gray-900">
          {placeholder}
        </label>
        {<FormInput {...props} noBorder={true} />}
      </div>
      {<Feedback {...props} />}
    </div>
  )
}

export default {
  Default: FormInputDefault,
  WithoutLabel: FormInputWithoutLabel,
  Overlapping: FormInputOverlapping,
  Inset: FormInputInset,
  Clean: FormInputClean,
  Indev: FormInputIndev
};