import { HTMLAttributes } from "react"
import { ConfigurationApis, getApiBaseUrl } from "src/config/config";
import useTenant from "src/hooks/useTenant";
import useUser from "src/hooks/useUser";
import Image from "../Media/Image";

export interface AvatarComponentProps extends HTMLAttributes<HTMLDivElement> {
  userId?: string;
}

export default (props: AvatarComponentProps) => {
  const { userId, ...otherProps } = props;
  const user = useUser();
  const tenant = useTenant();
  const defaultSource = `${getApiBaseUrl(ConfigurationApis.Auth)}/api/v1/avatars/${userId ?? user?.id}?XTenantId=${tenant}`;
  return <Image src={defaultSource} fallbackSrc='/img/avatars/man.webp' {...otherProps} />
}