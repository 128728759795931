import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { TrainingsClient, HttpQueryFilter } from "src/api/stable/Booking";
import useApiConfiguration from "src/hooks/useApiConfiguration";

interface Data {
  name: string;
  value: number;
}

const TrainingsChart = () => {
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const client = new TrainingsClient(apiConfiguration);
  const [data, setData] = useState<Data[]>([]);

  useEffect(() => {
    getStatisticsForInterval();
  }, [])

  const getStatisticsForInterval = () => {
    const currentDate = new Date();
    const months: string[] = [];

    for (let i = 5; i >= 0; i--) {
      const month = currentDate.getMonth() - i;
      const year = currentDate.getFullYear();
      const formattedMonth = `${year}-${String(month + 1).padStart(2, '0')}`;
      months.push(formattedMonth);
    }

    Promise.all(
      months.map((month) =>
        client.get(
          [{ property: 'Start.ToString()', type: '%', value: month } as HttpQueryFilter],
          undefined,
          1,
          0,
          undefined,
          undefined
        )
      )
    ).then((responses) => {
      const newData = responses.map((response, index) => ({
        name: months[index],
        value: response.totalCount || 0,
      }));
      setData(newData);
    });
  };

  return (
    <div>
      <h2 className="leading-2 text-xl my-3">{t('stable.trainings.group')}</h2>
      <LineChart width={800} height={400} data={data} style={{ maxWidth: '100%' }}>
        <CartesianGrid stroke="#ccc" />
        <Line type="monotone" dataKey="value" stroke="green" label={t('stable.trainings.group')} />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
      </LineChart>
    </div >
  );
}

export default TrainingsChart;