import { createRef } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { TableComponentRef } from 'src/components/Data/Table';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { ITableColumn } from 'src/components/Table/Table';
import Button from 'src/components/Actions/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import { PaymentMethod, PaymentMethodTranslation, Invoice, InvoicesClient, HttpQueryFilter, Buyer } from 'src/api/financial/Accountancy';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import { getPaymentStatusColor, getPaymentStatusLabel } from '../../../Invoices/Table';
import { IForm } from 'src/hooks/useForm';

interface BuyerInvoicesTableComponentProps {
  form: IForm<Buyer>;
}

const BuyerInvoicesTable = (props: BuyerInvoicesTableComponentProps) => {
  const { form } = props;
  const { t, i18n } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new InvoicesClient(apiConfiguration);
  const tableRef = createRef<TableComponentRef<Invoice>>();
  const updateClaim = useClaim('InvoicesUpdate');
  const deleteClaim = useClaim('InvoicesDelete');
  const authGuard = useAuthGuard('/panel/', ['InvoicesRead']);
  const paymentMethodTranslation = useEntityTranslation<PaymentMethod, PaymentMethodTranslation>();
  const filters = [{ property: 'buyerId', value: form.data.id, type: '=' } as HttpQueryFilter];

  /*const signalRCallbacks = {
    onUpdate: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onDelete: (id: string) => tableRef.current?.getData().some(e => e.id === id) && tableRef.current?.refresh(),
    onCreate: (_id: string) => tableRef.current?.isLastInvoice() && tableRef.current?.refresh()
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Accountancy)}/hubs/InvoicesHub`);

  useEffect(() => {
    if (tableRef.current && signalRHub) {
      signalRHub.on("onUpdate", signalRCallbacks.onUpdate)
      signalRHub.on("onDelete", signalRCallbacks.onDelete)
      signalRHub.on("onCreate", signalRCallbacks.onCreate)
    }
  }, [tableRef.current, signalRHub]);*/

  const onClickDelete = (entity: Invoice) => {
    if (entity?.id) {
      apiClient.delete(entity.id)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.feedback.deleted"));
          tableRef.current?.refresh()
        })
        .catch(() => Toast.error(t("common.status.error"), t("common.errors.delete")));
    }
  }

  const columns: ITableColumn<Invoice>[] = [
    { label: t('accountancy.invoices.fields.type'), selector: row => t(`accountancy.enums.invoiceType.${row.type}`), id: "id" },
    { label: t('accountancy.invoices.fields.number'), selector: row => row.number, id: "number" },
    { label: t('accountancy.invoices.fields.value'), selector: row => row.value ? Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency: row.currencyCode }).format(row.value.gross!) : "", id: "id" },
    { label: t('accountancy.paymentMethods.item'), selector: row => paymentMethodTranslation.getCurrentTranslation(row.paymentMethod)?.title, id: "id" },
    { label: t('accountancy.invoices.fields.issueDate'), selector: row => row.issueDate?.toLocaleDateString(), id: "id" },
    { label: t('accountancy.invoices.fields.transactionDate'), selector: row => row.transactionDate?.toLocaleDateString(), id: "id" },
    { label: t('accountancy.invoices.fields.paymentTerm'), selector: row => row.paymentTerm?.toLocaleDateString(), id: "id" },
    { label: t('accountancy.invoices.fields.paymentDate'), selector: row => row.paymentDate?.toLocaleDateString(), id: "id" },
    { label: t('common.fields.status'), selector: row => <span className={getPaymentStatusColor(row)}>{t(getPaymentStatusLabel(row))}</span>, id: "id" },
    { label: t('common.fields.created'), selector: row => row.created?.toLocaleDateString(), isOrderable: true, id: "created" },
    { label: t('common.fields.updated'), selector: row => row.updated?.toLocaleDateString(), isOrderable: true, id: "updated" },
    {
      id: 'buttons', label: '', selector: (row) => <div className="text-end">
        {updateClaim &&
          <LocalizedLink to={`/panel/accountancy/invoices/${row.id}`}>
            <Button colorName="primary">
              <FontAwesomeIcon icon={faEdit} className="w-5" />
            </Button>
          </LocalizedLink>
        }
        {deleteClaim && form.data.id && <Button colorName="red" onClick={() => onClickDelete(row)}>
          <FontAwesomeIcon icon={faTimes} className="w-5" />
        </Button>}
      </div >
    }
  ];

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <Table<Invoice>
        ref={tableRef}
        apiClient={apiClient}
        columnDefinitions={columns}
        filters={filters}
      />
    </>
  )
}

export default BuyerInvoicesTable;