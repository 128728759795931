
import { ChangeEvent, useEffect, useState } from 'react';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import GridSelect, { GridSelectOption } from 'src/components/Form/GridSelect';
import { useTranslation } from 'react-i18next';
import useForm, { IForm } from 'src/hooks/useForm';
import FormInput from 'src/components/Form/FormInput';
import useUser from 'src/hooks/useUser';
import { Dictionary } from 'lodash';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { PaymentMethodsClient, PaymentMethod, PaymentMethodTranslation } from 'src/api/financial/Payments';
import Spinner from 'src/components/Feedback/Spinner';
import { Horse, Instructor, PersonalData, Training, TrainingType, UserCoupon, UserCouponsClient } from 'src/api/stable/Booking';
import ContentParse from 'src/components/Cms/ContentParse';
import { PhoneNumberFormInset } from 'src/components/Form/PhoneNumberForm';
import BookTrainingHeader from './BookTrainingHeader';
import useFunctionality from 'src/hooks/stores/useFunctionality';
import moment from 'moment';

interface PersonalDataViewComponentProps {
  onSelect: (pd: PersonalData, pm?: PaymentMethod, c?: UserCoupon) => void;
  onGoBack: () => void;
  requirePhoneNumber?: boolean;
  personalData?: PersonalData;
  date: Date,
  type: TrainingType;
  instructor?: Instructor;
  horse?: Horse;
}

interface PaymentMethodFormComponentProps {
  paymentMethod?: PaymentMethod;
  setPaymentMethod: (v?: PaymentMethod) => void;
  coupon?: UserCoupon;
  setCoupon: (v?: UserCoupon) => void;
  date: Date,
  type: TrainingType;
  instructor?: Instructor;
  horse?: Horse;
}

interface PersonalDataFormComponentProps {
  form: IForm<PersonalData>;
  disabled: boolean;
  requirePhoneNumber?: boolean;
}

const PaymentMethodForm = (props: PaymentMethodFormComponentProps) => {
  const { paymentMethod, coupon, setPaymentMethod, setCoupon, date, type, horse, instructor } = props;
  const [methods, setMethods] = useState<PaymentMethod[]>();
  const [coupons, setCoupons] = useState<UserCoupon[]>();
  const [loading, setLoading] = useState(false);

  const apiConfiguration = useApiConfiguration();
  const paymentMethodsClient = new PaymentMethodsClient(apiConfiguration);
  const couponsClient = new UserCouponsClient(apiConfiguration);
  const entityTranslation = useEntityTranslation<PaymentMethod, PaymentMethodTranslation>();

  const onChangeMethod = (e: ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod(methods?.find(p => p.id === e.target.value));
  }
  const onChangeCoupon = (e: ChangeEvent<HTMLInputElement>) => {
    setCoupon(coupons?.find(c => c.id === e.target.value));
  }

  useEffect(() => {
    if (!coupon) return;
    setPaymentMethod(undefined);
  }, [coupon]);

  useEffect(() => {
    if (!paymentMethod) return;
    setCoupon(undefined);
  }, [paymentMethod]);

  useEffect(() => {
    setLoading(true);
    const training = { start: date, end: moment(date).add(type.duration, 'minutes').toDate(), typeId: type.id! } as Training;
    if (horse) training.horseId = horse.id!;
    if (instructor) training.instructorId = instructor.id!;
    paymentMethodsClient
      .get(undefined, undefined, undefined, undefined, undefined, undefined)
      .then(response => setMethods(response.items ?? []))
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
    couponsClient
      .getForTraining(
        undefined, undefined, undefined, undefined, undefined, undefined, training)
      .then(response => setCoupons(response.items ?? []))
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  const methodOptions = methods?.map(m => ({
    id: m.id,
    value: m.id,
    label: entityTranslation.getCurrentTranslation(m)?.title,
    description: <ContentParse>{entityTranslation.getCurrentTranslation(m)?.description}</ContentParse>
  }) as GridSelectOption);

  const couponOptions = coupons?.map(c => ({
    id: c.id,
    value: c.id,
    label: c.title,
    description: <ContentParse>{`Pozostało: ${c.validity?.remain ?? 0} / ${c.validity?.count ?? 0}`}</ContentParse>
  }) as GridSelectOption);

  return (
    <>
      {loading ?? <Spinner className="mx-auto h-8" />}
      {methodOptions && <GridSelect value={paymentMethod?.id} options={methodOptions} onChange={onChangeMethod} full />}
      {couponOptions && <GridSelect value={coupon?.id} options={couponOptions} onChange={onChangeCoupon} full />}
    </>
  )
}

const PersonalDataForm = (props: PersonalDataFormComponentProps) => {
  const { form, disabled, requirePhoneNumber } = props;
  const { t } = useTranslation();
  return (<>
    <div className="py-3">
      <FormInput.Inset {...form.input('givenName', 'text', { placeholder: t('users.fields.givenName'), required: true, minLength: 2, disabled: disabled })} />
    </div>
    <div className="py-3">
      <FormInput.Inset {...form.input('surname', 'text', { placeholder: t('users.fields.surname'), required: true, minLength: 2, disabled: disabled })} />
    </div>
    <div className="py-3">
      <FormInput.Inset {...form.input('email', 'email', { placeholder: t('auth.fields.email'), required: true, minLength: 4, disabled: disabled })} pattern="[^@\s]+@[^@\s]+\.[^@\s]+" title={t('validation.NotValidEmail')} />
    </div>
    <div className="py-3">
      <PhoneNumberFormInset {...form.input('phoneNumber', 'text', { placeholder: t('auth.fields.phoneNumber'), required: requirePhoneNumber, disabled: disabled })} />
    </div>
  </>)
}

const PersonalDataView = (props: PersonalDataViewComponentProps) => {
  const { onSelect, onGoBack, personalData, requirePhoneNumber, date, type, instructor, horse } = props;
  const paymentsFunctionality = useFunctionality('module-payments');
  const user = useUser();
  const { t } = useTranslation();

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | undefined>();
  const [coupon, setCoupon] = useState<UserCoupon | undefined>();
  const form = useForm<PersonalData>({ ...{}, ...user, ...personalData } as PersonalData);

  const arePaymentsAvailable = paymentsFunctionality;

  const validate = () => {
    const result: Dictionary<string[]> = {};
    if (arePaymentsAvailable && paymentMethod === undefined && coupon === undefined) {
      result.paymentMethod = ['Required'];
    }
    return result;
  }

  const onSubmit = () => {
    onSelect(form.data, paymentMethod, coupon);
    form.setPending(false);
  }

  const isDisabled: boolean = (arePaymentsAvailable && !paymentMethod && !coupon) || !form.data.givenName || !form.data.surname || !form.data.email || form.pending;

  return (
    <form onSubmit={(e) => form.onSubmit(e, onSubmit, validate)}>
      <div className="max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto px-4 md:px-0 mb-8">
        <BookTrainingHeader onClickBack={onGoBack} disabledNext={isDisabled} submitButton />
        <div className="lg:flex lg:justify-between">
          <div className="lg:w-5/12 lg:pr-3">
            <h2 className="text-2xl pb-5 mb-5 border-b border-gray-200">{t('stable.trainings.booking.fillPersonalData')}</h2>
            <PersonalDataForm form={form} disabled={user !== undefined} requirePhoneNumber={requirePhoneNumber} />
          </div>
          {arePaymentsAvailable &&

            <div className="lg:w-7/12">
              <h2 className="text-2xl pb-5 mb-5 border-b border-gray-200">{t('stable.trainings.booking.selectPaymentMethod')}</h2>
              <PaymentMethodForm date={date} type={type} instructor={instructor} horse={horse} paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} coupon={coupon} setCoupon={setCoupon} />
            </div>
          }
        </div>
        <BookTrainingHeader onClickBack={onGoBack} disabledNext={isDisabled} submitButton />
      </div>
    </form>
  )
}

export default PersonalDataView;