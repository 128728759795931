import { ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TrainingType, TrainingTypePlace, Place, PlacesClient, PlaceTranslation } from 'src/api/stable/Stable';
import { TextOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormSection from 'src/components/Layout/Panel/FormSection';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import { IForm } from 'src/hooks/useForm';

export interface PlacesFormComponentProps {
  form: IForm<TrainingType>;
}

export default function PlacesForm(props: PlacesFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const [places, setPlaces] = useState<Place[]>([]);

  const apiConfiguration = useApiConfiguration();
  const placesClient = new PlacesClient(apiConfiguration);
  const placeTranslation = useEntityTranslation<Place, PlaceTranslation>();

  useEffect(() => {
    setPlaces([]);
    placesClient
      .get(
        [],
        [],
        1000,
        0,
        undefined,
        undefined
      )
      .then(response => setPlaces(response.items ?? []))
      .catch(console.error);
  }, []);

  const placeOptions = places?.map(p => ({ id: p.id, value: p.id, label: placeTranslation.getCurrentTranslation(p)?.name }) as TextOptionProps);

  const onSelectPlace: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    const newData: TrainingType = { ...form.data, trainingTypePlaces: [...form.data.trainingTypePlaces || []] } as TrainingType;
    newData.trainingTypePlaces = value !== '' ? value.split(',').map(r => ({ placeId: r } as TrainingTypePlace)) : [];
    form.setData(newData);
  }

  return (
    <FormSection
      title={t('stable.places.group')}
      description={t('stable.trainingTypes.places.description')}
      full
    >
      <div className="mb-10">
        <span>{t("stable.places.group")}</span>
        {placeOptions && !form.pending && <Select options={placeOptions} value={form.data.trainingTypePlaces?.map(hr => hr.placeId!) || []} onChange={onSelectPlace} multiple={true} />}
      </div>
    </FormSection>
  )
}