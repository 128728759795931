import { faHatCowboySide, faSave, faTimesCircle, faPlusCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from "react-i18next";
import { Rider } from "src/api/stable/Stable";
import Button from "src/components/Actions/Button";
import Avatar from 'src/components/User/Avatar';
import useClaim from "src/hooks/useClaim";
import { IForm } from "src/hooks/useForm";

export interface RiderHeaderComponentProps {
  Rider: Rider;
  onDelete: () => void;
  form: IForm<Rider>
}

export default (props: RiderHeaderComponentProps) => {
  const { Rider, onDelete, form } = props;
  const { t } = useTranslation();

  const updateClaim = useClaim('RidersUpdate');
  const deleteClaim = useClaim('RidersDelete');
  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-5 pb-5 border-b border-b-gray-100">
      <div className="flex items-start space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            {!form.data.userId && <FontAwesomeIcon icon={faHatCowboySide} className="h-16 p-3 text-[--color-primary-500]" />}
            {form.data.userId && <Avatar userId={form.data.userId} className="h-16 w-16 rounded-full ring-1" />}
            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
          </div>
        </div>
        <div className="pt-1.5">
          <h1 className="text-2xl font-medium text-gray-900">{form.data.user?.fullName || t('stable.riders.actions.create')}</h1>
          <div className="text-sm font-medium text-gray-500">
            {Rider.created && <span>{t('common.fields.created')}: {Rider.created?.toLocaleString()}</span>}
            {Rider.updated && <span>, {t('common.fields.updated')}: {Rider.updated?.toLocaleString()}</span>}
          </div>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        {updateClaim && <Button className="text-md px-5 py-3" colorName="primary" disabled={form.pending}><FontAwesomeIcon icon={form.data.id ? faSave : faPlusCircle} className="mr-1" /> {form.data.id ? t('common.actions.save') : t('common.actions.add')}</Button>}
        {deleteClaim && form.data.id && <Button className="text-md px-5 py-3" type="button" colorName="red" onClick={onDelete} disabled={form.pending}><FontAwesomeIcon icon={faTimesCircle} className="mr-1" /> {t('common.actions.delete')}</Button>}
      </div>
    </div >
  )
}
