const mappings: string[][] = [
  ["ą", "a"],
  ["ć", "c"],
  ["ę", "e"],
  ["ł", "l"],
  ["ń", "n"],
  ["ó", "o"],
  ["ś", "s"],
  ["ź", "z"],
  ["ż", "z"]
];

const urlParse = (text?: string): string => {
  if (!text) return '';
  text = text.toLowerCase();
  mappings.forEach(([from, to]) => {
    text = text!.replace(new RegExp(from, 'g'), to);
  });
  return text.replace(/ /g, '-').replace(/[^a-z0-9-]/g, '').replace(/-+/g, '-');
}

export default urlParse;