import useMessagingContext from "src/hooks/stores/useMessagingContext";
import { setActiveTopic } from "src/store/messaging/actions";
import MessagesWindow from "./MessagesWindow";

export default () => {
  const { dispatch, state } = useMessagingContext();
  const { activeTopic } = state;

  return (
    <div>
      {activeTopic && <MessagesWindow topic={activeTopic} onClose={() => dispatch(setActiveTopic(undefined))} />}
    </div>
  );
}