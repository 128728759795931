import Spinner from "src/components/Feedback/Spinner";
import { WizardStepProps } from "./Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle } from "@fortawesome/pro-duotone-svg-icons";
import { useEffect, useState } from "react";
import { Horse, HorseInstructor, HorsesClient, HorseTrainingType, HorseTranslation, HttpQueryFilter, Instructor, InstructorsClient, InstructorTrainingType, InstructorTranslation, Place, PlacesClient, PlaceTranslation, TrainingType, TrainingTypePlace, TrainingTypesClient, TrainingTypeTranslation } from "src/api/stable/Stable";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { User, UsersClient } from "src/api/access/Authority";
import Alert from "src/components/Feedback/Alert";
import Button from "src/components/Actions/Button";
import LocalizedLink from "src/components/Router/LocalizedLink";

interface WizardStepInstallationProps extends WizardStepProps {
  horses?: Horse[];
  instructors?: Instructor[];
  places?: Place[];
  trainingTypes: TrainingType[];
}

export default (props: WizardStepInstallationProps) => {

  const [horses, setHorses] = useState([...props.horses || []] as Horse[]);
  const [instructors, setInstructors] = useState([...props.instructors || []] as Instructor[]);
  const [places, setPlaces] = useState([...props.places || []] as Place[]);
  const [trainingTypes, setTrainingTypes] = useState([...props.trainingTypes || []] as TrainingType[]);

  const [error, setError] = useState<string | undefined>();

  const apiConfiguration = useApiConfiguration();

  const horsesApiClient = new HorsesClient(apiConfiguration);
  const instructorsApiClient = new InstructorsClient(apiConfiguration);
  const placesApiClient = new PlacesClient(apiConfiguration);
  const trainingTypesApiClient = new TrainingTypesClient(apiConfiguration);
  const usersClient = new UsersClient(apiConfiguration);

  useEffect(() => {
    if (error) return;

    const index = horses.findIndex(h => !h.id);
    if (index === -1) return;
    const horse = horses.find((_h, i) => i === index);
    if (!horse) return;

    horse.translations = horse.translations?.map(t => ({ ...t, culture: 'pl' } as HorseTranslation)) || [];

    horsesApiClient.create(horse)
      .then((response) => setHorses([...horses].map((h, i) => (i === index ? { ...response } : { ...h }) as Horse)))
      .catch(error => setError(error.status))
  }, [horses]);

  useEffect(() => {
    if (error) return;
    if (horses.some(h => !h.id)) return;

    const index = instructors.findIndex(h => !h.id);
    if (index === -1) return;
    const instructor = instructors.find((_h, i) => i === index);
    if (!instructor) return;

    instructor.translations = instructor.translations?.map(t => ({ ...t, culture: 'pl', name: instructor.user?.givenName } as InstructorTranslation)) || [];
    instructor.horseInstructors = instructor.horseInstructors?.map(hi => ({ ...hi, horseId: horses[Number(hi.horseId)]?.id } as HorseInstructor)) || [];

    usersClient.get([{ property: 'email', value: instructor.user?.email, type: '=' } as HttpQueryFilter], undefined, undefined, undefined, undefined, undefined)
      .then((response) => {
        if (response.items && response.items.length > 0) {
          const user = response.items.find(_u => true)!;
          usersClient.find(user.id!)
            .then(userResponse => {
              usersClient.update(user.id!, {
                ...user,
                culture: 'pl',
                roles: user.roles?.some(r => r === 'Instructor') ? [...userResponse.roles ?? []] : [...userResponse.roles ?? [], 'Instructor']
              } as User)
                .then((__userResponse) => { })
                .catch(error => setError(error.status));
              instructorsApiClient.create({ ...instructor, userId: user.id, user: undefined } as Instructor)
                .then((response) => setInstructors([...instructors].map((e, i) => (i === index ? { ...response } : { ...e }) as Instructor)))
                .catch(error => setError(error.status))
            })

        } else {
          usersClient.create({ ...instructor.user, userName: instructor.user?.email, culture: 'pl', roles: ['Instructor'] } as User)
            .then((userResponse) => {
              instructorsApiClient.create({ ...instructor, userId: userResponse.id, user: undefined } as Instructor)
                .then((response) => setInstructors([...instructors].map((e, i) => (i === index ? { ...response } : { ...e }) as Instructor)))
                .catch(error => setError(error.status))
            })
            .catch(error => setError(error.status));
        }
      });
  }, [horses, instructors]);

  useEffect(() => {
    if (error) return;
    if (instructors.some(e => !e.id)) return;

    const index = places.findIndex(e => !e.id);
    if (index === -1) return;
    const place = places.find((_e, i) => i === index);
    if (!place) return;

    place.translations = place.translations?.map(t => ({ ...t, culture: 'pl' } as PlaceTranslation)) || [];

    placesApiClient.create(place)
      .then((response) => setPlaces([...places].map((e, i) => (i === index ? { ...response } : { ...e }) as Place)))
      .catch(error => setError(error.status))
  }, [instructors, places]);

  useEffect(() => {
    if (error) return;
    if (places.some(e => !e.id)) return;

    const index = trainingTypes.findIndex(e => !e.id);
    if (index === -1) return;
    const trainingType = trainingTypes.find((_e, i) => i === index);
    if (!trainingType) return;

    trainingType.translations = trainingType.translations?.map(t => ({ ...t, culture: 'pl' } as TrainingTypeTranslation)) || [];
    trainingType.horseTrainingTypes = trainingType.horseTrainingTypes?.map(r => ({ ...r, horseId: horses[Number(r.horseId)]?.id } as HorseTrainingType)) || [];
    trainingType.instructorTrainingTypes = trainingType.instructorTrainingTypes?.map(r => ({ ...r, instructorId: instructors[Number(r.instructorId)]?.id } as InstructorTrainingType)) || [];
    trainingType.trainingTypePlaces = trainingType.trainingTypePlaces?.map(r => ({ ...r, placeId: places[Number(r.placeId)]?.id } as TrainingTypePlace)) || [];
    trainingType.duration = Number(trainingType.duration);
    trainingType.price = Number(trainingType.price);


    trainingTypesApiClient.create(trainingType)
      .then((response) => setTrainingTypes([...trainingTypes].map((e, i) => (i === index ? { ...response } : { ...e }) as TrainingType)))
      .catch(error => setError(error.status))
  }, [places, trainingTypes]);

  const notProcessedHorses = horses.filter(h => !h.id);
  const notProcessedInstructors = instructors.filter(i => !i.id);
  const notProcessedPlaces = places.filter(p => !p.id);
  const notProcessedTrainingTypes = trainingTypes.filter(t => !t.id);

  return (
    <>
      <h3 className="text-lg">Teraz chwilkę nam to zajmie...</h3>
      {error && <Alert.Error title={error} />}
      <div className="text-center p-8">
        {(notProcessedHorses.length > 0
          || notProcessedInstructors.length > 0
          || notProcessedPlaces.length > 0
          || notProcessedTrainingTypes.length > 0
        ) && <Spinner className="mx-auto h-16" />}
      </div>
      <div>
        <div>
          <Check title="Zmiana hasła" done />
        </div>
        <div>
          <Check title="Ustawianie wyglądu strony" done />
        </div>
        <div>
          <Check title="Ustawianie preferencji rezerwacji" done />
        </div>
        <div>
          <Check title="Dodawanie koni" active={notProcessedHorses.length > 0} done={notProcessedHorses.length === 0} />
        </div>
        <div>
          <Check title="Dodawanie instruktorów" active={notProcessedHorses.length === 0 && notProcessedInstructors.length > 0} done={notProcessedInstructors.length === 0} />
        </div>
        <div>
          <Check title="Dodawanie miejsc treningowych" active={notProcessedInstructors.length === 0 && notProcessedPlaces.length > 0} done={notProcessedPlaces.length === 0} />
        </div>
        <div>
          <Check title="Dodawanie rodzajów treningu" active={notProcessedPlaces.length === 0 && notProcessedTrainingTypes.length > 0} done={notProcessedTrainingTypes.length === 0} />
        </div>
        {notProcessedHorses.length === 0 &&
          notProcessedInstructors.length === 0 &&
          notProcessedPlaces.length === 0 &&
          notProcessedTrainingTypes.length === 0 &&
          <div className="py-8 flex justify-between">
            <LocalizedLink to="/panel">
              <Button colorName="gray">
                Powrót do panelu
              </Button>
            </LocalizedLink>
            <LocalizedLink to="/">
              <Button colorName="primary">
                Przejdź do swojej wymarzonej wizytówki
              </Button>
            </LocalizedLink>
          </div>
        }
      </div>
    </>
  );
};

interface CheckProps {
  active?: boolean;
  done?: boolean;
  title: string;
}

const Check = (props: CheckProps) => {
  const { active, done, title } = props;
  if (done)
    return (<>
      <FontAwesomeIcon icon={faCheckCircle} className="text-emerald-700 opacity-50 mr-3" />
      <span className="text-gray-500">{title}</span>
    </>);
  if (active)
    return (<>
      <FontAwesomeIcon icon={faCircle} className="text-[--color-primary-700] mr-3" />
      <span className="text-gray-700">{title}</span>
    </>);
  return (<>
    <FontAwesomeIcon icon={faCircle} className="text-gray-200 mr-3" />
    <span className="text-gray-300">{title}</span>
  </>);
}