import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from "react-i18next";
import { Training } from 'src/api/stable/Booking';
import Button from "src/components/Actions/Button";
import LocalizedLink from 'src/components/Router/LocalizedLink';

import useUser from "src/hooks/useUser";

export interface CompleteComponentProps {
  training: Training;
}

const Complete = (props: CompleteComponentProps) => {
  const { training } = props;
  const { t } = useTranslation();

  const user = useUser();
  return (
    <>
      <div className="max-w-sm md:max-w-xl lg:max-w-full flex min-h-full flex-1 h-screen bg-gradient-to-t from-[--color-primary-50] to-white">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:max-w-xl">
            <div>

            </div>
            <div>
              <h1 className="text-3xl text-gray-300 mb-5">{t('stable.trainings.booking.header')}</h1>
              <h2 className="text-4xl text-[--color-primary-700] mb-8">{t('stable.trainings.booking.successful.header')}</h2>
              <h2 className="text-xl text-gray-500 mb-8">{t('stable.trainings.fields.number')}: {training.number ?? training.id}</h2>
              <div className="lg:flex text-gray-300 hover:text-[--color-primary-700] transition-colors duration-500">
                <FontAwesomeIcon icon={faCircleCheck} className="ml-4 inline h-8" />
                <div className="text-xl mb-3 pl-4 flex-1 text-gray-700">{t('stable.trainings.booking.successful.message')}</div>
              </div>

              {!user && <div className="lg:flex text-gray-300 hover:text-[--color-primary-700] transition-colors duration-500">
                <FontAwesomeIcon icon={faCircleCheck} className="ml-4 inline h-8" />
                <div className="text-xl mb-3 pl-4 flex-1 text-gray-500">{t('stable.trainings.booking.successful.guest')}</div>
              </div>}
              <div className="lg:flex lg:gap-5 pt-8 mt-5 border-t border-gray-200">
                <LocalizedLink to="/" className="w-full">
                  <Button className="w-full justify-center" colorName="gray">
                    {t('common.errors.actions.goBackHome')}
                  </Button>
                </LocalizedLink>
                {!user &&
                  <LocalizedLink to="/auth/sign-up/" className="w-full">
                    <Button className="w-full justify-center" colorName="primary">
                      {t('auth.signUp.action')}
                    </Button>
                  </LocalizedLink>}
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="/img/backgrounds/talking.webp"
            alt=""
          />
        </div>
      </div >
    </>
  )
}

export default Complete;