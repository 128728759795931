import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ConfigurationProvider } from './store/configuration/context';
import { ApplicationProvider } from './store/application/context';
import { ToastContainer } from "react-toastify";
import * as CookieConsent from "vanilla-cookieconsent";
import CookiesConfiguration from "./cookieconsent-config";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from './components/Feedback/ErrorBoundaryFallback';
import { router } from './router';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";
import configuration from './config/config';

import "./colors.css";
import "./index.css";
import 'react-toastify/dist/ReactToastify.css';
import "vanilla-cookieconsent/dist/cookieconsent.css";
import { MessagingProvider } from './store/messaging/context';
import MessagesOverlay from './components/Messages/MessagesOverlay';
import { NotificationProvider } from './store/notifications/context';

if (configuration.integrations?.googleAnalyticsApiKey) {
  ReactGA.initialize(configuration.integrations?.googleAnalyticsApiKey);
}

export const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    CookieConsent.run(CookiesConfiguration);
  }, []);

  document.documentElement.lang = i18n.resolvedLanguage || i18n.language;

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <ConfigurationProvider>
        <ApplicationProvider>
          <MessagingProvider>
            <NotificationProvider>
              <RouterProvider router={router} />
              <MessagesOverlay />
            </NotificationProvider>
          </MessagingProvider>
        </ApplicationProvider>
      </ConfigurationProvider>
      <div className="hidden">
        <span className="bg-[--color-primary-50]"></span>
        <span className="bg-[--color-primary-100]"></span>
        <span className="bg-[--color-primary-200]"></span>
        <span className="bg-[--color-primary-300]"></span>
        <span className="bg-[--color-primary-400]"></span>
        <span className="bg-[--color-primary-500]"></span>
        <span className="bg-[--color-primary-600]"></span>
        <span className="bg-[--color-primary-700]"></span>
        <span className="bg-[--color-primary-800]"></span>
        <span className="bg-[--color-primary-900]"></span>
        <span className="text-[--color-primary-50]"></span>
        <span className="text-[--color-primary-100]"></span>
        <span className="text-[--color-primary-200]"></span>
        <span className="text-[--color-primary-300]"></span>
        <span className="text-[--color-primary-400]"></span>
        <span className="text-[--color-primary-500]"></span>
        <span className="text-[--color-primary-600]"></span>
        <span className="text-[--color-primary-700]"></span>
        <span className="text-[--color-primary-800]"></span>
        <span className="text-[--color-primary-900]"></span>
      </div>
      <ToastContainer
        toastClassName={"relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"}
        bodyClassName={() => "text-sm font-white font-med block p-3"}
        position="bottom-right"
        autoClose={3000} />
    </ErrorBoundary>
  );
};
