import { faFileText } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { Place } from 'src/api/stable/Stable';
import Editor from 'src/components/Form/FormEditor';
import FormInput from 'src/components/Form/FormInput'
import { LocalizedFormSection } from 'src/components/Layout/Panel/FormSection';
import { IForm } from 'src/hooks/useForm';
import useTranslationForm from 'src/hooks/useTranslationForm';

export interface TranslationFormComponentProps {
  form: IForm<Place>;
}

export default function TranslationForm(props: TranslationFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const translationForm = useTranslationForm(form);
  const { getTranslationFieldName, translation, culture } = translationForm;

  return (
    <LocalizedFormSection
      title={t('common.form.translation.header')}
      description={t('common.form.translation.subheader')}
      form={translationForm}
      full
    >
      <div className="mb-10">
        <FormInput.Default {...form.input(getTranslationFieldName("name"), "text", { placeholder: t('common.fields.name') })} icon={<FontAwesomeIcon icon={faFileText} className="h-5 w-5 text-gray-400" aria-hidden="true" />} />
      </div>
      <div className="mb-10">
        {translation && <Editor {...form.input(getTranslationFieldName("description"), "text", { placeholder: t('common.fields.description') })} key={`${form.data.id}_${culture}_content`} style={{ height: '50vh' }} />}
      </div>
    </LocalizedFormSection>
  );
}