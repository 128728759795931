import { faFolderTree } from '@fortawesome/free-solid-svg-icons';
import { faGlobeEurope, faSave, faTimesCircle, faPlusCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from "react-i18next";
import { Content, ContentTranslation } from "src/api/cms/Cms";
import Button from "src/components/Actions/Button";
import LocalizedLink from 'src/components/Router/LocalizedLink';
import useClaim from "src/hooks/useClaim";

import useEntityTranslation from "src/hooks/useEntityTranslation";
import { IForm } from "src/hooks/useForm";

export interface ContentHeaderComponentProps {
  content: Content;
  onDelete: () => void;
  form: IForm<Content>
}

export default (props: ContentHeaderComponentProps) => {
  const { content, onDelete, form } = props;
  const { t } = useTranslation();

  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const contentsUpdateClaim = useClaim('NewsfeedUpdate');
  const contentsDeleteClaim = useClaim('NewsfeedDelete');
  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-5 pb-5 border-b border-b-gray-100">
      <div className="flex items-start space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <FontAwesomeIcon icon={faFolderTree} className="h-16 p-3 text-[--color-primary-500]" />
            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
          </div>
        </div>
        <div className="pt-1.5">
          <h1 className="text-2xl font-medium text-gray-900">{entityTranslation.getCurrentTranslation(content)?.title || t('cms.common.categories.actions.create')}</h1>
          <div className="text-sm font-medium text-gray-500">
            {content.created && <span>{t('common.fields.created')}: {content.created?.toLocaleString()}</span>}
            {content.updated && <span>, {t('common.fields.updated')}: {content.updated?.toLocaleString()}</span>}
            {entityTranslation.getCurrentTranslation(content)?.url && <LocalizedLink target="_blank" rel="noopener" to={`/n/c/${entityTranslation.getCurrentTranslation(content)?.url}`}>
              <FontAwesomeIcon icon={faGlobeEurope} className="ml-3" />
            </LocalizedLink>}
          </div>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        {contentsUpdateClaim && <Button className="text-md px-5 py-3" colorName="primary" disabled={form.pending}><FontAwesomeIcon icon={form.data.id ? faSave : faPlusCircle} className="mr-1" /> {form.data.id ? t('common.actions.save') : t('common.actions.add')}</Button>}
        {contentsDeleteClaim && <Button className="text-md px-5 py-3" type="button" colorName="red" onClick={onDelete} disabled={form.pending}><FontAwesomeIcon icon={faTimesCircle} className="mr-1" /> {t('common.actions.delete')}</Button>}
      </div>
    </div >
  )
}
