import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga4';
import configuration from "src/config/config";
import * as CookieConsent from "vanilla-cookieconsent";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (configuration.integrations?.googleAnalyticsApiKey && CookieConsent.acceptedCategory('analytics')) {
      setTimeout(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search, title: document.title });
      }, 250);
    }
  }, [document.title, location]);
};


export default usePageTracking;