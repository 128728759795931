import { ReactNode } from 'react';
import Select from 'src/components/Form/FormSelect';
import classNames from 'src/components/Utilities/classNames';
import { ITranslation } from 'src/hooks/useEntityTranslation';
import { ITranslatedForm } from 'src/hooks/useTranslationForm';

export interface FormSectionTextProps {
  content: string;
}

export interface FormSectionProps {
  children: ReactNode;
}

export interface FormSectionContainerProps {
  children: ReactNode;
  full?: boolean;
  withoutSidebar?: boolean;
}

export interface FormSectionComponentProps extends FormSectionProps {
  title: string;
  description?: string;
  full?: boolean;
  withoutSidebar?: boolean;
}

export interface LocalizedFormSectionComponentProps<T> extends FormSectionComponentProps {
  form: ITranslatedForm<T>;
}

const FormSectionWrapper = (props: FormSectionProps) => {
  const { children } = props;

  return (
    <div className="md:flex md:flex-row md:gap-5 md:mt-5 pb-5 border-b border-gray-200 md:divide-x divide-gray-100">
      {children}
    </div>
  )
}

const FormSectionSidebar = (props: FormSectionProps) => {
  const { children } = props;

  return (
    <div className="md:flex-none md:w-1/3 md:px-4 pt-5 md:pt-10 sm:px-0">
      {children}
    </div>
  )
}

const FormSectionContainer = (props: FormSectionContainerProps) => {
  const { children, full, withoutSidebar } = props;

  return (
    <>
      <div className={classNames([withoutSidebar ? "w-full md:pt-10" : "md:flex-1 pt-5 md:px-4 md:pt-10"])}>
        <div className={classNames(["mb-10", !full && "md:w-1/2"])}>
          {children}
        </div>
      </div >
    </>
  )
}

const FormSectionTitle = (props: FormSectionTextProps): JSX.Element => {
  const { content } = props;
  return <h3 className="text-base font-medium leading-7 text-gray-900">{content}</h3>;
}

const FormSectionDescription = (props: FormSectionTextProps): JSX.Element => {
  const { content } = props;
  return <div className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{content}</div>;
}

const FormSection = (props: FormSectionComponentProps): JSX.Element => {
  const { title, description, children, full, withoutSidebar } = props;

  return (
    <FormSectionWrapper>
      {withoutSidebar ?
        <div className="md:px-4 flex-grow w-full">
          <FormSectionTitle content={title} />
          {description && <FormSectionDescription content={description} />}
          <FormSectionContainer full={full} withoutSidebar={withoutSidebar}>
            {children}
          </FormSectionContainer>
        </div> :
        <>
          <FormSectionSidebar>
            <FormSectionTitle content={title} />
            {description && <FormSectionDescription content={description} />}
          </FormSectionSidebar>
          <FormSectionContainer full={full}>
            {children}
          </FormSectionContainer>
        </>
      }
    </FormSectionWrapper>
  )
}

export const LocalizedFormSection = <T extends ITranslation>(props: LocalizedFormSectionComponentProps<T>): JSX.Element => {
  const { title, description, children, form, full } = props;

  return (
    <FormSectionWrapper>
      <FormSectionSidebar>
        <FormSectionTitle content={title} />
        {description && <FormSectionDescription content={description} />}
        <Select
          name="culture"
          value={form.culture}
          onChange={(e) => form.setCulture(e.target.value)}
          options={form.cultures}
        />
      </FormSectionSidebar>
      <FormSectionContainer full={full}>
        {children}
      </FormSectionContainer>
    </FormSectionWrapper>
  )
}

export default FormSection;