import { FC, SyntheticEvent, createRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ExtendedInputHTMLAttributes from './ExtendedInputHTMLAttributes';
import { Feedback, FormLabel } from './FormInput';
import { useTranslation } from 'react-i18next';
import { de } from 'date-fns/locale/de';
import { uk } from 'date-fns/locale/uk';
import { fr } from 'date-fns/locale/fr';
import { pl } from 'date-fns/locale/pl';

import "react-datepicker/dist/react-datepicker.css";
import "./FormDatePicker.css"

registerLocale('de', de);
registerLocale('fr', fr);
registerLocale('uk', uk);
registerLocale('pl', pl);


export interface HTMLDateInputElement extends Omit<Omit<ExtendedInputHTMLAttributes<HTMLInputElement>, 'value'>, 'onChange'> {
  value: Date | null | undefined;
  time?: number;
  timeOnly?: boolean;
  minDate?: Date;
  maxDate?: Date;
  onChange: (value: Date | null, event: SyntheticEvent<any, Event> | undefined) => void; /* eslint-disable-line @typescript-eslint/no-explicit-any */
}

const FormDatePicker: FC<HTMLDateInputElement> = (props: HTMLDateInputElement) => {
  const { value, time, timeOnly, onChange, minDate, maxDate } = props;
  const inputRef = createRef<HTMLInputElement>();
  const { i18n } = useTranslation();

  const nativeInputProps = { ...props, value: value?.toISOString(), onChange: undefined };
  const stringValue = value?.toISOString();

  return (
    <>
      <div>
        <FormLabel {...nativeInputProps} />
      </div>
      <div>
        <DatePicker
          selected={value}
          onChange={onChange}
          showTimeSelect={time ? true : false}
          showTimeSelectOnly={time !== undefined && timeOnly}
          timeIntervals={time || 30}
          dateFormat={time !== undefined ? (timeOnly ? "p" : "Pp") : "P"}
          locale={i18n.resolvedLanguage}
          minDate={minDate}
          maxDate={maxDate}
          className="ring-1 ring-inset ring-gray-300 shadow-smfocus:ring-2 focus:ring-inset focus:ring-[--color-primary-600] block w-full rounded-md border-0 py-1.5 placeholder:text-gray-400 sm:text-sm sm:leading-6 text-black"
        />
        <input ref={inputRef} type="hidden" value={stringValue} />
      </div>
      <div>
        <Feedback {...nativeInputProps} />
      </div>
    </>
  );
}

export default FormDatePicker;