import { Dictionary } from "lodash";
import { PaymentMethod, PaymentStatus } from "src/api/financial/Accountancy";
import DataCache, { createCache } from "../interfaces/DataCache";
import ArrayDataCache, { createArrayCache } from "../interfaces/ArrayDataCache";

export interface AccountancyState {
    paymentMethods: ArrayDataCache<PaymentMethod>;
    paymentMethodStatuses: DataCache<Dictionary<PaymentStatus[]>>;
    requestedPaymentMethodStatuses: string[];
    loadedPaymentMethodStatuses: string[];
}

export const initialAccountancyState: AccountancyState = {
    paymentMethods: createArrayCache(),
    paymentMethodStatuses: createCache({}),
    requestedPaymentMethodStatuses: [],
    loadedPaymentMethodStatuses: [],
};