import { UserCoupon, UserCouponsClient } from "src/api/stable/Booking";
import { useTranslation } from "react-i18next";
import { PaymentMethod, PaymentMethodTranslation, PaymentMethodsClient, PaymentSubMethod, PaymentsClient } from "src/api/financial/Payments";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import Button from "src/components/Actions/Button";
import { useEffect, useState } from "react";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import GridSelect, { GridSelectOption } from "src/components/Form/GridSelect";
import Spinner from "src/components/Feedback/Spinner";
import { HttpQueryFilter, PaymentRequestsClient } from "src/api/financial/Accountancy";
import ContentParse from "src/components/Cms/ContentParse";
import { useParams } from "react-router";


const CouponPayment = () => {
  const { t } = useTranslation();
  const { couponId } = useParams();
  const [coupon, setCoupon] = useState<UserCoupon | undefined>();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | undefined>();
  const [subMethods, setSubMethods] = useState<PaymentSubMethod[]>([]);
  const [subMethod, setSubMethod] = useState<PaymentSubMethod | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const apiConfiguration = useApiConfiguration();
  const couponsClient = new UserCouponsClient(apiConfiguration);
  const paymentMethodsClient = new PaymentMethodsClient(apiConfiguration);
  const paymentsClient = new PaymentsClient(apiConfiguration);
  const paymentRequestsClient = new PaymentRequestsClient(apiConfiguration);
  const entityTranslation = useEntityTranslation<PaymentMethod, PaymentMethodTranslation>();


  useEffect(() => {
    paymentMethodsClient.get([{ property: "IsPrepaid", value: "true", type: "=" } as HttpQueryFilter], undefined, undefined, undefined, undefined, undefined)
      .then(response => setPaymentMethod(response?.items?.[0]))
      .catch(error => console.error(error));
  }, []);
  useEffect(() => {
    if (!couponId) return;
    couponsClient.find(couponId)
      .then(response => setCoupon(response))
      .catch(error => console.error(error));
  }, [couponId]);


  useEffect(() => {
    if (!paymentMethod) return;
    if (paymentMethod.haveSubMethods) {
      setLoading(true);
      paymentMethodsClient
        .listSubMethods(paymentMethod.id!, 100, 'PLN')
        .then(response => setSubMethods(response ?? []))
        .catch(error => console.error(error))
        .finally(() => setLoading(false));
    }
  }, [paymentMethod]);

  const onClickPayment = () => {
    if (!paymentMethod) return;
    if (!coupon) return;
    setPaymentLoading(true);
    paymentRequestsClient
      .get([{ property: "ExternalId", value: `UserCoupon-${couponId!}`, type: '=' } as HttpQueryFilter], undefined, undefined, undefined, undefined, undefined)
      .then(requestResponse => {
        const request = requestResponse.items?.[0];
        if (!request) {
          console.error("Request not found");
          setPaymentLoading(false);
          return;
        }
        paymentsClient
          .requestPayment(request.id!, paymentMethod.id!, subMethod?.id)
          .then(response => {
            window.location.href = response;
            return;
          })
          .catch(error => console.error(error))
          .finally(() => setPaymentLoading(false));
      })
      .catch(error => console.error(error))
  }

  const options = subMethods.map(m => ({
    id: m.id,
    value: m.id,
    label: m.name,
    description: m.description,
    image: m.imageUrl
  }) as GridSelectOption);

  return (
    <>
      <div className="max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto px-4 md:px-0 mb-8">
        <div className="lg:flex lg:justify-between mb-8 lg:mb-0">
          <div>
            <h2 className="text-3xl text-gray-300 mb-5">{t('stable.actions.buyCoupon')}</h2>
          </div>
        </div>
        <div className="lg:flex lg:justify-between">
          <div className="lg:w-5/12 lg:pr-3">
            <h2 className="text-4xl text-[--color-primary-700] mb-4">{t('accountancy.payments.item')}</h2>
            <h2 className="text-2xl text-gray-500 mb-4">{entityTranslation.getCurrentTranslation(paymentMethod)?.title}</h2>
            <h2 className="text-xl text-gray-500 mb-4">{coupon?.title || coupon?.id}</h2>
          </div>
          <div className="lg:w-7/12">
            {loading && <Spinner className="mx-auto h-8" />}
            {paymentMethod?.haveSubMethods &&
              <div className="h-96 overflow-y-scroll">
                <GridSelect value={paymentMethod?.id} options={options} onChange={(e) => setSubMethod(subMethods.find(i => i.id == e.target.value))} small />
              </div>
            }
            <div className="text-end">
              <Button colorName="primary" className="px-5 py-3 mt-8" onClick={onClickPayment} disabled={loading || (paymentMethod?.haveSubMethods && subMethod === undefined)}>
                {t('accountancy.payments.do')}
                {paymentLoading && <Spinner className="ml-3 h-4" />}
              </Button>
              {entityTranslation.getCurrentTranslation(paymentMethod)?.agreements && <div className="text-sm text-gray-400 mt-8"><ContentParse>{entityTranslation.getCurrentTranslation(paymentMethod)?.agreements}</ContentParse></div>}
              <div className="text-sm text-gray-400 mt-8">{t('accountancy.payments.externalRedirect')}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CouponPayment;