import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ApiStatus, IConfig } from "src/api/Base";
import { IMetaClient } from "src/api/Interfaces";
import { MetaClient as AuthMetaClient } from "src/api/access/Auth";
import { MetaClient as AuthorityMetaClient } from "src/api/access/Authority";
import { MetaClient as NotificationsMetaClient } from "src/api/notifications/Notifications";
import { MetaClient as CmsMetaClient } from "src/api/cms/Cms";
import { MetaClient as StableMetaClient } from "src/api/stable/Stable";
import { MetaClient as BookingMetaClient } from "src/api/stable/Booking";
import { MetaClient as AccountancyMetaClient } from "src/api/financial/Accountancy";
import { MetaClient as PaymentsMetaClient } from "src/api/financial/Payments";
import { useEffect, useState } from "react";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import configuration, { ConfigurationApis } from "src/config/config";
import { faFileCode } from '@fortawesome/free-solid-svg-icons';
import { faDisplayCode } from '@fortawesome/pro-duotone-svg-icons';
import useClaim from 'src/hooks/useClaim';

interface IApi {
  name: string;
  client: { new(configuration: IConfig, baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }): IMetaClient };
  url: string;
}

interface IApiData {
  name: string;
  version: string;
  status: ApiStatus;
  url: string;
}

const apis: IApi[] = [
  { name: 'Core', client: AuthMetaClient, url: configuration.api[ConfigurationApis.Core] },
  { name: 'Auth', client: AuthMetaClient, url: configuration.api[ConfigurationApis.Auth] },
  { name: 'Authority', client: AuthorityMetaClient, url: configuration.api[ConfigurationApis.Authority] },
  { name: 'Notifications', client: NotificationsMetaClient, url: configuration.api[ConfigurationApis.Notifications] },
  { name: 'Content', client: CmsMetaClient, url: configuration.api[ConfigurationApis.Content] },
  { name: 'Stable', client: StableMetaClient, url: configuration.api[ConfigurationApis.Stable] },
  { name: 'Booking', client: BookingMetaClient, url: configuration.api[ConfigurationApis.Booking] },
  { name: 'Accountancy', client: AccountancyMetaClient, url: configuration.api[ConfigurationApis.Accountancy] },
  { name: 'Payments', client: PaymentsMetaClient, url: configuration.api[ConfigurationApis.Payments] },
]

const createClient = <T extends IMetaClient>(
  type: { new(configuration: IConfig, baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }): T },
  configuration: IConfig
): T => {
  return new type(configuration);
}

export interface ApiStatusCircleComponentProps {
  status: ApiStatus;
}

export interface ApiRowComponentProps {
  api: IApiData;
}

const ApiStatusCircle = (props: ApiStatusCircleComponentProps) => {
  const { status } = props;
  switch (status) {
    case ApiStatus.Online:
      return <svg className="inline h-1.5 w-1.5 mb-1 fill-emerald-500" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx={3} cy={3} r={3} />
      </svg>;
    case ApiStatus.Maintenance:
      return <svg className="inline h-1.5 w-1.5 mb-1 fill-amber-500" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx={3} cy={3} r={3} />
      </svg>;
    case ApiStatus.Blocked:
      return <svg className="inline h-1.5 w-1.5 mb-1 fill-red-500" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx={3} cy={3} r={3} />
      </svg>;
    case ApiStatus.Offline:
      return <svg className="inline h-1.5 w-1.5 mb-1 fill-gray-500" viewBox="0 0 6 6" aria-hidden="true">
        <circle cx={3} cy={3} r={3} />
      </svg>;
  }
}

const ApiRow = (props: ApiRowComponentProps) => {
  const { api } = props;
  return (
    <a
      href={configuration.version === '0.0.0' ? `${api.url}/api-docs/` : undefined}
      target="_blank"
      rel="noopener"
      className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-medium leading-6 text-white hover:text-[--color-primary-200] hover:bg-gray-800"
    >
      <FontAwesomeIcon icon={faFileCode}
        className="inline h-6 w-6 shrink-0 text-[--color-primary-600] group-hover:text-[--color-primary-600]"
        aria-hidden="true"
      />
      <div>{api.name}</div>
      <div className="ml-auto">
        <span className="text-gray-300 px-2 py-1 text-xs opacity-85 rounded-md bg-slate-900 mr-2">{api.version}</span>
        <ApiStatusCircle status={api.status} />
      </div>
    </a>
  );
}

const DeveloperMenu = () => {
  const [apiData, setApiData] = useState<IApiData[]>([]);
  const apiConfiguration = useApiConfiguration();
  const isDeveloper = useClaim('Developer');

  const fetchApiData = (api: IApi) => {
    const apiClient = createClient(api.client, apiConfiguration);
    apiClient.info()
      .then(response => setApiData([...apiData, { name: api.name, version: response.version, status: response.status, url: api.url } as IApiData]))
      .catch(_ => setApiData([...apiData, { name: api.name, version: '0.0.0.0', status: ApiStatus.Offline, url: api.url } as IApiData]));
  }

  useEffect(() => {
    const apiToFetch = apis.find(a => !apiData.some(ad => ad.name === a.name));
    if (apiToFetch) {
      fetchApiData(apiToFetch);
    }
  }, [apiData]);

  if (!isDeveloper) return <></>;

  return (
    <li className="mt-auto">
      <h4 className="text-white font-medium">Developer</h4>
      {apiData?.map((a, i) => <ApiRow key={i} api={a} />)}
      <a
        target="_blank"
        rel="noopener"
        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-medium leading-6 text-white hover:text-[--color-primary-200] hover:bg-gray-800"
      >
        <FontAwesomeIcon icon={faDisplayCode}
          className="inline h-6 w-6 shrink-0 text-[--color-primary-600] group-hover:text-[--color-primary-600]"
          aria-hidden="true"
        />
        <div>Frontend</div>
        <div className="ml-auto">
          <span className="text-gray-300 px-2 py-1 text-xs opacity-85 rounded-md bg-slate-900 mr-2">{configuration.version}</span>
        </div>
      </a>
    </li>
  )
}

export default DeveloperMenu;