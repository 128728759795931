import { faPlusCircle, faMinusCircle } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dictionary } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Currency, Payment, PaymentMethod, PaymentStatus } from 'src/api/financial/Accountancy';
import CurrencySelect from 'src/components/Accountancy/CurrencySelect';
import PaymentMethodSelect from 'src/components/Accountancy/PaymentMethodSelect';
import PaymentStatusSelect from 'src/components/Accountancy/PaymentStatusSelect';
import Button from 'src/components/Actions/Button';
import NoRecordsFound from 'src/components/Feedback/NoRecordsFound';
import FormInput from 'src/components/Form/FormInput';
import FormSection from 'src/components/Layout/Panel/FormSection';
import { IForm } from 'src/hooks/useForm';

interface EntityWithPayment {
  payments?: Payment[];
}

export interface PaymentsFormComponentProps<T extends EntityWithPayment> {
  form: IForm<T>;
}

export interface PaymentFormComponentProps<T extends EntityWithPayment> {
  form: IForm<T>;
  id: number;
  row: Payment;
  onRemove: () => void;
}

function PaymentForm<T extends EntityWithPayment>(props: PaymentFormComponentProps<T>) {
  const { form, id, row, onRemove } = props;
  const { t } = useTranslation();

  const setPaymentData = (newValue: Payment) => {
    const data = {
      ...form.data,
      payments: [...form.data.payments ?? []].map((payment, index) => index === id ? newValue : payment)
    } as T;
    form.setData(data);
  }

  const onChangePaymentMethod = (value: string | Dictionary<PaymentMethod | undefined>, entity?: PaymentMethod) =>
    setPaymentData({ ...row, methodId: String(value), method: entity } as Payment);

  const onChangePaymentStatus = (value: string | Dictionary<PaymentStatus | undefined>, entity?: PaymentStatus) =>
    setPaymentData({ ...row, statusId: String(value), status: entity } as Payment);

  const onChangeCurrency = (value: string | Dictionary<Currency | undefined>, entity?: Currency) =>
    setPaymentData({ ...row, currencyCode: String(value), currency: entity } as Payment);

  return (
    <tr>
      <td>{id + 1}</td>
      <td className="pb-3 px-3">
        <PaymentMethodSelect
          value={row.methodId}
          onChange={onChangePaymentMethod}
        />
      </td>
      <td className="pb-3 px-3">
        <FormInput.WithoutLabel
          {...form.input(
            `payments.${id}.value`,
            'number',
            {
              min: 0.01,
              step: 0.01,
              placeholder: t('accountancy.payments.fields.value')
            }
          )}
        />
      </td>
      <td className="pb-3 px-3">
        <CurrencySelect
          value={row.currencyCode}
          onChange={onChangeCurrency}
        />
      </td>
      <td className="pb-3 px-3">
        <FormInput.WithoutLabel
          {...form.input(
            `payments.${id}.title`,
            'text',
            {
              placeholder: t('common.fields.title')
            }
          )}
        />
      </td>
      <td className="pb-3 px-3">
        <FormInput.WithoutLabel
          {...form.input(
            `payments.${id}.email`,
            'text',
            {
              placeholder: t('users.fields.email')
            }
          )}
        />
      </td>
      <td className="pb-3 px-3">
        <FormInput.WithoutLabel
          {...form.input(
            `payments.${id}.userId`,
            'text',
            {
              placeholder: t('users.item')
            }
          )}
        />
      </td>
      <td className="pb-3 px-3">
        <PaymentStatusSelect
          methodId={row.methodId}
          value={row.statusId}
          onChange={onChangePaymentStatus}
        />
      </td>
      <td>
        <FontAwesomeIcon className="text-red-700 cursor-pointer mb-2" icon={faMinusCircle} onClick={onRemove} />
      </td>
    </tr>
  )
}

export default function PaymentsForm<T extends EntityWithPayment>(props: PaymentsFormComponentProps<T>) {
  const { form } = props;
  const { t } = useTranslation();

  const addPayment = () => {
    const data = {
      ...form.data,
      payments: [...form.data.payments ?? [], {} as Payment]
    } as T;
    form.setData(data);
  }

  const removePayment = (index: number) => {
    const updatedPayments = [...form.data.payments ?? []];
    updatedPayments.splice(index, 1);
    const data = {
      ...form.data,
      payments: updatedPayments
    } as T;
    form.setData(data);
  }

  return (
    <FormSection
      title={t('accountancy.invoices.form.payments.header')}
      description={t('accountancy.invoices.form.payments.subheader')}
      full
      withoutSidebar
    >
      <div className="overflow-x-auto overflow-y-visible">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th>{t('accountancy.invoices.positions.fields.no')}</th>
              <th className="min-w-48">{t('accountancy.paymentMethods.item')}</th>
              <th className="min-w-32">{t('accountancy.payments.fields.value')}</th>
              <th className="min-w-24">{t('accountancy.currencies.item')}</th>
              <th className="min-w-48">{t('common.fields.title')}</th>
              <th className="min-w-48">{t('users.fields.email')}</th>
              <th className="min-w-48">{t('users.item')}</th>
              <th className="min-w-48">{t('accountancy.payments.field.status')}</th>
              <th>
                <FontAwesomeIcon className="text-emerald-700 cursor-pointer" icon={faPlusCircle} onClick={addPayment} />
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {form.data.payments?.map((row, id) => <PaymentForm key={id} id={id} row={row} form={form} onRemove={() => removePayment(id)} />)}
            {!form.data.payments?.length && <NoRecordsFound colSpan={9} onClickAdd={addPayment} addLabel={t('accountancy.invoices.payments.actions.add')} />}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={9} className="pt-3 text-end">
                <Button type="button" colorName="emerald" onClick={addPayment}><FontAwesomeIcon icon={faPlusCircle} /> {t('accountancy.invoices.payments.actions.add')}</Button>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </FormSection>
  )
}