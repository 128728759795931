import { FC, useEffect, useState } from 'react';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Currency, CurrenciesClient } from 'src/api/financial/Accountancy';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import Header from './Header';
import GeneralForm from './Form/General';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import Button from 'src/components/Actions/Button';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import ExchangeRatesForm from './Form/ExchangeRates';
import AddExchangeRateDialog from './Form/AddExchangeRateDialog';

const CurrenciesForm: FC = () => {
  const apiConfiguration = useApiConfiguration();

  const apiClient = new CurrenciesClient(apiConfiguration);
  const form = useForm<Currency>();
  const authGuard = useAuthGuard('/panel/', ['CurrenciesRead']);
  const currenciesUpdateClaim = useClaim('CurrenciesUpdate');
  const { id } = useParams<string>();
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();

  const onDelete = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id === 'create') return;
    apiClient.delete(id)
      .finally(() => {
        form.setPending(false);
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        navigate(`/panel/accountancy/currencies/`)
      })
  }

  const onSubmit = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id !== 'create') {
      apiClient.update(id, form.data)
        .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    } else {
      apiClient.create(form.data)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.form.saved"));
          navigate(`/panel/accountancy/currencies/`);
        })
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    }
  }

  const fetch = () => {
    if (!id) return;
    apiClient.find(id)
      .then(form.setData)
      .catch(form.catchAnyException);
  }

  useEffect(() => {
    if (id !== 'create') fetch();
    applicationDispatch(
      setBreadcrumbs([
        { label: "accountancy.currencies.group", href: "/panel/accountancy/currencies/" },
        { label: id !== 'create' ? 'common.actions.edit' : 'common.actions.createNew', href: `/panel/accountancy/currencies/${id}` }
      ])
    );
  }, [id]);

  useEffect(() => {
    form.setReadOnly(!currenciesUpdateClaim);
  }, [currenciesUpdateClaim])

  const [exchangeRatesDialogOpen, setExchangeRatesDialogOpen] = useState(false);

  if (authGuard === undefined) return <AuthGuardLoading />

  return (
    <>
      <form onSubmit={e => form.onSubmit(e, onSubmit)}>
        <Header currency={form.data} onDelete={onDelete} form={form} />
        <GeneralForm form={form} />
        {form.data.id && <ExchangeRatesForm form={form} open={exchangeRatesDialogOpen} setOpen={setExchangeRatesDialogOpen} />}
        <div className="text-end mt-5">
          <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
            {t('common.actions.save')}
          </Button>
        </div>
      </form >
      <AddExchangeRateDialog open={exchangeRatesDialogOpen} setOpen={setExchangeRatesDialogOpen} currencyCode={form.data.id!} />
    </>
  )
}

export default CurrenciesForm;