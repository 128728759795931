import Page from "src/components/Cms/Page"
import SectionHeader from "src/components/Typography/SectionHeader";

const AboutIndexWidget = () => {
  return (
    <div className="py-24 sm:py-32 bg-[--color-primary-900]">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <SectionHeader white>
            <Page url="3b2dfdee-8726-497e-9b4e-eff6a24e3da4" title raw />
          </SectionHeader>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-7xl text-lg text-white">
          <Page url="3b2dfdee-8726-497e-9b4e-eff6a24e3da4" content />
        </div>
      </div>
    </div>
  )
}

export default AboutIndexWidget;