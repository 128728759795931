import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { UserCoupon } from 'src/api/stable/Booking';
import FormDatePicker from 'src/components/Form/FormDatePicker';
import FormInput from 'src/components/Form/FormInput'
import FormSection from 'src/components/Layout/Panel/FormSection';
import { IForm } from 'src/hooks/useForm';

export interface ValidityFormComponentProps {
  form: IForm<UserCoupon>;
}

export default function ValidityForm(props: ValidityFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();


  return (
    <FormSection
      title={t('stable.coupons.form.validity.header')}
      description={t('stable.coupons.form.validity.subheader')}
    >
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('validity.count', 'number', { min: 1, required: true, placeholder: t('stable.userCoupons.fields.validity.count') })} />
        <div className="text-xs text-gray-500">{t('stable.userCoupons.descriptions.validity.count')}</div>
      </div>
      {form.data.id && <div className="mb-10">
        <FormInput.Overlapping {...form.input('validity.used', 'number', { placeholder: t('stable.userCoupons.fields.validity.used') })} />
        <div className="text-xs text-gray-500">{t('stable.userCoupons.descriptions.validity.used')
        }</div>
      </div>}
      <div className="mb-10">
        <FormDatePicker
          {...form.input('validity.date', 'date', { placeholder: t('stable.userCoupons.fields.validity.date'), required: true })}
          value={form.data.validity?.date}
          onChange={(date) => form.setData({ ...form.data, validity: { ...form.data.validity, date: date } } as UserCoupon)}
          time={15}
        />
        <div className="text-xs text-gray-500">{t('stable.userCoupons.descriptions.validity.date')
        }</div>
      </div>
    </FormSection>
  )
}
