import { useTranslation } from "react-i18next";
import getLocaleName from "./getLocaleName";

export interface LocaleNameProps {
  locale: string;
  native?: boolean;
}

const LocaleName = (props: LocaleNameProps) => {
  const { locale, native } = props;
  const { i18n } = useTranslation();
  const displayLocale = (native ? locale : i18n?.resolvedLanguage) || locale;
  return <>{getLocaleName(locale, displayLocale)}</>;
}

export default LocaleName;