import useApplicationState from "./useApplicationState";
import { IConfig } from "src/api/Base";

const useApiConfiguration = (): IConfig => {
    const applicationState = useApplicationState();
    return {
        token: applicationState.token,
        tenant: applicationState.tenant
    }
}

export default useApiConfiguration;