import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useUser from 'src/hooks/useUser';
import Notifications from './Notifications';
import Logo from 'src/components/Branding/Logo';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import LocaleMenu from '../Panel/LocaleMenu';
import AuthenticatedMenu from './AuthenticatedMenu';
import { useTranslation } from 'react-i18next';
import StableMenu from './StableMenu';
import classNames from 'src/components/Utilities/classNames';
import { useLocation } from 'react-router-dom';
import configuration from 'src/config/config';
import Button from 'src/components/Actions/Button';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import MessageTopicsMenu from 'src/components/Messages/MessageTopicsMenu';
import { MetaHead } from 'src/components/Meta/MetaHead';

const navigation = [
  { name: 'common.home', href: '/', exactly: true },
  { name: 'stable.actions.bookRide', href: '/stable/book-training', exactly: false },
  { name: 'stable.priceList', href: '/stable/price-list', exactly: false },
  { name: 'cms.newsfeed.header', href: '/n/', exactly: false },
  { name: 'cms.galleries.galleries.group', href: '/g/', exactly: false },
  { name: 'crm.contact.header', href: '/contact', exactly: false },
]

const mobileNavigation = [
  { name: 'stable.about', href: '/p/3b2dfdee-8726-497e-9b4e-eff6a24e3da4/', exactly: false },
  { name: 'stable.trainingTypes.group', href: '/stable/training-types/', exactly: false },
  { name: 'stable.horses.group', href: '/stable/horses/', exactly: false },
  { name: 'stable.instructors.group', href: '/stable/instructors/', exactly: false }
];

const UnauthenticatedMenu = () => {
  const { t } = useTranslation();
  return (
    <div className="hidden lg:flex lg:justify-end">
      <LocalizedLink to="/auth/sign-in" className="text-sm font-medium leading-6 text-gray-900">
        {t('auth.signIn.header')} <span aria-hidden="true">&rarr;</span>
      </LocalizedLink>
    </div>
  )
}

const Header = () => {
  const { t } = useTranslation();

  const user = useUser();

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const pathname = location.pathname.substring(3);

  return (
    <header className="sticky inset-x-0 top-0 z-50 bg-white">
      <MetaHead />
      <nav className="flex items-center justify-between p-2 lg:px-8" aria-label="Global">
        <div className="flex mr-5">
          <LocalizedLink to="/">
            <span className="sr-only h-16">{configuration?.meta?.owner || "Redini"}</span>
            <Logo className="w-auto fill-[--color-primary-600] hover:fill-[--color-primary-500]" appLogoClassName="h-8 m-4" localLogoClassName='h-16' />
          </LocalizedLink>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <LocalizedLink key={item.href} to={item.href} className={classNames(["text-sm font-medium leading-6 text-gray-900 border-b-2 text-center", (item.exactly ? (pathname === item.href ? 'border-[--color-primary-700]' : 'border-white') : (pathname.startsWith(item.href) ? 'border-[--color-primary-700]' : 'border-white'))])}>
              {t(item.name)}
            </LocalizedLink>
          ))}
        </div>
        <StableMenu />
        {user && <Notifications />}
        {user && <MessageTopicsMenu />}
        <LocaleMenu className="ml-auto mr-8 hidden md:block" />
        {!user && <UnauthenticatedMenu />}
        {user && <AuthenticatedMenu user={user} />}
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex text-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">{t('header.actions.openMainMenu')}</span>
            <FontAwesomeIcon icon={faBars} className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Redini</span>
              <Logo className="h-8 w-auto fill-[--color-primary-600] hover:fill-[--color-primary-500]" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">{t('header.actions.closeMenu')}</span>
              <FontAwesomeIcon icon={faTimes} className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="flex flex-grow w-full gap-3 my-8">
                {!user && <LocalizedLink
                  to="/auth/sign-in"
                  className="flex-1"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <Button colorName="primary" className="w-full justify-center">
                    {t('auth.signIn.header')}
                  </Button>
                </LocalizedLink>}
                {!user && <LocalizedLink
                  to="/auth/sign-up"
                  className="flex-1"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <Button colorName="primary" className="w-full justify-center">
                    {t('auth.signUp.action')}
                  </Button>
                </LocalizedLink>}
                {user && <LocalizedLink
                  to="/user"
                  className="flex-1"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <Button colorName="primary" className="w-full justify-center">
                    {t('users.panel.header')}
                  </Button>
                </LocalizedLink>}
                {user && <LocalizedLink
                  to="/auth/sign-out"
                  className="flex-1"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <Button colorName="primary" className="w-full justify-center">
                    {t('auth.signOut.header')}
                  </Button>
                </LocalizedLink>}
                <LocaleMenu className="ml-8 pt-3" />
              </div>
              <div className="space-y-2 py-6">
                {[...navigation, ...mobileNavigation].map((item) => (
                  <LocalizedLink
                    key={item.href}
                    to={item.href}
                    className={classNames(["-mx-3 block rounded-lg px-3 py-2 text-base font-medium leading-7 text-gray-900 hover:bg-gray-50 border-b-2", (item.exactly ? (pathname === item.href ? 'border-[--color-primary-700]' : 'border-white') : (pathname.startsWith(item.href) ? 'border-[--color-primary-700]' : 'border-white'))])}
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {t(item.name)}
                  </LocalizedLink>
                ))}
              </div>
            </div>
          </div >
        </Dialog.Panel >
      </Dialog >
    </header >
  )
}

export default Header;