import { faFacebookF, faInstagram, faTiktok, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Settings, SettingsClient } from "src/api/core/Core";
import Button from "src/components/Actions/Button";
import Alert from "src/components/Feedback/Alert";
import Toast from "src/components/Feedback/Toast";
import FormInput from "src/components/Form/FormInput";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useConfigurationDispatch from "src/hooks/useConfigurationDispatch";
import useConfigurationState from "src/hooks/useConfigurationState";

import { setConfiguration } from "src/store/configuration/actions";

const SocialSettingsSection = () => {
  const { t } = useTranslation();
  const configurationDispatch = useConfigurationDispatch();
  const configurationState = useConfigurationState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const apiConfiguration = useApiConfiguration();
  const apiClient = new SettingsClient(apiConfiguration);

  const onSaveSuccess = () => {
    Toast.success(t("common.status.success"), t("common.feedback.saved"));
  }

  const onSaveFailure = () => {
    setError(true);
    Toast.error(t("common.status.error"), t("common.errors.500"));
  }

  const onSave = () => {
    setLoading(true);
    setError(false);
    apiClient.set('social', undefined, { name: 'social', value: configurationState.social } as Settings)
      .catch(onSaveFailure)
      .then(onSaveSuccess)
      .finally(() => setLoading(false));
  }

  return (
    <>
      {error && <Alert.Error title={t('common.errors.500')} noClose />}
      <dl className="my-6 space-y-6 divide-y divide-gray-50 border-y border-gray-100 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('panel.settings.general.social.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className="w-full lg:w-1/2">
              <div className="mb-10">
                <FormInput.Overlapping
                  placeholder={t('panel.settings.general.social.facebook.title')}
                  aria-description={t('panel.settings.general.social.facebook.description')}
                  icon={<FontAwesomeIcon icon={faFacebookF} className="text-gray-300 group-hover:text-gray-700" />}
                  value={configurationState.social.facebook}
                  onChange={(e) => configurationDispatch(setConfiguration('social.facebook', e.target.value))}
                />
              </div>
              <div className="mb-10">
                <FormInput.Overlapping
                  placeholder={t('panel.settings.general.social.instagram.title')}
                  aria-description={t('panel.settings.general.social.instagram.description')}
                  icon={<FontAwesomeIcon icon={faInstagram} className="text-gray-300 group-hover:text-gray-700" />}
                  value={configurationState.social.instagram}
                  onChange={(e) => configurationDispatch(setConfiguration('social.instagram', e.target.value))}
                />
              </div>
              <div className="mb-10">
                <FormInput.Overlapping
                  placeholder={t('panel.settings.general.social.tiktok.title')}
                  aria-description={t('panel.settings.general.social.tiktok.description')}
                  icon={<FontAwesomeIcon icon={faTiktok} className="text-gray-300 group-hover:text-gray-700" />}
                  value={configurationState.social.tiktok}
                  onChange={(e) => configurationDispatch(setConfiguration('social.tiktok', e.target.value))}
                />
              </div>
              <div className="mb-10">
                <FormInput.Overlapping
                  placeholder={t('panel.settings.general.social.twitter.title')}
                  aria-description={t('panel.settings.general.social.twitter.description')}
                  icon={<FontAwesomeIcon icon={faXTwitter} className="text-gray-300 group-hover:text-gray-700" />}
                  value={configurationState.social.twitter}
                  onChange={(e) => configurationDispatch(setConfiguration('social.twitter', e.target.value))}
                />
              </div>
            </div>
          </dd>
        </div>
      </dl>
      <div className="flex justify-end">
        <Button colorName="primary" onClick={onSave} disabled={loading}><FontAwesomeIcon icon={faSave} className="h-5" /> {t('common.actions.save')}</Button>
      </div>
    </>
  );
}

export default SocialSettingsSection;