import React, { FC, useReducer } from 'react';
import { MessagingState, initialMessagingState } from './state';
import { MessagingActions } from './actions';
import { applicationReducer } from './reducer';
import _ from 'lodash';
import MessagingSignalRWatch from './MessagingSignalRWatch';

export const MessagingContext = React.createContext<{
  state: MessagingState;
  dispatch: React.Dispatch<MessagingActions>;
}>({
  state: initialMessagingState,
  dispatch: () => undefined,
});

interface MessagingProviderProps {
  children: React.ReactNode;
}
export const MessagingProvider: FC<MessagingProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(
    applicationReducer,
    {
      ...initialMessagingState,
    }
  );

  return (
    <MessagingContext.Provider value={{ state, dispatch }}>
      <MessagingSignalRWatch />
      {children}
    </MessagingContext.Provider>
  );
};