import { Fragment, createRef, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import NotificationsList, { NotificationsListComponentRef } from 'src/components/Notifications/NotificationsList'
import Spinner from 'src/components/Feedback/Spinner';
import './Notifications.css';
import { useTranslation } from 'react-i18next';
import LocalizedLink from 'src/components/Router/LocalizedLink';
import { faAngleDoubleDown, faAngleDown, faBars, faBell, faBellSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useNotificationState from 'src/hooks/stores/useNotificationState';

export default function Notifications() {
  const notificationsListRef = createRef<NotificationsListComponentRef>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { unread } = useNotificationState();

  return (
    <div className="flex flex-1 justify-end mr-6 md:mr-5">
      <Menu as="div" className="md:relative inline-block text-left">
        <Menu.Button className="flex items-center text-gray-400 hover:text-[--color-primary-700]">
          <FontAwesomeIcon icon={faBell} className="h-6 ml-3 mr-2 inline" />
          {(unread !== undefined && unread > 0) && <span className="bg-[--color-primary-700] text-white px-2 py-1 rounded-md text-xs">{unread}</span>}
          <FontAwesomeIcon icon={faAngleDown} className="inline -mr-1 h-5 w-5" aria-hidden="true" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute w-full left-0 md:left-auto md:right-0 md:w-96 z-10 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <h3 className="text-lg p-3">
              <div className="lg:flex">
                <div className="lg:flex lg:flex-1">
                  {t('notifications.notifications.group')}
                </div>
                <div className="lg:flex lg:flex-1 lg:justify-end">
                  {loading && <Spinner className="h-6" />}
                </div>
              </div>
            </h3>
            <div className="notifications-container">
              <NotificationsList ref={notificationsListRef} onChangeLoading={setLoading} />
            </div>
            <button
              type="button"
              className="w-1/5 p-5 text-xs text-gray-400 hover:text-[--color-primary-600]"
              title="Load more"
              onClick={() => notificationsListRef.current?.loadMore()}
              disabled={loading}
            >
              <FontAwesomeIcon icon={faAngleDoubleDown} className="h-4 mr-1 inline " />
            </button>
            <button
              type="button"
              className="w-3/5 p-5 text-xs text-gray-400 hover:text-[--color-primary-600] border-r-2 border-r-gray-400"
              title="Mark all as read"
              onClick={() => notificationsListRef.current?.markAsReadAll()}
              disabled={loading}
            >
              <FontAwesomeIcon icon={faBellSlash} className="h-4 mr-1 inline" />
              <span className="text-ellipsis overflow-hidden">{t('notifications.notifications.actions.markAsRead')}</span>
            </button>
            <LocalizedLink to="/user/notification-preferences/" className="w-1/5 p-5 text-sm text-gray-400 hover:text-[--color-primary-600]">
              <FontAwesomeIcon icon={faBars} className="h-4 ml-3 inline" />
            </LocalizedLink>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
