

export function DialogFooter(props: DialogFooterComponentProps) {
  const { children } = props;
  return (
    <div className="mt-5 sm:mt-6 border-t border-gray-100 pt-5">
      {children}
    </div>
  );
} export interface DialogFooterComponentProps {
  children: React.ReactNode;
}

