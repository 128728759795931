import _ from "lodash";
import { Instructor } from "src/api/stable/Booking"
import { IForm } from "src/hooks/useForm";
import AgendaWeek from "../../General/AgendaSections/Week";

export interface CalendarComponentProps {
  form: IForm<Instructor>;
}

export default (props: CalendarComponentProps) => {
  const { form } = props;

  return (
    <>
      <AgendaWeek user={form.data} />
    </>
  );
}