import _ from "lodash";

import PagesTable from "../../Cms/Pages/Table";
import { HttpQueryFilter } from "src/api/cms/Cms";

const HeadersPersonalizationSection = () => {
  return (
    <>
      <PagesTable filters={[{ property: 'id', value: '135bb7ab-e24b-4cb3-92ab-0f7b1d057717', type: '!=' } as HttpQueryFilter]} noHeader noActions />
    </>
  );
}

export default HeadersPersonalizationSection;