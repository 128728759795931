import { HttpQueryFilter, NotificationBroadcaster, NotificationBroadcastersClient, NotificationBroadcasterTranslation, NotificationPreference, NotificationReceiverType, NotificationType, NotificationTypesClient, NotificationTypeTranslation, UserPreferencesClient } from "src/api/notifications/Notifications";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { ChangeEvent, useEffect, useState } from "react";
import FormSwitch from "src/components/Form/FormSwitch";
import Button from "src/components/Actions/Button";
import Alert from "src/components/Feedback/Alert";
import Spinner from "src/components/Feedback/Spinner";
import { useTranslation } from "react-i18next";
import useEntityTranslation from "src/hooks/useEntityTranslation";


interface PreferenceHeaderCellComponentProps {
  broadcaster: NotificationBroadcaster;
}

const PreferenceHeaderCell = (props: PreferenceHeaderCellComponentProps) => {
  const { broadcaster } = props;
  const broadcasterTranslation = useEntityTranslation<NotificationBroadcaster, NotificationBroadcasterTranslation>();
  return <>
    <th className="text-end">
      {broadcasterTranslation.getCurrentTranslation(broadcaster)?.title ?? "Test"}
    </th>
  </>;
}


interface PreferenceCellComponentProps {
  preference: NotificationPreference | undefined;
  onChange: (v: boolean) => void;
}

const PreferenceCell = (props: PreferenceCellComponentProps) => {
  const { preference, onChange } = props;
  const isChecked = preference?.preferences ? (preference.preferences & NotificationReceiverType.Owner) > 0 : false;

  const onChangeSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  }
  const id = `${preference?.typeId}-${preference?.broadcasterId}`
  return <>
    <td>
      <div className="float-right">
        <FormSwitch.Clean id={id} checked={isChecked} onChange={onChangeSwitch} />
      </div>
    </td>
  </>;
}

interface PreferenceRowComponentProps {
  type: NotificationType;
  broadcasters: NotificationBroadcaster[] | undefined;
  preferences: NotificationPreference[] | undefined;
  onSetPreference: (b: string, v: boolean) => void;
}

const PreferenceRow = (props: PreferenceRowComponentProps) => {
  const { type, broadcasters, preferences, onSetPreference } = props;
  const typeTranslation = useEntityTranslation<NotificationType, NotificationTypeTranslation>();
  return <>
    <tr>
      <td className="text-start">
        {typeTranslation.getCurrentTranslation(type)?.description ?? "Test"}
      </td>
      {broadcasters
        ?.map(b => {
          if (!type.templates?.some(t => t.broadcasterId == b.id)) return <td></td>;
          return <PreferenceCell
            key={b.id}
            preference={preferences?.find(p => p.broadcasterId === b.id)}
            onChange={(v: boolean) => b.id && onSetPreference(b.id, v)}
          />
        })
      }
    </tr>
  </>;
}

const NotificationPreferences = () => {
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();

  const typesClient = new NotificationTypesClient(apiConfiguration);
  const broadcastersClient = new NotificationBroadcastersClient(apiConfiguration);
  const apiClient = new UserPreferencesClient(apiConfiguration);

  const [preferences, setPreferences] = useState<NotificationPreference[]>();
  const [broadcasters, setBroadcasters] = useState<NotificationBroadcaster[]>();
  const [types, setTypes] = useState<NotificationType[]>();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const setPreference = (typeId: string, broadcasterId: string, type: NotificationReceiverType, value: boolean) => {
    const preference = preferences?.find(p => p.typeId === typeId && p.broadcasterId === broadcasterId) ??
      { typeId, broadcasterId, preferences: NotificationReceiverType.None } as NotificationPreference;
    let pref = preference.preferences || NotificationReceiverType.None;
    if (value) {
      pref |= type;
    } else {
      pref &= ~type;
    }
    preference.preferences = pref;
    setPreferences([...preferences?.filter(p => p.typeId !== typeId || p.broadcasterId !== broadcasterId) || [], preference])
  }

  const submit = () => {
    setLoading(true);
    apiClient.post(preferences)
      .finally(() => setLoading(false))
      .then(_ => setSubmitted(true));
  }

  const load = () => {
    apiClient.get()
      .then(response => setPreferences(response.items));
  }

  const loadTypes = () => {
    typesClient.get([], [], 1000, undefined, undefined, undefined)
      .then(response => setTypes(response.items));
  }

  const loadBroadcasters = () => {
    broadcastersClient.get([{ property: 'IsEnabled', type: '=', value: 'true' } as HttpQueryFilter], [], 1000, undefined, undefined, undefined)
      .then(response => setBroadcasters(response.items));
  }

  useEffect(() => {
    load();
    loadTypes();
    loadBroadcasters();
  }, []);

  return (
    <>
      <div>
        <h2 className="text-2xl font-medium leading-9 tracking-tight text-gray-900">
          {t('auth.notificationPreferences.header')}
        </h2>
      </div >
      {submitted && <Alert.Success title={t('auth.notificationPreferences.submitted')} noClose />}
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-start"></th>
            {broadcasters?.map(b => <PreferenceHeaderCell key={b.id} broadcaster={b} />)}
          </tr>
        </thead>
        <tbody>
          {types
            ?.filter(f => !f.name.startsWith('Access'))
            .map(t =>
              <PreferenceRow
                key={t.id}
                type={t}
                broadcasters={broadcasters}
                preferences={preferences?.filter(p => t.id && p.typeId === t.id)}
                onSetPreference={(b: string, v: boolean) => t.id && setPreference(t.id, b, NotificationReceiverType.Owner, v)}
              />
            )}
        </tbody>
      </table>
      <div className="jutify-end">
        <Button colorName="primary" className="m-3 px-5 py-3" disabled={loading} onClick={submit}>{t('common.actions.save')}</Button>
        {loading && <Spinner />}
      </div >
    </>
  );
}

export default NotificationPreferences;