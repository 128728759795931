export default interface DataCache<T> {
  data: T;
  loaded: boolean;
  isLoading: boolean;
}

export function createCache<T>(value: T): DataCache<T> {
  return {
    data: value,
    loaded: false,
    isLoading: false,
  };
}