import _ from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { UserCoupon, DayOfWeek } from 'src/api/stable/Booking';
import FormDatePicker from 'src/components/Form/FormDatePicker';
import FormInput from 'src/components/Form/FormInput'
import { TextOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormSection from 'src/components/Layout/Panel/FormSection';
import HorseSelect from 'src/components/Stable/HorseSelect';
import InstructorSelect from 'src/components/Stable/InstructorSelect';
import TrainingTypeSelect from 'src/components/Stable/TrainingTypeSelect';
import { IForm } from 'src/hooks/useForm';

export interface ConditionsFormComponentProps {
  form: IForm<UserCoupon>;
}

export default function ConditionsForm(props: ConditionsFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();

  const daysOfWeek = [
    { id: '0', value: DayOfWeek._0, label: t('common.days.monday') },
    { id: '1', value: DayOfWeek._1, label: t('common.days.tuesday') },
    { id: '2', value: DayOfWeek._2, label: t('common.days.wednesday') },
    { id: '3', value: DayOfWeek._3, label: t('common.days.thursday') },
    { id: '4', value: DayOfWeek._4, label: t('common.days.friday') },
    { id: '5', value: DayOfWeek._5, label: t('common.days.saturday') },
    { id: '6', value: DayOfWeek._6, label: t('common.days.sunday') },
  ] as TextOptionProps[];

  useEffect(() => {
    if (form.data.conditions?.dayOfWeek) {
      if (form.data.conditions.dayOfWeek.some(d => typeof d === 'string')) {
        const newValue = form.data.conditions.dayOfWeek.map(d => Number(d) as DayOfWeek);
        form.setData({ ...form.data, conditions: { ...form.data.conditions, dayOfWeek: newValue } } as UserCoupon);
      }
    }
  }, [form.data.conditions?.dayOfWeek]);

  return (
    <FormSection
      title={t('stable.coupons.form.conditions.header')}
      description={t('stable.coupons.form.conditions.subheader')}
    >
      <div className="mb-10">
        <Select {...form.input('conditions.dayOfWeek[]', 'select', { placeholder: t('stable.coupons.fields.conditions.dayOfWeek') })} options={daysOfWeek} multiple />
        <div className="text-xs text-gray-500">{t('stable.coupons.descriptions.conditions.dayOfWeek')}</div>
      </div>
      <div className="mb-10">
        <div className="font-medium">{t('stable.coupons.fields.conditions.hours')}</ div>
        <div className="flex mb-5">
          <div className="flex-1 min-w-24">
            <FormDatePicker
              {...form.input('conditions.hours.start', 'date', { placeholder: t('common.fields.minimum') })}
              value={new Date('1970-01-01T' + (form.data.conditions?.hours?.start || "00:00:00"))}
              onChange={(date) => form.setData({ ...form.data, conditions: { ...form.data.conditions || {}, hours: { ...form.data.conditions?.hours || {}, start: date?.toISOString().split('T')[1].replace('Z', '') } } } as UserCoupon)}
              time={15}
              timeOnly={true}
            />
          </div>
          <div className="m-3">-</div>
          <div className="flex-1 min-w-24">
            <FormDatePicker
              {...form.input('conditions.hours.start', 'date', { placeholder: t('common.fields.maximum') })}
              value={new Date('1970-01-01T' + (form.data.conditions?.hours?.end || "00:00:00"))}
              onChange={(date) => form.setData({ ...form.data, conditions: { ...form.data.conditions || {}, hours: { ...form.data.conditions?.hours || {}, end: date?.toISOString().split('T')[1].replace('Z', '') } } } as UserCoupon)}
              time={15}
              timeOnly={true}
            />
          </div>
        </div>
        <div className="text-xs text-gray-500">{t('stable.coupons.descriptions.conditions.hours')}</div>
      </div>
      <div className="mb-10">
        <div className="font-medium">{t('stable.coupons.fields.conditions.duration')}</ div>
        <div className="flex my-5">
          <div className="flex-1 min-w-24">
            <FormInput.Overlapping suffix="min" {...form.input('conditions.duration.minimum', 'number', { min: 0, max: form.data.conditions?.duration?.maximum, placeholder: t('common.fields.minimum') })} />
          </div>
          <div className="m-3">-</div>
          <div className="flex-1 min-w-24">
            <FormInput.Overlapping suffix="min" {...form.input('conditions.duration.maximum', 'number', { min: form.data.conditions?.duration?.minimum, placeholder: t('common.fields.maximum') })} />
          </div>
        </div>
        <div className="text-xs text-gray-500">{t('stable.coupons.descriptions.conditions.duration')}</div>
      </div>
      <div className="mb-10">
        <TrainingTypeSelect
          {...form.input('conditions.trainingTypeId', 'select', {})}
          onChange={(trainingTypes) => form.setData({ ...form.data, conditions: { ...form.data.conditions, trainingTypeId: _.keys(trainingTypes) } } as UserCoupon)}
          value={form.data.conditions?.trainingTypeId}
          placeholder={t('stable.trainingTypes.item')}
          multiple
        />
      </div>
      <div className="mb-10">
        <InstructorSelect
          {...form.input('conditions.instructorId', 'select', {})}
          onChange={(instructors) => form.setData({ ...form.data, conditions: { ...form.data.conditions, instructorId: _.keys(instructors) } } as UserCoupon)}
          value={form.data.conditions?.instructorId}
          placeholder={t('stable.instructors.item')}
          multiple
        />
      </div>
      <div className="mb-10">
        <HorseSelect
          {...form.input('conditions.horseId', 'select', {})}
          onChange={(horses) => form.setData({ ...form.data, conditions: { ...form.data.conditions, horseId: _.keys(horses) } } as UserCoupon)}
          value={form.data.conditions?.horseId}
          placeholder={t('stable.horses.item')}
          multiple
        />
      </div>
    </FormSection >
  )
}
