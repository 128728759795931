import { ReactNode } from "react";

export interface DetailedGridItemComponentProps {
  id: string;
  image: React.ReactNode;
  label: string;
  slogan?: string;
  children?: ReactNode;
  dark?: boolean;
  className?: string;
}

export const DetailedGridItem = (props: DetailedGridItemComponentProps) => {
  const { id, image, label, slogan, children, className } = props;
  return (
    <li key={id} className={`flex flex-col gap-6 xl:flex-row${className} items-center`}>
      {typeof image === 'string' && <img className="aspect-[4/5] w-52 flex-none rounded-2xl object-cover" src={image} alt="" />}
      {typeof image !== 'string' && image}
      <div className="flex-auto">
        <h4 className="text-lg font-medium leading-8 tracking-tight">{label}</h4>
        {slogan && <div className="text-base leading-7 text-opacity-60">{slogan}</div>}
        <div className="mt-6 text-base leading-7 text-opacity-75">{children}</div>
      </div>
    </li>
  );
}

export interface DetailedGridComponentProps {
  children: ReactNode
}

export const DetailedGrid = (props: DetailedGridComponentProps) => {
  const { children } = props;
  return (
    <ul
      role="list"
      className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 md:grid-cols-2 2xl:grid-cols-3 lg:max-w-4xl lg:gap-x-8 xl:max-w-none"
    >
      {children}
    </ul>
  )
}