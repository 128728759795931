import { useTranslation } from 'react-i18next';
import { Horse } from 'src/api/stable/Stable';
import FormInput from 'src/components/Form/FormInput';
import FormSection from 'src/components/Layout/Panel/FormSection';
import { IForm } from 'src/hooks/useForm';

export interface LimitsFormComponentProps {
  form: IForm<Horse>;
}

export default function LimitsForm(props: LimitsFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();

  return (
    <FormSection
      title={t('stable.horses.limits.title')}
      description={t('stable.horses.limits.description')}
    >
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('trainingLimitContinuous', 'number', { min: 0, max: 720, placeholder: t('stable.horses.fields.trainingLimitContinuous'), "aria-description": t('stable.horses.descriptions.trainingLimitContinuous') })} />
      </div>
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('trainingLimitBreak', 'number', { min: 0, max: 720, placeholder: t('stable.horses.fields.trainingLimitBreak'), "aria-description": t('stable.horses.descriptions.trainingLimitBreak') })} />
      </div>
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('trainingLimitDaily', 'number', { min: 0, max: 720, placeholder: t('stable.horses.fields.trainingLimitDaily'), "aria-description": t('stable.horses.descriptions.trainingLimitDaily') })} />
      </div>
    </FormSection>
  )
}