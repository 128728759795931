import { ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TrainingType, TrainingTypeRider, Rider, RidersClient } from 'src/api/stable/Stable';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import { UserOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormSection from 'src/components/Layout/Panel/FormSection';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { IForm } from 'src/hooks/useForm';

export interface RidersFormComponentProps {
  form: IForm<TrainingType>;
}

export default function RidersForm(props: RidersFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const [riders, setRiders] = useState<Rider[]>([]);

  const apiConfiguration = useApiConfiguration();
  const ridersClient = new RidersClient(apiConfiguration);

  useEffect(() => {
    setRiders([]);
    ridersClient
      .get(
        [],
        [],
        1000,
        0,
        undefined,
        undefined
      )
      .then(response => setRiders(response.items ?? []))
      .catch(console.error);
  }, []);

  const riderOptions = riders?.map(r => ({ value: r.id, user: r.user }) as UserOptionProps);

  const onSelectRider: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    const newData: TrainingType = { ...form.data, trainingTypeRiders: [...form.data.trainingTypeRiders || []] } as TrainingType;
    newData.trainingTypeRiders = value !== '' ? value.split(',').map(r => ({ riderId: r } as TrainingTypeRider)) : [];
    form.setData(newData);
  }

  return (
    <FormSection
      title={t('stable.riders.group')}
      description={t('stable.trainingTypes.riders.description')}
      full
    >
      <div className="mb-10">
        <span>{t("stable.trainingTypes.fields.isAvailableForAnyRider")}</span>
        <FormCheckbox.Set title={t("stable.trainingTypes.fields.isAvailableForAnyRider")}>
          <FormCheckbox.Input {...form.input('isAvailableForAnyRider', 'checkbox', { value: "true", placeholder: t('common.bool.yes') })} />
        </FormCheckbox.Set>
      </div>
      {form.data?.isAvailableForAnyRider && <div className="mb-10">
        <span>{t("stable.trainingTypes.fields.isAvailableForGuest")}</span>
        <FormCheckbox.Set title={t("stable.trainingTypes.fields.isAvailableForGuest")}>
          <FormCheckbox.Input {...form.input('isAvailableForGuest', 'checkbox', { value: "true", placeholder: t('common.bool.yes') })} />
        </FormCheckbox.Set>
      </div>}
      {!form.data?.isAvailableForAnyRider && <div className="mb-10">
        <span>{t("stable.riders.group")}</span>
        {riderOptions && !form.pending && <Select options={riderOptions} value={form.data.trainingTypeRiders?.map(hr => hr.riderId!) || []} onChange={onSelectRider} multiple={true} />}
      </div>}
    </FormSection>
  )
}