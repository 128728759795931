import FormInput from "../Form/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { Message, MessagesClient } from "src/api/notifications/Notifications";
import useForm from "src/hooks/useForm";
import { useTranslation } from "react-i18next";
import Spinner from "../Feedback/Spinner";
import useUser from "src/hooks/useUser";


export interface MessageReplyProps {
  topicId: string;
  setRepliedMessage: (m?: Message) => void;
}

export default (props: MessageReplyProps) => {
  const { topicId, setRepliedMessage } = props;
  const { t } = useTranslation();
  const user = useUser();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new MessagesClient(apiConfiguration);
  const form = useForm({} as Message);

  const onReplied = (response: Message) => {
    setRepliedMessage({ ...response, created: new Date(), user: user } as Message);
    form.setData({ content: '' } as Message);
  }

  const onSubmit = () => {
    form.setPending(true);
    apiClient.replyToTopic(topicId, form.data)
      .then(onReplied)
      .catch(form.catchAnyException)
      .finally(() => form.setPending(false));
  }

  return (
    <form onSubmit={(e) => form.onSubmit(e, onSubmit)}>
      <div className="border-t border-gray-200 px-5 py-3 flex">
        <div className="flex-1">
          <FormInput.WithoutLabel {...form.input('content', 'text', { placeholder: t('crm.messages.typeMessage') })} />
        </div>
        <button type="submit" className="py-3 pl-3" disabled={form.pending}>
          {form.pending ? <Spinner className="h-4" /> : <FontAwesomeIcon icon={faPaperPlane} className="text-sky-500" />}
        </button>
      </div>
    </form>
  )
}