import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Coupon } from 'src/api/stable/Booking';
import FormDatePicker from 'src/components/Form/FormDatePicker';
import FormInput from 'src/components/Form/FormInput'
import FormSection from 'src/components/Layout/Panel/FormSection';
import useClaim from 'src/hooks/useClaim';
import { IForm } from 'src/hooks/useForm';

export interface GeneralFormComponentProps {
  form: IForm<Coupon>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const isDeveloper = useClaim('Developer');

  return (
    <FormSection
      title={t('common.form.general.header')}
      description={t('common.form.general.subheader')}
    >
      {isDeveloper && <div className="mb-10">
        <FormInput.Clean name="id" placeholder="Id" value={form.data.id} readOnly />
      </div>}
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('price', 'number', { min: 0, step: 0.01, placeholder: t('stable.coupons.fields.price'), required: true })} suffix="PLN" />
      </div>
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('sellAvailable', 'number', { min: 0, step: 1, placeholder: t('stable.coupons.fields.sellAvailable') })} />
        {form.data.sold && <div className="text-xs text-gray-500">{t('stable.coupons.fields.sold')}: {form.data.sold}</div>}
      </div>
      <div className="mb-10">
        <div className="font-medium">{t('stable.coupons.fields.sellDate')}</ div>
        <div className="flex mb-5">
          <div className="flex-1 min-w-24">
            <FormDatePicker
              {...form.input('sellStart', 'date', { placeholder: t('common.fields.start') })}
              value={form.data.sellStart}
              onChange={(date) => form.setData({ ...form.data, sellStart: date } as Coupon)}
              time={15}
            />
          </div>
          <div className="m-3">-</div>
          <div className="flex-1 min-w-24">
            <FormDatePicker
              {...form.input('sellEnd', 'date', { placeholder: t('common.fields.end') })}
              value={form.data.sellEnd}
              onChange={(date) => form.setData({ ...form.data, sellEnd: date } as Coupon)}
              time={15}
            />
          </div>
        </div>
        <div className="text-xs text-gray-500">{t('stable.coupons.descriptions.sellDate')}</div>
      </div>
    </FormSection>
  )
}
