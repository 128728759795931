import { useTranslation } from 'react-i18next';
import { IForm } from 'src/hooks/useForm';
import { UserForm } from '../Form';

export interface SingleSignOnFormComponentProps {
  form: IForm<UserForm>;
}

export default function SingleSignOnForm(_props: SingleSignOnFormComponentProps) {
  const { t } = useTranslation();
  return (
    <div className="md:flex md:flex-row md:mt-5 pb-5 border-b border-gray-200">
      <div className="md:w-2/3 md:px-4 pt-5 md:pt-10 sm:px-0">
        <h3 className="text-base font-medium leading-7 text-gray-900">{t('users.form.sso.header')}</h3>
        <div className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">{t('users.form.sso.subheader')}</div>
      </div>
      <div className="md:w-2/3 md:px-4 pt-5 md:pt-10 md:border-l border-gray-100">

      </div>
      <div className="md:w-2/3 md:px-4 pt-5 md:pt-10">
      </div>
    </div>
  )
}