import { ChangeEvent, SelectHTMLAttributes, createRef, useEffect, useState } from "react";
import classNames from "../Utilities/classNames";
import simulateChangeEvent from "./simulateChangeEvent";

export interface GridSelectOption {
  id: string;
  value: any; /* eslint-disable-line @typescript-eslint/no-explicit-any */
  label: string;
  description?: React.ReactNode;
  image?: React.ReactNode;
  disabled?: boolean;
}

interface GridSelectOptionComponentProps {
  option: GridSelectOption;
  isActive?: boolean;
  full?: boolean;
  small?: boolean;
  onClick: () => void;
}

interface GridSelectComponentProps extends SelectHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  options: GridSelectOption[];
  full?: boolean;
  small?: boolean;
}

const GridSelectOption = (props: GridSelectOptionComponentProps) => {
  const { option, isActive, onClick, small } = props;

  return (
    (
      <div
        key={option.id}
        className={classNames([
          small ? 'w-1/2 lg:w-1/3 xl:w-1/4 p-3' : 'w-full lg:w-1/2 xl:w-1/3 p-3',
          option.disabled && 'opacity-25'
        ])}
        onClick={() => !option.disabled && onClick()}
      >
        <div className={classNames([
          'flex flex-col justify-between w-full h-full rounded-md shadow-md overflow-hidden p-5 cursor-pointer bg-white',
          isActive && 'ring-2 ring-[--color-primary-700] bg-[--color-primary-100] shadow-lg',
          !isActive && !(option.disabled) && 'ring-1 ring-gray-200 hover:ring-2 hover:ring-[--color-primary-300] active:ring-[--color-primary-500] active:bg-[--color-primary-50] hover:shadow-lg'
        ])}>
          {option.image && typeof option.image === 'string' && <img src={option.image} alt={option.image} title={option.image} className="mx-auto max-w-full rounded-md aspect-[4/5]" />}
          {option.image && typeof option.image !== 'string' && option.image}
          {option.image && <div className="my-2" />}
          <div className="mt-auto">
            <h3 className="text-base font-medium leading-6 text-gray-900">
              {option.label}
            </h3>
            {option.description && <div className="mt-2 text-sm text-gray-500">{option.description}</div>}
          </div>
        </div>
      </div>
    )
  );
}


const GridSelectFullOption = (props: GridSelectOptionComponentProps) => {
  const { option, isActive, onClick } = props;

  return (
    (
      <div
        key={option.id}
        className={classNames([
          'w-full p-3',
          option.disabled && 'opacity-25'
        ])}
        onClick={() => !option.disabled && onClick()}
      >
        <div className={classNames([
          'w-full h-full rounded-md shadow-md overflow-hidden p-5 cursor-pointer flex bg-white',
          isActive && 'ring-2 ring-[--color-primary-700] bg-[--color-primary-100] shadow-lg',
          !isActive && !(option.disabled) && 'ring-1 ring-gray-200 hover:ring-2 hover:ring-[--color-primary-300] active:ring-[--color-primary-500] active:bg-[--color-primary-50] hover:shadow-lg'
        ])}>
          {option.image && typeof option.image === 'string' &&
            <div className="w-36 mr-5">
              <img src={option.image} alt={option.image} title={option.image} className="mr-auto max-w-32 rounded-md" />
            </div>
          }
          {option.image && typeof option.image !== 'string' &&
            <div className="w-36 mr-5">
              {option.image}
            </div>
          }
          <div className="flex-1">
            <h3 className="font-medium leading-6 text-gray-900">{option.label}</h3>
            {option.description && <div className="mt-2 text-sm text-gray-500">{option.description}</div>}
          </div>
        </div>
      </div>
    )
  );
}

const GridSelect = (props: GridSelectComponentProps) => {
  const { id, options, value, placeholder, onChange, full, small } = props;

  const [val, setVal] = useState<string>();
  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (val === value) return;
    if (!val && value) return;
    simulateChangeEvent(inputRef?.current, val);
  }, [val]);

  useEffect(() => {
    if (value !== val) setVal(String(value) ?? "");
  }, [value]);

  const onChangeMiddleware = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange)
      onChange(e);
  }

  return (

    <div className="flex flex-wrap">
      {!full && options.map((option, i) => <GridSelectOption key={i} onClick={() => setVal(option.value)} option={option} isActive={val === option.value} small={small} />)}
      {full && options.map((option, i) => <GridSelectFullOption key={i} onClick={() => setVal(option.value)} option={option} isActive={val === option.value} />)}
      <input ref={inputRef} id={id} name={id} value={value} onChange={onChangeMiddleware} title={placeholder} className="hidden" />
    </div>
  )

}

export default GridSelect;