import { faChevronCircleLeft } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export interface Step {
  name: string;
  disabled?: boolean;
}

export interface BulletStepsComponentProps {
  steps: Step[];
  currentStep: number;
  onClick?: (step: Step, index: number) => void;
}

export default function BulletSteps(props: BulletStepsComponentProps) {
  const { steps, currentStep, onClick } = props;
  const { t } = useTranslation();
  return (
    <nav className="flex items-center justify-center" aria-label="Progress">
      {currentStep > 0 && !steps[currentStep - 1].disabled &&
        <div className="pr-5">
          <FontAwesomeIcon
            icon={faChevronCircleLeft}
            className="size-6 relative top-0.5 text-[--color-primary-700] hover:text-[--color-primary-500] active:text-[--color-primary-400] cursor-pointer"
            onClick={() => onClick && onClick(steps[currentStep - 1], currentStep - 1)}
          />
        </div>
      }
      <div className="text-sm font-medium">
        {t('ui.steps.step')} {currentStep + 1} / {steps.length}
      </div>
      <ol role="list" className="ml-8 flex items-center space-x-5">
        {steps.map((step, id) => (
          <li key={step.name}>
            {id < currentStep ? (
              <span onClick={() => !step.disabled && onClick && onClick(step, id)} className="block h-2.5 w-2.5 rounded-full bg-[--color-primary-600] hover:bg-[--color-primary-900]">
                <span className="sr-only">{step.name}</span>
              </span>
            ) : id == currentStep ? (
              <span onClick={() => !step.disabled && onClick && onClick(step, id)} className="relative flex items-center justify-center" aria-current="step">
                <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                  <span className="h-full w-full rounded-full bg-[--color-primary-200]" />
                </span>
                <span className="relative block h-2.5 w-2.5 rounded-full bg-[--color-primary-600]" aria-hidden="true" />
                <span className="sr-only">{step.name}</span>
              </span>
            ) : (
              <span onClick={() => !step.disabled && onClick && onClick(step, id)} className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400">
                <span className="sr-only">{step.name}</span>
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}
