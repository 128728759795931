import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router"


const useLocalizedNavigate = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const localizedNavigate = (url: string) => {
    const localizedUrl = `/${i18n?.resolvedLanguage}/${url.toString()[0] === '/' ? url.toString().substring(1) : url.toString()}`;
    return navigate(localizedUrl);
  }

  return localizedNavigate;
}

export default useLocalizedNavigate;