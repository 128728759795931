import WizardStepWelcome from "./WizardStepWelcome";
import WizardStepAccess from "./WizardStepAccess";
import WizardStepAppearance from "./WizardStepAppearance";
import WizardStepReservations from "./WizardStepBooking";
import WizardStepHorses from "./WizardStepHorses";
import WizardStepInstructors from "./WizardStepInstructors";
import WizardStepTrainingPlaces from "./WizardStepTrainingPlaces";
import WizardStepTrainingTypes from "./WizardStepTrainingTypes";
import WizardStepOtherUsers from "./WizardStepOtherUsers";
import WizardStepInstallation from "./WizardStepInstallation";

export default [
  {
    key: "welcome",
    component: WizardStepWelcome
  },
  {
    key: "access",
    component: WizardStepAccess
  },
  {
    key: "appearance",
    component: WizardStepAppearance
  },
  {
    key: "booking",
    component: WizardStepReservations
  },
  {
    key: "horses",
    component: WizardStepHorses
  },
  {
    key: "instructors",
    component: WizardStepInstructors
  },
  {
    key: "trainingPlaces",
    component: WizardStepTrainingPlaces
  },
  {
    key: "trainingTypes",
    component: WizardStepTrainingTypes
  },
  {
    key: "otherUsers",
    component: WizardStepOtherUsers
  },
  {
    key: "installation",
    component: WizardStepInstallation
  }
];
