import _ from 'lodash';
import { Dictionary } from 'lodash';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { PaymentMethod, PaymentMethodTranslation } from 'src/api/financial/Accountancy';
import ComboBox from 'src/components/Form/FormComboBox';
import { TextOptionProps } from 'src/components/Form/FormOptions';
import useAccountancyContext from 'src/hooks/stores/useAccountancyContext';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import { requestPaymentMethods } from 'src/store/accountancy/actions';

export interface PaymentMethodSelectComponentProps {
  onChange: ((id: string | Dictionary<PaymentMethod | undefined>, entity?: PaymentMethod) => void);
  value?: string | string[];
  multiple?: boolean;
  placeholder?: string;
}

const PaymentMethodSelect = (props: PaymentMethodSelectComponentProps) => {
  const { onChange, value, multiple, ...otherProps } = props;
  const [query, setQuery] = useState<string>('');
  const entityTranslation = useEntityTranslation<PaymentMethod, PaymentMethodTranslation>();
  const [results, setResults] = useState<PaymentMethod[]>([]);
  const autocompleteTimeout = useRef<(NodeJS.Timeout) | undefined>(undefined);
  const { state, dispatch } = useAccountancyContext();

  useEffect(() => {
    applyAutocomplete(query);
  }, [query, state.paymentMethods.data]);

  useEffect(() => {
    if (state.paymentMethods.data?.length === 0 && !state.paymentMethods.isLoading && !state.paymentMethods.loaded) {
      loadEntities();
    }
  }, []);

  const onChangeMiddleware: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value;
    onChange(newValue, state.paymentMethods.data.find(e => e.id === newValue));
  };
  const onChangeMiddlewareMultiple: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValues = e.target.value.split(',').filter(v => v.length > 0);
    onChange(
      _.chain(newValues.map(v => ({ id: v, value: state.paymentMethods.data.find(e => e.id === v) })))
        .keyBy('id')
        .mapValues('value')
        .value()
    );
  };
  const loadEntities = () => {
    dispatch(requestPaymentMethods());
  }
  const applyAutocomplete = (query: string) => {
    query = query.toLowerCase();
    if (state.paymentMethods.data.length === 0) {
      setResults([]);
      return;
    }
    setResults([...state.paymentMethods.data].filter(e => entityTranslation.getCurrentTranslation(e)?.title?.toLowerCase().includes(query)));
  };
  const onAutoComplete = (query: string) => {
    if (autocompleteTimeout.current) {
      clearTimeout(autocompleteTimeout.current);
      autocompleteTimeout.current = undefined;
    }
    autocompleteTimeout.current = setTimeout(() => setQuery(query), 250);
  };
  const options = results?.map(r => ({ value: r.id, label: entityTranslation.getCurrentTranslation(r)?.title }) as TextOptionProps);

  return (
    <ComboBox options={options} value={value} onChange={multiple ? onChangeMiddlewareMultiple : onChangeMiddleware} onAutoComplete={onAutoComplete} multiple={multiple} {...otherProps} />
  );
};

export default PaymentMethodSelect;