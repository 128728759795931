import { FC, useEffect } from 'react';
import useForm from '../../../../hooks/useForm';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Invoice, InvoicePosition, InvoicesClient, InvoiceType, Payment } from 'src/api/financial/Accountancy';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import Header from './Header';
import GeneralForm from './Form/General';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import Button from 'src/components/Actions/Button';
import useClaim from 'src/hooks/useClaim';
import Toast from 'src/components/Feedback/Toast';
import useAuthGuard from 'src/hooks/useAuthGuard';
import AuthGuardLoading from 'src/components/Feedback/AuthGuardLoading';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import SellerForm from './Form/Seller';
import BuyerForm from './Form/Buyer';
import PositionsForm from './Form/Positions';
import PaymentsForm from '../Shared/Payments';
import { AccountancyProvider } from 'src/store/accountancy/context';
import _, { Dictionary } from 'lodash';
import moment from 'moment';

const InvoicesForm: FC = () => {
  const apiConfiguration = useApiConfiguration();

  const apiClient = new InvoicesClient(apiConfiguration);
  const form = useForm<Invoice>({
    transactionType: 0,
    sellType: 0,
    currencyCode: 'PLN',
    issueDate: new Date(),
    transactionDate: new Date(),
    paymentTerm: moment().add(14, 'days').toDate(),
  } as Invoice);
  const authGuard = useAuthGuard('/panel/', ['InvoicesRead']);
  const invoicesUpdateClaim = useClaim('InvoicesUpdate');
  const { id } = useParams<string>();
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();

  const onDelete = () => {
    form.setPending(true);
    if (!form.data || !id) return;
    if (id === 'create') return;
    apiClient.delete(id)
      .finally(() => {
        form.setPending(false);
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
        navigate(`/panel/accountancy/invoices/`)
      })
  }

  const onSubmit = () => {
    form.setPending(true);
    const data = { ...form.data } as Invoice;
    data.type = Number(data.type);
    data.transactionType = Number(data.transactionType);
    data.sellType = Number(data.sellType);
    data.positions = [...data.positions || []].map(p => {
      const position = { ...p } as InvoicePosition;
      delete position.priceObject;
      delete position.value;
      position.taxRate = Number(position.taxRate);
      position.priceType = Number(position.priceType);
      position.price = Number(position.price);
      position.amount = Number(position.amount);
      position.rebateRate = position.rebateRate ? Number(position.rebateRate) : undefined;
      position.rebateValue = position.rebateValue ? Number(position.rebateValue) : undefined;
      return position;
    });
    data.payments = [...data.payments || []].map(p => {
      const payment = { ...p } as Payment;
      delete payment.valueObject;
      delete payment.negativePrice;
      delete payment.negativeValueObject;
      delete payment.price;
      payment.value = Number(payment.value);
      return payment;
    });
    delete data.value;
    delete data.valuePaid;
    delete data.valuePerTaxRate;
    delete data.valueRemain;
    delete data.successfulPayments;
    if (data.buyerId == 'create') {
      delete data.buyerId;
    } else {
      delete data.buyer;
    }
    if (data.sellerId == 'create') {
      delete data.sellerId;
    } else {
      delete data.seller;
    }
    if (!form.data || !id) return;
    if (id !== 'create') {
      apiClient.update(id, data)
        .then(() => Toast.success(t("common.status.success"), t("common.form.saved")))
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    } else {
      apiClient.create(data)
        .then(() => {
          Toast.success(t("common.status.success"), t("common.form.saved"));
          navigate(`/panel/accountancy/invoices/`);
        })
        .catch(e => form.catchAnyException(e, true))
        .finally(() => form.setPending(false));
    }
  }

  const fetch = () => {
    if (!id) return;
    apiClient.find(id)
      .then(form.setData)
      .catch(form.catchAnyException);
  }

  useEffect(() => {
    if (id !== 'create') fetch();
    applicationDispatch(
      setBreadcrumbs([
        { label: "accountancy.invoices.group", href: "/panel/accountancy/invoices/" },
        { label: id !== 'create' ? 'common.actions.edit' : 'common.actions.createNew', href: `/panel/accountancy/invoices/${id}` }
      ])
    );
  }, [id]);

  useEffect(() => {
    form.setReadOnly(!invoicesUpdateClaim);
  }, [invoicesUpdateClaim])

  if (authGuard === undefined) return <AuthGuardLoading />

  const validate = (): Dictionary<string[]> => {
    const result = {} as Dictionary<string[]>;
    if (!form.data.sellerId && !form.data.seller) {
      result["sellerId"] = ["NotEmptyValidator"];
    }
    if (form.data.type != InvoiceType.Receipt && !form.data.buyerId && !form.data.buyer) {
      result["buyerId"] = ["NotEmptyValidator"];
    }
    return result;
  }

  return (
    <>
      <AccountancyProvider>
        <form onSubmit={e => form.onSubmit(e, onSubmit, validate)}>
          <Header invoice={form.data} onDelete={onDelete} form={form} />
          <GeneralForm form={form} />
          <SellerForm form={form} />
          <BuyerForm form={form} />
          <PositionsForm form={form} />
          <PaymentsForm form={form} />
          <div className="text-end mt-5">
            <Button colorName="primary" className="text-md px-5 py-3" disabled={form.pending}>
              {t('common.actions.save')}
            </Button>
          </div>
        </form >
      </AccountancyProvider>
    </>
  )
}

export default InvoicesForm;