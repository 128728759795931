import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Horse, HorseAbsence, HorseAbsencesClient, HttpQueryFilter } from 'src/api/stable/Stable';
import Button from 'src/components/Actions/Button';
import Spinner from 'src/components/Feedback/Spinner';
import Toast from 'src/components/Feedback/Toast';
import FormDatePicker from 'src/components/Form/FormDatePicker';
import FormSection from 'src/components/Layout/Panel/FormSection';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useForm, { IForm } from 'src/hooks/useForm';

type Absence = HorseAbsence;

export interface AbsencesFormComponentProps {
  form: IForm<Horse>;
}

export interface AbsenceRowComponentProps {
  form: IForm<Absence[]>;
  index: number;
  absence: Absence;
  onClickCreate: () => void;
  onClickUpdate: () => void;
  onClickDelete: () => void;
}

const AbsenceRow = (props: AbsenceRowComponentProps) => {
  const { form, index, absence, onClickCreate, onClickUpdate, onClickDelete } = props;
  const fieldName = (name: string) => `${index}.${name}`;
  const onChange = (value: Absence) =>
    form.setData([...form.data.map((absence, i) => i === index ? value : absence)]);
  return (
    <tr>
      <td>
        <FormDatePicker
          {...form.input(fieldName('start'), 'date')}
          value={absence.start}
          onChange={(date) => onChange({ ...absence, start: date } as Absence)}
          time={15}
          minDate={new Date()}
        />
      </td>
      <td>
        <FormDatePicker
          {...form.input(fieldName('end'), 'date')}
          value={absence.end}
          onChange={(date) => onChange({ ...absence, end: date } as Absence)}
          time={15}
          minDate={new Date()}
          required={absence.start !== undefined}
        />
      </td>
      <td>
        <div className="ml-auto flex mt-2 justify-end">
          {!absence.id && <Button type="button" colorName="primary" className="text-xs" onClick={onClickCreate} disabled={form.pending}>Dodaj</Button>}
          {absence.id && <Button type="button" colorName="primary" className="text-xs" onClick={onClickUpdate} disabled={form.pending}>Zapisz</Button>}
          {absence.id && <Button type="button" colorName="red" className="text-xs" onClick={onClickDelete} disabled={form.pending}>Usuń</Button>}
        </div>
      </td>
    </tr>
  )
}

export default function AbsencesForm(props: AbsencesFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const horsesClient = new HorseAbsencesClient(apiConfiguration);
  const absencesForm = useForm<Absence[]>([]);

  useEffect(() => {
    fetchAbsences();
  }, [form.data.id]);

  const insertAbsence = () =>
    absencesForm.setData([...absencesForm.data, { horseId: form.data.id! } as Absence]);
  const replaceAbsence = (index: number, value: Absence) =>
    absencesForm.setData([...absencesForm.data.map((absence, i) => i === index ? value : absence)])
  const removeAbsence = (index: number) =>
    absencesForm.setData([...absencesForm.data.filter((absence, i) => i !== index)])

  const fetchAbsences = () => {
    if (form.data.id === undefined) return;
    absencesForm.setPending(true);
    horsesClient.get(
      [{ property: 'HorseId', type: '=', value: form.data.id! } as HttpQueryFilter],
      undefined,
      undefined,
      undefined,
      undefined,
      undefined
    )
      .then(response => absencesForm.setData(response.items ?? []))
      .catch(absencesForm.catchAnyException)
      .finally(() => absencesForm.setPending(false));
  }
  const createAbsence = (index: number, value: Absence) => {
    absencesForm.setPending(true);
    horsesClient.create({ ...value, horseId: form.data.id! } as Absence)
      .then(response => {
        replaceAbsence(index, response);
        Toast.success(t("common.status.success"), t("common.form.saved"));
      })
      .catch(absencesForm.catchAnyException)
      .finally(() => absencesForm.setPending(false));
  }
  const updateAbsence = (index: number, value: Absence) => {
    absencesForm.setPending(true);
    horsesClient.update(value.id!, value)
      .then(response => {
        replaceAbsence(index, response);
        Toast.success(t("common.status.success"), t("common.form.saved"));
      })
      .catch(absencesForm.catchAnyException)
      .finally(() => absencesForm.setPending(false));
  }
  const deleteAbsence = (index: number) => {
    absencesForm.setPending(true);
    horsesClient.delete(absencesForm.data[index].id!)
      .then(() => {
        removeAbsence(index);
        Toast.success(t("common.status.success"), t("common.feedback.deleted"));
      })
      .catch(absencesForm.catchAnyException)
      .finally(() => absencesForm.setPending(false));
  }

  return (
    <FormSection
      title={t('stable.horses.absences.title')}
      description={t('stable.horses.absences.description')}
      full
    >
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-start">{t('stable.trainings.fields.start')}</th>
            <th className="text-start">{t('stable.trainings.fields.end')}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {absencesForm.data && absencesForm.data.map((absence, i) => <AbsenceRow
            key={i}
            form={absencesForm}
            index={i}
            absence={absence}
            onClickCreate={() => createAbsence(i, absence)}
            onClickUpdate={() => updateAbsence(i, absence)}
            onClickDelete={() => deleteAbsence(i)}
          />)}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3}>
              <div className="ml-auto flex justify-end border-t border-gray-100 mt-3 pt-3">
                {absencesForm.pending && <Spinner className="size-8 mr-5 mt-0.5" />}
                <Button type="button" colorName="primary" className="text-sm" onClick={insertAbsence} disabled={absencesForm.pending}>
                  <FontAwesomeIcon icon={faPlusCircle} className="w-6" /> {t('stable.horses.absences.add')}
                </Button>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </FormSection>
  )
}