import { useEffect } from "react";
import { ConfigurationApis, getApiBaseUrl } from "src/config/config";
import { useSignalRHub } from "use-signalr-hub"
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { NotificationsClient } from "src/api/notifications/Notifications";
import { setUnread } from "./actions";
import useToken from "src/hooks/useToken";
import useNotificationContext from "src/hooks/stores/useNotificationContext";

export default () => {
  const { state, dispatch } = useNotificationContext();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new NotificationsClient(apiConfiguration);
  const token = useToken();

  const fetchUnread = () => {
    if (!token) return;
    apiClient.countUnread()
      .then(response => {
        dispatch(setUnread(response));
      })
      .catch();
  }

  const signalRCallbacks = {
    onCreate: () => {
      fetchUnread();
    },
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Notifications)}/hubs/NotificationsHub`, {
    enabled: !!token,
  });

  useEffect(() => {
    if (signalRHub) {
      signalRHub.off("onCreate");
      signalRHub.on("onCreate", signalRCallbacks.onCreate);
    }
  }, [signalRHub]);

  useEffect(() => {
    fetchUnread();
  }, [token, state.refresh]);

  useEffect(() => {

    if (token) {
      signalRHub?.start();
    } else {
      signalRHub?.stop();
    }
  }, [token]);

  return <></>;
}