import useForm, { IForm } from "src/hooks/useForm";
import { WizardStepProps } from "./Index";
import { Dictionary } from "lodash";
import Button from "src/components/Actions/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight, faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import FormInput from "src/components/Form/FormInput";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Select from "src/components/Form/FormSelect";
import { User } from "src/api/access/Authority";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface WizardStepOtherUsersForm {
  rows: User[];
}

export interface WizardStepOtherUsersProps extends WizardStepProps {
  data: User[];
  setData: (data: User[]) => void;
}

interface UserRowProps {
  form: IForm<WizardStepOtherUsersForm>;
  index: number;
  row: User;
  onClickDelete: () => void;
}

const UserRow = (props: UserRowProps) => {
  const { form, index, onClickDelete } = props;
  const { t } = useTranslation();

  return (
    <tr>
      <td>
        <FormInput.WithoutLabel {...form.input(`rows.${index}.givenName`, 'text', { placeholder: t('users.fields.givenName') })} />
      </td>
      <td>
        <FormInput.WithoutLabel {...form.input(`rows.${index}.surname`, 'text', { placeholder: t('users.fields.surname') })} />
      </td>
      <td>
        <FormInput.WithoutLabel {...form.input(`rows.${index}.email`, 'email', { placeholder: t('users.fields.email') })} />
      </td>
      <td>
        <Select
          {...form.input(`rows.${index}.roles`, 'select')}
          options={[
            { id: "Administrator", value: "Administrator", label: "Administrator" },
            { id: "Instructor", value: "Instructor", label: "Instruktor" },
            { id: "StableManager", value: "StableManager", label: "Zarządzanie stajnią" },
            { id: "ContentManager", value: "ContentManager", label: "Zarządzanie blogiem" }
          ]}
          multiple
        />
      </td>
      <td className="text-end"><FontAwesomeIcon icon={faTimesCircle} className="text-rose-700 cursor-pointer" onClick={onClickDelete} /></td>
    </tr>
  );
}

export default (props: WizardStepOtherUsersProps) => {
  const { nextStep, prevStep, data, setData } = props;
  const form = useForm({ rows: [] } as WizardStepOtherUsersForm);
  useEffect(() => {
    form.setData({ rows: data });
  }, []);
  const onSubmit = () => {
    setData([...form.data.rows]);
    nextStep();
  }
  const validate = (): Dictionary<string[]> => {
    const result = {} as Dictionary<string[]>;
    return result;
  }

  const deleteRow = (index: number) => {
    form.setData({
      ...form.data,
      rows: form.data.rows.filter((_, i) => i !== index)
    } as WizardStepOtherUsersForm);
  }

  const addRow = () => {
    form.setData({
      ...form.data,
      rows:
        [
          ...form.data.rows,
          {

          } as User
        ]
    } as WizardStepOtherUsersForm);
  }

  return (
    <form onSubmit={e => form.onSubmit(e, onSubmit, validate)}>
      <p className="text-sm text-gray-600">
        To nie jest konieczne, ale jeżeli chcesz dodać administratorów do serwisu, możesz to zrobić teraz w bardzo łatwy sposób.
      </p>
      <table className="w-full mt-8">
        <thead>
          <tr>
            <th className="font-medium text-xs text-start w-1/4">Imię</th>
            <th className="font-medium text-xs text-start w-1/4">Nazwisko</th>
            <th className="font-medium text-xs text-start w-1/4">Adres e-mail</th>
            <th className="font-medium text-xs text-start">Uprawnienia</th>
          </tr>
        </thead>
        <tbody>
          {form.data?.rows?.map((row, index) => <UserRow key={index} form={form} index={index} row={row} onClickDelete={() => deleteRow(index)} />)}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4} className="text-end pt-4">
              <Button type="button" colorName="emerald" className="text-sm px-5 font-medium" onClick={addRow}>
                <FontAwesomeIcon icon={faPlusCircle} className="mr-3" />
                Dodaj
              </Button>
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="flex justify-between border-t border-gray-100 pt-4 mt-8">
        <Button type="button" colorName="gray" className="px-5 py-3" onClick={prevStep}>
          <FontAwesomeIcon icon={faChevronCircleLeft} className="mr-3" />
          Powrót
        </Button>
        <Button type="submit" colorName="primary" className="px-5 py-3">
          <FontAwesomeIcon icon={faChevronCircleRight} className="mr-3" />
          Przejdź dalej
        </Button>
      </div>
    </form>
  );
};
