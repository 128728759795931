import { useState } from "react";

export interface Uploader {
  setPhoto: (url: string) => void;
  photo?: string;
  error: boolean;
  onUploadFile: (file: File) => Promise<string>;
}

export const useUploader = (callback: (file: File) => Promise<string>, defaultPhoto?: string): Uploader => {
  const [photo, setPhoto] = useState<string | undefined>(defaultPhoto);
  const [error, setError] = useState(false);

  return {
    setPhoto,
    photo,
    error,
    onUploadFile: (file: File) => new Promise<string>((resolve, reject) => {
      callback(file)
        .then(result => {
          setPhoto(result);
          setError(false);
          resolve(result);
        })
        .catch(result => {
          setError(false);
          reject(result);
        })
    })
  }
}