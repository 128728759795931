import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Coupon } from 'src/api/stable/Booking';
import FormInput from 'src/components/Form/FormInput'
import FormSection from 'src/components/Layout/Panel/FormSection';
import { IForm } from 'src/hooks/useForm';

export interface ValidityFormComponentProps {
  form: IForm<Coupon>;
}

export default function ValidityForm(props: ValidityFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();

  return (
    <FormSection
      title={t('stable.coupons.form.validity.header')}
      description={t('stable.coupons.form.validity.subheader')}
    >
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('validity.days', 'number', { min: 1, required: true, placeholder: t('stable.coupons.fields.validity.days') })} />
        <div className="text-xs text-gray-500">{t('stable.coupons.descriptions.validity.days')}</div>
      </div>
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('validity.count', 'number', { min: 1, required: true, placeholder: t('stable.coupons.fields.validity.count') })} />
        <div className="text-xs text-gray-500">{t('stable.coupons.descriptions.validity.count')
        }</div>
      </div>
    </FormSection>
  )
}
