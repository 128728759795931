import { Menu, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import LocalizedLink from "src/components/Router/LocalizedLink";
import classNames from "src/components/Utilities/classNames";
import useUser from "src/hooks/useUser";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import Avatar from "src/components/User/Avatar";

interface IUserNavigation {
  name: string;
  href: string;
  active?: boolean;
}

interface MenuItemProps {
  item: IUserNavigation;
}

function MenuItem(props: MenuItemProps) {
  const { item } = props;
  return (
    <Menu.Item key={item.name}>
      <LocalizedLink
        to={item.href}
        className={classNames(
          item.active ? 'bg-gray-50' : '',
          'block px-3 py-1 text-sm leading-6 text-gray-900'
        )}
      >
        {item.name}
      </LocalizedLink>
    </Menu.Item>
  )
}

export default function UserMenu() {
  const { t } = useTranslation();
  const user = useUser();

  const userNavigation: IUserNavigation[] = [
    { name: t('userMenu.profile'), href: `/panel/users/${user?.id}` },
    { name: t('users.panel.header'), href: `/user/` },
    { name: t('auth.signOut.header'), href: '/auth/sign-out' },
  ];

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="-m-1.5 flex items-center p-1.5">
        <span className="sr-only">{t('userMenu.header')}</span>
        <Avatar className="h-8 w-8 rounded-full bg-gray-50" />
        <span className="hidden lg:flex lg:items-center">
          <span className="ml-4 text-sm font-medium leading-6 text-gray-900" aria-hidden="true">
            {user?.givenName}
          </span>
          <FontAwesomeIcon icon={faAngleDown} className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          {userNavigation.map((item) => <MenuItem item={item} key={item.href} />)}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}