export enum ActionType {
    SetConfiguration
}

export interface KeyValuePair {
    key: string;
    value: any; /* eslint-disable-line @typescript-eslint/no-explicit-any */
}

export interface SetConfiguration {
    type: ActionType.SetConfiguration;
    payload: KeyValuePair;
}

export const setConfiguration = (key: string, value: any): SetConfiguration => ({ /* eslint-disable-line @typescript-eslint/no-explicit-any */
    type: ActionType.SetConfiguration,
    payload: { key: key, value: value },
});

export type ConfigurationActions = SetConfiguration;