import { faMagicWandSparkles } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Transition, Dialog } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import Button from "src/components/Actions/Button";
import useLocalizedNavigate from "src/hooks/useNavigate";


interface WizardModalProps {
  visible: boolean;
  setVisible: (state: boolean) => void;
}

const WizardModal = (props: WizardModalProps) => {
  const { visible, setVisible } = props;
  const { t } = useTranslation();
  const navigate = useLocalizedNavigate();
  const onCancel = () => setVisible(false);
  const onAccept = () => navigate('/wizard');

  return (
    <>
      <Transition.Root show={visible} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 overflow-y-auto z-50" onClose={() => setVisible(false)}>
          <div className="flex items-center justify-center min-h-screen z-50 ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="bg-white rounded-lg p-4 z-50 flex-grow max-w-lg">
                <div>
                  <div className="text-center py-8">
                    <FontAwesomeIcon icon={faMagicWandSparkles} className="text-[--color-primary-700] size-24 opacity-50" />
                  </div>
                  <div className="my-3 text-center sm:my-8">
                    <h3 className="text-3xl font-semibold leading-6 text-gray-900 mb-8" id="modal-title">{t('panel.insights.wizard.header')}</h3>
                    <div className="mt-2">
                      <p className="text-base text-gray-500">{t('panel.insights.wizard.description')}</p>
                    </div>
                  </div>
                </div>
                <div className="flex gap-x-4">
                  <Button colorName="gray" className="flex-1" onClick={onCancel}>{t('common.actions.cancel')}</Button>
                  <Button colorName="primary" className="flex-1" onClick={onAccept}>{t('panel.insights.wizard.action')}</Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog >
      </Transition.Root >
    </>
  );
};

export default WizardModal;