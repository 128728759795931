import { useTranslation } from "react-i18next";
import useUser from "src/hooks/useUser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LocalizedLink from "src/components/Router/LocalizedLink";
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { useState } from "react";
import { ConfigurationApis, getApiBaseUrl } from "src/config/config";
import useTenant from "src/hooks/useTenant";
import Alert from "src/components/Feedback/Alert";
import { AvatarsClient } from "src/api/access/Auth";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import FormImage from "src/components/Form/FormImage";


const UserIndex = () => {
  const { t, i18n } = useTranslation();
  const user = useUser();
  const tenant = useTenant();
  const [error, setError] = useState(false);
  const apiConfiguration = useApiConfiguration();
  const avatarClient = new AvatarsClient(apiConfiguration);

  const avatarUrl = `${getApiBaseUrl(ConfigurationApis.Auth)}/api/v1/avatars/${user?.id}?XTenantId=${tenant}`;
  const [avatar, setAvatar] = useState<string>(avatarUrl);
  const uploadAvatar = (file: File) => new Promise<string>(
    (resolve, reject) => {
      const onAvatarUploadError = (_e: any) => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
        setError(true);
        setAvatar(avatar);
        reject();
      }
      const onAvatarUploadSuccess = () => {
        setError(false);
        const url = `${avatarUrl}&${(new Date()).getTime()}`;
        setAvatar(url);
        resolve(url);
      }

      avatarClient
        .uploadMyAvatar(undefined, { fileName: file.name, data: file })
        .catch(onAvatarUploadError)
        .then(onAvatarUploadSuccess);
    }
  );

  return (
    <>
      <div>
        <h2 className="text-2xl font-medium leading-9 tracking-tight text-gray-900">
          {t('users.panel.general')}
        </h2>
      </div>
      {error && <Alert.Error title={t('common.errors.500')} noClose />}
      <div className="xl:grow">
        <dl className="mt-6 pb-6 space-y-6 divide-y divide-gray-100 border-y border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('users.fields.givenName')}</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">{user?.givenName}</div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('users.fields.surname')}</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">{user?.surname}</div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('users.fields.email')}</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {user?.email}
                {user?.emailConfirmed && <FontAwesomeIcon icon={faCircleCheck} className="inline text-paradise-700 h-5 ml-3" />}
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('auth.fields.phoneNumber')}</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">
                {user?.phoneNumber || <LocalizedLink to="/user/change-phone-number/" className="text-[--color-primary-700] hover:text-[--color-primary-500] active:text-[--color-primary-400]">{t('auth.addPhoneNumber.action')}</LocalizedLink>}
                {user?.phoneNumberConfirmed && <FontAwesomeIcon icon={faCircleCheck} className="inline text-paradise-700 h-5 ml-3" />}
              </div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('auth.fields.created')}</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">{user?.created?.toLocaleDateString(i18n.resolvedLanguage)}</div>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{t('auth.fields.avatar')}</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <FormImage current={avatar} onUploadFile={uploadAvatar} />
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
}

export default UserIndex;