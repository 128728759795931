import React, { ReactElement, Ref, useEffect, useImperativeHandle, useState } from 'react';

import { IndexResult } from 'src/api/Interfaces';
import { NotificationsClient, SystemNotification } from 'src/api/notifications/Notifications';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBellSlash } from '@fortawesome/free-solid-svg-icons';
import ContentParse from '../Cms/ContentParse';
import useNotificationContext from 'src/hooks/stores/useNotificationContext';
import { refresh as refreshState } from 'src/store/notifications/actions';
import Alert from '../Feedback/Alert';
import { useTranslation } from 'react-i18next';

interface SystemNotificationRowComponentProps {
  notification: SystemNotification;
  onMarkAsRead: () => void;
}

interface SystemNotificationsListComponentProps {
  notifications: SystemNotification[];
  onMarkAsRead: (i: string) => void;
}

const SystemNotificationRow = (props: SystemNotificationRowComponentProps) => {
  const { notification, onMarkAsRead } = props;
  return (
    <>
      <div className="p-3">
        <div>
          <div className="lg:flex text-sm">
            <div className="lg:flex lg:flex-auto lg:justify-start">
              <span className="font-medium">{notification.title}</span>
              <span onClick={() => notification.readAt ? undefined : onMarkAsRead()}>
                {!notification.readAt && <FontAwesomeIcon icon={faBellSlash} className="h-4 ml-3 inline top-4 cursor-pointer" />}
              </span>
            </div>
            <div className="text-end text-xs">
              <span className="text-gray-500">{notification.createdAt?.toLocaleDateString()}</span><br />
              <span className="text-gray-500">{notification.createdAt?.toLocaleTimeString()}</span>
            </div>
          </div>
        </div>
        <div className="text-sm text-gray-700">
          <ContentParse>{notification.content}</ContentParse>
        </div>
      </div>
    </>
  );
}


const SystemNotificationsList = (props: SystemNotificationsListComponentProps) => {
  const { notifications, onMarkAsRead } = props;
  const { t } = useTranslation();
  return (
    <>
      {notifications.map(n => <SystemNotificationRow key={n.id} notification={n} onMarkAsRead={() => n.id && onMarkAsRead(n.id)} />)}
      {notifications?.length === 0 && <div className="px-3"><Alert.Information title={t('common.noResults')} noClose /></div>}
    </>
  );
}

interface NotificationsListComponentProps {
  take?: number;
  onChangeLoading: (v: boolean) => void;
}

export interface NotificationsListComponentRef {
  loadMore: () => void;
  setTake: (v: number) => void;
  getTake: () => number;
  canLoadMore: () => boolean;
  markAsReadAll: () => void;
  markAsRead: (i: string) => void;
  refresh: () => void;
}

const NotificationsListComponent = (props: NotificationsListComponentProps, ref: Ref<NotificationsListComponentRef>): JSX.Element => {
  const { onChangeLoading } = props;
  const [loading, setLoading] = useState(false);
  const [take, setTake] = useState(props.take || 10);
  const [result, setResult] = useState<IndexResult<SystemNotification>>();
  const { dispatch } = useNotificationContext();

  const apiConfig = useApiConfiguration();
  const apiClient = new NotificationsClient(apiConfig);

  const canLoadMore = (): boolean => true;
  const getTake = (): number => take;

  const mergeResult = (newResult: IndexResult<SystemNotification>, onlyItems: boolean): void => {
    const items = [...result?.items || [], ...newResult.items || []];
    const uniqueItems = items.reduce<SystemNotification[]>((accumulator, current) => {
      if (!accumulator.find((item) => item.id === current.id)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);

    if (onlyItems) {
      setResult({ ...result, items: uniqueItems });
    } else {
      setResult({ ...newResult, items: uniqueItems });
    }
  }

  const refresh = (): void => {
    if (loading)
      return;
    setLoading(true);
    apiClient.get(take, 0)
      .then(response => mergeResult(response, true))
      .finally(() => setLoading(false));
  }

  const loadMore = (): void => {
    if (loading)
      return;
    setLoading(true);
    const skip = result ? (result?.skipped || 0) + take : 0;
    apiClient.get(take, skip)
      .then(response => mergeResult(response, false))
      .finally(() => setLoading(false));
  }

  const markAsRead = (id: string): void => {
    if (loading)
      return;
    setLoading(true);
    apiClient.markAsRead2(id)
      .then(_response =>
        setResult({
          ...result,
          items: result
            ?.items
            ?.map(r => r.id === id ? { ...r, readAt: new Date() } as SystemNotification : r)
        })
      )
      .finally(() => {
        setLoading(false);
        dispatch(refreshState());
      });
  }

  const markAsReadAll = (): void => {
    if (loading)
      return;
    setLoading(true);
    apiClient.markAllAsRead()
      .then(_response =>
        setResult({
          ...result,
          items: result
            ?.items
            ?.map(r => ({ ...r, readAt: new Date() }) as SystemNotification)
        })
      )
      .finally(() => {
        setLoading(false);
        dispatch(refreshState());
      });
  }

  useImperativeHandle(ref, () => ({
    loadMore,
    setTake,
    canLoadMore,
    refresh,
    getTake,
    markAsReadAll,
    markAsRead,
    onChangeLoading
  }));

  useEffect(() => {
    loadMore();
  }, []);

  useEffect(() => {
    onChangeLoading(loading);
  }, [loading]);

  return (
    <>
      <SystemNotificationsList
        notifications={result?.items || []}
        onMarkAsRead={markAsRead}
      />
    </>
  );
};

export default React.forwardRef(NotificationsListComponent) as
  (p: NotificationsListComponentProps & { ref?: Ref<NotificationsListComponentRef> }) => ReactElement;