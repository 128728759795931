
interface HeaderProps {
  children?: React.ReactNode;
  className?: string;
  white?: boolean;
}

const SectionHeader = (props: HeaderProps) => {
  const { children, className, white } = props;

  return (
    <h2 className={`text-4xl sm:text-5xl tracking-tight ${white ? "text-white" : "text-[--color-primary-700]"} ${className}`}>
      {children}
    </h2>
  )
}

export default SectionHeader;