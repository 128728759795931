import useForm from "src/hooks/useForm";
import { WizardStepProps } from "./Index";
import Button from "src/components/Actions/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/pro-duotone-svg-icons";
import FormSwitch from "src/components/Form/FormSwitch";
import { useTranslation } from "react-i18next";
import { AuthorizationScope } from "src/store/configuration/state";
import Select from "src/components/Form/FormSelect";
import BookingWorkingHours, { daysOfWeek } from "src/components/Booking/BookingWorkingHours";
import useConfigurationContext from "src/hooks/useConfigurationContext";
import { setConfiguration } from "src/store/configuration/actions";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { Settings, SettingsClient } from "src/api/core/Core";
import _ from "lodash";

export default (props: WizardStepProps) => {
  const { nextStep, prevStep } = props;
  const { t } = useTranslation();
  const form = useForm({} as object);
  const { state, dispatch } = useConfigurationContext();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new SettingsClient(apiConfiguration);


  const onSubmit = () => {
    form.setPending(true);
    apiClient.set('booking', undefined, { name: 'booking', value: state.booking } as Settings)
      .catch((ex) => form.catchAnyException(ex, true))
      .then(() => nextStep())
      .finally(() => form.setPending(false));
  }

  const authorizationScope = [
    { id: String(AuthorizationScope.Any), value: AuthorizationScope.Any, label: t('accessScope.any'), disabled: !state.booking.guests },
    { id: String(AuthorizationScope.Users), value: AuthorizationScope.Users, label: t('accessScope.users') },
    { id: String(AuthorizationScope.Noone), value: AuthorizationScope.Noone, label: t('accessScope.noone') },
  ]

  return (
    <form onSubmit={e => form.onSubmit(e, onSubmit)}>
      <p className="text-sm text-gray-600">
        Potrzebujemy jeszcze kilka informacji dotyczących systemu rezerwacyjnego.
        Wybierz odpowiadające Ci opcje w polach ponizszego formularza.
      </p>

      <dl className="mt-6 space-y-6 divide-y divide-gray-50 border-y border-gray-100 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.guests.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <FormSwitch.Decorated
                checked={state.booking.guests}
                onChange={(e) => dispatch(setConfiguration('booking.guests', e.target.checked))}
                placeholder={t('panel.settings.booking.guests.title')}
                aria-description={t('panel.settings.booking.guests.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6"></dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <FormSwitch.Decorated
                name="switch"
                checked={state.booking.requireEquestrianCentreAgreements}
                onChange={(e) => dispatch(setConfiguration('booking.requireEquestrianCentreAgreements', e.target.checked))}
                placeholder={t('panel.settings.booking.requireEquestrianCentreAgreements.title')}
                aria-description={t('panel.settings.booking.requireEquestrianCentreAgreements.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6"></dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <FormSwitch.Decorated
                name="switch"
                checked={state.booking.requirePhoneNumber}
                onChange={(e) => dispatch(setConfiguration('booking.requirePhoneNumber', e.target.checked))}
                placeholder={t('panel.settings.booking.requirePhoneNumber.title')}
                aria-description={t('panel.settings.booking.requirePhoneNumber.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.trainingTypeSelection.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <Select
                options={authorizationScope}
                value={state.booking.selectTrainingType}
                onChange={(e) => dispatch(setConfiguration('booking.selectTrainingType', e.target.value))}
                placeholder={t('panel.settings.booking.trainingTypeSelection.title')}
                aria-description={t('panel.settings.booking.trainingTypeSelection.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.instructorSelection.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <Select
                options={authorizationScope}
                value={state.booking.selectInstructor}
                onChange={(e) => dispatch(setConfiguration('booking.selectInstructor', e.target.value))}
                placeholder={t('panel.settings.booking.instructorSelection.title')}
                aria-description={t('panel.settings.booking.instructorSelection.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.horseSelection.title')}</dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div>
              <Select
                options={authorizationScope}
                value={state.booking.selectHorse}
                onChange={(e) => dispatch(setConfiguration('booking.selectHorse', e.target.value))}
                placeholder={t('panel.settings.booking.horseSelection.title')}
                aria-description={t('panel.settings.booking.horseSelection.description')}
              />
            </div>
          </dd>
        </div>
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6">{t('panel.settings.booking.workingHours.title')}</dt>
          <dd className="mt-1 mb-6 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <table className="max-w-full">
              {daysOfWeek
                .map((d, i) => <BookingWorkingHours
                  key={i}
                  dayOfWeek={d}
                  value={_.get(state.booking.hours, d)}
                  onChange={(value) => dispatch(setConfiguration(`booking.hours.${d}`, value))}
                />)}
            </table>
          </dd>
        </div>
      </dl>
      <div className="flex justify-between border-t border-gray-100 pt-4 mt-8">
        <Button type="button" colorName="gray" className="px-5 py-3" onClick={prevStep}>
          <FontAwesomeIcon icon={faChevronCircleLeft} className="mr-3" />
          Powrót
        </Button>
        <Button type="submit" colorName="primary" className="px-5 py-3">
          <FontAwesomeIcon icon={faChevronCircleRight} className="mr-3" />
          Przejdź dalej
        </Button>
      </div>
    </form>
  );
};
