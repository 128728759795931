import { User } from "src/api/access/Auth";
import { IBreadcrumb } from "./state";

export enum ActionType {
    SetUser,
    SetToken,
    SetTenant,
    ClearUser,
    ClearToken,
    ClearTenant,
    SetOnline,
    SetOffline,
    SetBreadcrumbs
}

export interface SetUser {
    type: ActionType.SetUser;
    payload: User;
}

export interface SetToken {
    type: ActionType.SetToken;
    payload: string;
}

export interface SetTenant {
    type: ActionType.SetTenant;
    payload: string;
}

export interface ClearUser {
    type: ActionType.ClearUser;
}

export interface ClearToken {
    type: ActionType.ClearToken;
}

export interface ClearTenant {
    type: ActionType.ClearTenant;
}

export interface SetOnline {
    type: ActionType.SetOnline;
}

export interface SetOffline {
    type: ActionType.SetOffline;
}

export interface SetBreadcrumbs {
    type: ActionType.SetBreadcrumbs;
    payload: IBreadcrumb[];
}

export const setUser = (user: User): SetUser => ({
    type: ActionType.SetUser,
    payload: user,
});

export const setToken = (token: string): SetToken => ({
    type: ActionType.SetToken,
    payload: token,
});

export const setTenant = (tenant: string): SetTenant => ({
    type: ActionType.SetTenant,
    payload: tenant,
});

export const setOnline = (): SetOnline => ({
    type: ActionType.SetOnline,
});

export const setOffline = (): SetOffline => ({
    type: ActionType.SetOffline,
});

export const clearUser = (): ClearUser => ({
    type: ActionType.ClearUser,
});

export const clearToken = (): ClearToken => ({
    type: ActionType.ClearToken,
});

export const clearTenant = (): ClearTenant => ({
    type: ActionType.ClearTenant,
});

export const setBreadcrumbs = (breadcrumbs: IBreadcrumb[]): SetBreadcrumbs => ({
    type: ActionType.SetBreadcrumbs,
    payload: breadcrumbs
});

export type ApplicationActions = SetUser | SetTenant | SetToken | ClearUser | ClearToken | ClearTenant | SetOnline | SetOffline | SetBreadcrumbs;