import { useTranslation } from 'react-i18next';
import { Buyer } from 'src/api/financial/Accountancy';
import FormInput from 'src/components/Form/FormInput'
import PhoneNumberForm from 'src/components/Form/PhoneNumberForm';
import CountrySelect from 'src/components/i18n/CountryComboBox';
import FormSection from 'src/components/Layout/Panel/FormSection';
import useClaim from 'src/hooks/useClaim';
import { IForm } from 'src/hooks/useForm';

export interface GeneralFormComponentProps {
  form: IForm<Buyer>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const isDeveloper = useClaim('Developer');

  return (
    <FormSection
      title={t('common.form.general.header')}
      description={t('common.form.general.subheader')}
    >
      <h3 className="text-xl mb-8 border-b border-gray-100">{t('accountancy.sides.headers.generalData')}</h3>
      {isDeveloper && <div className="mb-10">
        <FormInput.Clean name="id" placeholder="Id" value={form.data.id} readOnly />
      </div>}
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('name', 'text', { required: true, placeholder: t('common.fields.name') })} />
      </div>
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('vatId', 'text', { placeholder: t('accountancy.sides.fields.vatId') })} />
      </div>
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('notes', 'text', { placeholder: t('common.fields.notes') })} />
      </div>
      <h3 className="text-xl mb-8 border-b border-gray-100">{t('accountancy.sides.headers.address')}</h3>
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('address', 'text', { required: true, placeholder: t('accountancy.sides.fields.address') })} />
      </div>
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('extra', 'text', { placeholder: t('accountancy.sides.fields.extra') })} />
      </div>
      <div className="mb-10 flex">
        <div className="flex-1 w-1/4">
          <FormInput.Overlapping {...form.input('postCode', 'text', { required: true, placeholder: t('accountancy.sides.fields.postCode') })} />
        </div>
        <div>
          <FormInput.Overlapping {...form.input('city', 'text', { required: true, placeholder: t('accountancy.sides.fields.city') })} />
        </div>
      </div>
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('region', 'text', { placeholder: t('accountancy.sides.fields.region') })} />
      </div>
      <div className="mb-10">
        <CountrySelect value={form.data.countryCode} onChange={(id) => form.setData({ ...form.data, countryCode: String(id) } as Buyer)} placeholder={t('common.fields.countryCode')} />
      </div>
      <h3 className="text-xl mb-8 border-b border-gray-100">{t('accountancy.sides.headers.contactData')}</h3>
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('email', 'text', { placeholder: t('accountancy.sides.fields.email') })} />
      </div>
      <div className="mb-10">
        <PhoneNumberForm {...form.input('phoneNumber', 'text', { placeholder: t('auth.fields.phoneNumber') })} />
      </div>
    </FormSection>
  )
}