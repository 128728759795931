

export function DialogHeader(props: DialogHeaderComponentProps) {
  const { children } = props;
  return (
    <div className="flex justify-between mb-5 sm:mb-6 border-b border-gray-200 pb-3">
      {children}
    </div>
  );
} export interface DialogHeaderComponentProps {
  children: React.ReactNode;
}

