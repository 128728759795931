import { useState } from 'react'
import './MessagesTopicsMenu.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessages } from '@fortawesome/pro-duotone-svg-icons';
import UserSelect from '../User/UserSelect';
import { User } from 'src/api/access/Authority';
import Button from '../Actions/Button';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { MessagesClient, MessageTopic } from 'src/api/notifications/Notifications';
import useUser from 'src/hooks/useUser';
import useMessagingDispatch from 'src/hooks/stores/useMessagingDispatch';
import { setActiveTopic } from 'src/store/messaging/actions';
import useClaim from 'src/hooks/useClaim';

const StartNewConversation = () => {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState<User>();

  const apiConfiguration = useApiConfiguration();
  const apiClient = new MessagesClient(apiConfiguration);
  const user = useUser();
  const dispatch = useMessagingDispatch();
  const readUsersClaim = useClaim('UsersRead');

  const onClick = () => {
    if (!selectedUser) return;
    if (!selectedUser.id) return;
    if (user?.id == selectedUser?.id) return;

    apiClient.findTopicWithUser(selectedUser.id)
      .then(topic => {
        dispatch(setActiveTopic(topic));
      })
      .catch(error => {
        if (error.status === 404) {
          apiClient.createTopic({
            users: [{ userId: selectedUser.id }]
          } as MessageTopic)
            .then(createdTopic => {
              apiClient.findTopic(createdTopic.id!)
                .then(newTopic => dispatch(setActiveTopic(newTopic)))
            })
            .catch(() => { });
        }

      });
  }

  if (!readUsersClaim) return <></>;

  return (
    <div className="p-3 flex flex-col">
      <div className="text-xs text-gray-500 uppercase">
        {t('crm.messages.startNewChat')}
      </div>
      <div className="flex gap-x-3">
        <div className="flex-grow">
          <UserSelect value={selectedUser?.id} onChange={(_userId, user) => setSelectedUser(user as User)} hideSelf />
        </div>
        <div>
          <Button colorName="primary" className="mt-2" disabled={!selectedUser || selectedUser?.id === user?.id} onClick={onClick}>
            <FontAwesomeIcon icon={faMessages} />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default StartNewConversation;