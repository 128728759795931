import { ConfigurationApis, getApiBaseUrl } from "src/config/config";
import useTenant from "src/hooks/useTenant";
import Image from "src/components/Media/Image";

export interface HorseImageComponentProps {
  id: string;
  className?: string;
}

export const HorseImage = (props: HorseImageComponentProps) => {
  const { id, className } = props;
  const tenant = useTenant();
  const photoUrl = `${getApiBaseUrl(ConfigurationApis.Stable)}/api/v1/horses/${id}/photo?XTenantId=${tenant}`;
  return (
    <Image src={photoUrl} fallbackSrc='/img/default-horse.webp' className={className} />
  );
}