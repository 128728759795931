import { AccountancyState } from './state';
import { ActionType, AccountancyActions } from './actions';
import _ from 'lodash';

export function configurationReducer(state: AccountancyState, action: AccountancyActions): AccountancyState {
    switch (action.type) {
        case ActionType.RequestPaymentMethods:
            return {
                ...state,
                paymentMethods: {
                    ...state.paymentMethods,
                    isLoading: true
                },
            };
        case ActionType.RequestPaymentMethodStatuses:
            return {
                ...state,
                requestedPaymentMethodStatuses: Array.from(new Set([...state.requestedPaymentMethodStatuses, action.payload]))
            };
        case ActionType.SetPaymentMethods:
            return {
                ...state,
                paymentMethods: {
                    ...state.paymentMethods,
                    isLoading: false,
                    data: action.payload.paymentMethods || []
                },
            };
        case ActionType.SetPaymentMethodStatuses:
            return {
                ...state,
                paymentMethodStatuses: {
                    ...state.paymentMethodStatuses,
                    isLoading: false,
                    data: {
                        ...state.paymentMethodStatuses.data,
                        [action.payload.methodId]: action.payload.statuses || []
                    }
                },
                requestedPaymentMethodStatuses: state.requestedPaymentMethodStatuses.filter(id => id !== action.payload.methodId),
                loadedPaymentMethodStatuses: Array.from(new Set([...state.loadedPaymentMethodStatuses, action.payload.methodId]))
            };
        default:
            return state;
    }
}