import { useTranslation } from 'react-i18next';
import { PaymentRequest } from 'src/api/financial/Accountancy';
import CurrencySelect from 'src/components/Accountancy/CurrencySelect';
import PaymentMethodSelect from 'src/components/Accountancy/PaymentMethodSelect';
import FormDatePicker from 'src/components/Form/FormDatePicker';
import FormInput from 'src/components/Form/FormInput'
import FormSection from 'src/components/Layout/Panel/FormSection';
import { IForm } from 'src/hooks/useForm';

export interface GeneralFormComponentProps {
  form: IForm<PaymentRequest>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();

  return (
    <FormSection
      title={t('common.form.general.header')}
      description={t('common.form.general.subheader')}
    >
      <div className="mb-10">
        <FormInput.Clean
          name="id"
          placeholder="Id"
          value={form.data.id}
          readOnly
        />
      </div>
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('value', 'number', { placeholder: t('accountancy.paymentRequests.fields.value') })} />
      </div>
      <div className="mb-10">
        <CurrencySelect
          value={form.data.currencyCode}
          onChange={(id) => form.setData({ ...form.data, currencyCode: String(id) } as PaymentRequest)}
          placeholder={t('common.fields.currencyCode')}
        />
      </div>
      <div className="mb-10">
        <PaymentMethodSelect
          value={form.data.methodId}
          onChange={(id) => form.setData({ ...form.data, methodId: String(id) } as PaymentRequest)}
          placeholder={t('accountancy.payments.method.single')}
        />
      </div>
      <div className="mb-10">
        <FormDatePicker
          {...form.input('paymentTerm', 'date', { placeholder: t('accountancy.invoices.fields.issueDate') })}
          value={form.data.paymentTerm}
          onChange={(date) => form.setData({ ...form.data, paymentTerm: date } as PaymentRequest)}
          placeholder={t('accountancy.paymentRequests.fields.paymentTerm')}
        />
      </div>
      <div className="mb-10">
        <FormInput.Clean
          name="paymentDate"
          placeholder={t('accountancy.invoices.fields.paymentDate')}
          value={form.data.paymentDate?.toLocaleDateString()}
          readOnly
        />
      </div>
    </FormSection>
  )
}