import { Dialog, Transition } from "@headlessui/react";
import { forwardRef, Fragment, Ref, useImperativeHandle, useState } from 'react';
import useLocalizedNavigate from "src/hooks/useNavigate";
import Button from "../Actions/Button";
import { useTranslation } from "react-i18next";
import useClaim from "src/hooks/useClaim";


export interface BookingAddContextProps {
  userId?: string;
  horseId?: string;
  riderId?: string;
  instructorId?: string;
  date?: Date;
}

export interface BookingAddContextRef {
  setDate: (date: Date) => void;
  open: () => void;
  close: () => void;
}


const BookingAddContext = (props: BookingAddContextProps, ref: Ref<BookingAddContextRef>): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const [date, setDate] = useState<Date | undefined>(props.date);
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();

  const open = () => setOpen(true);
  const close = () => setOpen(false);

  const canUpdateTrainings = useClaim('TrainingsUpdate');
  const canUpdateTasks = useClaim('TasksUpdate');

  const constructParams = () => {
    const params = new URLSearchParams();
    if (props.userId) params.append('userId', props.userId);
    if (props.horseId) params.append('horseId', props.horseId);
    if (props.instructorId) params.append('instructorId', props.instructorId);
    if (props.riderId) params.append('riderId', props.riderId);
    if (date) params.append('date', date.toISOString());
    return params.toString();
  }

  const onClickGeneralTask = () => {
    navigate(`/panel/stable/workers/tasks/create/?${constructParams()}`);
    close();
  }

  const onClickHorseTask = () => {
    navigate(`/panel/stable/horses/tasks/create/?${constructParams()}`);
    close();
  }

  const onClickTraining = () => {
    navigate(`/panel/booking/trainings/create/?${constructParams()}`);
    close();
  }

  useImperativeHandle(ref, () => ({
    open,
    close,
    setDate
  }));

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {t('stable.addActivity')}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500"></p>
                  </div>
                  <div className="mt-4 flex flex-col gap-y-5">
                    {canUpdateTasks && <Button colorName="amber" onClick={onClickGeneralTask}>{t('stable.generalTasks.item')}</Button>}
                    {canUpdateTasks && <Button colorName="pink" onClick={onClickHorseTask}>{t('stable.horseTasks.item')}</Button>}
                    {canUpdateTrainings && <Button colorName="indigo" onClick={onClickTraining}>{t('stable.trainings.item')}</Button>}
                    <Button onClick={close}>{t('common.actions.cancel')}</Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default forwardRef(BookingAddContext);