import { ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Horse, HorseTrainingType, HorseTranslation, HorsesClient, TrainingType } from 'src/api/stable/Stable';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import { TextOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormSection from 'src/components/Layout/Panel/FormSection';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import { IForm } from 'src/hooks/useForm';

export interface TrainingTypesFormComponentProps {
  form: IForm<TrainingType>;
}

export default function HorsesForm(props: TrainingTypesFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const horseTranslation = useEntityTranslation<Horse, HorseTranslation>();
  const [horses, setHorses] = useState<Horse[]>([]);

  const apiConfiguration = useApiConfiguration();
  const horsesClient = new HorsesClient(apiConfiguration);

  useEffect(() => {
    setHorses([]);
    horsesClient
      .get(
        [],
        [],
        1000,
        0,
        undefined,
        undefined
      )
      .then(response => setHorses(response.items ?? []))
      .catch(console.error);
  }, []);

  const horsesOptions = horses?.map(t => ({ value: t.id!, label: horseTranslation.getCurrentTranslation(t)?.name }) as TextOptionProps);

  const onSelectHorse: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    const newData: TrainingType = { ...form.data, horseTrainingTypes: [...form.data.horseTrainingTypes || []] } as TrainingType;
    newData.horseTrainingTypes = value !== '' ? value.split(',').map(r => ({ horseId: r } as HorseTrainingType)) : [];
    form.setData(newData);
  }

  return (
    <FormSection
      title={t('stable.horses.group')}
      description={t('stable.trainingTypes.horses.description')}
      full
    >
      <div className="mb-10">
        <span>{t("stable.trainingTypes.fields.isAvailableForAnyHorse")}</span>
        <FormCheckbox.Set title={t("stable.trainingTypes.fields.isAvailableForAnyHorse")}>
          <FormCheckbox.Input {...form.input('isAvailableForAnyHorse', 'checkbox', { value: "true", placeholder: t('common.bool.yes') })} />
        </FormCheckbox.Set>
      </div>
      {!form.data?.isAvailableForAnyHorse && <div className="mb-10">
        <span>{t("stable.horses.group")}</span>
        {horsesOptions && !form.pending && <Select options={horsesOptions} value={form.data.horseTrainingTypes?.map(hr => hr.horseId!) || []} onChange={onSelectHorse} multiple={true} />}
      </div>}
    </FormSection>
  )
}