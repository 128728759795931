import _ from "lodash";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Alert from "./Alert";


export interface StatusCodeAlert {
  code?: number;
  messages?: { [key: string]: string };
  noClose?: boolean;
}

const StatusCodeAlert = (props: StatusCodeAlert) => {
  const { code, messages, noClose } = props;
  const { t } = useTranslation();

  const mergeMessages = { ...t(`common.errors`, { returnObjects: true }) as { [key: string]: string }, ...messages };
  const title = _.has(mergeMessages, String(code)) ? _.get(mergeMessages, String(code)) : t('common.errors.unknown');

  if (!code) return <Fragment />;
  if (code >= 400 && code <= 499) return <Alert.Information title={title} noClose={noClose} />;
  if (code >= 500 && code <= 599) return <Alert.Error title={title} noClose={noClose} />;
  return <Fragment />;
}

export default StatusCodeAlert;