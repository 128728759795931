import { faCogs, faFlag, faStars, faTimesCircle, faCheckCircle, faFileText, faTimes } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethod, PaymentStatus, PaymentStatusTranslation } from 'src/api/financial/Accountancy';
import Button from 'src/components/Actions/Button';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import FormInput from 'src/components/Form/FormInput'
import { LocalizedFormSection } from 'src/components/Layout/Panel/FormSection';
import { ITranslation } from 'src/hooks/useEntityTranslation';
import { IForm } from 'src/hooks/useForm';
import useTranslationForm, { ITranslatedForm } from 'src/hooks/useTranslationForm';

export interface StatusesFormComponentProps {
  form: IForm<PaymentMethod>;
}

interface StatusRowComponentProps {
  form: IForm<PaymentMethod>;
  translationForm: ITranslatedForm<ITranslation>;
  id: number;
  onDelete: (id: number) => void;
}

function StatusRow(props: StatusRowComponentProps) {
  const { form, translationForm, id, onDelete } = props;
  const { t } = useTranslation();
  const { getTranslationFieldName, culture } = translationForm;

  const onClickDelete = () => onDelete(id);

  useEffect(() => {
    form.setData({ ...form.data, statuses: [...form.data.statuses ?? []].map(s => !s.translations?.some(t => t.culture === culture) ? ({ ...s, translations: [...s.translations ?? [], { culture }] }) : ({ ...s })) } as PaymentMethod);
  }, [culture]);

  return (
    <tr id={`status-${id}`}>
      <td>
        <FormInput.WithoutLabel {...form.input(`statuses.${id}.externalId`, "text", { placeholder: t('common.fields.externalId') })} />
      </td>
      <td>
        <FormInput.WithoutLabel {...form.input(getTranslationFieldName("title", `statuses.${id}`), "text", { placeholder: t('common.fields.title') })} />
      </td>
      <td>
        <FormInput.WithoutLabel {...form.input(getTranslationFieldName("description", `statuses.${id}`), "text", { placeholder: t('common.fields.description') })} />
      </td>
      <td className="pl-4 pt-3">
        <FormCheckbox.Set title={t("accountancy.paymentStatuses.fields.isDefault")}>
          <FormCheckbox.Input {...form.input(`statuses.${id}.isDefault`, 'checkbox', { value: "true" })} />
        </FormCheckbox.Set>
      </td>
      <td className="pl-4 pt-3">
        <FormCheckbox.Set title={t("accountancy.paymentStatuses.fields.isComplete")}>
          <FormCheckbox.Input {...form.input(`statuses.${id}.isComplete`, 'checkbox', { value: "true" })} />
        </FormCheckbox.Set>
      </td>
      <td className="pl-4 pt-3">
        <FormCheckbox.Set title={t("accountancy.paymentStatuses.fields.isProcessing")}>
          <FormCheckbox.Input {...form.input(`statuses.${id}.isProcessing`, 'checkbox', { value: "true" })} />
        </FormCheckbox.Set>
      </td>
      <td className="pl-4 pt-3">
        <FormCheckbox.Set title={t("accountancy.paymentStatuses.fields.isSuccessful")}>
          <FormCheckbox.Input {...form.input(`statuses.${id}.isSuccessful`, 'checkbox', { value: "true" })} />
        </FormCheckbox.Set>
      </td>
      <td className="pl-4 pt-3">
        <FormCheckbox.Set title={t("accountancy.paymentStatuses.fields.isFailed")}>
          <FormCheckbox.Input {...form.input(`statuses.${id}.isFailed`, 'checkbox', { value: "true" })} />
        </FormCheckbox.Set>
      </td>
      <td className="pl-8 pt-3">
        <FontAwesomeIcon icon={faTimes} className="h-4 text-red-700" onClick={onClickDelete} />
      </td>
    </tr>
  )
}

export default function StatusesForm(props: StatusesFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const translationForm = useTranslationForm(form);

  const addMethodStatus = () => {
    form.setData({
      ...form.data,
      statuses: [
        ...form.data.statuses ?? [],
        {
          translations: [
            { culture: translationForm.culture } as PaymentStatusTranslation
          ]
        } as PaymentStatus
      ]
    } as PaymentMethod);
  }

  const deleteMethodStatus = (id: number) => {
    form.setData({
      ...form.data,
      statuses: [
        ...form.data.statuses ?? []
      ].filter((_, index) => index !== id)
    } as PaymentMethod);
  }

  return (
    <LocalizedFormSection
      title={t('accountancy.paymentMethods.form.statuses.header')}
      description={t('accountancy.paymentMethods.form.statuses.subheader')}
      form={translationForm}
      full
    >
      <table className="w-full">
        <thead>
          <tr>
            <th className="text-start">{t('common.fields.externalId')}</th>
            <th className="text-start">{t('common.fields.title')}</th>
            <th className="text-start">{t('common.fields.description')}</th>
            <th><FontAwesomeIcon icon={faStars} className="text-amber-600" /></th>
            <th><FontAwesomeIcon icon={faFlag} className="text-gray-400" /></th>
            <th><FontAwesomeIcon icon={faCogs} className="text-sky-600" /></th>
            <th><FontAwesomeIcon icon={faCheckCircle} className="text-emerald-600" /></th>
            <th><FontAwesomeIcon icon={faTimesCircle} className="text-red-600" /></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {form.data.statuses?.map((_status, index) => <StatusRow key={index} form={form} translationForm={translationForm} id={index} onDelete={deleteMethodStatus} />)}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={9} className="text-end pt-5">
              <Button colorName="primary" type="button" onClick={addMethodStatus}>
                <FontAwesomeIcon icon={faFileText} />
                {t('accountancy.paymentStatuses.actions.add')}
              </Button>
            </td>
          </tr>
        </tfoot>
      </table>
    </LocalizedFormSection>
  );
}