import { Dialog, Transition } from "@headlessui/react";
import { forwardRef, Fragment, Ref, useEffect, useImperativeHandle, useState } from 'react';
import { PeriodicUpdateDecision } from "src/api/stable/Booking";
import Button from "../Actions/Button";
import FormDatePicker from "../Form/FormDatePicker";
import { useTranslation } from "react-i18next";

export enum PeriodicOperation {
  Update,
  Delete
}

export interface PeriodicOperationModalProps {
  decision?: PeriodicUpdateDecision;
  date?: Date;
  onConfirm?: (decision: PeriodicUpdateDecision, date: Date) => void;
  operation: PeriodicOperation;
  removeToday?: boolean;
}

export interface PeriodicOperationModalRef {
  decision?: PeriodicUpdateDecision;
  date?: Date;
  setDate: (date: Date) => void;
  setDecision: (decision: PeriodicUpdateDecision) => void;
  open: () => void;
  close: () => void;
}

const PeriodicDeleteModal = (props: PeriodicOperationModalProps, ref: Ref<PeriodicOperationModalRef>): JSX.Element => {
  const { operation, removeToday } = props;
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [decision, setDecision] = useState<PeriodicUpdateDecision | undefined>(props.decision);
  const [date, setDate] = useState<Date | undefined>(props.date);
  const [option, setOption] = useState<number | undefined>();

  const open = () => setOpen(true);
  const close = () => setOpen(false);

  useEffect(() => {
    if (!isOpen) {
      setOption(undefined);
      setDate(undefined);
      setDecision(undefined);
    }
  }, [isOpen]);

  const onClickToday = () => {
    setOption(1);
    setDecision(0);
    if (props.onConfirm) props.onConfirm(0, date ?? new Date());
    close();
  }

  const onClickAllFuture = () => {
    setOption(2);
    setDecision(1);
    if (!date) setDate(new Date());
  }

  const onConfirmAllFuture = () => {
    if (props.onConfirm) props.onConfirm(1, date ?? new Date());
    close();
  }

  const onClickCancel = () => {
    close();
  }

  useImperativeHandle(ref, () => ({
    decision,
    date,
    open,
    close,
    setDate,
    setDecision
  }));

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {operation === PeriodicOperation.Delete && <span>{t('booking.repeat.delete.title')}</span>}
                    {operation === PeriodicOperation.Update && <span>{t('booking.repeat.update.title')}</span>}
                  </Dialog.Title>
                  {option === undefined && <>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {operation === PeriodicOperation.Delete && <span>{t('booking.repeat.delete.slogan')}</span>}
                        {operation === PeriodicOperation.Update && <span>{t('booking.repeat.update.slogan')}</span>}
                      </p>
                    </div>
                    <div className="mt-4 flex flex-col gap-y-5">
                      {date !== undefined && <>
                        <Button type="button" colorName="amber" onClick={onClickToday}>
                          {operation === PeriodicOperation.Delete && <span>{t('booking.repeat.delete.at')} {date.toLocaleDateString()}</span>}
                          {operation === PeriodicOperation.Update && <span>{t('booking.repeat.update.at')} {date.toLocaleDateString()}</span>}
                        </Button>
                      </>}
                      {removeToday && <Button type="button" colorName="amber" onClick={onClickToday}>
                        {operation === PeriodicOperation.Delete && <span>{t('booking.repeat.delete.today')}</span>}
                        {operation === PeriodicOperation.Update && <span>{t('booking.repeat.update.today')}</span>}
                      </Button>}
                      <Button type="button" colorName="rose" onClick={onClickAllFuture}>
                        {operation === PeriodicOperation.Delete && <span>{t('booking.repeat.delete.allFuture')}</span>}
                        {operation === PeriodicOperation.Update && <span>{t('booking.repeat.update.allFuture')}</span>}
                      </Button>
                      <Button type="button" colorName="slate" onClick={onClickCancel}>
                        {t('common.actions.cancel')}
                      </Button>
                    </div>
                  </>}
                  {option === 2 && <>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {operation === PeriodicOperation.Delete && <span>{t('booking.repeat.delete.chooseDate')}</span>}
                        {operation === PeriodicOperation.Update && <span>{t('booking.repeat.update.chooseDate')}</span>}
                      </p>
                    </div>
                    <div className="mt-4 flex flex-col gap-y-5">
                      <FormDatePicker
                        value={date}
                        onChange={(d) => setDate(d ?? new Date())}
                        minDate={new Date()}
                        time={undefined}
                        className="my-0 py-0"
                      />
                      <Button type="button" colorName="rose" onClick={onConfirmAllFuture}>
                        {operation === PeriodicOperation.Delete && <span>{t('booking.repeat.delete.allSince')} {date?.toLocaleDateString()}</span>}
                        {operation === PeriodicOperation.Update && <span>{t('booking.repeat.update.allSince')} {date?.toLocaleDateString()}</span>}
                      </Button>
                      <Button type="button" colorName="slate" onClick={onClickCancel}>
                        {t('common.actions.cancel')}
                      </Button>
                    </div>
                  </>}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default forwardRef(PeriodicDeleteModal);