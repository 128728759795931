export interface IConfig {
  token?: string;
  tenant?: string;
}

export class ApiBase {
  public readonly config: IConfig;

  protected constructor(config: IConfig) {
    this.config = config;
  }

  protected transformOptions = (options: RequestInit): Promise<RequestInit> => {
    if (this.config.token) options.headers = { ...options.headers, 'Authorization': `Bearer ${this.config.token}` };
    if (this.config.tenant) options.headers = { ...options.headers, 'X-TenantId': this.config.tenant };
    return Promise.resolve(options);
  };

  protected jsonParseReviver? = (key: any, value: any): any | undefined => { /* eslint-disable-line @typescript-eslint/no-explicit-any */
    const reDateDetect = /(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/ // startswith: 2015-04-29T22:06:55
    if (typeof value === 'string' && reDateDetect.exec(value)) {
      return new Date(value)
    }
    return value
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export enum QueryOrderDirection {
  DESC = <any>"DESC",
  ASC = <any>"ASC",
}

export enum ApiStatus {
  Online = 0,
  Maintenance = 1,
  Blocked = 2,
  Offline = 3,
}