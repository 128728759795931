import _ from 'lodash';
import { Dictionary } from 'lodash';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { HttpQueryFilter, Rider, RidersClient } from 'src/api/stable/Stable';
import ComboBox from 'src/components/Form/FormComboBox';
import { UserOptionProps } from 'src/components/Form/FormOptions';
import useApiConfiguration from 'src/hooks/useApiConfiguration';

export interface RiderSelectComponentProps {
  onChange: ((id: string | Dictionary<Rider | undefined>, entity?: Rider) => void);
  value?: string | string[];
  multiple?: boolean;
  placeholder?: string;
}

const RiderSelect = (props: RiderSelectComponentProps) => {
  const { onChange, value, multiple, ...otherProps } = props;
  const apiConfiguration = useApiConfiguration();

  const client = new RidersClient(apiConfiguration);
  const [entities, setEntities] = useState<Rider[]>([]);
  const [currentEntities, setCurrentEntities] = useState<Rider[]>([]);
  const autocompleteTimeout = useRef<(NodeJS.Timeout) | undefined>(undefined);

  useEffect(() => {
    applyAutocomplete('');
  }, []);

  const onChangeMiddleware: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value;
    onChange(newValue, entities.find(e => e.id === newValue));
  };
  const onChangeMiddlewareMultiple: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValues = e.target.value.split(',').filter(v => v.length > 0);
    onChange(
      _.chain(newValues.map(v => ({ id: v, value: entities.find(e => e.id === v) })))
        .keyBy('id')
        .mapValues('value')
        .value()
    );
  };
  const applyAutocomplete = (query: string) => {
    client
      .get(
        query
          .split(' ')
          .filter(phrase => phrase.length > 0)
          .map(phrase => ({ type: '%', property: 'User.GivenName,User.Surname,User.Email', value: phrase } as HttpQueryFilter)),
        [],
        10,
        0,
        undefined,
        undefined
      )
      .then(response => setEntities(response.items ?? []))
      .catch(console.error);
  };
  useEffect(() => {
    if (!value) return;
    if (value instanceof Array) {
      client.get(
        [{ type: '%', property: 'Id', value: ((value as string[]).join(',')) } as HttpQueryFilter],
        [],
        10,
        0,
        undefined,
        undefined,
      )
        .then(response => setCurrentEntities(response.items ?? []))
        .catch(console.error);
    }
    else {
      client.find(value as string)
        .then(response => setCurrentEntities([response]))
        .catch(console.error);
    }
  }, [value]);
  const onAutoComplete = (query: string) => {
    if (autocompleteTimeout.current) {
      clearTimeout(autocompleteTimeout.current);
      autocompleteTimeout.current = undefined;
    }
    autocompleteTimeout.current = setTimeout(() => applyAutocomplete(query), 250);
  };
  const options = [...currentEntities, ...entities.filter(e => !currentEntities.map(c => c.id).includes(e.id))]?.map(r => ({ value: r.id, user: r.user }) as UserOptionProps);

  return (
    <ComboBox options={options} value={value} onChange={multiple ? onChangeMiddlewareMultiple : onChangeMiddleware} onAutoComplete={onAutoComplete} multiple={multiple} {...otherProps} />
  );
};

export default RiderSelect;