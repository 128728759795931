import useForm, { IForm } from "src/hooks/useForm";
import { WizardStepProps } from "./Index";
import { Dictionary } from "lodash";
import Button from "src/components/Actions/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight, faPlusCircle, faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import FormInput from "src/components/Form/FormInput";
import FormCheckbox from "src/components/Form/FormCheckbox";
import { Horse, HorseTranslation } from "src/api/stable/Stable";
import FormRequired from "src/components/Form/FormRequired";
import { useTranslation } from "react-i18next";

export interface WizardStepHorsesForm {
  rows: Horse[];
}

export interface WizardStepHorsesProps extends WizardStepProps {
  data: Horse[];
  setData: (data: Horse[]) => void;
}

export interface HorseRowProps {
  form: IForm<WizardStepHorsesForm>;
  row: Horse;
  index: number;
  onClickDelete: () => void;
}

const HorseRow = (props: HorseRowProps) => {
  const { form, row, index, onClickDelete } = props;
  const { t } = useTranslation();
  return (
    <>
      <tr>
        <td rowSpan={2}>
          <FormInput.WithoutLabel
            {...form.input(
              `rows.${index}.translations.0.name`,
              'text', {
              placeholder: 'Błyskawica',
              required: true,
              minLength: 2
            })}
          />
        </td>
        <td rowSpan={2}>
          <FormInput.WithoutLabel
            {...form.input(
              `rows.${index}.translations.0.description`,
              'text', {
              placeholder: 'Przyjazny koń, doskonały do treningów dla osób początkujących'
            })}
          />
        </td>
        <td>
          <div className="flex flex-row-reverse">
            <FormCheckbox.Input
              {...form.input(
                `rows.${index}.isAvailableForAnyRider`,
                'checkbox', {
                value: "true"
              })}
              disabled={row.isAvailableForGuest}
              checked={row.isAvailableForGuest || row.isAvailableForAnyRider}
            />
          </div>
        </td>
        <td>
          <div className="flex flex-row-reverse">
            <FormCheckbox.Input
              {...form.input(
                `rows.${index}.isAvailableForGuest`,
                'checkbox', {
                value: "true",
              })} />
          </div>
        </td>
        <td className="text-end"><FontAwesomeIcon icon={faTimesCircle} className="text-rose-700 cursor-pointer" onClick={onClickDelete} /></td>
      </tr>
      <tr>
        <td colSpan={3}>
          {(!row.isAvailableForAnyRider && !row.isAvailableForGuest) && <span className="text-xs text-rose-700">{t('validation.AtLeastOneItem')}</span>}
        </td>
      </tr>
    </>
  )
}

export default (props: WizardStepHorsesProps) => {
  const { nextStep, prevStep, data, setData } = props;
  const form = useForm({ rows: data } as WizardStepHorsesForm);
  const onSubmit = () => {
    setData([...form.data.rows]);
    nextStep();
  }
  const validate = (): Dictionary<string[]> => {
    const result = {} as Dictionary<string[]>;
    return result;
  }

  const deleteRow = (index: number) => {
    form.setData({
      ...form.data,
      rows: form.data.rows.filter((_, i) => i !== index)
    } as WizardStepHorsesForm);
  }

  const addRow = () => {
    form.setData({
      ...form.data,
      rows:
        [
          ...form.data.rows,
          {
            translations: [{
              culture: 'pl',
              name: '',
              description: ''
            } as HorseTranslation],
            isAvailableForAnyRider: true,
            isAvailableForGuest: true
          } as Horse
        ]
    } as WizardStepHorsesForm);
  }

  return (
    <form onSubmit={e => form.onSubmit(e, onSubmit, validate)}>
      <p className="text-sm text-gray-600 py-4">
        Teraz to co jest najważniejsze, czyli konie. Narazie wystarczą nam ich imiona, opisy oraz uprawnienia możesz zdefiniować później.
      </p>
      <p className="text-sm text-gray-600 py-4">
        Zachęcamy abyś po konfiguracji, w panelu sterowania, dodał także ich zdjęcia.
      </p>
      <table className="w-full mt-8">
        <thead>
          <tr>
            <th className="font-medium text-xs text-start w-3/12">Imię konia<FormRequired required /></th>
            <th className="font-medium text-xs text-start w-6/12">Krótki opis</th>
            <th className="font-medium text-xs text-end">Dla zalogowanych?</th>
            <th className="font-medium text-xs text-end">Dla gości?</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {form.data?.rows?.map((row, index) => <HorseRow key={index} form={form} index={index} row={row} onClickDelete={() => deleteRow(index)} />)}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4} className="text-end pt-4">
              <Button type="button" colorName="emerald" className="text-sm px-5 font-medium" onClick={addRow}>
                <FontAwesomeIcon icon={faPlusCircle} className="mr-3" />
                Dodaj
              </Button>
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="flex justify-between border-t border-gray-100 pt-4 mt-8">
        <Button type="button" colorName="gray" className="px-5 py-3" onClick={prevStep}>
          <FontAwesomeIcon icon={faChevronCircleLeft} className="mr-3" />
          Powrót
        </Button>
        <Button type="submit" colorName="primary" className="px-5 py-3" disabled={form.data.rows.length === 0}>
          <FontAwesomeIcon icon={faChevronCircleRight} className="mr-3" />
          Przejdź dalej
        </Button>
      </div>
    </form>
  );
};
