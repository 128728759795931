
interface FormRequiredProps {
  required?: boolean;
}

const FormRequired = (props: FormRequiredProps) => {
  const { required } = props;
  if (!required) return <></>;
  return <span className="text-red-700 ml-1">*</span>;
}

export default FormRequired;