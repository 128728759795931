import { useEffect } from "react";
import useToken from "./useToken";
import useUser from "./useUser";
import useLocalizedNavigate from "./useNavigate";

const useAuthGuard = (redirectTo?: string, claims?: string[]): boolean | undefined => {
  const token = useToken();
  const user = useUser();
  const navigate = useLocalizedNavigate();

  const result = token && user ? (claims ? !claims?.some(c => !user?.claims?.includes(c)) : true) : undefined;

  useEffect(() => {
    if (result === false) navigate(redirectTo ?? "/");
  }, [user, token]);

  if (!token) {
    navigate(redirectTo ?? "/");
  }

  return result;
}

export default useAuthGuard;