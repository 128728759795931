import { Menu, Transition } from "@headlessui/react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from "react"
import { useTranslation } from "react-i18next";
import config from "src/config/config";
import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import useTenant from "src/hooks/useTenant"
import { setTenant } from "src/store/application/actions";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

export interface TenantItemProps {
  tenant: string;
}

const TenantItem = (props: TenantItemProps) => {
  const { tenant } = props;
  const applicationDispatch = useApplicationDispatch();
  const onClickSetTenant = (t: string) => applicationDispatch(setTenant(t));

  return (
    <Menu.Item>
      <a className='block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer' onClick={() => onClickSetTenant(tenant)}>
        {tenant}
      </a>
    </Menu.Item>
  );
}

const TenantMenu = () => {
  const tenant = useTenant();
  const { t } = useTranslation();

  if (config.tenants.length <= 1) {
    return <></>;
  }

  return (
    <Menu as="div" className="relative">
      <Menu.Button className="-m-1.5 flex items-center">
        <span className="sr-only">{t('tenant.menu')}</span>
        <span className="hidden lg:flex lg:items-center">
          <span className="ml-4 text-sm font-medium leading-6 text-gray-900" aria-hidden="true">
            {tenant}
          </span>
          <FontAwesomeIcon icon={faAngleDown} className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          {config.tenants.map(t => <TenantItem tenant={t} key={t} />)}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default TenantMenu;