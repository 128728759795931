import Alert from "../Feedback/Alert";
import { Dictionary } from "lodash";

export interface FormAlertProps {
  code?: number;
  errorMessages: Dictionary<string>;
}

export default (props: FormAlertProps) => {
  const { code, errorMessages } = props;
  return (
    <>
      {code && <Alert.Error title={errorMessages[code] ?? "Unexcepted error"} noClose />}
    </>
  )
}