import { faFileText } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import { Coupon } from 'src/api/stable/Booking';
import FormInput from 'src/components/Form/FormInput'
import { LocalizedFormSection } from 'src/components/Layout/Panel/FormSection';
import { IForm } from 'src/hooks/useForm';
import useTranslationForm from 'src/hooks/useTranslationForm';

export interface TranslationFormComponentProps {
  form: IForm<Coupon>;
}

export default function TranslationForm(props: TranslationFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const translationForm = useTranslationForm(form);
  const { getTranslationFieldName } = translationForm;

  return (
    <LocalizedFormSection
      title={t('common.form.translation.header')}
      description={t('common.form.translation.subheader')}
      form={translationForm}
      full
    >
      <div className="mb-10">
        <FormInput.Default {...form.input(getTranslationFieldName("title"), "text", { placeholder: t('common.fields.title') })} icon={<FontAwesomeIcon icon={faFileText} className="h-5 w-5 text-gray-400" aria-hidden="true" />} />
      </div>
    </LocalizedFormSection>
  );
}