import { User } from "src/api/access/Auth";
import config from "src/config/config";

export interface IBreadcrumb {
    label: string;
    href?: string;
    current?: boolean;
}

export interface ApplicationState {
    token?: string;
    user?: User;
    tenant?: string;
    online: boolean;
    breadcrumbs: IBreadcrumb[];
}

export const initialApplicationState: ApplicationState = {
    token: undefined,
    user: undefined,
    tenant: config.defaultTenant,
    online: false,
    breadcrumbs: []
};