import { useEffect, useRef } from "react";
import { AuthClient, UserClient } from "src/api/access/Auth";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import useToken from "src/hooks/useToken";
import useUser from "src/hooks/useUser";
import { clearToken, clearUser, setToken, setUser } from "./actions";
import AuthenticationSignalRClient from "./AuthenticationSignalRClient";

export default () => {
  const timerRef = useRef<NodeJS.Timeout>();
  const apiConfiguration = useApiConfiguration();
  const authClient = new AuthClient(apiConfiguration);
  const userClient = new UserClient(apiConfiguration);
  const token = useToken();
  const user = useUser();
  const appDispatch = useApplicationDispatch();

  const signOut = () => appDispatch(clearToken());

  const refreshToken = () => {
    authClient.refresh()
      .then(t => appDispatch(setToken(t)))
      .catch(() => appDispatch(clearToken()));
  }

  const fetchUser = () => {
    userClient.getUser()
      .then(u => appDispatch(setUser(u)))
      .catch(() => appDispatch(clearToken()));
  }

  useEffect(() => {
    if (timerRef.current) clearTimeout(timerRef.current);

    if (!token) {
      if (user) appDispatch(clearUser());
      return;
    }

    timerRef.current = setTimeout(refreshToken, 600000);
    fetchUser();
  }, [token]);

  return (<>
    {token && <AuthenticationSignalRClient token={token} signOut={signOut} fetchUser={fetchUser} />}
  </>);
}