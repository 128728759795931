import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next';
import useApplicationState from 'src/hooks/useApplicationState';
import { IBreadcrumb } from 'src/store/application/state';
import LocalizedLink from '../Router/LocalizedLink';
import { faHome } from '@fortawesome/free-solid-svg-icons';

export const Breadcrumb = (props: IBreadcrumb) => {
  const { label, href, current } = props;
  const { t } = useTranslation();
  return (
    <li key={label}>
      <div className="flex items-center">
        <svg
          className="h-5 w-5 flex-shrink-0 text-gray-300"
          fill="currentColor"
          viewBox="0 0 20 20"
          aria-hidden="true"
        >
          <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
        </svg>
        {href ?
          <LocalizedLink
            to={href}
            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            aria-current={current ? 'page' : undefined}
          >
            {t(label)}
          </LocalizedLink>
          :
          <span
            className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
            aria-current={current ? 'page' : undefined}
          >
            {t(label)}
          </span>
        }

      </div>
    </li>
  )
}

export default function Breadcrumbs() {
  const applicationState = useApplicationState();
  const { t } = useTranslation();
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <LocalizedLink to="/" className="text-gray-400 hover:text-gray-500">
              <FontAwesomeIcon icon={faHome} className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">{t('common.home')}</span>
            </LocalizedLink>
          </div>
        </li>
        {applicationState.breadcrumbs.map((breadcrumb) => <Breadcrumb key={breadcrumb.label} {...breadcrumb} />)}
      </ol>
    </nav>
  )
}
