import { faBoxOpen, faPlusCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import Button from "../Actions/Button";

export interface NoRecordsFoundComponentProps {
  colSpan?: number;
  onClickAdd?: () => void;
  addLabel?: string;
}

export default function NoRecordsFound(props: NoRecordsFoundComponentProps) {
  const { colSpan, onClickAdd, addLabel } = props;
  const { t } = useTranslation();
  return (
    <tr>
      <td colSpan={colSpan} className="text-center text-gray-400 py-4 md:py-8 lg:py-12 xl:py-16">
        <div className="mb-3">
          <FontAwesomeIcon icon={faBoxOpen} className="h-24" />
        </div>
        <span className="text-sm"><i>{t('common.errors.noRecords')}</i></span>
        <div>
          {onClickAdd && <Button type="button" colorName="emerald" className="px-3 py-1 mt-4" onClick={onClickAdd}><FontAwesomeIcon icon={faPlusCircle} /> {addLabel ?? t('common.actions.add')}</Button>}
        </div>
      </td>
    </tr>
  )
}