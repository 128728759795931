import { ConfigurationState } from './state';
import { ActionType, ConfigurationActions } from './actions';
import _ from 'lodash';

export function configurationReducer(state: ConfigurationState, action: ConfigurationActions): ConfigurationState {
    switch (action.type) {
        case ActionType.SetConfiguration:
            return _.set({ ...state }, action.payload.key, action.payload.value);
        default:
            return state;
    }
}