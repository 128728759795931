import { useTranslation } from 'react-i18next';
import { Invoice } from 'src/api/financial/Accountancy';
import CurrencySelect from 'src/components/Accountancy/CurrencySelect';
import PaymentMethodSelect from 'src/components/Accountancy/PaymentMethodSelect';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import FormDatePicker from 'src/components/Form/FormDatePicker';
import FormInput from 'src/components/Form/FormInput'
import { TextOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormSection from 'src/components/Layout/Panel/FormSection';
import { IForm } from 'src/hooks/useForm';

export interface GeneralFormComponentProps {
  form: IForm<Invoice>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();

  const invoiceTypes = [0, 1, 2, 3, 4, 5];
  const invoiceTypeOptions: TextOptionProps[] =
    invoiceTypes.map((i) => ({
      id: String(i),
      value: Number(i),
      label: t(`accountancy.enums.invoiceType.${i}`)
    }));

  const invoiceTransactionTypes = [0, 1, 2];
  const invoiceTransactionTypeOptions: TextOptionProps[] =
    invoiceTransactionTypes.map((i) => ({
      id: String(i),
      value: Number(i),
      label: t(`accountancy.enums.invoiceTransactionType.${i}`)
    }));
  const invoiceSellTypes = [0, 1, 2, 3, 4, 5];
  const invoiceSellTypeOptions: TextOptionProps[] =
    invoiceSellTypes.map((i) => ({
      id: String(i),
      value: Number(i),
      label: t(`accountancy.enums.invoiceSellType.${i}`)
    }));

  return (
    <FormSection
      title={t('common.form.general.header')}
      description={t('common.form.general.subheader')}
      full
    >
      <div className="lg:flex gap-x-8">
        <div className="mb-10 lg:w-1/2">
          <FormInput.Clean
            name="id"
            placeholder="Id"
            value={form.data.id}
            readOnly
          />
        </div>
        <div className="mb-10 lg:w-1/2">
          <span>{t("accountancy.invoices.fields.confirmed")}?</span>
          <FormCheckbox.Set title={t("accountancy.invoices.fields.confirmed")}>
            <FormCheckbox.Input {...form.input('confirmed', 'checkbox', { value: "true", placeholder: t('common.bool.yes') })} />
          </FormCheckbox.Set>
        </div>
      </div>
      <div className="lg:flex gap-x-8">
        <div className="mb-10 lg:w-1/2">
          {!form.data.id ?
            <Select {...form.input('type', 'select', { placeholder: t('accountancy.invoiceNumberings.fields.type') })} options={invoiceTypeOptions} />
            :
            <FormInput.Clean
              name="type"
              placeholder={t('accountancy.invoices.fields.type')}
              value={t(`accountancy.enums.invoiceType.${form.data.type}`)}
              readOnly
            />}
        </div>
        <div className="mb-10 lg:w-1/2">
          <FormInput.Clean
            name="id"
            placeholder={t('accountancy.invoices.fields.number')}
            value={form.data.number}
            readOnly
          />
        </div>
      </div>
      <div className="lg:flex gap-x-8">
        <div className="mb-10 lg:w-1/2">
          <Select {...form.input('sellType', 'select', { placeholder: t('accountancy.invoiceNumberings.fields.sellType') })} options={invoiceSellTypeOptions} />
        </div>
        <div className="mb-10 lg:w-1/2">
          <Select {...form.input('transactionType', 'select', { placeholder: t('accountancy.invoiceNumberings.fields.transactionType') })} options={invoiceTransactionTypeOptions} />
        </div>
      </div>
      <div className="lg:flex gap-x-8">
        <div className="mb-10 lg:w-1/2">
          <PaymentMethodSelect
            value={form.data.paymentMethodId}
            onChange={(id) => form.setData({ ...form.data, paymentMethodId: String(id) } as Invoice)}
            placeholder={t('accountancy.paymentMethods.item')}
          />
        </div>
        <div className="mb-10 lg:w-1/2">
          <CurrencySelect
            value={form.data.currencyCode}
            onChange={(id) => form.setData({ ...form.data, currencyCode: String(id) } as Invoice)}
            placeholder={t('accountancy.currencies.item')}
          />
        </div>
      </div>
      <div className="lg:flex gap-x-8">
        <div className="mb-10 lg:w-1/2">
          <FormDatePicker
            {...form.input('issueDate', 'date', { placeholder: t('accountancy.invoices.fields.issueDate') })}
            value={form.data.issueDate}
            onChange={(date) => form.setData({ ...form.data, issueDate: date } as Invoice)}
            placeholder={t('accountancy.invoices.fields.issueDate')}
          />
        </div>
        <div className="mb-10 lg:w-1/2">
          <FormDatePicker
            {...form.input('transactionDate', 'date', { placeholder: t('accountancy.invoices.fields.transactionDate') })}
            value={form.data.transactionDate}
            onChange={(date) => form.setData({ ...form.data, transactionDate: date } as Invoice)}
            placeholder={t('accountancy.invoices.fields.transactionDate')}
          />
        </div>
      </div>
      <div className="lg:flex gap-x-8">
        <div className="mb-10 lg:w-1/2">
          <FormDatePicker
            {...form.input('paymentTerm', 'date', { placeholder: t('accountancy.invoices.fields.paymentTerm') })}
            value={form.data.paymentTerm}
            onChange={(date) => form.setData({ ...form.data, paymentTerm: date } as Invoice)}
            placeholder={t('accountancy.invoices.fields.paymentTerm')}
          />
        </div>
        <div className="mb-10 lg:w-1/2">
          <FormInput.Clean
            name="paymentDate"
            placeholder={t('accountancy.invoices.fields.paymentDate')}
            value={form.data.paymentDate?.toLocaleDateString()}
            readOnly
          />
        </div>
      </div>
    </FormSection>
  )
}