import { faCheck, faCheckCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "src/components/Actions/Button";
import Tabs, { Tab } from "src/components/Tabs/Pills";
import useApplicationDispatch from "src/hooks/useApplicationDispatch";

import { setBreadcrumbs } from "src/store/application/actions";

export interface PlanPricing {
  monthly: number;
  annually: number;
}

export interface Plan {
  id: string;
  name: string;
  color: string;
  price: PlanPricing;
  description: string;
  features: string[];
}

const plans: Plan[] = [
  {
    name: 'Basic',
    color: 'bg-gray-500',
    id: 'tier-basic',
    price: { monthly: 100, annually: 90 },
    description: 'Everything necessary to get started.',
    features: [
      'plans.features.frontPage',
      'plans.features.newsfeed',
      'plans.features.galleries',
      'plans.features.helpPages',
      'plans.features.rideBooking',
      'plans.features.onlinePayments',
      'plans.features.stable',
      'plans.features.contact',
      'plans.features.tasks',
    ],
  },
  {
    name: 'Premium',
    color: 'bg-sun-600',
    id: 'tier-premium',
    price: { monthly: 150, annually: 130 },
    description: 'Essential tools for growing your stable.',
    features: [
      'plans.features.frontPage',
      'plans.features.newsfeed',
      'plans.features.galleries',
      'plans.features.helpPages',
      'plans.features.rideBooking',
      'plans.features.onlinePayments',
      'plans.features.stable',
      'plans.features.contact',
      'plans.features.tasks',
      'plans.features.sms',
      'plans.features.passes',
      'plans.features.messaging'
    ],
  },
  {
    name: 'Pro',
    color: 'bg-[--color-primary-600]',
    id: 'tier-pro',
    price: { monthly: 200, annually: 160 },
    description: 'Additional tools and deeper insights.',
    features: [
      'plans.features.frontPage',
      'plans.features.newsfeed',
      'plans.features.galleries',
      'plans.features.helpPages',
      'plans.features.rideBooking',
      'plans.features.onlinePayments',
      'plans.features.stable',
      'plans.features.contact',
      'plans.features.tasks',
      'plans.features.sms',
      'plans.features.passes',
      'plans.features.messaging',
      'plans.features.newsletter',
      'plans.features.events',
      'plans.features.dataInsight',
      'plans.features.customDomain'
    ],
  },
]

export interface PlansComponentProps {
  currentPlan: number;
  setCurrentPlan: (v: number) => void;
}

const Plans = (props: PlansComponentProps) => {
  const { currentPlan, setCurrentPlan } = props;
  const { t } = useTranslation();
  return (
    <div className="bg-white">
      <div className="mx-auto">
        <div className="mt-20 flow-root">
          <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4">
            {plans.map((plan, id) => (
              <div key={plan.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-14">
                <h3 id={plan.id} className="text-base font-medium leading-7 text-gray-900">
                  <span className={`${plan.color} text-white font-medium px-3 py-1 rounded-md mr-3`}>{plan.name}</span>
                  {plan.name} plan
                </h3>
                <div className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-5xl font-medium tracking-tight text-gray-900">{plan.price.monthly}</span>
                  <span className="text-sm font-medium leading-6 text-gray-600">PLN net per month</span>
                </div>
                <div className="mt-3 text-sm leading-6 text-gray-500">
                  {plan.price.annually} PLN net per month if paid annually<br />
                  <span className="text-xs">{plan.price.annually * 12} PLN net annually ({((plan.price.monthly - plan.price.annually) * 12)} PLN, {Math.ceil(100 - plan.price.annually / plan.price.monthly * 100)}% cheaper)</span>
                </div>

                {id !== currentPlan &&
                  <button
                    onClick={() => setCurrentPlan(id)}
                    aria-describedby={plan.id}
                    className="mt-10 mx-auto block rounded-md bg-[--color-primary-600] px-3 py-2 text-center text-sm font-medium leading-6 text-white shadow-sm hover:bg-[--color-primary-500] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                  >
                    Select plan
                  </button>
                }
                {id === currentPlan && <div className="my-12">&nbsp;</div>}
                <div className="mt-10 text-sm font-medium leading-6 text-gray-900">{plan.description}</div>
                <ul role="list" className="mt-6 space-y-3 text-sm leading-6 text-gray-600">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <FontAwesomeIcon icon={faCheck} className="h-6 w-5 flex-none text-[--color-primary-600]" aria-hidden="true" />
                      {t(feature)}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export interface CurrentPlanComponentProps {
  plan: Plan;
  annually: boolean;
  setSelectingPlan: (v: boolean) => void;
  setAnnually: (v: boolean) => void;
}

const CurrentPlan = (props: CurrentPlanComponentProps) => {
  const { plan, annually, setSelectingPlan, setAnnually } = props;
  const { t } = useTranslation();

  return (
    <div className="mx-auto">
      <div className="mx-auto max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 xl:mt-15 xl:mx-0 xl:flex xl:max-w-none">
        <div className="p-8 sm:p-10 xl:flex-auto">
          <h3 className="text-2xl font-medium tracking-tight text-gray-900">
            <span className={`${plan.color} text-white font-medium px-3 py-1 rounded-md mr-3`}>{plan.name}</span>
            Current plan
          </h3>
          <div className="mt-6 text-base leading-7 text-gray-600">
            Lorem ipsum dolor sit amet consect etur adipisicing elit. Itaque amet indis perferendis blanditiis
            repellendus etur quidem assumenda.
          </div>
          <div className="mt-10 flex items-center gap-x-4">
            <h4 className="flex-none text-sm font-medium leading-6 text-[--color-primary-600]">What’s included</h4>
            <div className="h-px flex-auto bg-gray-100" />
          </div>
          <ul
            role="list"
            className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
          >
            {plan.features.map((feature) => (
              <li key={feature} className="flex gap-x-3">
                <FontAwesomeIcon icon={faCheck} className="h-6 w-5 flex-none text-[--color-primary-600]" aria-hidden="true" />
                {t(feature)}
              </li>
            ))}
          </ul>
        </div>
        <div className="-mt-2 p-2 xl:mt-0 xl:w-full xl:max-w-md xl:flex-shrink-0">
          <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 xl:flex xl:flex-col xl:justify-center xl:py-16">
            <div className="mx-auto max-w-xs px-8">
              <div className="text-base font-medium text-gray-600">You choose {annually ? 'annually' : 'monthly'} payment</div>
              <div className="mt-6 flex items-baseline justify-center gap-x-2">
                <span className="text-sm font-medium leading-6 tracking-wide text-gray-600">PLN</span>
                <span className="text-5xl font-medium tracking-tight text-gray-900">{annually ? plan.price.annually * 12 : plan.price.monthly}</span>
                <span className="text-sm font-medium leading-6 tracking-wide text-gray-600">net*</span>
              </div>
              <div className="text-xs leading-5 text-gray-600">
                per {annually ? 'year' : 'month'}
              </div>
              <button
                className="mt-10 block w-full rounded-md bg-[--color-primary-600] px-3 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-[--color-primary-500] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                onClick={() => setSelectingPlan(true)}
              >
                Change plan
              </button>
              <button
                className="mt-3 text-[--color-primary-600] hover:text-[--color-primary-500] active:text-[--color-primary-300]"
                onClick={() => setAnnually(!annually)}
              >
                Change payment to {annually ? 'monthly' : 'annually'}
              </button>
              <div className="mt-6 text-xs leading-5 text-gray-600">
                Clicking button above will show you plan options available for you
              </div>
              <div className="mt-6 text-xs leading-5 text-gray-600">
                * Tax not included in price
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const TicketsSection = () => {

  return (
    <section className="mb-5">
      <div className="border-b border-gray-200 pb-5 mb-5 flex justify-between">
        <h2 className="leading-2 text-xl mt-2">Your tickets</h2>
        <div>
          <Button colorName="primary" className="px-5 py-3"><FontAwesomeIcon icon={faPlusCircle} className="h-4 mr-3" /> Create ticket</Button>
        </div>
      </div>
      <div className="text-center my-12 text-gray-400 text-xs">
        <div>No tickets reported to show...</div>
      </div>
    </section>
  );
}

const LicenseSection = () => {
  const [currentPlan, setCurrentPlan] = useState(1);
  const [annually, setAnnually] = useState(true);
  const [isSelectingPlan, setSelectingPlan] = useState(false);

  useEffect(() => {
    setSelectingPlan(false);
  }, [currentPlan]);

  return (
    <section>
      <div>
        <h3 className="leading-3 text font-medium text-gray-900">Owner</h3>
        <div className="xl:flex">
          <div className="xl:grow">
            <dl className="mt-6 space-y-6 divide-y divide-gray-50 border-t border-gray-100 text-sm leading-6">
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Full name</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">H0PE.CLOUD DAWID MARTENCZUK</div>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Administrator</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">Dawid Martenczuk</div>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Contact e-mail address</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">dawid.martenczuk@redini.app</div>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Contact phone number</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">+48 501 699 911</div>
                </dd>
              </div>
            </dl>
          </div>
          <div className="xl:grow">
            <dl className="mt-6 space-y-6 divide-y divide-gray-50 border-t border-gray-100 text-sm leading-6">
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Billing Info</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">
                    H0PE.CLOUD DAWID MARTENCZUK<br />
                    PL7393926062<br />
                    ul. Żurawia 64<br />
                    11-036 Naglady<br />
                    Poland
                  </div>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Current status</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <span className="bg-paradise-600 text-white px-3 py-1 font-medium rounded-md"><FontAwesomeIcon icon={faCheckCircle} className="inline h-4 mt-1 mr-2" /> Paid</span>
                </dd>
              </div>
              <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Next billing will be issued</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                  <div className="text-gray-900">{new Date().toLocaleDateString()}</div>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div>
        {!isSelectingPlan && <CurrentPlan plan={plans[currentPlan]} annually={annually} setAnnually={setAnnually} setSelectingPlan={setSelectingPlan} />}
        {isSelectingPlan && <Plans currentPlan={currentPlan} setCurrentPlan={setCurrentPlan} />}
      </div>
    </section >
  );
}

const Support = () => {
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();

  const tabs: Tab[] = [
    { id: 'license', name: 'panel.support.license.header' },
    { id: 'tickets', name: 'panel.support.tickets.header' },
    { id: 'invoices', name: 'panel.support.invoices.header' }
  ]
  const [currentTab, setCurrentTab] = useState('');

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "panel.support.header", href: "/panel/support" }
      ])
    );
  }, []);

  return (
    <>
      <div className="mb-5">
        <h1 className="leading-1 text-2xl">{t('panel.support.header')}</h1>
      </div>
      <Tabs tabs={tabs} currentTab={currentTab} setCurrentTab={setCurrentTab} />
      {currentTab === 'tickets' && <TicketsSection />}
      {currentTab === 'license' && <LicenseSection />}
    </>
  )
}

export default Support;