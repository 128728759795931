const getCurrencyName = (currencySymbol?: string, displayedLocale?: string): string | undefined => {
  if (!currencySymbol || !displayedLocale || currencySymbol === '' || displayedLocale === '') return;

  const countryNameRegionalizer = new Intl.DisplayNames([displayedLocale], { type: 'currency' });
  try {
    return countryNameRegionalizer?.of(currencySymbol.toUpperCase());
  }
  catch (exception) {
    return '';
  }
}

export default getCurrencyName;