import useForm, { IForm } from "src/hooks/useForm";
import { WizardStepProps } from "./Index";
import { Dictionary } from "lodash";
import Button from "src/components/Actions/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight, faCirclePlus, faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import FormInput from "src/components/Form/FormInput";
import FormTextarea from "src/components/Form/FormTextarea";
import FormCheckbox from "src/components/Form/FormCheckbox";
import { Horse, HorseInstructor, Instructor, InstructorTranslation } from "src/api/stable/Stable";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export interface WizardStepInstructorsForm {
  rows: Instructor[];
}

export interface WizardStepInstructorsProps extends WizardStepProps {
  data: Instructor[];
  setData: (data: Instructor[]) => void;
  horses: Horse[];
}

interface InstructorFormProps {
  form: IForm<WizardStepInstructorsForm>;
  instructor: Instructor;
  index: number;
  onClickDelete: () => void;
  horses?: Horse[];
}

interface InstructorPillProps {
  instructor: Instructor;
  onClick: () => void;
  active?: boolean;
}

const InstructorForm = (props: InstructorFormProps) => {
  const { form, instructor, index, horses, onClickDelete } = props;
  const { t } = useTranslation();

  const onChangeHorse: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value, checked } = e.target;
    const stringValue = String(value);
    let relations = [...instructor.horseInstructors || []] as HorseInstructor[];
    if (checked && !relations.some(hi => hi.horseId === value)) {
      relations = [...relations, { horseId: stringValue }] as HorseInstructor[];
    }
    else if (!checked && relations.some(hi => hi.horseId === stringValue)) {
      relations = [...relations.filter(hi => hi.horseId !== stringValue)] as HorseInstructor[];
    }
    form.set(`rows.${index}.horseInstructors`, relations);
  }

  return (
    <div className="border-t border-gray-100 pt-4 mt-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
        <div>
          <FormInput.Overlapping
            {...form.input(
              `rows.${index}.user.givenName`,
              'text',
              {
                placeholder: t('users.fields.givenName'),
                required: true
              })
            }
          />
        </div>
        <div>
          <FormInput.Overlapping
            {...form.input(
              `rows.${index}.user.surname`,
              'text',
              {
                placeholder: t('users.fields.surname'),
                required: true
              })
            }
          />
        </div>
        <div>
          <FormInput.Overlapping
            {...form.input(
              `rows.${index}.user.email`,
              'email',
              {
                placeholder: t('users.fields.email'),
                required: true
              })
            }
          />
        </div>
        <div>
        </div>
        <div>
          <p className="font-medium text-sm color-gray-600 mb-2">Konie, na których może prowadzić treningi:</p>
          <div className="text-center">
            <FormCheckbox.Input {...form.input(`rows.${index}.isAvailableForAnyHorse`, 'checkbox', { value: 'true' })} aria-description={t('stable.instructors.fields.isAvailableForAnyHorse')} />
          </div>
          <hr className="my-4" />
          {!instructor.isAvailableForAnyHorse &&
            <FormCheckbox.Set title="Horses">
              <div className="grid grid-cols-1 lg:grid-cols-2">
                {horses?.map((horse, horseId) => <FormCheckbox.Input
                  value={horseId}
                  checked={instructor.horseInstructors?.some(hi => hi.horseId === String(horseId))}
                  aria-description={horse.translations?.find(_t => true)?.name} onChange={onChangeHorse}
                />)}
              </div>
            </FormCheckbox.Set>
          }
          {(!instructor.isAvailableForAnyHorse && (instructor.horseInstructors ?? []).length === 0) && <span className="text-xs text-rose-700">{t('validation.AtLeastOneItem')}</span>}
        </div>
        <div>
          <FormTextarea.Overlapping
            {...form.textArea(
              `rows.${index}.translations.0.description`,
              'text',
              {
                placeholder: t('common.fields.description')
              })
            }
          />
        </div>
      </div>
      <div className="flex justify-between mt-4">
        <Button type="button" colorName="rose" className="text-sm px-5 font-medium" onClick={onClickDelete}>
          <FontAwesomeIcon icon={faTimesCircle} className="mr-3" />
          Usuń
        </Button>
      </div>
    </div>
  )
}

const InstructorPill = (props: InstructorPillProps) => {
  const { instructor, onClick, active } = props;

  if (active) {
    return (
      <div className="bg-[--color-primary-100] rounded-md text-xs px-5 py-3 text-[--color-primary-700]" onClick={onClick}>
        {instructor?.user?.givenName} ({instructor.user?.email})
      </div>
    );
  }

  return (
    <div className="bg-gray-100 rounded-md text-xs px-5 py-3 text-gray-700" onClick={onClick}>
      {instructor?.user?.givenName} ({instructor.user?.email})
    </div>
  );
}

export default (props: WizardStepInstructorsProps) => {
  const { nextStep, prevStep, data, setData, horses } = props;
  const form = useForm({ rows: [] } as WizardStepInstructorsForm);
  const [currentInstructor, setCurrentInstructor] = useState<number | undefined>(undefined);

  useEffect(() => {
    form.setData({ rows: [...data] });
  }, []);

  const onSubmit = () => {
    setData([...form.data.rows] as Instructor[]);
    nextStep();
  }
  const validate = (): Dictionary<string[]> => {
    const result = {} as Dictionary<string[]>;
    return result;
  }

  const deleteRow = (index: number) => {
    form.setData({
      ...form.data,
      rows: form.data.rows.filter((_, i) => i !== index)
    } as WizardStepInstructorsForm);
  }

  const addRow = () => {
    form.setData({
      ...form.data,
      rows:
        [
          ...form.data.rows,
          {
            user: {
              givenName: '',
              surname: '',
              email: ''
            },
            translations: [{
              culture: 'pl',
              name: '',
              description: ''
            } as InstructorTranslation],
            isAvailableForAnyRider: true,
            isAvailableForGuest: true
          } as Instructor
        ]
    } as WizardStepInstructorsForm);
    setCurrentInstructor(form.data.rows.length);
  }

  return (
    <form onSubmit={e => form.onSubmit(e, onSubmit, validate)}>
      <p className="text-sm text-gray-600">
        Teraz dodajmy instruktorów. Możesz dokonać wyboru z jakimi końmi mogą prowadzić treningi.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-4">
        {form.data.rows?.map((instructor, index) => <InstructorPill key={index} instructor={instructor} onClick={() => setCurrentInstructor(index)} active={index === currentInstructor} />)}
        <div className="bg-emerald-100 rounded-md text-xs px-5 py-3 text-emerald-700 hover:bg-emerald-200 active:bg-emerald-400 cursor-pointer" onClick={addRow}>
          <FontAwesomeIcon icon={faCirclePlus} className="mr-1" /> Dodaj kolejnego instruktora
        </div>
      </div>
      {currentInstructor !== undefined && form.data.rows.some((_instructor, index) => index === currentInstructor) !== false && <InstructorForm instructor={form.data.rows[currentInstructor]} index={currentInstructor} onClickDelete={() => deleteRow(currentInstructor!)} horses={horses} form={form} />}
      <div className="flex justify-between border-t border-gray-100 pt-4 mt-8">
        <Button type="button" colorName="gray" className="px-5 py-3" onClick={prevStep}>
          <FontAwesomeIcon icon={faChevronCircleLeft} className="mr-3" />
          Powrót
        </Button>
        <Button type="submit" colorName="primary" className="px-5 py-3" disabled={form.data.rows.length === 0 || form.data.rows.some(i => !i.user?.givenName || !i.user?.surname || !i.user.email || (!i.isAvailableForAnyHorse && (i.horseInstructors ?? []).length === 0))}>
          <FontAwesomeIcon icon={faChevronCircleRight} className="mr-3" />
          Przejdź dalej
        </Button>
      </div>
    </form>
  );
};
