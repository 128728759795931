import { ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Horse, HorseTrainingType, TrainingType, TrainingTypeTranslation, TrainingTypesClient } from 'src/api/stable/Stable';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import { TextOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormSection from 'src/components/Layout/Panel/FormSection';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import { IForm } from 'src/hooks/useForm';

export interface TrainingTypesFormComponentProps {
  form: IForm<Horse>;
}

export default function TrainingTypesForm(props: TrainingTypesFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const trainingTypeTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();
  const [trainingTypes, setTrainingTypes] = useState<TrainingType[]>([]);

  const apiConfiguration = useApiConfiguration();
  const trainingTypesClient = new TrainingTypesClient(apiConfiguration);

  useEffect(() => {
    setTrainingTypes([]);
    trainingTypesClient
      .get(
        [],
        [],
        1000,
        0,
        undefined,
        undefined
      )
      .then(response => setTrainingTypes(response.items ?? []))
      .catch(console.error);
  }, []);

  const trainingTypeOptions = trainingTypes?.map(t => ({ value: t.id!, label: trainingTypeTranslation.getCurrentTranslation(t)?.name }) as TextOptionProps);

  const onSelectTrainingType: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    const newData: Horse = { ...form.data, horseTrainingTypes: [...form.data.horseTrainingTypes || []] } as Horse;
    newData.horseTrainingTypes = value !== '' ? value.split(',').map(r => ({ trainingTypeId: r } as HorseTrainingType)) : [];
    form.setData(newData);
  }

  return (
    <FormSection
      title={t('stable.trainingTypes.group')}
      description={t('stable.horses.trainingTypes.description')}
      full
    >
      <div className="mb-10">
        <span>{t("stable.horses.fields.isAvailableForAnyTrainingType")}</span>
        <FormCheckbox.Set title={t("stable.horses.fields.isAvailableForAnyTrainingType")}>
          <FormCheckbox.Input {...form.input('isAvailableForAnyTrainingType', 'checkbox', { value: "true", placeholder: t('common.bool.yes') })} />
        </FormCheckbox.Set>
      </div>
      {!form.data?.isAvailableForAnyTrainingType && <div className="mb-10">
        <span>{t("stable.trainingTypes.group")}</span>
        {trainingTypeOptions && !form.pending && <Select options={trainingTypeOptions} value={form.data.horseTrainingTypes?.map(hr => hr.trainingTypeId!) || []} onChange={onSelectTrainingType} multiple={true} />}
      </div>}
    </FormSection>
  )
}