import { ChangeEvent, SelectHTMLAttributes, createRef, useEffect, useState } from "react";
import classNames from "../Utilities/classNames";
import simulateChangeEvent from "./simulateChangeEvent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export interface ImageSelectOption {
  id: string;
  value: any; /* eslint-disable-line @typescript-eslint/no-explicit-any */
  label?: string;
  description?: string;
  image: string;
  disabled?: boolean;
}

interface ImageSelectOptionComponentProps {
  option: ImageSelectOption;
  isActive?: boolean;
  full?: boolean;
  onClick: () => void;
}

interface ImageSelectComponentProps extends SelectHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  options: ImageSelectOption[];
  onSelectFile?: (file: File) => Promise<string>;
}

export interface FileDropzoneComponentProps {
  onSelectFile: (file: File) => Promise<string>;
}

const FileDropzone = (props: FileDropzoneComponentProps) => {
  const { onSelectFile } = props;
  const { t } = useTranslation();
  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      onSelectFile(file);
    });

  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  });

  return (
    <div className="relative text-xs text-gray-400 hover:text-gray-500 active:text-gray-700 h-full" {...getRootProps()}>
      <input {...getInputProps()} />
      <FontAwesomeIcon icon={faFileUpload} className="block mx-auto h-12 mb-3" />
      <div className="absolute bottom-0 left-0">{t('common.upload.selectFile')}</div>
    </div>
  );
}

const ImageSelectOption = (props: ImageSelectOptionComponentProps) => {
  const { option, isActive, onClick } = props;

  return (
    (
      <div
        key={option.id}
        className={classNames([
          'w-1/2 lg:w-1/3 xl:w-1/4 p-3',
          option.disabled && 'opacity-25'
        ])}
        onClick={() => !option.disabled && onClick()}
      >
        <div className={classNames([
          'w-full h-full rounded-md shadow-md overflow-hidden p-5 cursor-pointer bg-white',
          isActive && 'ring-2 ring-[--color-primary-700] bg-[--color-primary-100] shadow-lg',
          !isActive && !(option.disabled) && 'ring-1 ring-gray-200 hover:ring-2 hover:ring-[--color-primary-300] active:ring-[--color-primary-500] active:bg-[--color-primary-50] hover:shadow-lg'
        ])}>
          <img src={option.image} alt={option.image} title={option.image} className="mx-auto max-w-full" />
          {option.label &&
            <div className="mt-8">
              <h3 className="text-base font-medium leading-6 text-gray-900">
                {option.label}
              </h3>
              {option.description && <div className="mt-2 text-sm text-gray-500">{option.description}</div>}
            </div>
          }
        </div>
      </div>
    )
  );
}


interface ImageSelectOptionUploadComponentProps {
  onSelectFile: (file: File) => Promise<string>;
}

const ImageSelectOptionUpload = (props: ImageSelectOptionUploadComponentProps) => {
  const { onSelectFile } = props;
  return (
    (
      <div
        key="upload"
        className={classNames([
          'w-1/2 lg:w-1/3 xl:w-1/4 p-3',
        ])}
      >
        <div className={classNames([
          'w-full h-full min-h-32 rounded-md shadow-md overflow-hidden p-5 cursor-pointer bg-white',
          'ring-1 ring-gray-200 hover:ring-2 hover:ring-[--color-primary-300] active:ring-[--color-primary-500] active:bg-[--color-primary-50] hover:shadow-lg text-center'
        ])}>
          <FileDropzone onSelectFile={onSelectFile} />
        </div>
      </div>
    )
  );
}


const ImageSelect = (props: ImageSelectComponentProps) => {
  const { id, options, value, placeholder, onChange, onSelectFile } = props;

  const [val, setVal] = useState<string>();
  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (val === value) return;
    if (!val && value) return;
    simulateChangeEvent(inputRef?.current, val);
  }, [val]);

  useEffect(() => {
    if (value !== val) setVal(String(value) ?? "");
  }, [value]);

  const onChangeMiddleware = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange)
      onChange(e);
  }

  return (

    <div className="flex flex-wrap">
      {options.map((option, i) => <ImageSelectOption key={i} onClick={() => setVal(option.value)} option={option} isActive={val == option.value} />)}
      {onSelectFile && <ImageSelectOptionUpload onSelectFile={onSelectFile} />}
      <input ref={inputRef} id={id} name={id} value={value} onChange={onChangeMiddleware} title={placeholder} className="hidden" />
    </div>
  )

}

export default ImageSelect;