import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { UserCoupon } from 'src/api/stable/Booking';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import FormInput from 'src/components/Form/FormInput'
import FormSection from 'src/components/Layout/Panel/FormSection';
import UserSelect from 'src/components/User/UserSelect';
import useClaim from 'src/hooks/useClaim';
import { IForm } from 'src/hooks/useForm';

export interface GeneralFormComponentProps {
  form: IForm<UserCoupon>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const isDeveloper = useClaim('Developer');

  return (
    <FormSection
      title={t('common.form.general.header')}
      description={t('common.form.general.subheader')}
    >
      {isDeveloper && <div className="mb-10">
        <FormInput.Clean name="id" placeholder="Id" value={form.data.id} readOnly />
      </div>}
      <div className="mb-10">
        <span>{t("stable.trainings.fields.isPaid")}</span>
        <FormCheckbox.Set title={t("stable.trainings.fields.isPaid")}>
          <FormCheckbox.Input {...form.input('isPaid', 'checkbox', { value: "true", placeholder: t('common.bool.yes') })} />
        </FormCheckbox.Set>
      </div>
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('title', 'text', { min: 0, step: 0.01, placeholder: t('common.fields.title'), required: true })} />
      </div>
      <div className="mb-10">
        <span>{t("users.item")}</span>
        <UserSelect
          {...form.input('userId', 'select', {})}
          onChange={(userId) => form.setData({ ...form.data, userId: userId as string } as UserCoupon)}
          value={form.data.userId}
          placeholder={t('users.item')}
        />
      </div>
    </FormSection>
  )
}
