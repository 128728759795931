import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useApplicationDispatch from "src/hooks/useApplicationDispatch";
import { setBreadcrumbs } from "src/store/application/actions";
import LatestUsersTable from './DashboardWidgets/LatestUsersTable';
import TrainingStatistics from './DashboardWidgets/TrainingStatistics';
import TrainingsChart from "./DashboardWidgets/TrainingsChart";
import UserRegistrationsChart from "./DashboardWidgets/UserRegistrationsChart";
import Insights from "./DashboardWidgets/Insights";
import SMSWidget from "./DashboardWidgets/SMS";
import useClaim from "src/hooks/useClaim";

const Dashboard = () => {
  const { t } = useTranslation();
  const applicationDispatch = useApplicationDispatch();

  const usersClaim = useClaim('UsersRead');
  const traningsClaim = useClaim('TrainingsRead');

  useEffect(() => {
    applicationDispatch(
      setBreadcrumbs([
        { label: "panel.dashboard", href: "/panel/dashboard" }
      ])
    );
  }, []);

  return (
    <>
      <div className="flex justify-between">
        <div className="">
          <h1 className="leading-1 text-2xl">{t('panel.dashboard')}</h1>
        </div>
      </div>
      <div className="md:flex gap-x-8">
        <Insights />
        <SMSWidget />
      </div>
      {traningsClaim && <TrainingStatistics />}
      <div className="w-full xl:flex justify-between gap-3">
        <div className="w-full md:w-1/2 xl:flex-1">
          {traningsClaim && <TrainingsChart />}
          {usersClaim && <UserRegistrationsChart />}
        </div>
        <div className="w-full xl:flex-1">
          {usersClaim && <LatestUsersTable />}
        </div>
      </div>
    </>
  )
}

export default Dashboard;