import { useState, useEffect } from "react";
import useUser from "./useUser";

const useAuthenticated = (): boolean => {
  const userState = useUser();
  const [ isAuthenticated, setAuthenticated ] = useState(userState !== undefined && userState !== null);
  useEffect(() => setAuthenticated(userState !== undefined && userState !== null), [ userState ]);
  return isAuthenticated;
}

export default useAuthenticated;