import { ChangeEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TrainingType, InstructorTrainingType, Instructor, InstructorsClient } from 'src/api/stable/Stable';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import { UserOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormSection from 'src/components/Layout/Panel/FormSection';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { IForm } from 'src/hooks/useForm';

export interface InstructorsFormComponentProps {
  form: IForm<TrainingType>;
}

export default function InstructorsForm(props: InstructorsFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const [instructors, setInstructors] = useState<Instructor[]>([]);

  const apiConfiguration = useApiConfiguration();
  const instructorsClient = new InstructorsClient(apiConfiguration);

  useEffect(() => {
    setInstructors([]);
    instructorsClient
      .get(
        [],
        [],
        1000,
        0,
        undefined,
        undefined
      )
      .then(response => setInstructors(response.items ?? []))
      .catch(console.error);
  }, []);

  const instructorOptions = instructors?.map(r => ({ value: r.id, user: r.user } as UserOptionProps));

  const onSelectInstructor: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    const newData: TrainingType = { ...form.data, instructorTrainingTypes: [...form.data.instructorTrainingTypes || []] } as TrainingType;
    newData.instructorTrainingTypes = value !== '' ? value.split(',').map(r => ({ instructorId: r } as InstructorTrainingType)) : [];
    form.setData(newData);
  }

  return (
    <FormSection
      title={t('stable.instructors.group')}
      description={t('stable.trainingTypes.instructors.description')}
      full
    >
      <div className="mb-10">
        <span>{t("stable.trainingTypes.fields.isAvailableForAnyInstructor")}</span>
        <FormCheckbox.Set title={t("stable.trainingTypes.fields.isAvailableForAnyInstructor")}>
          <FormCheckbox.Input {...form.input('isAvailableForAnyInstructor', 'checkbox', { value: "true", placeholder: t('common.bool.yes') })} />
        </FormCheckbox.Set>
      </div>
      {!form.data?.isAvailableForAnyInstructor && <div className="mb-10">
        <span>{t("stable.instructors.group")}</span>
        {instructorOptions && !form.pending && <Select options={instructorOptions} value={form.data.instructorTrainingTypes?.map(hr => hr.instructorId!) || []} onChange={onSelectInstructor} multiple={true} />}
      </div>}
    </FormSection>
  )
}