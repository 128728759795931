import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { DayWorkingHours, Instructor, WorkingHours } from 'src/api/stable/Stable';
import BookingWorkingHours, { daysOfWeek } from 'src/components/Booking/BookingWorkingHours';
import FormSection from 'src/components/Layout/Panel/FormSection';
import useConfigurationState from 'src/hooks/useConfigurationState';
import { IForm } from 'src/hooks/useForm';
import { ConfigurationState } from 'src/store/configuration/state';

export interface WorkingHoursFormProps {
  form: IForm<Instructor>;
}

export default function WorkingHoursForm(props: WorkingHoursFormProps) {
  const { form } = props;
  const { t } = useTranslation();
  const configurationState = useConfigurationState();

  const defaultHours = getDefaultHours(configurationState);

  return (
    <FormSection
      title={t('stable.instructors.workingHours.title')}
      description={t('stable.instructors.workingHours.description')}
      full
    >
      <table className="max-w-full">
        {daysOfWeek
          .map((d, i) => <BookingWorkingHours
            key={i}
            dayOfWeek={d}
            value={{
              start: _.get({ ...defaultHours, ...form.data.workingHours }, `values.${dayOfWeekNumberToString(d)}`)?.start?.split(':').slice(0, 2).map(Number) || [8, 0],
              end: _.get({ ...defaultHours, ...form.data.workingHours }, `values.${dayOfWeekNumberToString(d)}`)?.end?.split(':').slice(0, 2).map(Number) || [16, 0]
            }}
            onChange={(value) => {
              const workingHours = { ...defaultHours, ...form.data.workingHours };
              const dCorrected = dayOfWeekNumberToString(d);
              _.set(workingHours, `values.${dCorrected}`, {
                start: `${zeroPad(value.start[0], 2)}:${zeroPad(value.start[1], 2)}:00.0000000`,
                end: `${zeroPad(value.end[0], 2)}:${zeroPad(value.end[1], 2)}:00.0000000`
              });

              form.setData({ ...form.data, workingHours: workingHours } as Instructor);
            }}
          />)}
      </table>
    </FormSection>
  )
}

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

const dayOfWeekNumberToString = (dayOfWeek: number) => {
  switch (dayOfWeek) {
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    case 0:
      return 'Sunday';
    default:
      return 'Sunday';
  }
}

const getDefaultHours = (configuration: ConfigurationState): WorkingHours => {
  const withDayOfWeekKey = _.keyBy(_.mapValues(_.map(
    { ...configuration.booking.hours },
    (value, key) => {
      return {
        key: dayOfWeekNumberToString(Number(key)),
        start: `${zeroPad(value.start[0], 2)}:${zeroPad(value.start[1], 2)}:00.0000000`,
        end: `${zeroPad(value.end[0], 2)}:${zeroPad(value.end[1], 2)}:00.0000000`
      };
    }
  )), 'key');
  const dictionary = _.mapValues(withDayOfWeekKey, (value, _key) => ({ start: value.start, end: value.end }) as DayWorkingHours);
  return { values: dictionary } as WorkingHours;
}
