import { faMessages } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MessagesClient, MessageTopic } from "src/api/notifications/Notifications";
import useMessagingDispatch from "src/hooks/stores/useMessagingDispatch";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import useUser from "src/hooks/useUser";
import { setActiveTopic } from "src/store/messaging/actions";
import Button from "../Actions/Button";

export interface MessageToUserButtonProps {
  userId?: string;
  className?: string;
}

export default (props: MessageToUserButtonProps) => {
  const { userId, className } = props;
  const apiConfiguration = useApiConfiguration();
  const apiClient = new MessagesClient(apiConfiguration);
  const user = useUser();
  const dispatch = useMessagingDispatch();

  const onClick = () => {
    if (!userId) return;
    apiClient.findTopicWithUser(userId)
      .then(topic => {
        dispatch(setActiveTopic(topic));
      })
      .catch(error => {
        if (error.status === 404) {
          apiClient.createTopic({
            users: [{ userId: userId }]
          } as MessageTopic)
            .then(createdTopic => {
              apiClient.findTopic(createdTopic.id!)
                .then(newTopic => dispatch(setActiveTopic(newTopic)))
            })
            .catch(() => { });
        }

      });
  };

  if (user?.id === userId) return <></>;
  return (
    <div>
      <Button colorName="primary" onClick={onClick} className={`px-1 py-1 text-xs rounded-full ${className}`}>
        <FontAwesomeIcon icon={faMessages} className="w-4 relative" />
      </Button>
    </div>
  );
}