import { createRef, useEffect, useState } from 'react'
import { Switch } from '@headlessui/react'
import classNames from '../Utilities/classNames'
import ExtendedInputHTMLAttributes from './ExtendedInputHTMLAttributes';
import simulateChangeEvent from './simulateChangeEvent';

function SwitchComponent(props: ExtendedInputHTMLAttributes<HTMLInputElement>) {
  const { id, placeholder } = props;

  const description = props["aria-description"] ?? "";
  return (
    <div className="relative flex items-start">
      <SwitchCleanComponent {...props} />
      <div className="ml-3 text-sm leading-6">
        <label htmlFor={id} className="font-medium text-gray-900">
          {placeholder}
          {description && <div id="comments-description" className="text-gray-500 text-xs font-normal">{description}</div>}
        </label>
      </div>
    </div>
  )
}

function SwitchCleanComponent(props: ExtendedInputHTMLAttributes<HTMLInputElement>) {
  const { id, placeholder, checked, value, disabled, onChange } = props;
  const [enabled, setEnabled] = useState(checked ?? false);
  const inputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (enabled === checked) return;
    simulateChangeEvent(inputRef?.current, checked);
  }, [enabled]);

  useEffect(() => {
    if (checked !== enabled) setEnabled(checked ?? false);
  }, [checked]);

  return (
    <div className="flex h-6 items-center">
      <Switch
        checked={checked}
        onChange={(t) => !disabled && setEnabled(t)}
        className={classNames(
          enabled ? 'bg-[--color-primary-600] ' : 'bg-gray-200 ',
          disabled && "opacity-25 ",
          disabled && "cursor-not-allowed ",
          !disabled && "cursor-pointer ",
          'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-[--color-primary-600] focus:ring-offset-2'
        )}
      >
        <span className="sr-only">{placeholder}</span>
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
      <input ref={inputRef} id={id} name={id} checked={enabled} value={value} placeholder={placeholder} onChange={onChange} className="hidden" />
    </div>
  )
}


export default {
  Decorated: SwitchComponent,
  Clean: SwitchCleanComponent
}