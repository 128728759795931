export enum BookTrainingStep {
  TrainingType,
  Instructor,
  Horse,
  Date,
  PersonalData,
  Confirmation,
  Payment,
  Complete
}
