import React, { ReactElement, Ref, useEffect, useImperativeHandle, useState } from 'react';

import { IConfig, QueryOrderDirection } from 'src/api/Base';
import { IHttpQueryFilter, IHttpQueryOrder, IndexResult } from 'src/api/Interfaces';
import Table, { TableOrderDirection, ITableColumn, IEntity, TableOrder } from '../Table/Table';

interface IApiClient<T> {
  get(
    filters: IHttpQueryFilter[] | undefined,
    order: IHttpQueryOrder[] | undefined,
    take: number | undefined,
    skip: number | undefined,
    page: number | undefined,
    pageSize: number | undefined
  ): Promise<IndexResult<T>>;
  readonly config: IConfig;
}

interface TableComponentProps<T extends IEntity> {
  apiClient: IApiClient<T>;
  columnDefinitions: ITableColumn<T>[]
  filters?: IHttpQueryFilter[];
  initialPageSize?: number;
}

export interface TableComponentRef<T> {
  setFilters: (f: IHttpQueryFilter[]) => void;
  setOrder: (v: IHttpQueryOrder[]) => void;
  setPage: (v: number) => void;
  setPageSize: (v: number) => void;
  getData: () => T[];
  getFilters: () => IHttpQueryFilter[];
  getOrder: () => IHttpQueryOrder[];
  getPage: () => number;
  getPages: () => number;
  getPageSize: () => number;
  refresh: () => void;
  isLastPage: () => boolean;
  isFirstPage: () => boolean;
}

const mapSortDirection = (sortDirection: TableOrderDirection): QueryOrderDirection => {
  switch (sortDirection) {
    case "desc":
      return QueryOrderDirection.DESC;
    default:
      return QueryOrderDirection.ASC;
  }
}

const TableComponent = <T extends IEntity>(props: TableComponentProps<T>, ref: Ref<TableComponentRef<T>>): JSX.Element => {
  const { columnDefinitions, apiClient, initialPageSize } = props;

  const globalFilters = props.filters;

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<IndexResult<T>>();

  const [filters, setFilters] = useState<IHttpQueryFilter[]>();
  const [order, setOrder] = useState<IHttpQueryOrder[]>();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(initialPageSize ?? 10);

  useImperativeHandle(ref, () => ({
    setFilters: (v) => setFilters(v),
    setOrder: (v) => setOrder(v),
    setPage: (v) => setPage(v),
    setPageSize: (v) => setPageSize(v),
    getData: () => result?.items || [],
    getFilters: () => filters ?? [],
    getOrder: () => order ?? [],
    getPage: () => page,
    getPages: () => result?.totalPages ?? 1,
    isLastPage: () => !result?.hasNextPage,
    isFirstPage: () => !result?.hasPreviousPage,
    getPageSize: () => pageSize,
    refresh: () => load(),
  }));

  const onChangeOrder = (o?: TableOrder): void => {
    if (o)
      setOrder([{ property: o.property, direction: mapSortDirection(o.direction) }]);
    else
      setOrder([]);
  }

  const onChangePage = (currentPage: number): void => {
    if (currentPage !== page)
      setPage(currentPage);
  }

  const onChangePageSize = (currentRowsPerPage: number): void => {
    if (pageSize !== currentRowsPerPage)
      setPageSize(currentRowsPerPage);
  }

  const onLoad = (result: IndexResult<T>): void => {
    setResult(result);
    setLoading(false);
  }

  const load = () => {
    setLoading(true);
    let validFilters: IHttpQueryFilter[] = [];
    if (globalFilters?.length) {
      validFilters = [...validFilters, ...(globalFilters || [])];
    }
    if (filters?.length) {
      validFilters = [...validFilters, ...(filters || [])];
    }
    apiClient
      .get(
        validFilters.length > 0 ? validFilters : undefined,
        order ?? undefined,
        undefined,
        undefined,
        page ?? 1,
        pageSize ?? undefined
      )
      .then(onLoad)
  }

  useEffect(() => {
    if (page && pageSize) {
      load();
    }// eslint-disable-next-line
  }, [order, filters, page, pageSize, apiClient.config.tenant])

  return <Table
    columns={columnDefinitions}
    rows={result?.items || []}
    totalRows={result?.totalCount}
    onChangeOrder={onChangeOrder}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    initialPageSize={10}
    pageSizes={[5, 10, 20, 25, 50, 75, 100, 200]}
    pending={loading}
  />;
};

export default React.forwardRef(TableComponent) as
  <T extends IEntity>(p: TableComponentProps<T> & { ref?: Ref<TableComponentRef<T>> }) => ReactElement;