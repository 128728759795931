import { LicenseState } from './state';
import { ActionType, LicenseActions } from './actions';
import _ from 'lodash';

export function configurationReducer(state: LicenseState, action: LicenseActions): LicenseState {
    switch (action.type) {
        case ActionType.SetLicenseInfo:
            return { ...state, info: action.payload };
        default:
            return state;
    }
}