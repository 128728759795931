import { PaymentMethod, PaymentStatus } from "src/api/financial/Payments";

export enum ActionType {
    RequestPaymentMethods,
    RequestPaymentMethodStatuses,
    SetPaymentMethods,
    SetPaymentMethodStatuses,
}

export interface RequestPaymentMethodStatuses {
    type: ActionType.RequestPaymentMethodStatuses;
    payload: string;
}

export interface RequestPaymentMethods {
    type: ActionType.RequestPaymentMethods;
}

export interface SetPaymentMethodsPayload {
    paymentMethods?: PaymentMethod[];
}

export interface SetPaymentMethodStatusesPayload {
    methodId: string;
    statuses?: PaymentStatus[];
}

export interface SetPaymentMethods {
    type: ActionType.SetPaymentMethods;
    payload: SetPaymentMethodsPayload;
}

export interface SetPaymentMethodStatuses {
    type: ActionType.SetPaymentMethodStatuses;
    payload: SetPaymentMethodStatusesPayload;
}

export const requestPaymentMethods = (): RequestPaymentMethods => ({ type: ActionType.RequestPaymentMethods });

export const requestPaymentMethodStatuses = (methodId: string): RequestPaymentMethodStatuses => ({ type: ActionType.RequestPaymentMethodStatuses, payload: methodId });

export const setPaymentMethods = (paymentMethods?: PaymentMethod[]): SetPaymentMethods => ({
    type: ActionType.SetPaymentMethods,
    payload: { paymentMethods },
});

export const setPaymentMethodStatuses = (methodId: string, statuses: PaymentStatus[]): SetPaymentMethodStatuses => ({
    type: ActionType.SetPaymentMethodStatuses,
    payload: { methodId, statuses },
});

export type AccountancyActions = RequestPaymentMethods | RequestPaymentMethodStatuses | SetPaymentMethods | SetPaymentMethodStatuses;