import { useTranslation } from 'react-i18next';
import { PaymentMethod } from 'src/api/financial/Accountancy';
import FormCheckbox from 'src/components/Form/FormCheckbox';
import FormInput from 'src/components/Form/FormInput'
import { TextOptionProps } from 'src/components/Form/FormOptions';
import Select from 'src/components/Form/FormSelect';
import FormSection from 'src/components/Layout/Panel/FormSection';
import useClaim from 'src/hooks/useClaim';
import { IForm } from 'src/hooks/useForm';

export interface GeneralFormComponentProps {
  form: IForm<PaymentMethod>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const isDeveloper = useClaim('Developer');

  const invoicePaymentMethods = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const invoicePaymentMethodsOptions: TextOptionProps[] =
    invoicePaymentMethods.map((i) => ({
      id: String(i),
      value: Number(i),
      label: t(`accountancy.enums.invoicePaymentMethod.${i}`)
    }));


  return (
    <FormSection
      title={t('common.form.general.header')}
      description={t('common.form.general.subheader')}
    >
      {isDeveloper && <div className="mb-10">
        <FormInput.Clean name="id" placeholder="Id" value={form.data.id} readOnly />
      </div>}
      <div className="mb-10">
        <Select {...form.input('invoiceMethod', 'select', { placeholder: t('accountancy.paymentMethods.fields.invoiceMethod') })} options={invoicePaymentMethodsOptions} />
      </div>
      <div className="mb-10">
        <FormCheckbox.Input {...form.input('isEnabled', 'checkbox', { value: 'true', placeholder: t('common.fields.isEnabled') })} aria-description={t('accountancy.paymentMethods.descriptions.isEnabled')} />
      </div>
      <div className="mb-10">
        <span>{t("accountancy.paymentMethods.fields.isPrepaid")}</span>
        <FormCheckbox.Set title={t("accountancy.paymentMethods.fields.isPrepaid")}>
          <FormCheckbox.Input {...form.input('isPrepaid', 'checkbox', { value: "true", placeholder: t('common.bool.yes') })} />
        </FormCheckbox.Set>
      </div>
      <div className="mb-10">
        <span>{t("accountancy.paymentMethods.fields.isAvailableForGuests")}</span>
        <FormCheckbox.Set title={t("accountancy.paymentMethods.fields.isAvailableForGuests")}>
          <FormCheckbox.Input {...form.input('isAvailableForGuests', 'checkbox', { value: "true", placeholder: t('common.bool.yes') })} />
        </FormCheckbox.Set>
      </div>
      <div className="mb-10">
        <span>{t("accountancy.paymentMethods.fields.isAvailableForUsers")}</span>
        <FormCheckbox.Set title={t("accountancy.paymentMethods.fields.isAvailableForUsers")}>
          <FormCheckbox.Input {...form.input('isAvailableForUsers', 'checkbox', { value: "true", placeholder: t('common.bool.yes') })} />
        </FormCheckbox.Set>
      </div>
      <div className="mb-10">
        <span>{t("accountancy.paymentMethods.fields.isAvailableForTrustedUsers")}</span>
        <FormCheckbox.Set title={t("accountancy.paymentMethods.fields.isAvailableForTrustedUsers")}>
          <FormCheckbox.Input {...form.input('isAvailableForTrustedUsers', 'checkbox', { value: "true", placeholder: t('common.bool.yes') })} />
        </FormCheckbox.Set>
      </div>
    </FormSection>
  )
}