import { ChangeEvent, createRef, useEffect, useRef, useState } from 'react'
import { Combobox } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import simulateChangeEvent from './simulateChangeEvent';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { ComboBoxOption, OptionProps, optionText } from './FormOptions';
import { Feedback } from './FormInput';
import { ExtendedSelectHTMLAttributes } from './ExtendedInputHTMLAttributes';

interface ComboBoxComponentProps extends ExtendedSelectHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  options: OptionProps[];
  query?: string;
  onAutoComplete?: (query: string) => void;
}

const ComboBox = (props: ComboBoxComponentProps) => {
  const { id, value, placeholder, options, multiple, onChange, onAutoComplete, disabled, ...otherProps } = props;
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState(props.query ?? "");

  const [val, setVal] = useState<string | undefined>((!multiple && value ? String(value) : undefined) || undefined);
  const [multipleVal, setMultipleVal] = useState<string[] | undefined>(multiple && Array.isArray(value) ? [...value] : []);
  const inputRef = createRef<HTMLInputElement>();
  const description = props["aria-description"] ?? "";

  useEffect(() => {
    if (multiple) return;
    if (val === value) return;
    simulateChangeEvent(inputRef?.current, val);
    if (open) {
      setOpen(false);
    }
  }, [val]);

  useEffect(() => {
    if (!multiple) return;
    simulateChangeEvent(inputRef?.current, multipleVal);
  }, [multipleVal]);

  useEffect(() => {
    if (multiple) {
      setMultipleVal(Array.isArray(value) ? value : [] || []);
    } else {
      if (value !== val) {
        setVal(String(value) || "");
      }
    }
  }, [value]);

  const selectedOption = !multiple && options?.find(o => String(o.value) === val) || undefined;

  useEffect(() => {
    if (!onAutoComplete) return;
    if (query === "" || query !== optionText(selectedOption)) {
      onAutoComplete(query);
    }
  }, [query]);

  const onChangeMiddleware = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange)
      onChange(e);
  }

  const onClickOption = (clickedValue: string) => {
    if (multiple) {
      if (multipleVal?.some(v => v === clickedValue)) {
        setMultipleVal([...multipleVal.filter(v => v !== clickedValue)]);
      } else {
        setMultipleVal([...multipleVal || [], clickedValue]);
      }
    } else {
      setVal(clickedValue);
    }
  }

  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Combobox value={val} onChange={setVal} disabled={disabled}>
        {placeholder && <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 mt-2">{placeholder}</Combobox.Label>}
        {description && <div id="comments-description" className="text-gray-500 text-xs font-normal">{description}</div>}
        <div className="relative mt-2 w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" onClick={() => buttonRef?.current?.click()}>
          <div className="flex">
            {multiple && multipleVal?.length !== 0 && <span className="bg-[--color-primary-600] rounded-md px-2 py-1 text-xs text-white">{multipleVal?.length}</span>}
            {multiple && multipleVal?.length === 0 && <span className="bg-gray-500 rounded-md px-2 py-1 text-xs text-white">0</span>}
            <Combobox.Input
              className="border-none p-0 ml-3 focus:border-none focus:ring-0 w-full"
              onChange={(event) => setQuery(event.target.value)}
              displayValue={() => optionText(selectedOption)}
              autoComplete="off"
              placeholder={placeholder}
            />
          </div>
          <Combobox.Button ref={buttonRef} className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <FontAwesomeIcon icon={open ? faAngleUp : faAngleDown} className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
          <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {options?.map((option, i) => <ComboBoxOption key={i} {...option} onClick={() => onClickOption(option.value)} selected={multiple ? multipleVal?.includes(option.value) : val === option.value} />)}
          </Combobox.Options>
          <input ref={inputRef} id={id} name={id} value={value} onChange={onChangeMiddleware} title={placeholder} className="hidden" />
        </div>
      </Combobox >
      <Feedback {...otherProps} />
    </>
  )
}

export default ComboBox;