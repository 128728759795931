import { Message, MessageTopic } from "src/api/notifications/Notifications";

export interface MessagingState {
    activeTopic?: MessageTopic;
    unread?: number;
    topics: MessageTopic[];
    messages: Message[];
}

export const initialMessagingState: MessagingState = {
    activeTopic: undefined,
    unread: undefined,
    topics: [],
    messages: []
};