import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';

export interface FormFileInputComponentProps {
  onSelectFiles: (files: File[]) => void;
}

const FileDropzone = (props: FormFileInputComponentProps) => {
  const { t } = useTranslation();
  const { onSelectFiles } = props;
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onSelectFiles(acceptedFiles);

  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  });

  return (
    <div
      className="relative block w-full cursor-pointer rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-[--color-primary-500] focus:ring-offset-2"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        stroke="currentColor"
        fill="none"
        viewBox="0 0 48 48"
        aria-hidden="true"
      >
        <FontAwesomeIcon icon={faFileUpload} />
      </svg>
      <span className="mt-2 block text-sm font-medium text-gray-900">
        {t('common.upload.selectFile')}</span>
    </div>
  );
}

export default FileDropzone;