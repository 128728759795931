import _ from 'lodash';
import { Dictionary } from 'lodash';
import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { PaymentStatus, PaymentStatusTranslation } from 'src/api/financial/Accountancy';
import ComboBox from 'src/components/Form/FormComboBox';
import { TextOptionProps } from 'src/components/Form/FormOptions';
import useAccountancyContext from 'src/hooks/stores/useAccountancyContext';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import { requestPaymentMethodStatuses } from 'src/store/accountancy/actions';

export interface PaymentStatusSelectComponentProps {
  onChange: ((id: string | Dictionary<PaymentStatus | undefined>, entity?: PaymentStatus) => void);
  value?: string | string[];
  multiple?: boolean;
  placeholder?: string;
  methodId?: string;
}

const PaymentStatusSelect = (props: PaymentStatusSelectComponentProps) => {
  const { onChange, value, multiple, methodId, ...otherProps } = props;
  const [query, setQuery] = useState<string>('');
  const entityTranslation = useEntityTranslation<PaymentStatus, PaymentStatusTranslation>();
  const [results, setResults] = useState<PaymentStatus[]>([]);
  const autocompleteTimeout = useRef<(NodeJS.Timeout) | undefined>(undefined);
  const { state, dispatch } = useAccountancyContext();

  useEffect(() => {
    applyAutocomplete(query);
  }, [query, state.paymentMethodStatuses.data]);

  useEffect(() => {
    if (!methodId) {
      setResults([]);
      return;
    }
    if (
      !_.has(state.paymentMethodStatuses.data, methodId) &&
      !state.paymentMethodStatuses.isLoading &&
      !state.requestedPaymentMethodStatuses.includes(methodId) &&
      !state.loadedPaymentMethodStatuses.includes(methodId)
    ) {
      loadEntities();
    }
  }, [methodId]);

  const onChangeMiddleware: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValue = e.target.value;
    if (!methodId || !_.has(state.paymentMethodStatuses.data, methodId) || state.paymentMethodStatuses.data[methodId].length === 0) {
      return;
    }
    onChange(newValue, state.paymentMethodStatuses.data[methodId!].find(e => e.id === newValue));
  };
  const onChangeMiddlewareMultiple: ChangeEventHandler<HTMLInputElement> = (e) => {
    const newValues = e.target.value.split(',').filter(v => v.length > 0);
    if (!methodId || !_.has(state.paymentMethodStatuses.data, methodId) || state.paymentMethodStatuses.data[methodId].length === 0) {
      return;
    }
    onChange(
      _.chain(newValues.map(v => ({ id: v, value: state.paymentMethodStatuses.data[methodId!].find(e => e.id === v) })))
        .keyBy('id')
        .mapValues('value')
        .value()
    );
  };
  const loadEntities = () => {
    dispatch(requestPaymentMethodStatuses(methodId!));
  }
  const applyAutocomplete = (query: string) => {
    query = query.toLowerCase();
    if (!methodId || !_.has(state.paymentMethodStatuses.data, methodId) || state.paymentMethodStatuses.data[methodId].length === 0) {
      setResults([]);
      return;
    }
    setResults([...state.paymentMethodStatuses.data[methodId!]].filter(e => entityTranslation.getCurrentTranslation(e)?.title?.toLowerCase().includes(query)));
  };
  const onAutoComplete = (query: string) => {
    if (autocompleteTimeout.current) {
      clearTimeout(autocompleteTimeout.current);
      autocompleteTimeout.current = undefined;
    }
    autocompleteTimeout.current = setTimeout(() => setQuery(query), 250);
  };
  const options = results?.map(r => ({ value: r.id, label: entityTranslation.getCurrentTranslation(r)?.title }) as TextOptionProps);

  return (
    <ComboBox options={options} value={value} onChange={multiple ? onChangeMiddlewareMultiple : onChangeMiddleware} onAutoComplete={onAutoComplete} multiple={multiple} {...otherProps} />
  );
};

export default PaymentStatusSelect;