import { useEffect } from "react";
import { ConfigurationApis, getApiBaseUrl } from "src/config/config";
import useMessagingContext from "src/hooks/stores/useMessagingContext";
import { useSignalRHub } from "use-signalr-hub"
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { MessagesClient } from "src/api/notifications/Notifications";
import { countUnread, setMessages, setTopics, setUnread } from "./actions";
import useToken from "src/hooks/useToken";
import useUser from "src/hooks/useUser";

export default () => {
  const { state, dispatch } = useMessagingContext();
  const apiConfiguration = useApiConfiguration();
  const apiClient = new MessagesClient(apiConfiguration);
  const token = useToken();
  const user = useUser();

  const fetchUnread = () => {
    if (!token) return;
    apiClient.countUnread(0, 0)
      .then(response => {
        dispatch(setUnread(response));
      })
      .catch();
  }

  const fetchMessages = () => {
    if (!token) return;
    if (!state.activeTopic) {
      fetchTopics();
      return;
    }
    apiClient.listTopicMessages(state.activeTopic.id!, 0, 10)
      .then(response => {
        dispatch(setMessages(response.items ?? []));
      })
      .catch();
  }

  const fetchTopics = () => {
    if (!token) return;
    apiClient.listTopics(10, 0)
      .then(response => {
        dispatch(setTopics(response.items ?? []));
      })
      .catch();
  }

  const signalRCallbacks = {
    onCreate: () => {
      fetchTopics();
    },
    onReply: () => {
      fetchMessages();
    }
  };

  const signalRHub = useSignalRHub(`${getApiBaseUrl(ConfigurationApis.Notifications)}/hubs/MessagesHub`, {
    enabled: !!token,
  });

  useEffect(() => {
    if (signalRHub) {
      signalRHub.off("onCreate");
      signalRHub.off("onReply");
      signalRHub.on("onCreate", signalRCallbacks.onCreate)
      signalRHub.on("onReply", signalRCallbacks.onReply)
    }
  }, [signalRHub, state.activeTopic]);

  useEffect(() => {
    if (!state.activeTopic) return;
    fetchMessages();
  }, [state.activeTopic]);

  useEffect(() => {
    dispatch(countUnread(user?.id));
  }, [state.topics, state.activeTopic]);

  useEffect(() => {
    fetchUnread();
    if (token) {
      signalRHub?.start();
    } else {
      signalRHub?.stop();
    }
  }, [token]);

  return <></>;
}
