import useForm, { IForm } from "src/hooks/useForm";
import { WizardStepProps } from "./Index";
import { Dictionary } from "lodash";
import Button from "src/components/Actions/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight, faPlusCircle, faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import FormInput from "src/components/Form/FormInput";
import { Place, PlaceTranslation } from "src/api/stable/Stable";
import FormRequired from "src/components/Form/FormRequired";

export interface WizardStepPlacesForm {
  rows: Place[];
}

export interface WizardStepPlacesProps extends WizardStepProps {
  data: Place[];
  setData: (data: Place[]) => void;
}

export interface PlaceRowProps {
  form: IForm<WizardStepPlacesForm>;
  row: Place;
  index: number;
  onClickDelete: () => void;
}

const PlaceRow = (props: PlaceRowProps) => {
  const { form, index, onClickDelete } = props;

  return (
    <tr>
      <td>
        <FormInput.WithoutLabel
          {...form.input(
            `rows.${index}.translations.0.name`,
            'text', {
            placeholder: 'Padok',
            required: true,
            minLength: 2
          })}
        />
      </td>
      <td>
        <div className="flex">
          <FormInput.WithoutLabel
            {...form.input(
              `rows.${index}.slots`,
              'number', {
              placeholder: '0',
              required: true,
              min: 1
            })}
          />
        </div>
      </td>
      <td className="text-end"><FontAwesomeIcon icon={faTimesCircle} className="text-rose-700 cursor-pointer" onClick={onClickDelete} /></td>
    </tr>
  )
}

export default (props: WizardStepPlacesProps) => {
  const { nextStep, prevStep, data, setData } = props;
  const form = useForm({ rows: data } as WizardStepPlacesForm);
  const onSubmit = () => {
    setData([...form.data.rows]);
    nextStep();
  }
  const validate = (): Dictionary<string[]> => {
    const result = {} as Dictionary<string[]>;
    return result;
  }

  const deleteRow = (index: number) => {
    form.setData({
      ...form.data,
      rows: form.data.rows.filter((_, i) => i !== index)
    } as WizardStepPlacesForm);
  }

  const addRow = () => {
    form.setData({
      ...form.data,
      rows:
        [
          ...form.data.rows,
          {
            slots: 5,
            translations: [{
              culture: 'pl',
              name: '',
              description: ''
            } as PlaceTranslation]
          } as Place
        ]
    } as WizardStepPlacesForm);
  }

  return (
    <form onSubmit={e => form.onSubmit(e, onSubmit, validate)}>
      <p className="text-sm text-gray-600 py-4">
        Skoro mamy już konie, mamy instruktorów, poświęćmy chwilę aby dowiedzieć się gdzie możemy prowadzić treningi.
      </p>
      <table className="w-full mt-8">
        <thead>
          <tr>
            <th className="font-medium text-xs text-start w-8/12">Nazwa miejsca<FormRequired required /></th>
            <th className="font-medium text-xs text-start w-4/12">Limit treningów<FormRequired required /></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {form.data?.rows?.map((row, index) => <PlaceRow key={index} form={form} index={index} row={row} onClickDelete={() => deleteRow(index)} />)}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4} className="text-end pt-4">
              <Button type="button" colorName="emerald" className="text-sm px-5 font-medium" onClick={addRow}>
                <FontAwesomeIcon icon={faPlusCircle} className="mr-3" />
                Dodaj
              </Button>
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="text-xs text-gray-400 py-4">Limit treningów określa ile jazd maksymalnie może odbywać się w tym samym czasie w danym miejscu.</div>
      <div className="flex justify-between border-t border-gray-100 pt-4 mt-8">
        <Button type="button" colorName="gray" className="px-5 py-3" onClick={prevStep}>
          <FontAwesomeIcon icon={faChevronCircleLeft} className="mr-3" />
          Powrót
        </Button>
        <Button type="submit" colorName="primary" className="px-5 py-3" disabled={form.data.rows.length === 0}>
          <FontAwesomeIcon icon={faChevronCircleRight} className="mr-3" />
          Przejdź dalej
        </Button>
      </div>
    </form>
  );
};
