import { useTranslation } from 'react-i18next';
import { Place } from 'src/api/stable/Stable';
import FormInput from 'src/components/Form/FormInput'
import FormSection from 'src/components/Layout/Panel/FormSection';
import useClaim from 'src/hooks/useClaim';
import { IForm } from 'src/hooks/useForm';

export interface GeneralFormComponentProps {
  form: IForm<Place>;
}

export default function GeneralForm(props: GeneralFormComponentProps) {
  const { form } = props;
  const { t } = useTranslation();
  const isDeveloper = useClaim('Developer');

  return (
    <FormSection
      title={t('common.form.general.header')}
      description={t('common.form.general.subheader')}
    >
      {isDeveloper && <div className="mb-10">
        <FormInput.Clean name="id" placeholder="Id" value={form.data.id} readOnly />
      </div>}
      <div className="mb-10">
        <FormInput.Overlapping {...form.input('slots', 'number', { required: true, min: 1, max: 1000, placeholder: t('stable.places.fields.slots'), "aria-description": t('stable.places.descriptions.slots') })} />
      </div>
    </FormSection>
  )
}