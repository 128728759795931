import { useTranslation } from "react-i18next";
import { User } from "src/api/access/Authority"
import Button from "src/components/Actions/Button";
import useClaim from "src/hooks/useClaim";
import { IForm } from "src/hooks/useForm";
import useUser from "src/hooks/useUser";
import { UserForm } from "./Form";
import Avatar from "src/components/User/Avatar";
import { faPlusCircle, faSave, faTimesCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export interface UserHeaderComponentProps {
  user: User;
  onDelete: () => void;
  form: IForm<UserForm>
}

export default (props: UserHeaderComponentProps) => {
  const { user, onDelete, form } = props;

  const { t } = useTranslation();
  const authenticatedUser = useUser();
  const usersUpdateClaim = useClaim('UsersUpdate');
  const usersDeleteClaim = useClaim('UsersDelete');
  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-5 pb-5 border-b border-b-gray-100">
      <div className="flex items-start space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <Avatar userId={user.id} className="object-cover h-16 w-16 rounded-full" />
            <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
          </div>
        </div>
        <div className="pt-1.5">
          <h1 className="text-2xl font-medium text-gray-900">{user.givenName || user.surname ? `${user.givenName} ${user.surname}` : (user.userName || "New user")}</h1>
          <div className="text-sm font-medium text-gray-500">
            {user.email}
            {user.created && <span>{t('common.fields.created')}: {user.created?.toLocaleString()}</span>}
            {user.updated && <span>, {t('common.fields.updated')}: {user.updated?.toLocaleString()}</span>}
          </div>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        {usersUpdateClaim && <Button className="text-md px-5 py-3" colorName="primary" disabled={form.pending}><FontAwesomeIcon icon={form.data.id ? faSave : faPlusCircle} className="mr-1" /> {form.data.id ? t('common.actions.save') : t('common.actions.add')}</Button>}
        {usersDeleteClaim && authenticatedUser?.id !== user.id && user.email !== 'developer@localhost' && <Button className="text-md px-5 py-3" type="button" colorName="red" onClick={onDelete} disabled={form.pending}><FontAwesomeIcon icon={faTimesCircle} className="mr-1" /> {t('common.actions.delete')}</Button>}
      </div>
    </div >
  )
}
