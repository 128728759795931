const getCountryName = (countryCode?: string, displayedLocale?: string): string | undefined => {
  if (!countryCode || !displayedLocale || countryCode === '' || displayedLocale === '') return;

  const countryNameRegionalizer = new Intl.DisplayNames([displayedLocale], { type: 'region' });
  try {
    return countryNameRegionalizer?.of(countryCode.toUpperCase());
  }
  catch (exception) {
    return '';
  }
}

export default getCountryName;