import { Menu, Transition } from "@headlessui/react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from "react"
import { useTranslation } from "react-i18next";
import LocaleName from "src/components/i18n/LocaleName";
import getLocales from "src/components/i18n/getLocales";
import { Dictionary } from "lodash";
import _ from "lodash";
import classNames from "src/components/Utilities/classNames";
import getCountryName from "src/components/i18n/getCountryName";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

export interface CountryFlagsProps {
  code: string;
  className?: string;
}

export const CountryFlag = (props: CountryFlagsProps) => {
  const { className } = props;
  const code = props.code.toLowerCase();
  const { i18n } = useTranslation();
  const alt = getCountryName(code, i18n.resolvedLanguage);
  return (
    <img
      src={`https://flagcdn.com/w80/${code}.webp`}
      width="20"
      alt={alt}
      className={className}
    />
  )
}

export interface LocaleItemProps {
  locale: string;
}

export const getCountryCodeByLocaleCode = (localeCode: string) => {
  localeCode = localeCode.toUpperCase()
  const mappings = {
    EN: "gb",
    UK: "ua"
  } as Dictionary<string>;

  if (_.has(mappings, localeCode)) {
    return mappings[localeCode];
  }

  const countryName = new Intl.DisplayNames(['en'], { type: 'region' });
  const name = countryName.of(localeCode);
  if (localeCode !== name) {
    return localeCode;
  }

  return "";
}

const LocaleItem = (props: LocaleItemProps) => {
  const { locale } = props;
  const { i18n } = useTranslation();

  const onClickSetLanguage = (locale: string) => {
    i18n.changeLanguage(locale);
    window.history.replaceState(null, '', `${window.location.origin}/${locale}${window.location.href.substring(window.location.origin.length + 3)}`);
  }

  return (
    <Menu.Item>
      <a className={classNames(
        'block px-5 py-3 text-sm leading-6  cursor-pointer ',
        locale === i18n?.resolvedLanguage && 'text-white bg-[--color-primary-600]',
        locale !== i18n?.resolvedLanguage && 'text-gray-700 hover:bg-[--color-primary-300] active:bg-[--color-primary-500] hover:text-white',

      )} onClick={() => onClickSetLanguage(locale)}>
        <CountryFlag code={getCountryCodeByLocaleCode(locale)} className="inline h-3 mr-2 shadow-md" /> <LocaleName locale={locale} native /> ({locale})
      </a>
    </Menu.Item>
  )
}

export interface LocaleMenuComponentProps {
  className?: string;
}

const LocaleMenu = (props: LocaleMenuComponentProps) => {
  const { className } = props;
  const { t, i18n } = useTranslation();

  if (getLocales().length <= 1) {
    return <></>;
  }

  return (
    <Menu as="div" className={`relative ${className}`}>
      <Menu.Button className="-m-1.5 flex items-center">
        <span className="sr-only">Open locale menu</span>
        <span className="flex items-center">
          <span className="ml-4 text-sm font-medium leading-6 text-gray-900" aria-hidden="true">
            {i18n?.resolvedLanguage && <><CountryFlag code={getCountryCodeByLocaleCode(i18n?.resolvedLanguage)} className="inline h-3 shadow-md" /></>}
          </span>
          <FontAwesomeIcon icon={faAngleDown} className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          <Menu.Item>
            <span className="block px-5 py-3 text-sm leading-6 text-gray-500 border-gray-200 border-b">{t('ui.language.choose')}:</span>
          </Menu.Item>
          {getLocales().map(l => <LocaleItem key={l} locale={l} />)}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default LocaleMenu;