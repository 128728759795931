import useEntityTranslation from 'src/hooks/useEntityTranslation';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Horse, HorseTranslation, HorsesClient } from 'src/api/stable/Stable';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { HorseImage } from 'src/components/Stable/HorseImage';

import Spinner from 'src/components/Feedback/Spinner';
import SectionHeader from 'src/components/Typography/SectionHeader';
import SectionDescription from 'src/components/Typography/SectionDescription';
import Page from 'src/components/Cms/Page';
import { MetaPageHead } from 'src/components/Meta/MetaHead';

export const HorsesView = () => {
  return (
    <>
      <MetaPageHead id="739b2e83-2d2d-4fdc-90c7-fac9ccd908c7" />
      <Horses />
    </>
  )
}

const Horses = () => {
  const apiConfiguration = useApiConfiguration();
  const horsesClient = new HorsesClient(apiConfiguration);
  const [horses, setHorses] = useState<Horse[] | undefined>(undefined);
  const enityTranslation = useEntityTranslation<Horse, HorseTranslation>();

  useEffect(() => {
    horsesClient
      .get(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      )
      .then(response => setHorses(response.items ?? []));
  }, []);

  return (
    <>
      <div className="py-24 sm:py-32 bg-[--color-primary-900]">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <SectionHeader white><Page id="739b2e83-2d2d-4fdc-90c7-fac9ccd908c7" title raw /></SectionHeader>
            <SectionDescription color><Page id="739b2e83-2d2d-4fdc-90c7-fac9ccd908c7" content raw /></SectionDescription>
          </div>
          {typeof horses === 'undefined' && <Spinner className="mx-auto h-8" />}
          <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-3 pt-8">
            {horses?.map((horse, id) => <div key={id} className="inline bg-white overflow-hidden rounded-2xl">
              <HorseImage id={horse.id!} className="aspect-[4/3] w-full flex-none object-cover mx-auto mb-6" />
              <div className="px-6 pb-6">
                <h3 className="text-xl text-center">{enityTranslation.getCurrentTranslation(horse)?.name}</h3>
              </div>
            </div>)}
          </div>
        </div>
      </div >
    </>
  );
}

export default Horses;