import { HTMLAttributes, useEffect, useState } from "react"
import classNames from "../Utilities/classNames";

export interface ImageComponentProps extends HTMLAttributes<HTMLDivElement> {
  src: string;
  fallbackSrc?: string;
}

export default (props: ImageComponentProps) => {
  const { src, fallbackSrc, title, className, ...otherProps } = props;
  const [fallback, setFallback] = useState(false);
  useEffect(() => {
    setFallback(false);
  }, [src]);
  const onImageError = () => setFallback(true);
  return (
    <div className={classNames([className, 'overflow-hidden'])} {...otherProps}>
      <img src={fallback ? fallbackSrc : src} alt={title} title={title} onError={onImageError} className="h-full w-full object-cover" />
    </div>
  );
}