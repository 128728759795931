import { ButtonHTMLAttributes } from "react";
import classNames from "../Utilities/classNames";

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  colorName?: string;
}

export default (props: IButtonProps) => {
  const { children, className, colorName, disabled, ...otherProps } = props;
  const cn = colorName || 'slate';

  if (cn === 'primary') {
    return (
      <button
        className={classNames(
          `items-center gap-x-1.5 ${className?.includes('rounded') ? "" : "rounded-md"} ${className?.includes('px-') ? "" : "px-2"} ${className?.includes('py-') ? "" : "py-2"} text-sm font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 text-white shadow-sm transition-colors duration-500`,
          disabled ? `bg-[--color-${cn}-400] hover:bg-[--color-${cn}-300] focus-visible:outline-[--color-${cn}-400]` : `bg-[--color-${cn}-600] hover:bg-[--color-${cn}-500] focus-visible:outline-[--color-${cn}-600]`,
          className
        )}
        disabled={disabled}
        {...otherProps}
      >
        {children}
      </button>
    )
  } else {
    return (
      <button
        className={classNames(
          `items-center gap-x-1.5 ${className?.includes('rounded') ? "" : "rounded-md"} ${className?.includes('px-') ? "" : "px-2"} ${className?.includes('py-') ? "" : "py-2"} text-sm font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 text-white shadow-sm transition-colors duration-500`,
          disabled ? `bg-${cn}-400 hover:bg-${cn}-300 focus-visible:outline-${cn}-400` : `bg-${cn}-600 hover:bg-${cn}-500 focus-visible:outline-${cn}-600`,
          className
        )}
        disabled={disabled}
        {...otherProps}
      >
        {children}
      </button>
    )
  }
}