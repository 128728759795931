import moment from "moment";
import Button from "../Actions/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

export interface CalendarWeekControllerProps {
  currentDate: Date;
  onChangeDate: (date: Date) => void;
}

export default (props: CalendarWeekControllerProps) => {
  const { currentDate, onChangeDate } = props;
  const { t } = useTranslation();

  const handlePrevWeek = () => {
    onChangeDate(moment(currentDate).subtract(1, 'week').toDate());
  }

  const handleCurrentWeek = () => {
    onChangeDate(moment().startOf('isoWeek').toDate());
  }

  const handleNextWeek = () => {
    onChangeDate(moment(currentDate).add(1, 'week').toDate());
  }

  return (
    <>
      <div className="flex">
        <Button type="button" colorName="primary" onClick={handlePrevWeek} className="rounded-r-none border-r border-r-white"><FontAwesomeIcon icon={faChevronLeft} className="h-4" /></Button>
        <Button type="button" colorName="primary" onClick={handleCurrentWeek} className="rounded-none">{t('ui.calendar.currentWeek')}</Button>
        <Button type="button" colorName="primary" onClick={handleNextWeek} className="rounded-l-none border-l border-l-white"><FontAwesomeIcon icon={faChevronRight} className="h-4" /></Button>
      </div>
    </>
  );
}