import { ApplicationState } from './state';
import { ActionType, ApplicationActions } from './actions';

export function applicationReducer(state: ApplicationState, action: ApplicationActions): ApplicationState {
    switch (action.type) {
        case ActionType.SetToken:
            return { ...state, token: action.payload };
        case ActionType.SetUser:
            return { ...state, user: action.payload };
        case ActionType.SetTenant:
            return { ...state, tenant: action.payload };
        case ActionType.ClearToken:
            return { ...state, token: undefined };
        case ActionType.ClearUser:
            return { ...state, user: undefined };
        case ActionType.ClearTenant:
            return { ...state, tenant: undefined };
        case ActionType.SetOnline:
            return { ...state, online: true };
        case ActionType.SetOffline:
            return { ...state, online: false };
        case ActionType.SetBreadcrumbs:
            return { ...state, breadcrumbs: action.payload };
        default:
            return state;
    }
}