import { ConfigurationApis, getApiBaseUrl } from "src/config/config";
import useTenant from "src/hooks/useTenant";
import Image from "src/components/Media/Image";

export interface TrainingTypeImageComponentProps {
  id: string;
  className?: string;
}

export const TrainingTypeImage = (props: TrainingTypeImageComponentProps) => {
  const { id, className } = props;
  const tenant = useTenant();
  const photoUrl = `${getApiBaseUrl(ConfigurationApis.Stable)}/api/v1/training-types/${id}/photo?XTenantId=${tenant}`;
  return (
    <Image src={photoUrl} fallbackSrc='/img/default-horse.webp' className={className} />
  );
}
