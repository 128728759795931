import _ from "lodash";
import { HttpQueryFilter } from "src/api/cms/Cms";
import PagesTable from "../../Cms/Pages/Table";
import FormSwitch from "src/components/Form/FormSwitch";
import { useTranslation } from "react-i18next";
import useConfigurationContext from "src/hooks/useConfigurationContext";
import { useState } from "react";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import { Settings, SettingsClient } from "src/api/core/Core";
import Toast from "src/components/Feedback/Toast";
import { setConfiguration } from "src/store/configuration/actions";
import Button from "src/components/Actions/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/pro-duotone-svg-icons";


const DocumentsSettingsSection = () => {
  const { t } = useTranslation();
  const apiConfiguration = useApiConfiguration();
  const { state, dispatch } = useConfigurationContext();
  const [loading, setLoading] = useState(false);
  const [_error, setError] = useState(false);
  const apiClient = new SettingsClient(apiConfiguration);

  const onSaveSuccess = () => {
    Toast.success(t("common.status.success"), t("common.feedback.saved"));
  }

  const onSaveFailure = () => {
    setError(true);
    Toast.error(t("common.status.error"), t("common.errors.500"));
  }

  const onSave = () => {
    setLoading(true);
    setError(false);
    apiClient.set('booking', undefined, { name: 'booking', value: state.booking } as Settings)
      .catch(onSaveFailure)
      .then(onSaveSuccess)
      .finally(() => setLoading(false));
  }

  return (
    <>
      <dl className="my-6 space-y-6 divide-y divide-gray-50 border-y border-gray-100 text-sm leading-6">
        <div className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 hidden md:block sm:flex-none sm:pr-6 pl-6">
            {t('panel.settings.booking.header')}
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto pb-6">
            <div>
              <FormSwitch.Decorated
                name="switch"
                checked={state.booking.requireEquestrianCentreAgreements}
                onChange={(e) => dispatch(setConfiguration('booking.requireEquestrianCentreAgreements', e.target.checked))}
                placeholder={t('panel.settings.booking.requireEquestrianCentreAgreements.title')}
                aria-description={t('panel.settings.booking.requireEquestrianCentreAgreements.description')}
              />
            </div>
          </dd>
        </div>
      </dl>
      <div className="flex justify-end my-8">
        <Button colorName="primary" onClick={onSave} disabled={loading}>
          <FontAwesomeIcon icon={faSave} className="h-5" /> {t('common.actions.save')}
        </Button>
      </div>
      <PagesTable filters={[{ property: 'id', value: '135bb7ab-e24b-4cb3-92ab-0f7b1d057717', type: '=' } as HttpQueryFilter]} noHeader noActions />
    </>
  );
}

export default DocumentsSettingsSection;