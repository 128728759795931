import { useEffect, useState } from "react";
import { ClientPagesClient, Content, ContentTranslation } from "src/api/cms/Cms";
import useApiConfiguration from "src/hooks/useApiConfiguration";
import Spinner from "../Feedback/Spinner";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import ContentParse from "./ContentParse";

export interface PageComponentProps {
  id?: string;
  url?: string;
  title?: boolean;
  content?: boolean;
  raw?: boolean;
}

export interface PageElementComponentProps {
  page: Content;
}

const PageHeader = (props: PageElementComponentProps) => {
  const { page } = props;
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(page);
  return (
    <div className="border-b border-gray-200 pb-5 mt-12 mb-5">
      <h3 className="text-2xl font-medium leading-6 text-gray-900">{translation?.title}</h3>
    </div>
  );
}

const PageContent = (props: PageElementComponentProps) => {
  const { page } = props;
  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(page);

  return <div style={{ all: 'unset' }}><ContentParse>{translation?.content}</ContentParse></div>;
}

const Page = (props: PageComponentProps) => {
  const { id, url, title, content, raw } = props;
  const apiConfiguration = useApiConfiguration();
  const apiClient = new ClientPagesClient(apiConfiguration);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<Content | undefined>(undefined);

  const entityTranslation = useEntityTranslation<Content, ContentTranslation>();
  const translation = entityTranslation.getCurrentTranslation(page);

  useEffect(() => {
    if (id) {
      setLoading(true);
      apiClient.findById(id)
        .then(response => setPage(response))
        .catch(console.error)
        .finally(() => setLoading(false));
    } else if (url) {
      setLoading(true);
      apiClient.findByUrl(url)
        .then(response => setPage(response))
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [id, url])

  if (!id && !url) {
    return <><span>You must reference exactly one identifier to find.</span></>
  }
  if (!title && !content) {
    return <><span>You must select at least one thing to render.</span></>
  }

  if (loading) {
    return <Spinner className="mx-auto h-4" />;
  }

  if (!page) {
    return <><span>Page not found.</span></>;
  }

  if (title && content) {
    if (raw) {
      return <><span>Raw page title and content is not available.</span></>
    } else {
      return (
        <>
          <PageHeader page={page} />
          <PageContent page={page} />
        </>
      );
    }
  } else if (title) {
    if (raw) {
      return <>{translation?.title?.replace(/<[^>]+>/g, '') ?? ""}</>;
    } else {
      return <PageHeader page={page} />;
    }
  } else if (content) {
    if (raw) {
      return <>{translation?.content?.replace(/<[^>]+>/g, '') ?? ""}</>;
    } else {
      return <PageContent page={page} />;
    }
  }
  return <><span>Cannot render.</span></>
}

export default Page;